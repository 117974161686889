<app-headers></app-headers>
<section class="text-center mb-2 settingTabs">
    <app-user-breadcrumb></app-user-breadcrumb>
</section>
<section class="mb-5 profileView">
    <div class="container">
        <div class="row">
            <div class="col-md-5 col-lg-3">
                <app-user-left-menu></app-user-left-menu>
            </div>
            <div class="col-md-7 col-lg-9">
                <div class="rightbox-dashbord">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                            <h4 class="list-heading">Settings</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="redeemtopbox">
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                                        <label class="block">Cryptocurrency</label>
                                        <select class="cate-setting" [(ngModel)]="profileObj.currency_code" (change)="doUpdateCurrency();">
                                            <option>Default Crypto</option>
                                            <option *ngFor="let method of CyrptCurrency;" value="{{method.symbol}}">{{method.code}}</option>
                                        </select>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                                        <label class="block">Language</label>
                                        <select class="cate-setting">
                                            <option>English</option>
                                        </select>
                                    </div>
                                    <div class="col-md-6">
                                        <a (click)="goToNavigation('user/address');" class="waves-effect waves-light add-addressnew">+ Add Address</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <div class="redeemtopbox">
                                <form [formGroup]="userProfileForm">
                                    <div class="row save-addreform">
                                        <div class="col-12 col-sm-6 col-md-12 col-lg-6">
                                            <div class="md-form">
                                                <input class="form-control" type="email" id="e-mail" name="email" required="required" formControlName="email" [ngClass]="{ 'is-invalid': profileFormObj.isFormSubmit &&  profileget?.email?.errors }"/>
                                                <label for="e-mail" class="active">Email *</label>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                            <div class="md-form">
                                                <input class="form-control" id="f-name" type="text" name="full_name" required="required" formControlName="full_name" [ngClass]="{ 'is-invalid': profileFormObj.isFormSubmit &&  profileget?.full_name?.errors }"/>
                                                <label for="f-name" class="active">Full Name *</label>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                            
											<div class="md-form">
                                                <textarea id="form7" class="md-textarea form-control billing-textare" formControlName="about_info" rows="2"></textarea>
                                                <label for="form7" class="active">About Info</label>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" [disabled]="isLoading" class="joinnow-btn waves-effect waves-light mt-1" (click)="updateProfile(userProfileForm.value)"><i class="fas fa-save"></i> Update Profile</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <h5 class="fontbold mb-0">Security</h5>
                            <h6 class="mb-3 fontbold">Change Password</h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="redeemtopbox">
                                <div class="row">
                                    <div class="col-md-12 col-lg-8">
                                        <label for="text" class="mb-0">Email</label>
                                        <div class="md-form searchbox coplinkbtn mt-0">
                                            <input class="form-control" type="text" name="email" [(ngModel)]="userEmail" disabled />
                                            <button type="button" (click)="sendForgotLink();" [disabled]="resetPasswordForm" class="reset-btn waves-effect waves-light">Reset Password</button>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-lg-8" *ngIf="resetPasswordForm">
                                        <form [formGroup]="updatePassword">
                                            <div class="row save-addreform">
                                                <div class="col-12 col-sm-6 col-md-12 col-lg-6">
                                                    <div class="md-form">
                                                        <input type="password" class="form-control" name="forgot_code" id="forgot_code" required="required" formControlName="forgot_code"  [ngClass]="{ 'is-invalid': resetPasswordFormSubmit &&  pwdget?.forgot_code?.errors }"/>
                                                        <label for="forgot_code" class="active">Reset Code*</label>
                                                        <span class="eyeview show_forgot_code" (click)="showPassword('forgot_code')"><i class="fas fa-eye"></i></span>
                                                        <span class="eyeview hide hide_forgot_code" (click)="hidePassword('forgot_code')"><i class="fas fa-eye-slash"></i></span>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div class="md-form">
                                                        <input class="form-control" type="password" name="password" id="password" required="required" formControlName="password" [ngClass]="{ 'is-invalid': resetPasswordFormSubmit &&  pwdget?.password?.errors }"/>
                                                        <label for="password" class="active">New Password *</label>
                                                        <span class="eyeview show_password" (click)="showPassword('password')"><i class="fas fa-eye"></i></span>
                                                        <span class="eyeview hide hide_password" (click)="hidePassword('password')"><i class="fas fa-eye-slash"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="submit" [disabled]="isLoading" class="joinnow-btn waves-effect waves-light mt-1" (click)="resetPassword(updatePassword.value)">Change Password</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12" *ngIf="!profileObj.email_validate">
                            <p class="tag-predeem1" *ngIf="!otpConfirmForm">
                                <span class="mr-1"><img src="/assets/img/stopicon.png" /></span>
                                Your email has not been verified yet.
                                <a (click)="sendVerificationLink()" class="leanmore">Send Link</a>
                            </p>
                            <p class="tag-predeem1" *ngIf="otpConfirmForm">
                                Otp Sent to your email
                                <input class="form-control" style="width: 150px;" name="forgot_code" [(ngModel)]="verificationCode"/>
                                <a (click)="confirmVerification()" class="leanmore">Confirm</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>