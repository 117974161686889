import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoreService } from '../shared/services/core.service';
import { HelperService } from '../shared/services/helper-service.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
//import { METHODS } from 'src/app/shared/constants/app.paymentmethods.constants';
import { COUNTRY } from 'src/app/shared/constants/app.country.constants';
import { CRYPTOS } from 'src/app/shared/constants/app.cryptos.constants';
import { environment } from 'src/environments/environment';
import {ToastrService} from 'ngx-toastr';
declare var $: any;
declare var document: any;

@Component({
  selector: 'app-cryptos',
  templateUrl: './cryptos.component.html',
  styleUrls: ['./cryptos.component.css']
})
export class CryptosComponent implements OnInit {
  public imgBaseUrl = environment.AWSImageURL;
  public webUrl = environment.WEB_APP;
  public isLoadingData:boolean = true;
  public lang:any = '';
  public usd_value:any = 1;
  public crypto_value:any = 0;
  public productList:any = {};
  public buySellList:any = [];
  public paymentMethodsLists:any = [
    'Bank Transfer',
    "Cash",
    "Paypal",
    'WesternUnion',
    'Money Gram',
    'Alipay',
    'WeChat',
    'E-Wallet',
    'Mobile Payments',
    'Cryptocurrencies',
    'Others'
  ];
  public countryLists:any = COUNTRY;
  public cryptosLists:any = CRYPTOS;
  public otcBuyFormRequest!: FormGroup;
  public otcSellFormRequest!: FormGroup;
  public otcFormReply!: FormGroup;
  public otcBuyFormStatus:any = {
    isLoading : false,
    isFormSubmit : false,
  }

  public otcSellFormStatus:any = {
    isLoading : false,
    isFormSubmit : false,
  }

  public otcFormStatus:any = {
    isLoading : false,
    isFormSubmit : false,
  }
  public cryptoPrice:any = {};
  constructor(
    private fb: FormBuilder,
    private coreService: CoreService,
    private toast: ToastrService,
    public helperService: HelperService
  ) { }

  ngOnInit(): void {
    this.otcBuyFormRequest = this.fb.group({
      request_type: ['buy', [Validators.required]],
      coin_amount: ['', [Validators.required]],
      crypto_method: ['Bitcoin (BTC)', [Validators.required]],
      payment_method: ['Bank Transfer', [Validators.required]],
      country: ['Afghanistan', [Validators.required]],
      contect_info: ['', [Validators.required]]
    });

    this.otcSellFormRequest = this.fb.group({
      request_type: ['sell', [Validators.required]],
      coin_amount: ['', [Validators.required]],
      crypto_method: ['Bitcoin (BTC)', [Validators.required]],
      payment_method: ['Bank Transfer', [Validators.required]],
      country: ['Afghanistan', [Validators.required]],
      contect_info: ['', [Validators.required]]
    });

    this.otcFormReply = this.fb.group({
      otc_id: ['', [Validators.required]],
      full_name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      contact: [],
      message: []
    });

    this.getRecommendationProduct();
    this.coreService.get(`${ENDPOINTS.SOLUTION.OTC_BUY_SELL_LIST}`, {}).subscribe((res) => {
      this.buySellList = res.body.data;
      let that = this;
      setTimeout(function(){
        let langCode = (localStorage.getItem("lang_direction")!='' && localStorage.getItem("lang_direction")!=null)?localStorage.getItem("lang_direction"):'ltr';
        $(".buysellregular").slick({
          dots: true,
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 3,
          rtl: (langCode=='rtl')?true:false,
          responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
              // You can unslick at a given breakpoint now by adding:
              // settings: "unslick"
              // instead of a settings object
            ] 
        });
        that.isLoadingData = false;
      },200);
    }, (err) => {      
      this.isLoadingData = false;
    });
  }

  get getbuy() { return this.otcBuyFormRequest.controls; }
  get getsell() { return this.otcSellFormRequest.controls; }
  get getreply() { return this.otcFormReply.controls; }

  viewProducts(obj:any){
    if(obj=='all'){
      $(".filter").show("1000");
    }else{
      $(".filter")
          .not(".filterclass_" + obj)
          .hide("1000");
      $(".filter")
          .filter(".filterclass_" + obj)
          .show("1000");
    }
    $(".filter-button").removeClass("active");
    $("#filter_"+obj).addClass("active");
  }

  getRecommendationProduct(){
    this.coreService.get(`${ENDPOINTS.SOLUTION.RECOMMENDATION}`+'?type=blockchain', {}).subscribe((res) => {
      if (res.body.success) {
        this.productList = res.body.data;
        if(this.productList.category.length>0){
          let that = this;
          setTimeout(function(){
            that.viewProducts(that.productList.category[0].id);
          },1000);          
        }
      } else {
        this.productList = [];
      }
    }, (err) => {
    });
  }

  moveToAvailableOrders(){
    document.getElementById('available_orders').scrollIntoView();
  }

  goToProductDetailPage(obj:any) {
    //this.helperService.navigate(`/shop-detail/${obj.product_slug}`, this.lang);
    window.location.href = '/shop-detail/'+obj.product_slug;
  }

  /**
   * @method: otcBuyFormRequest
   * @description: using for to submit Buy Enq.
   * @param formData
   */
   submitCryptosEnq(formData:any) {
    this.otcBuyFormStatus.isFormSubmit = true;
    if (this.otcBuyFormRequest.valid && !this.otcBuyFormStatus.isLoading) {
      this.otcBuyFormStatus.isLoading = true;
      const paylaod = {
        ...formData
      }
      this.coreService.post(`${ENDPOINTS.SOLUTION.OTC_REQUEST}`, paylaod).subscribe((res) => {
        this.otcBuyFormStatus.isLoading = false;
        this.otcBuyFormStatus.isFormSubmit = false;
        if(res.body.success){
          this.toast.success(res.body.message, "Success!");
          this.otcBuyFormRequest.reset();
          this.otcBuyFormRequest.patchValue({
            'request_type': 'buy',
            'coin_amount' : '',
            'crypto_method' : 'Bitcoin (BTC)',
            'payment_method' : 'Bank Transfer',
            'country' : 'Afghanistan',
            'contect_info' : ''
          });
        }else{
          this.toast.error(res.body.message, "Error!");
        }
      }, (err) => {
        this.otcBuyFormStatus.isLoading = false;
        this.toast.error(err.body.message, "Error!");
      });
    }
  }

  /**
   * @method: submitCryptosSellEnq
   * @description: using for to submit Sell Enq
   * @param formData
   */
   submitCryptosSellEnq(formData:any) {
    this.otcSellFormStatus.isFormSubmit = true;
    if (this.otcSellFormRequest.valid && !this.otcSellFormStatus.isLoading) {
      this.otcSellFormStatus.isLoading = true;
      const paylaod = {
        ...formData
      }
      this.coreService.post(`${ENDPOINTS.SOLUTION.OTC_REQUEST}`, paylaod).subscribe((res) => {
        this.otcSellFormStatus.isFormSubmit = false;
        this.otcSellFormStatus.isLoading = false;
        if(res.body.success){
          this.toast.success(res.body.message, "Success!");
          this.otcSellFormRequest.reset();
          this.otcSellFormRequest.patchValue({
            'request_type': 'sell',
            'coin_amount' : '',
            'crypto_method' : 'Bitcoin (BTC)',
            'payment_method' : 'Bank Transfer',
            'country' : 'Afghanistan',
            'contect_info' : ''
          });
        }else{
          this.toast.error(res.body.message, "Error!");
        }
      }, (err) => {
        this.toast.error(err.body.message, "Error!");
      });
    }
  }

  doReply(obj:any){
    this.otcFormReply.patchValue({
      'otc_id': obj.id
    });
    $("#exampleModal").modal('show');
  }

  submitOtcReply(formData:any) {
    this.otcFormStatus.isFormSubmit = true;
    if (this.otcFormReply.valid && !this.otcFormStatus.isLoading) {
      this.otcFormStatus.isLoading = true;
      this.otcFormStatus.isFormSubmit = false;
      const paylaod = {
        ...formData
      }
      this.coreService.post(`${ENDPOINTS.SOLUTION.OTC_REPLY}`, paylaod).subscribe((res) => {
        this.otcFormStatus.isLoading = false;
        if(res.body.success){
          this.toast.success(res.body.message, "Success!");
          this.otcFormReply.reset();
          this.otcFormReply.patchValue({
            'otc_id': formData.otc_id
          });
          $("#exampleModal").modal('hide');
        }else{
          this.toast.error(res.body.message, "Error!");
        }
      }, (err) => {
        this.otcFormStatus.isLoading = false;
        this.toast.error(err.body.message, "Error!");
      });
    }
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang)
  }
}