import { Component, OnInit } from '@angular/core';
import { CoreService } from '../shared/services/core.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelperService } from '../shared/services/helper-service.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { TIMEZONES } from 'src/app/shared/constants/app.timezone.constants';
import { environment } from 'src/environments/environment';
import {ToastrService} from 'ngx-toastr';
declare var $: any;
@Component({
  selector: 'app-gift-designer',
  templateUrl: './gift-designer.component.html',
  styleUrls: ['./gift-designer.component.css']
})
export class GiftDesignerComponent implements OnInit {
  public timeZones:any = TIMEZONES;
  public lang:any = '';
  public designList:any = [];
  public designForm!: FormGroup;
  public showDetail:any = false;
  public isFormSubmit:boolean = false;
  public isLoading:boolean = true;
  public imgBaseUrl = environment.AWSImageURL;
  public minDate:any = '';
  constructor(
    private toast: ToastrService,
    private fb: FormBuilder,
    private coreService: CoreService,
    public helperService: HelperService
  ) { }

  ngOnInit(): void {
    var dtToday = new Date();

    let tomorrow =  new Date()
    tomorrow.setDate(dtToday.getDate() + 1)

    var month:any = tomorrow.getMonth() + 1;
    var day:any = tomorrow.getDate();
    var year = tomorrow.getFullYear();

    if(month < 10)
        month = '0' + month.toString();
    if(day < 10)
        day = '0' + day.toString();

    this.minDate = year + '-' + month + '-' + day;

    this.designForm = this.fb.group({
      image: ['', [Validators.required]],
      to: ['', [Validators.required]],
      from: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      message: ['', [Validators.required]],
      brand: ['', [Validators.required]],
      code: ['', [Validators.required]],
      category_name: ['', [Validators.required]],
      generate_type: ['instant'],
      view_type: ['pdf'],
      schedule_date: [''],
      schedule_time: ['00:00'],
      schedule_timezone: [''],
      is_happy: ['0']
    });
    this.showDetail = false;
    this.coreService.get(`${ENDPOINTS.GIFT_CARD.VOUCHER_DESIGN}`, {}).subscribe((res) => {
      if (res.body.success) {
        this.designList = res.body.data;
        if(this.designList.length>0){
          let that = this;
          setTimeout(function(){
            that.viewProducts(that.designList[0]);
             $(".gift-slider").slick({
                dots: false,
                infinite: true,
                speed: 300,
                slidesToShow: 8,
                centerMode: false,
                slidesToScroll: 8,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 5,
                            slidesToScroll: 5,
                            infinite: true,
                            dots: true,
                        },
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        },
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 2,
                            centerMode: true,
                            centerPadding: "0",
                            slidesToScroll: 2,
                        },
                    },
                ],
            });
            that.isLoading = false;
          },2000);
        }
      } else {
        this.designList = [];
      }
    }, (err) => {
    });
  }
   get get() { return this.designForm.controls; }

  selectImage(obj:any,img:string){
    this.designForm.patchValue({
      category_name: obj?.category_name,
      message: obj?.category_message,
      image: img,
      is_happy : (obj.allow_happy)?'1':'0'
    }); 
  }
  
  showPreview(){
      let formData = this.designForm.value;
      formData.preview_type = 'view';
      formData.type = 'download';
      this.isFormSubmit = true;
      if(formData.image==''){
        this.toast.error("Please select image first.", "Error!");
      }
      if (this.designForm.valid && !this.isLoading) {
        this.isLoading = true;
        const paylaod = {
          ...formData
        }
        let params = new URLSearchParams();
        for(let key in paylaod){
            params.set(key, paylaod[key]);
        }
        this.isFormSubmit = false;
        this.isLoading = false;
        window.open(
          environment.API_ENDPOINT + 'download/gift?'+params.toString(),
          '_blank' // <- This is what makes it open in a new window.
        );
      }
  }
  
  changeInstanttype(buttonType:any){
    if(buttonType=='schedule'){
      $("#poldg").show();
    }else{
      $("#poldg").hide();
    }
  }

    submitDesignForm(formData:any) {
      this.isFormSubmit = true;
      if(formData.image==''){
        this.toast.error("Please select image first.", "Error!");
      }
      if (this.designForm.valid && !this.isLoading) {
        formData.preview_type = 'download';
        formData.type = 'email';
        this.isLoading = true;
        const paylaod = {
          ...formData
        }
        let params = new URLSearchParams();
        for(let key in paylaod){
            params.set(key, paylaod[key]);
        }
        // this.isFormSubmit = false;
        // this.isLoading = false;
        // window.location.href = environment.API_ENDPOINT + 'download/gift?'+params.toString();
        // this.designForm.reset();
        this.coreService.get('download/gift?'+params.toString(), {}).subscribe((res) => {
          if (res.body.success) {
            this.toast.success(res.body.message, "Success!");
            this.isFormSubmit = false;
            this.isLoading = false;
            this.designForm.reset();
            this.designForm.patchValue({
              category_name: formData?.category_name,
              message: formData?.category_message,
              image: formData.image,
              is_happy : '0',
              view_type : 'pdf',
              generate_type : 'instant',
              schedule_time : '00:00',
              schedule_timezone : '',
              schedule_date : ''
            }); 
            $("#poldg").hide();
          } else {
            this.isLoading = false;
            this.toast.error(res.body.message, "Error!");
          }
        }, (err) => {
        });
      }else{
        this.toast.error("Please fill all required and valid information!", "Error!");
      }
    }

  viewProducts(obj:any){
    this.designForm.patchValue({
      category_name: obj?.category_name,
      message: obj?.category_message
    });     
    $(".filter").not(".filterclass_" + obj.id).hide("1000");
    $(".filter").filter(".filterclass_" + obj.id).show("1000");
    $(".design-title").removeClass("slick-active");
    $("#filter_"+obj.id).addClass("slick-active");
  }
}