<app-header [pageName]="'homepage'"></app-header>
<section class="view hm-gradient" id="intro">
    <div class="site-bg-img d-flex align-items-center">
        <div class="container">
            <div class="row no-gutters d-flex align-items-center justify-content-center mobilepad">
                <div class="col-md-12 col-lg-9 text-center text-md-center margins">
                    <div class="white-text padd-top170">
                        <div>
                            <h2 class="h1-responsive font-weight-bold mt-sm-5">Buy Electronics, Gift Cards,  <br> and Phone Top-ups With 
							  <br> 
							</h2>
                            <div class="output newchangw" >
                                <h1 class="">Bitcoin and other Cryptos</h1>
                            </div>
                            <div class="h6 mt-3">
                               Easy, fast, and secure payments with over 60 different cryptocurrencies
                            </div>
                        </div>
                        <div class="header-search">
                            <form autocomplete="off" (submit)="goToShopPage()">
                                <div class="md-form">
                                    <button type="button" class="search-btn"><img src="/assets/img/search-icon.png"  (click)="goToShopPage()"/></button>
                                    <input 
                                    autofill="off"
                                    autocomplete="new-password"
                                    class="header-input" 
                                    id="searchhome"
                                    [(ngModel)]="product_name"
                                    [typeahead]="productnames"
                                    (typeaheadLoading)="changeTypeaheadLoading($event)"
                                    (ngModelChange)="filterResults()"
                                    (typeaheadOnSelect)="typeaheadOnSelect($event)"
                                    [typeaheadOptionsLimit]="6"
                                    typeaheadOptionField="product_name"
                                    placeholder="START SHOPPING"
                                    type="search" 
                                    [typeaheadItemTemplate]="rt"
                                    name="hidden" 
                                    appAutocompleteOff
                                    />
                                    <ng-template #rt let-model="item" let-index="index">
                                        <img src="{{imgBaseUrl}}products/{{model.product_image}}" style="width: 50px;border-radius: 25px;height: 50px;border: 1px solid rgb(221, 221, 221);float: left;margin-right: 15px;position: unset;right: unset;top: unset;"/>
                                        <span style="float: left;padding-top: 12px;width: 142px;overflow: hidden;text-overflow: ellipsis;-webkit-line-clamp: 5;-webkit-box-orient: vertical;">{{model.product_name}}</span>
                                    </ng-template>
                                    <label for="searchhome" class="">Search +5000 Products</label>
                                </div>
                            </form>
                        </div>
                        <div>
                            <!-- <a *ngIf="typeaheadLoading" class="btn btn-outline-white start-btn" href="javascript:;" (click)="goToShopPage()">Searching...</a> -->
                            <a class="btn btn-outline-white start-btn" href="javascript:;" (click)="goToShopPage()">Start Shopping</a>
                        </div>
                        <div>
                            <a class="font-weight-bold ml-0 watch-video" data-toggle="modal" data-target="#watchvideo" data-controls-modal="watchvideo" data-backdrop="static" data-keyboard="false">
                                <i class="watch-icon mr-1"><img src="/assets/img/playbtn.png" /></i> Watch Video
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="text-center py-4" id="shopnew">
    <div class="container">
        <div class="row d-flex align-items-center justify-content-center">
            <div class="title-heading">
                <h2 class="h1 pt-5 pb-3">What You Can Do</h2>
            </div>
        </div>
        <div class="row h-second-fold">
            <div class="col-12 col-md-4 col-sm-12 col-lg-4 mb-5">
                <div class="cando">
                    <i class="fa-3x"><img src="/assets/img/new-electronics.webp" class="f1im" /></i>
                    <h3 class="h4 mt-3">Electronics</h3>
                    <div class="line-title"></div>
                    <p class="mt-3 blue-grey-text">
                        Buy mobile phones, computers, wearables, and other items with your cryptos and have them delivered to your door.
                    </p>
                    <div>
                        <a class="btn btn-outline-white buynow-btn" (click)="goToNavigation('shop');">Discover Products</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 col-sm-12 col-lg-4 mb-5 mt-7">
                <div class="cando">
                    <i class="fa-3x"><img src="/assets/img/new-gift card.webp" /></i>
                    <h3 class="h4 mt-3">Gift Cards</h3>
                    <div class="line-title2"></div>
                    <p class="mt-3 blue-grey-text">
                        Use your Bitcoin and other cryptos to access thousands of vouchers that you can use in your everyday life.
                    </p>
                    <div>
                        <a class="btn btn-outline-white buynow-btn" (click)="goToNavigation('gift');">Buy Now</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 col-sm-12 col-lg-4 mb-5">
                <div class="cando">
                    <i class="fa-3x"><img src="/assets/img/new-Phone-topup.webp" /></i>
                    <h3 class="h4 mt-3">Phone Top-up</h3>
                    <div class="line-title3"></div>
                    <p class="mt-3 blue-grey-text">
                        Top up your prepaid line directly with cryptocurrencies. More than 500 service providers in over 165 countries.
                    </p>
                    <div>
                        <a class="btn btn-outline-white buynow-btn" (click)="goToNavigation('topup');">Top-up Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--
<section class="text-center py-4 homeproductbox">
    <div class="container">
        <div class="row d-flex align-items-center justify-content-center">
            <div class="title-heading">
                <h2 class="h1 pb-3">Product Recommendation</h2>
            </div>
        </div>
        <div class="row d-flex align-items-center justify-content-center">
            <div class="filter-box">
               
                <button class="btn btn-default1 filter-button" *ngFor="let categoryRow of productRecommendationList.category; let i = index"  [ngClass]="{active: i == 0}" (click)="viewProducts(categoryRow.id)" id="filter_{{categoryRow.id}}">{{categoryRow.category_id.category_name}}</button>
            </div>            
            <div *ngFor="let productRow of productRecommendationList.products" class="col-6 col-md-4 col-sm-6 col-lg-3 mb-5 filter filterclass_{{productRow.recommended_category_id}}">
                <div class="shop-title">
                    <a (click)="goToProductDetailPage(productRow.product_id);">
                        <i class="fa-3x"><img src="{{imgBaseUrl}}products/{{productRow.product_id.product_image}}" alt="{{productRow.product_id.product_name}}"/></i>
                    </a>
                    <h3 class="h4 mt-3">{{productRow.product_id.product_name}}</h3>
                    <h4><b>{{productRow.product_id.regular_price | currency}}</b></h4>
                    <div>
                        <a class="btn btn-outline-white shopbuynow-btn" (click)="goToProductDetailPage(productRow.product_id);">Buy Now</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row d-flex align-items-center justify-content-center">
            <a  (click)="openShopPage();" class="discover-btn waves-effect waves-light">Discover More </a>
        </div>
    </div>
</section>
-->

<section class="text-center py-4 padd-top">
    <div class="container">
        <iframe src="https://cryptomate.com.cn/coinmarketcap-cpm.html" style="width: 100%;height: 80px;border: none;"></iframe>
    </div>
</section>

<section class="py-5 minorbg" id="projects">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-lg-6 col-xl-5 pb-5">
                <img class="img-fluid rounded w-100" src="/assets/img/wepay.webp" alt="project image" />
            </div>
            <div class="col-md-6 col-lg-6 col-xl-7 pl-lg-5 pb-4">
                <div class="row mb-3">
                    <div class="col-12 wepay-title">
                        <h5 class="font-weight-bold">WePay</h5>
                        <p class="mb-6">
                           WePay is a blockchain-based e-commerce service that makes online shopping more accessible to those willing to use their cryptocurrencies as an alternate option for payment.
                        </p>
                        <!--(click)="goToNavigation('wepay');"-->
                        <a href="javascript:;" class="learnmore-btn waves-effect waves-light">Coming Soon</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-6 col-xl-5 mb-3 order-lg-12"><img class="img-fluid rounded w-100" src="/assets/img/Outsource.webp" alt="project image" /></div>
            <div class="col-md-6 col-lg-6 col-xl-7 mb-3">
                <div class="row mb-3">
                    <div class="col-12 Outsourcing-title order-lg-1">
                        <h5 class="font-weight-bold">Outsourcing</h5>
                        <p class="mb-6">
                            Use your ₿itcoin and other cryptocurrencies to boost your business and outshine your competitors by importing reliable, high-quality products at fair and affordable prices.
                        </p>
                        <a (click)="goToNavigation('outsourcing');" class="learnmore-btn waves-effect waves-light">Learn More </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--
<section class="logo-bg">
    <div class="container">
        <div class="row d-flex align-items-center justify-content-center">
            <div class="title-heading-black">
                <h2 class="h1 pb-3">Top Brands Available For You.</h2>
            </div>
        </div>
        <div class="row no-gutters d-flex align-items-center justify-content-center">
            <div class="col-md-12 col-lg-8 text-center text-md-center margins">
                <div class="row">
                    <div class="col-6 col-sm-4" *ngFor="let brand of brands;" (click)="goToBrandProducts(brand);">
                        <img src="{{imgBaseUrl}}brand/{{brand.brand_logo}}" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
-->

<section class="py-3">
    <div class="container">
        <div class="row d-flex align-items-center justify-content-center">
            <div class="title-heading">
                <h2 class="h1 pt-3 pb-3">Latest News</h2>
            </div>
        </div>
        <div id="carousel-example-multi1" class="carousel slide carousel-multi-item v-2" data-ride="carousel">
            <!--Controls-->
            <div class="controls-top">
                <a class="btn-floating leftarow" href="#carousel-example-multi1" data-slide="prev"><i class="fas fa-arrow-left"></i></a>
                <a class="btn-floating rightarow" href="#carousel-example-multi1" data-slide="next"><i class="fas fa-arrow-right"></i></a>
            </div>
            <!--/.Controls-->
            <div class="carousel-inner v-2 mb-lg-4 center-on-small-only" role="listbox">
                <div *ngFor="let newsRow of crptoNews; let i = index" class="carousel-item " [ngClass]="{active: i == 0}">
                    <div class="col-12 col-md-4">
                        <div class="box-academy-2">
                            <div class="col-md-12 paddno">
                                <img class="img-fluid rounded mb-3" src="{{newsRow.image_url}}" alt="{{newsRow.title}}" />
                            </div>
                            <div class="col-md-12">
                                <div class="content-box">
                                    <h5 class="news-header-title"><b>{{newsRow.title}}</b></h5>
                                    <p class="news-detail grey-text">{{newsRow.text}}</p>
                                    <a href="{{newsRow.news_url}}" target="_blank" class="learnmore-btn waves-effect waves-light">Read More <img  src="/assets/img/arrow_new.png" alt="team member" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="py-5 morecollection">
    <div class="morecollection-topbot">
    <div class="container">
        <div class="col-sm-12">
            <div class="row d-flex align-items-center justify-content-center">
                <div class="title-heading">
                    <h2 class="h1 pb-3">Explore More Collections</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <section class="explore_collection slider">
                    <div class="carousel-item">
                        <div>
                            <a (click)="goToGiftCard('entertainment')">
                                <div class="box-academy-3">
                                    <div class="col-md-12 paddno">
                                        <img class="img-fluid rounded mb-3" src="/assets/img/entertainment.webp" alt="team member" />
                                    </div>
                                    <div class="col-md-12">
                                        <div class="content-box">
                                            <h5><b>Entertainment</b></h5>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div>
                            <a (click)="goToShopProduct('phones')">
                                <div class="box-academy-3">
                                    <div class="col-md-12 paddno">
                                        <img class="img-fluid rounded mb-3" src="/assets/img/mobile phone.webp" alt="team member" />
                                    </div>
                                    <div class="col-md-12">
                                        <div class="content-box">
                                            <h5><b>Mobile Phones</b></h5>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div>
                            <a (click)="goToGiftCard('ecommerce')">
                                <div class="box-academy-3">
                                    <div class="col-md-12 paddno">
                                        <img class="img-fluid rounded mb-3" src="/assets/img/ecommerce.webp" alt="team member" />
                                    </div>
                                    <div class="col-md-12">
                                        <div class="content-box">
                                            <h5><b>E-commerce</b></h5>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div>
                            <a (click)="goToGiftCard('food')">
                                <div class="box-academy-3">
                                    <div class="col-md-12 paddno">
                                        <img class="img-fluid rounded mb-3" src="/assets/img/food.webp" alt="team member" />
                                    </div>
                                    <div class="col-md-12">
                                        <div class="content-box">
                                            <h5><b>Food</b></h5>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div>
                            <a (click)="goToShopProduct('computer')">
                                <div class="box-academy-3">
                                    <div class="col-md-12 paddno">
                                        <img class="img-fluid rounded mb-3" src="/assets/img/laptop.webp" alt="team member" />
                                    </div>
                                    <div class="col-md-12">
                                        <div class="content-box">
                                            <h5><b>Computers</b></h5>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>
</section>
<!--
<section class="py-5">
    <div class="container">
        <div class="col-sm-12">
            <div class="row d-flex align-items-center justify-content-center">
                <div class="title-heading">
                    <h2 class="h1 pb-3">What Our Customers Say</h2>
                </div>
            </div>
        </div>
        <div class="mb-lg-4 center-on-small-only">
            <div id="carousel-example-multi" class="carousel slide carousel-multi-item v-2" data-ride="carousel">
                <div class="carousel-inner v-2" role="listbox">
                    <div class="carousel-item active">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <div class="testimonial-box">
                                <h6><img src="/assets/img/star.png" style="width: 135px;" /></h6>
                                <p class="">I received great customer service from the specialists who helped me. I would recommend to anyone who wants quality.</p>
                                <div class="msc-review-person">
                                    <div class="msc-review-avata">
                                        <img src="/assets/img/woman-1.jpg" alt="" />
                                    </div>
                                    <div class="msc-review-person-info">
                                        <h5 class="msc-review-by">Viola Manisa</h5>
                                        <div class="msc-review-tag">
                                            <span><img src="/assets/img/Icon.png" /></span> Verified customer
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <div class="testimonial-box">
                                <h6><img src="/assets/img/star.png" style="width: 135px;" /></h6>
                                <p class="">Very responsive and competent! I've never dealt with an insurance company this customer-friendly in my entire life.</p>
                                <div class="msc-review-person">
                                    <div class="msc-review-avata">
                                        <img src="/assets/img/man-1.jpg" alt="" />
                                    </div>
                                    <div class="msc-review-person-info">
                                        <h5 class="msc-review-by">Bryan Arnoldy</h5>
                                        <div class="msc-review-tag">
                                            <span><img src="/assets/img/Icon.png" /></span> Verified customer
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <div class="testimonial-box">
                                <h6><img src="/assets/img/star.png" style="width: 135px;" /></h6>
                                <p class="">My experience with this platform so far has been great. Everything is easy, from signing the contract to making an appointment.</p>
                                <div class="msc-review-person">
                                    <div class="msc-review-avata">
                                        <img src="/assets/img/man-2.jpg" alt="" />
                                    </div>
                                    <div class="msc-review-person-info">
                                        <h5 class="msc-review-by">Joshua William</h5>
                                        <div class="msc-review-tag">
                                            <span><img src="/assets/img/Icon.png" /></span> Verified customer
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <div class="testimonial-box">
                                <h6><img src="/assets/img/star.png" style="width: 135px;" /></h6>
                                <p class="">I received great customer service from the specialists who helped me. I would recommend to anyone who wants quality.</p>
                                <div class="msc-review-person">
                                    <div class="msc-review-avata">
                                        <img src="/assets/img/woman-2.jpg" />
                                    </div>
                                    <div class="msc-review-person-info">
                                        <h5 class="msc-review-by">Viola Manisa</h5>
                                        <div class="msc-review-tag">
                                            <span><img src="/assets/img/Icon.png" /></span> Verified customer
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <div class="testimonial-box">
                                <h6><img src="/assets/img/star.png" style="width: 135px;" /></h6>
                                <p class="">Very responsive and competent! I've never dealt with an insurance company this customer-friendly in my entire life.</p>
                                <div class="msc-review-person">
                                    <div class="msc-review-avata">
                                        <img src="/assets/img/man-1.jpg" alt="" />
                                    </div>
                                    <div class="msc-review-person-info">
                                        <h5 class="msc-review-by">Bryan Arnoldy</h5>
                                        <div class="msc-review-tag">
                                            <span><img src="/assets/img/Icon.png" /></span> Verified customer
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>-->

<section id="contact">
    <div class="container">
        <div class="wow fadeIn">
            <h2 class="h1 text-black pt-5 pb-3 text-center">Ready To Experience?</h2>
        </div>
        <div class="row d-flex align-items-center justify-content-center">
            <a *ngIf="isLoggedIn" (click)="goToNavigation('user/my-products');" class="joinnow-btn waves-effect waves-light">Join Us Now </a>
            <a *ngIf="!isLoggedIn" data-toggle="modal" data-target="#login" class="joinnow-btn waves-effect waves-light">Join Us Now </a>
        </div>
        <div class="container mb-5 mt-7">
            <div class="row d-flex align-items-center justify-content-center mynewssd">
                <div class="title-heading">
                    <h2 class="h1 newss">Newsletter</h2>
                </div>
            </div>
            <div class="row d-flex align-items-center justify-content-center">
                <div class="col-md-10 col-lg-10 text-center text-md-center">
                    <form class="row joinotcform" [formGroup]="newsSubscribeForm" (submit)="doSubscribe(newsSubscribeForm.value);">
                       
                        <div class="col-sm-2">
                            
                        </div>
                        <div class="col-sm-8">
                            <div class="md-form newlater">
                                <span class="iconform"><i class="far fa-envelope"></i></span>
                                <input class="form-control cardpopup-input" id="e-mail" type="email" name="email" required="required" formControlName="email" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.email?.errors }"/>
                                <label for="e-mail">Email</label>
                                 <button [disabled]="isLoading" type="submit" class="otbbuttom waves-effect waves-light">Subscribe</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="modal fade" id="watchvideo" tabindex="-1">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <button (click)="stopVideo()" class="close" data-dismiss="modal">&times;</button>
            <div class="modal-body model-pad0 mb-0 text-center">
                <div class="Flexible-container">
                   <iframe id="youtube_videos" width="560"  height="315" src="https://www.youtube.com/embed/doodYTuRXdE?rel=0"  title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media;  gyroscope; picture-in-picture"  allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</div>