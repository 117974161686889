import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CoreService } from '../shared/services/core.service';
import { HelperService } from '../shared/services/helper-service.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-loyalty-programe',
  templateUrl: './loyalty-programe.component.html',
  styleUrls: ['./loyalty-programe.component.css']
})
export class LoyaltyProgrameComponent implements OnInit {
  public lang:any = '';
  public programeList:any = [];
  public isLoggedIn:boolean = false;
  public isLoading:boolean = false;
  
  constructor(
    private coreService: CoreService,
    private toast: ToastrService,
    public helperService: HelperService,
    private _auth : AuthService
  ) { }

  ngOnInit(): void {
    if(this._auth.loggedIn()){
      this.isLoggedIn = true;
    }else{
      this.isLoggedIn = false;
    }
    this.getProgrameLists();
  }

  getProgrameLists(){
    this.isLoading = true;
    this.coreService.get(`${ENDPOINTS.LOYALTY_PROGRAME.LIST}`, {}).subscribe((res) => {
      if (res.body.success) {
        this.programeList = res.body.data;
        this.isLoading = false;
      } else {
        this.programeList = [];
        this.isLoading = false;
      }
    }, (err) => {
      this.isLoading = false;
    });
  }

  doActivatePrograme(obj:any){
    this.isLoading = true;
    this.coreService.post(`${ENDPOINTS.LOYALTY_PROGRAME.ACTIVATE}`, {'loyalty_program_id':obj.id}).subscribe((res) => {
      if (res.body.success) {
        obj.is_activated = true;
        obj.is_availed=0;
        obj.order_count=0;
        this.isLoading = false;
        this.toast.success(res.body.message);
      } else {
        this.isLoading = false;
        this.toast.error(res.body.message);
      }
    }, (err) => {
      this.isLoading = false;
      this.toast.error("something went wrong!");
    });
  }

  goToLPDetail(obj:any){
    localStorage.setItem('lpObj', JSON.stringify(obj));
    this.helperService.navigate(`/loyalty-program/${obj.slug_url}`, this.lang);
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang)
  }
}
