import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/shared/services/core.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { HelperService } from '../../shared/services/helper-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-redeem-gift-card',
  templateUrl: './redeem-gift-card.component.html',
  styleUrls: ['./redeem-gift-card.component.css']
})
export class RedeemGiftCardComponent implements OnInit {
  public isLoading:boolean = false;
  public lang:any = '';
  public voucherCode:any = '';
  constructor(
    private toast: ToastrService,
    public helperService: HelperService,
    private coreService: CoreService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }
  /**
 * @method: createNow
 * @description: Using to create User address
 * @param formData
 */
  doRedeemVoucher() {
      if(this.voucherCode==''){
        //this.toast.error("Please enter voucher code to redeem.","Error!");
      }else{
        this.isLoading = true;
        const paylaod = {
          'voucher_code' : this.voucherCode
        }
        this.coreService.post(`${ENDPOINTS.USER_PROFILE.REDEEM_VOUCHER}`, paylaod).subscribe((res) => {
          if(res.body.success){
            this.isLoading = false;
            this.toast.success(res.body.message, "Success!");
            this.voucherCode = '';
            this.helperService.navigate('/user/wallet', this.lang);
          }else{
            this.isLoading = false;
            this.toast.error(res.body.message,"Error!");
          }
        }, (err) => {
          this.isLoading = false;
          this.toast.error(err.body.message,"Error!");
        });
      }
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang)
  }

  goToFaqGift() {
    this.router?.navigate(['/faq'], { queryParams: {'active':'gift'}});
  }
}
