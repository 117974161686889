import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
import { CoreService } from 'src/app/shared/services/core.service';
import { HelperService } from 'src/app/shared/services/helper-service.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import {ToastrService} from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-forgot-modal',
  templateUrl: './forgot-modal.component.html',
  styleUrls: ['./forgot-modal.component.css']
})
export class ForgotModalComponent implements OnInit {
  public forgotForm!: FormGroup;
  public resetForm!: FormGroup;
  public isFormSubmit: boolean = false;
  public lang: any;
  public showResetForm:boolean = false;
  constructor(
    private toast: ToastrService,
    private fb: FormBuilder,
    public coreService: CoreService,
    private helperService: HelperService,
    public router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.showResetForm = false;
    this.initForm();
  }

  get get() { return this.forgotForm.controls; };
  get rstget() { return this.resetForm.controls; };

  clearResetForm(){
    this.initForm();
  }

  initForm(){
    this.isFormSubmit = false;
    this.forgotForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.resetForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      forgot_code: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  sendForgotRequest(formData:any){
    this.isFormSubmit = true;
    if (this.forgotForm.valid) {    
      const paylaod = {
        ...formData
      }
      this.coreService.post(`${ENDPOINTS.AUTH.FORGOT_PASSWORD}`, paylaod).subscribe((res) => {
        if (res.body.success) {
          this.showResetForm = true;
          this.resetForm.patchValue({
            forgot_code: '',
            email: paylaod.email,
            password: ''
          });
          this.forgotForm.reset();
          this.toast.success(res.body.message, "Success!");
          this.isFormSubmit = false;
        } else {
          this.toast.error(res.body.message, "Error!");
          this.isFormSubmit = false;
        }
      }, (err) => {
        this.isFormSubmit = false;
        this.toast.error(err.body.message, "Error!");
      });
    }
    else{
      this.toast.error("Please fill all required and valid information!", "Error!");
    }
  }

  resetPassword(formData:any){
    this.isFormSubmit = true;
    if (this.resetForm.valid) {      
      const paylaod = {
        ...formData
      }
      this.coreService.post(`${ENDPOINTS.AUTH.RESET_PASSWORD}`, paylaod).subscribe((res) => {
        if (res.body.success) {
          this.showResetForm = false;
          this.resetForm.reset();
          this.toast.success(res.body.message, "Success!");
          this.isFormSubmit = false;
          $("#forgotpassword").modal("hide");
          $("#login").modal("show");
        } else {
          this.showResetForm = false;
          this.toast.error(res.body.message, "Error!");
          this.isFormSubmit = false;
        }
      }, (err) => {
        this.showResetForm = false;
        this.isFormSubmit = false;
        this.toast.error(err.body.message, "Error!");
      });
    }
  }

  showPassword(str:string){
    $("#"+str).attr("type","text");
    $(".hide_"+str).removeClass("hide");
    $(".show_"+str).addClass("hide");
  }

  hidePassword(str:string){
    $("#"+str).attr("type","password");
    $(".hide_"+str).addClass("hide");
    $(".show_"+str).removeClass("hide");
  }
}
