<!--<app-header></app-header>
<section class="text-center py-4 padd-top" style="margin-top: 100px;">
    <div class="container">
        <iframe class="mybg" style="#f00" src="https://cryptomate.com.cn/cpm-outsourcing.html" style="width: 100%;height: 500px;border: none;"></iframe>
    </div>
</section>-->

<app-header></app-header>
<section class="text-center py-4 padd-top outMobile" style="margin-top: 100px;">
    <div class="container">
        <iframe src="https://cryptomate.com.cn/cpm-outsourcing.html" class="tr-page" style="width: 100%;height: 500px;border: none; background: transparent;"></iframe>
    </div>
</section>