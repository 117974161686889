import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from 'src/app/shared/services/core.service';
import { HelperService } from 'src/app/shared/services/helper-service.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login"
import { SocialUser } from "angularx-social-login";
import * as DeviceDetector from 'device-detector-js';
import {ToastrService} from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.css']
})
export class LoginModalComponent implements OnInit {
  public loginForm!: FormGroup;
  public registerForm!: FormGroup;
  public isFormSubmit: boolean = false;
  public lang: any;
  userAgents: any;
  
  user: SocialUser = new SocialUser;
  public loggedIn:boolean =false;
  public isLoading:boolean =false;

  public loginObj: any = {
    isFormSubmit: false,
    isEmailExits: false,
    soicalData: null
  }
  public registerObj: any = {
    emailValidate: false
  }

  constructor(
    private authService: SocialAuthService,
    private toast: ToastrService,
    private fb: FormBuilder,
    public coreService: CoreService,
    private helperService: HelperService,
    public router: Router,
    private route: ActivatedRoute
  ) { 
    this.lang = localStorage.getItem('DefaultLangSet') != null ? localStorage.getItem('DefaultLangSet') : 'en';
  }

  ngOnInit(): void {
    const deviceDetector = new DeviceDetector();
    const userAgent = navigator.userAgent;
    let device = deviceDetector.parse(userAgent);
    this.userAgents = device;

    localStorage.removeItem('userInfo');

    this.initLoginForm();
    this.initSignupForm();
    this.authService.authState.subscribe((user) => {
      this.user = user;
      this.loggedIn = (user != null);
      if(this.loggedIn){
        if (this.user.provider == "GOOGLE") {
          this.validateSocialEmail(this.user.email, this.user.provider);
        }
        if (this.user.provider == "FACEBOOK") {
          this.validateSocialEmail(this.user.email, this.user.provider);
        }
      }
    });
  }  
  get loginget() { return this.loginForm.controls; }
  get register() { return this.registerForm.controls; }

  clearLoginForm(){
    this.initLoginForm();
  }

  clearSignupForm(){
    this.initSignupForm();
  }

  initLoginForm(){
    this.loginObj.isFormSubmit = false;
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  validateSocialEmail(email:any,provider:any) :void{
    if (provider == 'GOOGLE') this.googleCallToAction();
    if (provider == 'FACEBOOK') this.facebookCallToAction();
  }

  googleCallToAction() {
    this.loginNow(this.helperService.getGooglePayload(this.user), true);
  }

  facebookCallToAction() {
    this.loginNow(this.helperService.getFBPayload(this.user), true);
  }

  /**
   * @method: loginNow
   * @description: using for login now
   * @param formData
   */
   loginNow(formData:any, isSocial:boolean) {
    this.loginObj.isFormSubmit = true;
    if (this.loginForm.valid || isSocial) {
      this.isLoading = true;
      this.loginObj.isFormSubmit = false;
      const paylaod = {
        ...formData,
        device_type: 'web',
        device_token: '',
        ...this.userAgents
      }
      if($("#parent_id").val()!=''){
        paylaod.parent_id = $("#parent_id").val();
      }
      if (localStorage.getItem('fcm_token') && localStorage.getItem('fcm_token') != null) {
        paylaod.device_token = localStorage.getItem('fcm_token');
      } else {
        delete paylaod.device_token
      }
      this.coreService.post(`${ENDPOINTS.AUTH.LOGIN}`, paylaod).subscribe((res) => {
        if(res.body.success){
          this.toast.success(res.body.message, "Success!");
          this.responseHandler(res);
          this.loginForm.reset();
        }else{
          this.isLoading = false;
          this.toast.error(res.body.message, "Error!");
        }
      }, (err) => {
        this.isLoading = false;
        this.toast.error(err.body.message, "Error!");
        this.authService.signOut()
      });
    }
  }

  responseHandler(res:any) {
    console.log(this.router.url);
    if (res.body.success) {
      localStorage.setItem('token', res.body.auth);
      localStorage.setItem('currentUser', JSON.stringify(res.body.data));
      this.helperService.setLoggedUserInfo(res.body.data) ;
      const { lang_code } = res.body.data;
      this.lang = lang_code ? lang_code : this.lang;
      this.helperService.changeUserProfile(res?.body?.data);
      this.isLoading = false;
      if (this.user) this.authService.signOut();
      if (this.loginObj.returnUrl) {
        $("#login").modal("hide");
      } else {
        if(this.router.url!='/shop'){
          this.helperService.navigate('/shop', this.lang);
        }
        else{
          window.location.reload();
        }
        $("#login").modal("hide");
      }
    } else {
      this.isLoading = false;
      if (this.user) this.authService.signOut();
    }
  }

  refreshToken(): void {
    this.authService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  initSignupForm() {
    this.isFormSubmit = false;
    this.registerForm = this.fb.group({
      full_name: [''],
      email: ['', [Validators.required, Validators.email]],
      isTerms: [true],
      password: ['', [Validators.required, Validators.minLength(6)]],
      cfpassword: ['', [Validators.required, Validators.minLength(6)]]
    }, { validator: this.pwdMatchValidator });
  }

  pwdMatchValidator(frm:any = FormGroup) {
    return frm.get('password').value === frm.get('cfpassword').value
      ? null : { mismatch: true };
  }

  validateEmail(formData:any) {
    this.isFormSubmit = true;
    if(this.registerForm.value.isTerms){
      if(this.registerForm.value.password==this.registerForm.value.cfpassword){
        if ((this.registerForm.valid && this.registerForm.value.isTerms)) {
          this.isLoading = true;
          this.coreService.post(`${ENDPOINTS.AUTH.VALIDATEEMAIL}`, {
            email: this.registerForm.value.email
          }).subscribe((res) => {
            if (res.body.success) {
              this.registerObj.emailValidate = false;
              this.registerNow(formData);
            } else {
              this.isLoading = false;
              this.toast.error(res.body.message, "Error!");
              this.helperService.scrollUpPage(0);
              this.registerObj.emailValidate = true;
            }
          }, (err) => {
            this.isLoading = false;
            this.toast.error(err.body.message, "Error!");
          });
        }
      }else{
        this.toast.error("Password and Confirm Password must be same.", "Error!");
      }
    }
    else{
      this.toast.error("Please Accept Term & Privacy Policy", "Error!");
    }
  }

  registerNow(formData:any){
    if ((this.registerForm.valid && !this.registerObj.emailValidate && this.registerForm.value.isTerms)) {
      delete formData.cfpassword;
      const paylaod = {
        ...formData,
        signup_from: 'web',
        device_type: 'web',
        device_token: '',
        ...this.userAgents
      }
      if($("#parent_id").val()!=''){
        paylaod.parent_id = $("#parent_id").val();
      }
      this.coreService.post(`${ENDPOINTS.AUTH.SIGNUP}`, paylaod).subscribe((res) => {
        if (res.body.success) {
          this.registerForm.reset();
          this.isLoading = false;
          this.isFormSubmit = false;
          //localStorage.setItem('token', res.body.auth);
          //this.helperService.setLoggedUserInfo(res.body.data) ;
          //localStorage.setItem('currentUser', JSON.stringify(res.body.data));
          if (this.user) this.authService.signOut();
          setTimeout(() => {
            //this.helperService.navigate(`/shop`, this.lang);
            $("#signup").modal("hide");
            $("#login").modal("hide");
          }, 1000);
          this.toast.success(res.body.message, "Success!");
        } else {
          this.isLoading = false;
          this.toast.error(res.body.message, "Error!");
          this.isFormSubmit = false;
        }
      }, (err) => {
        this.isFormSubmit = false;
        this.isLoading = false;
        this.toast.error(err.body.message, "Error!");
      });
    }
  }
  
  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang)
  }

  showPassword(str:string){
    $("#"+str).attr("type","text");
    $(".hide_"+str).removeClass("hide");
    $(".show_"+str).addClass("hide");
  }

  hidePassword(str:string){
    $("#"+str).attr("type","password");
    $(".hide_"+str).addClass("hide");
    $(".show_"+str).removeClass("hide");
  }
}
