import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { HelperService } from '../../shared/services/helper-service.service';
import { CoreService } from 'src/app/shared/services/core.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { environment } from 'src/environments/environment';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.css']
})
export class ReferralComponent implements OnInit {
  public isLoading:boolean = false;
  public usersList:any = [];
  public profileObj:any = {};
  public lang:any = '';
  public refeeralURL:any = environment.WEB_APP;
  constructor(
    private toast: ToastrService,
    public coreService: CoreService,
    private router: Router,
    private helperService: HelperService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getMyInvitedUsers();
    this.getMyProfile();
  }

  getMyProfile(){
    this.coreService.get(`${ENDPOINTS.USER_PROFILE.GET}`, {}).subscribe((res) => {
      if (res.body.success) {
        this.profileObj = res.body.data;
        this.refeeralURL = this.refeeralURL + 'signup?reff='+this.profileObj.referral_code;
      } else {
        this.profileObj = {};
      }
    }, (err) => {
    });
  }

  copyURL(){    
    const el = document.createElement('textarea');
    el.value = this.refeeralURL;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.toast.success("Copying to clipboard was successful!", "Success!");
  }

  getMyInvitedUsers(){
    this.isLoading = true;
    this.coreService.post(`${ENDPOINTS.USER_PROFILE.INVITED_USER}`, {}).subscribe((res) => {
      this.isLoading = false;
      if (res.body.success) {
        this.usersList = res.body.data.items;
      } else {
        this.usersList = [];
      }
    }, (err) => {
    });
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang)
  }
}
