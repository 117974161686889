import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  

  constructor(
    private router: Router,
    private _auth : AuthService
  ) { }
 
  /**
  * @method - canActivate
  * @desc - Check route authentication before navigate for parent route
  */
   canActivate(): boolean {
     if(this._auth.loggedIn()){
       return true;
     }else{
      this.router.navigate(["/"]);
      return false;
     }
  }
}
