import { Component,  Input,Output,OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoreService } from '../shared/services/core.service';
import { HelperService } from '../shared/services/helper-service.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { TOPUP_COUNTRY } from 'src/app/shared/constants/app.topup-country.constants';
import { AuthService } from '../shared/services/auth.service';
import { environment } from 'src/environments/environment';
import {ToastrService} from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-topup-detail',
  templateUrl: './topup-detail.component.html',
  styleUrls: ['./topup-detail.component.css']
})
export class TopupDetailComponent implements OnInit {
  public imgBaseUrl = environment.AWSImageURL;
  public lang:any = '';
  public countryISO:any = '';
  public operatorID:any = '';
  public isLoading:boolean = false;
  public addingToCart:boolean = false;
  public isLoggedIn:boolean = false;
  public countryLists:any = TOPUP_COUNTRY;
  public products:any = [];
  public operators:any = [];
  public topupObj:any = {};
  public cryptoPrice:any = {};
  public planID:any = '';
  public planObj:any = {};
  public cartItems:any = '';
  public mobile_number:string = '';
  public country_code:string = '';
  public exchangeRates:any = 0;
  @Input() public cartItemData:any = [];
  constructor(
    private toast: ToastrService,
    private fb: FormBuilder,
    private coreService: CoreService,
    public helperService: HelperService,
    private route: ActivatedRoute,
    private _auth : AuthService,
    private router: Router,
  ) { 
    if(this._auth.loggedIn()){
      this.isLoggedIn = true;
    }else{
      this.isLoggedIn = false;
    }
    this.route.params.subscribe(params => {
      this.countryISO = params['countryISO'];
      this.operatorID = params['operatorID'];
      this.getOperatorDetails(this.operatorID);
    });
  }

  ngOnInit(): void {    
    let topupObj:any = localStorage.getItem('topupObj') != null ? localStorage.getItem('topupObj') : '{"name":"","country":{"iso_code":"RUS"}}';
    this.topupObj = JSON.parse(topupObj);

    let operators:any = localStorage.getItem('operatorsList') != null ? localStorage.getItem('operatorsList') : JSON.stringify([]);
    this.operators = JSON.parse(operators);

    this.operators = this.operators.filter((curElem:any) => {
      return curElem.id != this.topupObj.id;
    });
       
    let mobile_number:any = localStorage.getItem('mobile_number') != null ? localStorage.getItem('mobile_number') : '';
    this.mobile_number = mobile_number;
    this.saveCountryCode();
  }

  ngOnChanges(){
    this.cartItems = this.cartItemData;
  }

  cartItemLists(){
    if(this.isLoggedIn ){
      this.coreService.get(`${ENDPOINTS.CART.LIST}`, {}).subscribe((res) => {
        this.cartItems = res.body.data;
      }, (err) => {
      });
    }
  }

  getOperatorDetails(operatorID:any){
    this.isLoading = true;
    this.coreService.post(`${ENDPOINTS.DTONE.OPERATOR_DETAIL}`, {'operator_id':operatorID}).subscribe((res) => {
      if (res.body.success) {
        this.topupObj = res.body.data;
        this.getAllProducts();
      }
    }, (err) => {
    });
  }

  saveCountryCode(){
    let object = {
      "iso_code" : this.countryISO
    };
    var results = this.findByMatchingProperties(this.countryLists, object);
    if(results.length>0){
      let planObj = results[0];
      this.country_code = planObj.countrycode;
    }
  }

  selectPlan(){
    let object = {
      "id" : parseInt(this.planID)
    };
    var results = this.findByMatchingProperties(this.products, object);
    if(results.length>0){
      this.planObj = results[0];
      // if(this.planObj.destination.unit!='USD'){
      //   this.planObj.destination.amount = parseFloat(this.planObj.destination.amount) + (this.planObj.destination.amount * environment.EXCHANGE_FEE);
      //   this.planObj.destination.amount = this.planObj.destination.amount * this.exchangeRates;
      // }
    }
  }

  findByMatchingProperties(set:any, properties:any) {
    return set.filter(function (entry:any) {
        return Object.keys(properties).every(function (key) {
            return entry[key] === properties[key];
        });
    });
  }

  getAllProducts(){
    this.isLoading = true;
    let qs = 'country_iso_code='+this.countryISO+'&service_id=1&operator_id='+this.operatorID;
    this.coreService.post(`${ENDPOINTS.DTONE.PRODUCTS}`, {'qs':qs}).subscribe((res) => {
      if (res.body.success) {
        if(res.body.data.errors){
          this.products = [];
        }else{
          this.products = res.body.data;
          if(this.products.length>0){
            this.planObj = this.products[0];
            this.planID = this.planObj.id;
          }
          // if(this.planObj.destination.unit!='USD'){
          //   this.planObj.destination.amount = parseFloat(this.planObj.destination.amount) + (this.planObj.destination.amount * environment.EXCHANGE_FEE);
          //   this.getExchangeRates(this.planObj.destination.unit);
          // }
        }
      } else {
        this.products = [];
      }
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
    });
  }

  // getExchangeRates(currencyCode:any){
  //   this.coreService.getExchangeRates(currencyCode).subscribe((res) => {
  //     let exchangeRates = res.body.data;
  //     //this.exchangeRates = 0;
  //     if(exchangeRates.rates){
  //       this.exchangeRates = exchangeRates.rates['USD'];
  //       this.planObj.destination.amount = this.planObj.destination.amount * this.exchangeRates;
  //     }
  //   }, (err) => {
  //    // this.exchangeRates = 0;
  //   });
  // }
 

  /**
  * @method: doTopUp
  * @description: Using to add item in the cart
  * @param productObj
  */
   doTopUp(obj:any){
    this.mobile_number = this.mobile_number.trim();
    if(this.mobile_number==''){
      this.toast.error("Mobile number is required.", "Error!");
    }else{
      if(!this.addingToCart){        
        let country_code = this.country_code;
        this.coreService.post(`${ENDPOINTS.DTONE.LOOKUP_MOBILE}`, {'mobile':"+"+country_code+this.mobile_number}).subscribe((res) => {
          if (res.body.success){
            if(res.body.data.errors && res.body.data.errors.length){
              this.toast.error(res.body.data.errors[0].message, "Error!");
            }else{
              this.addingToCart = true
              var paylaod:any = {
                'allow_only_fiat' : 0,
                'cart_item' : 'toupup',
                'product_name' : this.topupObj.name,
                'mobile_number' : "+"+country_code+this.mobile_number,
                'topup_operator_id' : this.topupObj.id,
                'topup_product_id' : this.planObj.id,
                'quantity' : 1,
                'topup_info' : this.topupObj,
                'topup_product_info' : this.planObj,
                'price' :parseFloat(this.planObj.destination.amount)
              }
              if(paylaod.quantity>0 && paylaod.price>0){
                this.coreService.post(`${ENDPOINTS.CART.ADD}`, paylaod).subscribe((res) => {
                  this.addingToCart = false;
                  if(res.body.success){
                    obj.addedToCart = true;
                    this.cartItems = res.body.data;
                    this.toast.success(res.body.message, "Success!");
                  }else{
                    this.toast.error(res.body.message, "Error!");
                  }
                  setTimeout(function(){
                    $("#pol").show();
                    $(".cartPopupModal").show();
                  },500);
                }, (err) => {
                });
              }else{
                this.toast.error("Please select price and quantity", "Error!");
              }
            }
          }else{
            this.toast.error("There is an error, Please try again", "Error!");
          }
        }, (err) => {
          this.toast.error("There is an error, Please try again", "Error!");
        });
      }
    }
  }

  public numbersOnlyValidator(event: any) {
    const pattern = /^[0-9\-]*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9\-]/g, "");
      this.mobile_number = event.target.value;
    }
  }

  goToOperatorDetailsPage(obj:any){
    localStorage.setItem('topupObj', JSON.stringify(obj));
    this.topupObj = obj;
    this.countryISO = this.topupObj.country.iso_code;
    this.operatorID = obj.id;
    this.getAllProducts();
    $("html, body").animate({ scrollTop: 0 }, "slow");
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang);
  }
}
