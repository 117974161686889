<app-header></app-header>
<!-- Intro Section-->
<section class="view hm-gradient" id="wepay-bg">
    <div class="container">
        <div *ngIf="isLoadingPage" id="loader"></div>
        <div class="row no-gutters">
            <div class="mobileshownew"><img src="/assets/img/mobile-wepay-bg.png" /></div>

            <div class="col-md-12 col-lg-7 order-lg-12"></div>
            <div class="col-md-12 col-lg-5 text-left text-md-left order-lg-1">
                <div class="white-text padd-top170">
                    <div>
                        <h5 class="mt-sm-5">With our WePay solution, you can use your ₿itcoin and other cryptocurrencies for online shopping, hotel bookings, course payments, flight reservations, or medical cost billing.<br /></h5>
                        <h5>It is that simple;</h5>
                        <h5>
                            <b> You Plan Your Way, And We Pay Our Way! </b>
                        </h5>
                    </div>
                    <div>
                        <a (click)="moveTo('shownow')" class="btn btn-outline-white start-btn">Start Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="text-center py-4 wepaytabs mb-3">
    <div class="container">
        <div class="row d-flex align-items-center justify-content-center">
            <div class="title-heading">
                <h2 class="h1 pt-5 pb- shopnow mob">Plan Your Way, Pay Our Way</h2>
            </div>
        </div>
        <div class="row h-second-fold timeline-oursource PlanWayWrap">
            <div class="col-12 col-md-12 col-sm-12 col-lg-12">
                <div class="classic-tabs mx-2">
                    <ul class="nav tabs-orange" id="myClassicTabOrange" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active show" id="profile-tab-classic-orange" data-toggle="tab" href="#profile-classic-orange" role="tab" aria-controls="profile-classic-orange" aria-selected="true">
                                <i class="hotelimgtab tabimgbx"></i><span>Hotel</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="follow-tab-classic-orange" data-toggle="tab" href="#follow-classic-orange" role="tab" aria-controls="follow-classic-orange" aria-selected="false">
                                <i class="hotelimgtab1 tabimgbx"></i><span> Flight</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="contact-tab-classic-orange" data-toggle="tab" href="#contact-classic-orange" role="tab" aria-controls="contact-classic-orange" aria-selected="false">
                                <i class="hotelimgtab2 tabimgbx"></i><span>Education</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="awesome-tab-classic-orange" data-toggle="tab" href="#awesome-classic-orange" role="tab" aria-controls="awesome-classic-orange" aria-selected="false">
                                <i class="hotelimgtab3 tabimgbx"></i><span>E-Commerce</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="Health-tab-classic-orange" data-toggle="tab" href="#Health-classic-orange" role="tab" aria-controls="Health-classic-orange" aria-selected="false">
                                <i class="hotelimgtab4 tabimgbx"></i><span>Health</span>
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content newhei" id="myClassicTabContentOrange">
                        <div class="tab-pane active show" id="profile-classic-orange" role="tabpanel" aria-labelledby="profile-tab-classic-orange">
                            <div class="row d-flex align-items-center justify-content-center m-hide">
                                <div class="col-md-12 col-lg-12 text-center text-md-center p-top">
                                    
                                     <div class="mybox clearfix">
                                        <section class="regular slider">
                                            <div class="wap" *ngFor="let channelRow1 of pageObj.category_brands.hotel">
                                                <a href="{{channelRow1.brand_url}}" target="_blank">
                                                    <!-- <a href="javascript:;"> -->
                                                    <img src="{{imgBaseUrl}}brand/{{channelRow1.brand_logo}}" alt="{{channelRow1.brand_name}}" />
                                                </a>
                                            </div>
                                        </section>
                                      </div>  
                                     
                                </div>
                            </div>
                            
                            <div class="mobileView clearfix">
                              <div class="wap" *ngFor="let channelRow1 of pageObj.category_brands.hotel">
                                                <a href="{{channelRow1.brand_url}}" target="_blank">
                                                    <!-- <a href="javascript:;"> -->
                                                    <img src="{{imgBaseUrl}}brand/{{channelRow1.brand_logo}}" alt="{{channelRow1.brand_name}}" />
                                                </a>
                                            </div>
                            </div>
                            
                        </div>
                        <div class="tab-pane" id="contact-classic-orange" role="tabpanel" aria-labelledby="contact-tab-classic-orange">
                            <div class="row d-flex align-items-center justify-content-center m-hide">
                                <div class="col-md-12 col-lg-12 text-center text-md-center p-top">
                                    <div class="mybox">
                                        <section class="regular slider">
                                            <div class="wap" *ngFor="let channelRow2 of pageObj.category_brands.flight">
                                                <a href="{{channelRow2.brand_url}}" target="_blank">
                                                    <img src="{{imgBaseUrl}}brand/{{channelRow2.brand_logo}}" alt="{{channelRow2.brand_name}}" />
                                                </a>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="mobileView clearfix">
                             <div class="wap" *ngFor="let channelRow2 of pageObj.category_brands.flight">
                                    <a href="{{channelRow2.brand_url}}" target="_blank">
                                        <img src="{{imgBaseUrl}}brand/{{channelRow2.brand_logo}}" alt="{{channelRow2.brand_name}}" />
                                    </a>
                                </div>
                            </div>
                            
                        </div>
                        <div class="tab-pane" id="follow-classic-orange" role="tabpanel" aria-labelledby="follow-tab-classic-orange">
                            <div class="row d-flex align-items-center justify-content-center m-hide">
                                <div class="col-md-12 col-lg-12 text-center text-md-center p-top">
                                    <div class="mybox">
                                        <section class="regular slider">
                                            <div class="wap" *ngFor="let channelRow5 of pageObj.category_brands.health">
                                                <a href="{{channelRow5.brand_url}}" target="_blank">
                                                    <!-- <a href="javascript:;"> -->
                                                    <img src="{{imgBaseUrl}}brand/{{channelRow5.brand_logo}}" alt="{{channelRow5.brand_name}}" />
                                                </a>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="mobileView clearfix">
                             <div class="wap" *ngFor="let channelRow5 of pageObj.category_brands.health">
                                    <a href="{{channelRow5.brand_url}}" target="_blank">
                                        <!-- <a href="javascript:;"> -->
                                        <img src="{{imgBaseUrl}}brand/{{channelRow5.brand_logo}}" alt="{{channelRow5.brand_name}}" />
                                    </a>
                                </div>
                            </div>
                            
                        </div>
                        <div class="tab-pane" id="awesome-classic-orange" role="tabpanel" aria-labelledby="awesome-tab-classic-orange">
                            <div class="row d-flex align-items-center justify-content-center m-hide">
                                <div class="col-md-12 col-lg-12 text-center text-md-center p-top">
                                    <div class="mybox">
                                        <section class="regular slider">
                                            <div class="wap" *ngFor="let channelRow3 of pageObj.category_brands.education">
                                                <a href="{{channelRow3.brand_url}}" target="_blank">
                                                    <!-- <a href="javascript:;"> -->
                                                    <img src="{{imgBaseUrl}}brand/{{channelRow3.brand_logo}}" alt="{{channelRow3.brand_name}}" />
                                                </a>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="mobileView clearfix">
                            <div class="wap" *ngFor="let channelRow3 of pageObj.category_brands.education">
                                <a href="{{channelRow3.brand_url}}" target="_blank">
                                    <!-- <a href="javascript:;"> -->
                                    <img src="{{imgBaseUrl}}brand/{{channelRow3.brand_logo}}" alt="{{channelRow3.brand_name}}" />
                                </a>
                            </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="Health-classic-orange" role="tabpanel" aria-labelledby="Health-tab-classic-orange">
                            <div class="row d-flex align-items-center justify-content-center m-hide">
                                <div class="col-md-12 col-lg-12 text-center text-md-center p-top">
                                    <div class="mybox">
                                        <section class="regular slider">
                                            <div class="wap" *ngFor="let channelRow5 of pageObj.category_brands.health">
                                                <a href="{{channelRow5.brand_url}}" target="_blank">
                                                    <img src="{{imgBaseUrl}}brand/{{channelRow5.brand_logo}}" alt="{{channelRow5.brand_name}}" />
                                                </a>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="mobileView clearfix">
                             <div class="wap" *ngFor="let channelRow5 of pageObj.category_brands.health">
                                    <a href="{{channelRow5.brand_url}}" target="_blank">
                                        <img src="{{imgBaseUrl}}brand/{{channelRow5.brand_logo}}" alt="{{channelRow5.brand_name}}" />
                                    </a>
                                </div>
                            </div>
                            
                        </div>
                        <!-- <div class="tab-pane fade" id="follow-classic-orange" role="tabpanel" aria-labelledby="follow-tab-classic-orange">
                     <div class="row d-flex align-items-center justify-content-center">
                         <div class="col-md-12 col-lg-12 text-center text-md-center p-top">
                             <div class="container mybox">
                                 <section class="regular  slider">
                                     <div class="wap" *ngFor="let channelRow2 of pageObj.category_brands.flight">
                                         <a href="{{channelRow2.brand_url}}" target="_blank">
                            
                                             <img src="{{imgBaseUrl}}brand/{{channelRow2.brand_logo}}" alt="{{channelRow2.brand_name}}"/>
                                         </a>
                                     </div>
                                 </section>
                             </div>
                         </div>
                     </div>
                     </div>             
                     
                     <div class="tab-pane fade" id="contact-classic-orange" role="tabpanel" aria-labelledby="contact-tab-classic-orange">
                     <div class="row d-flex align-items-center justify-content-center">
                         <div class="col-md-12 col-lg-12 text-center text-md-center p-top">
                             <div class="container mybox">
                                 <section class="regular  slider">
                                     <div class="wap" *ngFor="let channelRow3 of pageObj.category_brands.education">
                                         <a href="{{channelRow3.brand_url}}" target="_blank">
                                        
                                             <img src="{{imgBaseUrl}}brand/{{channelRow3.brand_logo}}" alt="{{channelRow3.brand_name}}"/>
                                         </a>
                                     </div>
                                 </section>
                             </div>
                         </div>
                     </div>
                     </div>
                     
                     <div class="tab-pane fade" id="awesome-classic-orange" role="tabpanel" aria-labelledby="awesome-tab-classic-orange">
                     <div class="row d-flex align-items-center justify-content-center">
                         <div class="col-md-12 col-lg-12 text-center text-md-center p-top">
                             <div class="container mybox">
                                 <section class="regular  slider">
                                     <div class="wap" *ngFor="let channelRow4 of pageObj.category_brands.ecommerce">
                                         <a href="{{channelRow4.brand_url}}" target="_blank">
                                        
                                             <img src="{{imgBaseUrl}}brand/{{channelRow4.brand_logo}}" alt="{{channelRow4.brand_name}}"/>
                                         </a>
                                     </div>
                                 </section>
                             </div>
                         </div>
                     </div>
                     </div>
                     
                     <div class="tab-pane fade" id="Health-classic-orange" role="tabpanel" aria-labelledby="Health-tab-classic-orange">
                     <div class="row d-flex align-items-center justify-content-center">
                         <div class="col-md-12 col-lg-12 text-center text-md-center p-top">
                             <div class="container mybox">
                                 <section class="regular  slider">
                                     <div class="wap" *ngFor="let channelRow5 of pageObj.category_brands.health">
                                         <a href="{{channelRow5.brand_url}}" target="_blank">
                                       
                                             <img src="{{imgBaseUrl}}brand/{{channelRow5.brand_logo}}" alt="{{channelRow5.brand_name}}"/>
                                         </a>
                                     </div>
                                 </section>
                             </div>
                         </div>
                     </div>
                     </div>    -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="wepayshopright-bg" id="shownow">
    <div class="container">
        <div class="row d-flex align-items-center">
            <div class="col-12 col-md-12 col-lg-6">
                <div class="col-sm-12">
                    <!--<div class="row d-flex align-items-left justify-content-left">
                  <div class="title-heading-black">
                     <h2 class="h1 btm-title-out shopnow">Shop Now</h2>
                  </div>
               </div>-->

                    <div class="mobileshownew"><img src="/assets/img/wepay2.webp" /></div>
                </div>
                <p><b>Fill out the form to have an online shopping expert on your side.</b></p>
                <form class="col-md-12 col-lg-12 save-addreform" [formGroup]="wePayEnqForm">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="md-form">
                                <span class="iconform"><i class="fab fa-bitcoin"></i></span>
                                <input class="form-control cardpopup-input" id="item_name" type="text" name="name" required="required" formControlName="item_name" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.item_name?.errors }" />
                                <label for="item_name">Item Link</label>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <!--<div class="file-upload-wrapper">
                        <div class="card card-body view file-upload bpx-shadownoe">
                            <div class="card-text file-upload-message">
                                <i class="far fa-file-alt"></i>
                                <p><span class="imgcolorupload">Drag & Drop files </span> here or Browse to begin the upload</p>
                                <p><small>Only image/PDF file are accepted ( Accept 3 files and size 3 MB )</small></p>
                            </div>
                            <input type="file" id="input-file-max-fs" class="file_upload" data-max-file-size="2M" (change)="handleInputChange($event)"/>
                        </div>
                        </div>-->
                            <div class="file-upload">
                                <label for="upload-1" class="btn">
                                    <div class="card-text file-upload-message">
                                        <i class="far fa-file-alt"></i>
                                        <p><span class="imgcolorupload">Drag & Drop files </span> here or Browse to begin the upload</p>
                                        <p><small>Only image/PDF file are accepted ( Accept 3 files and size 3 MB )</small></p>
                                    </div>
                                    <p><small style="text-transform: capitalize;">Select File</small></p>
                                </label>
                                <input type="file" id="upload-1" class="file_upload" data-max-file-size="2M" (change)="handleInputChange($event)" />
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6">
                            <div class="md-form">
                                <span class="iconform"><i class="far fa-user"></i></span>
                                <input class="form-control cardpopup-input" id="full_name" type="text" name="full_name" required="required" formControlName="full_name" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.full_name?.errors }" />
                                <label for="full_name">Name</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6">
                            <div class="md-form">
                                <span class="iconform"><i class="far fa-envelope"></i></span>
                                <input class="form-control cardpopup-input" id="email" type="text" name="email" required="required" formControlName="email" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.email?.errors }" />
                                <label for="email">Email</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6">
                            <div class="md-form">
                                <select
                                    class="form-control cardpopup-input"
                                    style="padding-left: 15px !important; font-size: 13px;"
                                    required="required"
                                    formControlName="country"
                                    [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.country?.errors }"
                                >
                                    <option value="">Select Country</option>
                                    <option *ngFor="let country of countryLists;" value="{{country}}">{{country}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6">
                            <div class="md-form">
                                <select
                                    class="form-control cardpopup-input"
                                    style="padding-left: 15px !important; font-size: 13px;"
                                    required="required"
                                    formControlName="payment_method"
                                    [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.payment_method?.errors }"
                                >
                                    <option value="">Select Payment method</option>
                                    <option *ngFor="let method of paymentMethodsLists;" value="{{method}}">{{method}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="md-form">
                                <span class="iconform"><i class="far fa-envelope"></i></span>
                                <textarea
                                    id="form7"
                                    class="md-textarea form-control billing-textare"
                                    rows="3"
                                    style="padding-left: 45px !important;"
                                    required="required"
                                    formControlName="message"
                                    [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.message?.errors }"
                                ></textarea>
                                <label for="form7" class="">Any Message</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-left">
                            <button [disabled]="isLoading" type="button" class="joinnow-btn waves-effect waves-light w-100" (click)="submitEnq(wePayEnqForm.value)">Send</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-12 col-md-6 col-lg-6"></div>
        </div>
    </div>
</section>
<section class="partner-bg support mt-5">
    <div class="container">
        <div class="row d-flex align-items-center justify-content-center">
            <h2 class="h1">Supported Channels</h2>
        </div>
        <div class="container mybox">
            <section class="regular slider">
                <div class="mystyle" *ngFor="let channelRow of pageObj.channels">
                    <a href="javascript:;">
                        <div class="wap">
                            <img src="{{imgBaseUrl}}brand/{{channelRow.brand_logo}}" alt="{{channelRow.brand_name}}" />
                        </div>
                    </a>
                </div>
            </section>
        </div>
    </div>
</section>
<section class="bottom-outsource-bg">
    <div class="container">
        <div class="row d-flex align-items-center justify-content-center">
            <div class="col-12 col-md-7 col-lg-7 order-lg-12">
                <img src="/assets/img/giftvouchers.webp" class="w-100" />
            </div>
            <div class="col-12 col-md-5 col-lg-5 order-lg-1">
                <div class="col-sm-12">
                    <div class="row d-flex align-items-left justify-content-left">
                        <div class="title-heading-black">
                            <h2 class="h1 btm-title-out">Gift Cards:</h2>
                        </div>
                    </div>
                </div>
                <p>Want to go cashless and avoid bank overdrafts? Get all of this with gift cards. Join us now with thousands of vouchers for the world's most popular brands.</p>
                <a (click)="goToNavigation('gift');" class="shopnownt waves-effect waves-light">Shop Now</a>
            </div>
        </div>
    </div>
</section>