import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/shared/services/helper-service.service';

@Component({
  selector: 'app-user-breadcrumb',
  templateUrl: './user-breadcrumb.component.html',
  styleUrls: ['./user-breadcrumb.component.css']
})
export class UserBreadcrumbComponent implements OnInit {
  public lang:any ='';
  constructor(
    private helperService: HelperService
  ) { }

  ngOnInit(): void {
  }
  
  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang);
  }
}
