import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoreService } from '../shared/services/core.service';
import { HelperService } from '../shared/services/helper-service.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { environment } from 'src/environments/environment';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  public limit_amount = environment.DAILY_PURCHASE_LIMIT;
  public lang:any = '';
  public imgBaseUrl = environment.AWSImageURL;
  public loadCartItem:boolean = true;
  public isLoading:boolean = false;
  public cartItems:any = '';
  public totalCartValue:any = 0.00;
  public profileObj:any = {};
  public cart: any = {
    'quantity' : 1
  }
  public isFiatProductAdded:boolean = false;
  public exchangeRates:any = 0;
  public inrExchangeRates:any = 0;
  public cryptoPrice:any = {};
  constructor(
    private fb: FormBuilder,
    private toast: ToastrService,
    private coreService: CoreService,
    public helperService: HelperService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    let profileObj:any = localStorage.getItem('currentUser') != null ? localStorage.getItem('currentUser') : '{"is_document_verified":true}';
    this.profileObj = JSON.parse(profileObj);
    this.getExchangeRates('USD');
    this.getExchangeRates('INR');
  }

  /**
   * @method: addToCart
   * @description: Using to get list of cart
   * @param productObj
   */
  cartItemLists(){
    this.loadCartItem = true;
    this.coreService.get(`${ENDPOINTS.CART.LIST}`, {}).subscribe((res) => {
      this.cartItems = res.body.data;
      this.loadCartItem = false;
      this.isFiatProductAdded = false;
      this.updateCartTotal();
    }, (err) => {
      this.cartItems = [];
      this.loadCartItem = false;
      this.toast.success(err.body.message, "Errr!");
    });
  }

  getExchangeRates(currencyCode:any){
    //if(!this.exchangeRates){
      this.coreService.getExchangeRates(currencyCode).subscribe((res) => {
        if(currencyCode=='USD'){
          this.exchangeRates = res.body.data.rates;
        }
        else{
          this.inrExchangeRates = res.body.data.rates;
        }
        this.cartItemLists();
      }, (err) => {
        //this.exchangeRates = 0;
      });
    //}
  }

  /**
   * @method: deleteItemFromCart
   * @description: Using to delete item from cart
   * @param itemObj
   */
  deleteItemFromCart(itemObj:any){
    this.isLoading = true;
    this.coreService.post(`${ENDPOINTS.CART.DELETE}`, {cart_id:itemObj.id}).subscribe((res) => {
      if(res.body.success){
        this.cartItems = res.body.data;
        this.isFiatProductAdded = false;
        this.updateCartTotal();
      }else{
        this.isLoading = false;
        this.toast.success(res.body.message, "Errr!");
      }      
    }, (err) => {
      this.isLoading = false;
      this.toast.success(err.body.message, "Errr!");
    });
  }

  updateCartTotal(){
    this.totalCartValue = 0;
    for (var i = 0; i <this.cartItems.length; i++) {
      let price = 0;
      if(this.cartItems[i].allow_only_fiat){
        this.isFiatProductAdded = true;
      }
      if(this.cartItems[i].cart_item=='product'){
        price = parseFloat(this.cartItems[i].price);
      }else{
        if(this.cartItems[i].cart_item=='toupup'){
          if(this.cartItems[i].topup_product_info.destination.unit!='USD'){
            let rates:any = this.exchangeRates[this.cartItems[i].topup_product_info.destination.unit];
            let p:any = parseFloat(this.cartItems[i].topup_product_info.destination.amount) + (this.cartItems[i].topup_product_info.destination.amount * environment.EXCHANGE_FEE);
            this.cartItems[i].price = p / rates;
            price = parseFloat(this.cartItems[i].price);
          }
          else{
            let rates:any = 1;
            let p:any = parseFloat(this.cartItems[i].topup_product_info.destination.amount) + (this.cartItems[i].topup_product_info.destination.amount * environment.EXCHANGE_FEE);
            this.cartItems[i].price = p / rates;
            price = parseFloat(this.cartItems[i].price);
          }
        }
        else if(this.cartItems[i].cart_item=='gift_card'){
          console.log("*******************************"+this.cartItems[i].gift_card_info.currencyCode+"******************************")
          let fee = (this.cartItems[i].gift_card_info.fee && this.cartItems[i].gift_card_info.fee>0)?this.cartItems[i].gift_card_info.fee:0;
          let feeAdditonalAmount = 0;
          if(fee>0){
            feeAdditonalAmount = (parseFloat(this.cartItems[i].denomination) * fee) / 100;
          }
          console.log("denomination",this.cartItems[i].denomination);
          console.log("feeAdditonalAmount",feeAdditonalAmount);
          let p:any = parseFloat(this.cartItems[i].denomination) + (this.cartItems[i].denomination * environment.GIFT_EXCHANGE_FEE) + feeAdditonalAmount;
          console.log("p",p);
          var inrPrice = 0;
          //Convert It into INR
          console.log("exchangeRateRule",this.cartItems[i].gift_card_info.exchangeRateRule)
          let xoxoInrRate = 1/parseFloat(this.cartItems[i].gift_card_info.exchangeRateRule);
          console.log("xoxoInrRate",xoxoInrRate);
          let inrRate = 1/parseFloat(this.inrExchangeRates[this.cartItems[i].gift_card_info.currencyCode]);
          console.log("inrRate",inrRate);
          if(inrRate>xoxoInrRate){
            inrPrice = p * inrRate;
            console.log("Here inrRate");
          }
          else{
            inrPrice = p * xoxoInrRate;
            console.log("Here xoxoInrRate");
          }
          console.log(inrPrice);
          let rates:any = this.exchangeRates['INR'];
          console.log("rates",rates);        
          this.cartItems[i].price = inrPrice / rates;
          price = parseFloat(this.cartItems[i].price);
          console.log(this.cartItems[i].price);
          console.log("******************************* End Here "+this.cartItems[i].gift_card_info.currencyCode+"******************************")
        }
        else{
          price = parseFloat(this.cartItems[i].price);
        }
      }
      this.totalCartValue = parseFloat(this.totalCartValue) + (this.cartItems[i].quantity * price);
    }
    this.totalCartValue = parseFloat(this.totalCartValue).toFixed(2);
    this.isLoading = false;
  }

  /**
   * @method: deleteItemFromCart
   * @description: Using to delete item from cart
   * @param itemObj
   */
   updateCartItem(itemObj:any,type:string){
    var q = itemObj.quantity;
    if(type=='minus'){
      if(q>1){
        q = q - 1;
      }else{
        this.deleteItemFromCart(itemObj);
        return;
      }
    }else{
      q = q + 1;
    }
    if(!this.isLoading){
      this.isLoading = true;
      const paylaod = {
        'cart_id' : itemObj.id,
        'quantity' : q
      }
      this.coreService.post(`${ENDPOINTS.CART.UPDATE}`, paylaod).subscribe((res) => {
        if(res.body.success){
          //this.toast.success(res.body.message, "Success!");
          itemObj.quantity = q;
          this.isFiatProductAdded = false;
          this.updateCartTotal();
        }else{
          this.isLoading = false;
          this.toast.error(res.body.message, "Error!");
        }
      }, (err) => {
        this.isLoading = false;
        this.toast.error(err.body.message);
      });
    }
  }

  updateCartPrice(){
    this.isLoading = true;
    const paylaod = {
      'data' : this.cartItems
    };
    this.coreService.post(`${ENDPOINTS.CART.UPDATE_PRICE}`, paylaod).subscribe((res) => {
      if(res.body.success){
        this.goToNavigation('checkout');
      }else{
        this.isLoading = false;
        this.toast.error(res.body.message, "Error!");
      }
    }, (err) => {
      this.isLoading = false;
      this.toast.error(err.body.message);
    });
  }

  goToTopupDetails(id:any,iso:any){
    this.helperService.navigate(`/topup-detail/${iso}/${id}`, this.lang);
  }

  goToGiftCardDetail(id:any){
    this.helperService.navigate(`/gift-detail/${id}`, this.lang);
  }

  goToGameDetail(id:any){
    this.helperService.navigate(`/game-products/${id}`, this.lang);
  }

  goToProductDetail(obj:any){
    //this.helperService.navigate(`/shop-detail/${obj.product_slug}`, this.lang);
    window.location.href = '/shop-detail/'+obj.product_slug;
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang)
  }
}