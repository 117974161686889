<app-headers></app-headers>
<section class="text-center mb-2 settingTabs">
    <app-user-breadcrumb></app-user-breadcrumb>
</section>
<section class="mb-5 profileView">
    <div class="container">
        <div class="row">
            <!--  dashboard-menu-->
            <div class="col-md-5 col-lg-3">
                <app-user-left-menu></app-user-left-menu>
            </div>
            <div class="col-md-7 col-lg-9">
                <div class="rightbox-dashbord">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                            <a (click)="goToNavigation('user/settings');" class="backbtn"><i class="fas fa-arrow-left opcity0-5"></i> Back </a>
                            <h4 class="list-heading">Add Address</h4>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-6 col-md-6 col-lg-6" *ngFor="let address of addressList;">
                            <div class="save-adress">
                                <input class="checkbox-tools" type="radio" name="address_id" id="tool-{{address.id}}" />
                                <label class="for-checkbox-tools waves-effect waves-light" for="tool-{{address.id}}">
                                    <span class="default-address">{{address.address_type}}</span>
                                    <span class="edit-adress">
                                        <a (click)="editAddress(address)" class="greenedit"><i class="fas fa-pencil-alt"></i></a>
                                        <a (click)="deleteAddress(address)" class="redelete"><i class="far fa-trash-alt"></i></a>
                                    </span>
                                    <h5 class="float-left mt-1 float-left w-100">{{address.first_name}} {{address.last_name}}</h5>
                                    <p class="float-left">
                                        <i class="fas fa-map-marker-alt"></i>
                                        <span class="addsmall">{{address.address}}, {{address.landmark}}, {{address.city}}, {{address.zipcode}}, {{address.country}}</span>
                                    </p>
                                    <p class="float-left">
                                        <i class="fas fa-phone-square-alt"></i>
                                        <span class="addsmall"> {{address.phone_number}}</span>
                                    </p>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <h5 *ngIf="!addressForm.value.id" class="font-weight-normal mb-1">Add New Address</h5>
                            <h5 *ngIf="addressForm.value.id" class="font-weight-normal mb-1">Edit Address</h5>
                        </div>

                        <form class="col-md-12 col-lg-9 save-addreform" [formGroup]="addressForm">
                            <div class="redeemtopbox">
                                <div class="row">
                                    <div class="col-md-12 mb-10">
                                        <label class="pure-material-radio">
                                            <input type="radio" name="group" name="address_type" value="HOME" checked formControlName="address_type"/>
                                            <span>HOME</span>
                                        </label>

                                        <label class="pure-material-radio ">
                                            <input type="radio" name="group" name="address_type" value="OFFICE" formControlName="address_type"/>
                                            <span>OFFICE</span>
                                        </label>

                                        <label class="pure-material-radio ">
                                            <input type="radio" name="group" name="address_type" value="OTHER" formControlName="address_type"/>
                                            <span>OTHER</span>
                                        </label>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <div class="md-form">
                                            <input class="form-control" type="text" id="f-name" name="name" required="required"  formControlName="first_name" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.first_name?.errors }"/>
                                            <label for="f-name">First Name*</label>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <div class="md-form">
                                            <input class="form-control" type="text" id="l-name" name="name" required="required" formControlName="last_name"  [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.last_name?.errors }"/>
                                            <label for="l-name">Last Name*</label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-12">
                                        <div class="md-form">
                                            <input class="form-control" type="text" id="p-number" name="name" required="required"  formControlName="phone_number" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.phone_number?.errors }"/>
                                            <label for="p-number">Phone Number*</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="md-form">
                                            <input class="form-control" type="text" id="ad-rss" name="name" required="required"  formControlName="address" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.address?.errors }"/>
                                            <label for="ad-rss">Address</label>
                                        </div>
                                        <div class="md-form">
                                            <input class="form-control" type="text" id="ad-rss1" name="landmark" required="required"  formControlName="landmark" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.landmark?.errors }"/>
                                            <label for="ad-rss1">Landmark</label>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <div class="md-form">
                                            <input class="form-control" type="text" id="city" name="name" required="required" formControlName="city" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.city?.errors }"/>
                                            <label for="city">City*</label>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <div class="md-form">
                                            <input class="form-control" type="text" id="p-code" name="name" required="required"  formControlName="zipcode" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.zipcode?.errors }"/>
                                            <label for="p-code">Postcode / ZIP*</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="md-form">
                                            <select class="form-control cardpopup-input" style="padding-left: 15px !important; font-size: 13px;" required="required" formControlName="country" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.country?.errors }">
                                                <option>Select Country</option>
                                                <option *ngFor="let country of countryLists;" value="{{country}}">{{country}}</option>
                                            </select>
                                            <label for="p-code">Country*</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="md-form">
                                            <input class="form-control" type="text" id="s-te" name="name" required="required" formControlName="state"  [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.state?.errors }"/>
                                            <label for="s-te">State / Province*</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <button type="button" [disabled]="isLoading" class="joinnow-btn waves-effect waves-light mt-3" (click)="createNow(addressForm.value)">Save Address</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>