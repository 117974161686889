import { Component, OnInit } from '@angular/core';
import { CoreService } from '../shared/services/core.service';
import { HelperService } from '../shared/services/helper-service.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';

@Component({
  selector: 'app-balance-card-terms',
  templateUrl: './balance-card-terms.component.html',
  styleUrls: ['./balance-card-terms.component.css']
})
export class BalanceCardTermsComponent implements OnInit {
  public lang:any = '';
  public pageObj:any = {};
  public showDetail:any = false;
  constructor(
    private coreService: CoreService,
    public helperService: HelperService
  ) { }

  ngOnInit(): void {
    this.showDetail = false;
    this.coreService.get(`${ENDPOINTS.STATIC.CMSPAGES}`+'balance-card-terms', {}).subscribe((res) => {
      if (res.body.success) {
        this.pageObj = res.body.data;
      } else {
        this.pageObj = [];
      }
    }, (err) => {
    });
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang)
  }

  showDetailPage(){
    this.showDetail = !this.showDetail;
  }
}
