import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [TranslateModule]
})
export class I18nModule {
  public langList = [{"language_name":"한국어","lang_code":"ko","lang_direction":"ltr","id":"61740502f88d9d3ea9dca163"},{"language_name":"Italiano","lang_code":"it","lang_direction":"ltr","id":"617404d7f88d9d3ea9dca162"},{"language_name":"tiếng Việt","lang_code":"vi","lang_direction":"ltr","id":"6174048ff88d9d3ea9dca161"},{"language_name":"Português","lang_code":"pt","lang_direction":"ltr","id":"61740440f88d9d3ea9dca0e5"},{"language_name":"Deutsch","lang_code":"de","lang_direction":"ltr","id":"6174041cf88d9d3ea9dca0e4"},{"language_name":"Español","lang_code":"es","lang_direction":"ltr","id":"617403ebf88d9d3ea9dca0e2"},{"language_name":"Français","lang_code":"fr","lang_direction":"ltr","id":"617403b8f88d9d3ea9dca0e1"},{"language_name":"Русский","lang_code":"ru","lang_direction":"ltr","id":"61740377f88d9d3ea9dca0e0"},{"language_name":"العربية","lang_code":"ar","lang_direction":"rtl","id":"617402da98ee732bce1915fc"},{"language_name":"简体中文","lang_code":"zh","lang_direction":"ltr","id":"6173d2db98ee732bce190fa4"},{"language_name":"English","lang_code":"en","lang_direction":"ltr","id":"6173d2c698ee732bce190fa3"}];
  constructor(translate: TranslateService) {
    let langList:any = localStorage.getItem('supportedLang') != null ? localStorage.getItem('supportedLang') : JSON.stringify(this.langList);
    this.langList = JSON.parse(langList);
    let langCode = [];
    for (let index = 0; index < this.langList.length; index++) {
      langCode.push(this.langList[index].lang_code);      
    }
    translate.addLangs(langCode);
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|ar/) ? browserLang : 'en');
  }
}

export function translateLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}