<app-header [pageName]="'topup'"></app-header>


<nav id="guide-template" class="navbar navbar-expand-lg text-center newHea">
 <div class="container">
  <div class="collapse navbar-collapse" id="main_nav">
         <div class="div-shop">
            <div class="title-heading">
               <ul class="list-unstyled three-menu">
                  <li>
                     <a (click)="goToNavigation('shop');"><img src="/assets/img/ele-icon.png" /> <span>Electronics</span></a>
                  </li>
                  <li>
                     <a (click)="goToNavigation('gift');"><img src="/assets/img/gift-icon.png" /> <span >Gift Cards</span></a>
                  </li>
                  <li>
                     <a (click)="goToNavigation('topup');"><img src="/assets/img/top-icon.png" /> <span class="active">Top-Up</span></a>
                  </li>
                  <li>
                    <a (click)="goToNavigation('games')"><img src="/assets/img/game.svg" style="width: 30px;" /> <span>Games</span></a>
                    </li>
               </ul>
            </div>
         </div>
  </div> <!-- navbar-collapse.// -->
 </div> <!-- container-fluid.// -->
</nav>



 
<section>

        <div class="row d-flex align-items-center justify-content-center mt-3 mb-5">
            <div class="col-md-7 col-lg-6">
                <div class="title-heading">
                    <h5>Top-up prepaid mobile phones with Bitcoin and other cryptocurrencies from anywhere in the world.</h5>
                </div>
                <div class="phonenumber-box">
                    <select [(ngModel)]="country" (change)="getOperatorLists()" style="width: 150px;">
                        <option *ngFor="let country of countryLists;" value="{{country.iso_code}}">{{country.name}} +{{country.countrycode}}</option>
                    </select>
                    <input type="text" name="mobile_number" (keydown)="onKeyDownEvent($event)" placeholder="1234567890" [(ngModel)]="mobile_number"  (input)="numbersOnlyValidator($event)"/>
                    <button type="button" class="number-btn waves-effect waves-light" (click)="doLookupMobile()">Check</button>
                </div>
            </div>
        </div>
  
</section>
<section class="mb-5">
    <div class="container">
        <div class="row mycenter">
            <div class="col-6 col-sm-4 col-md-4 col-lg-3" *ngFor="let operatorRow of operators;">
                <a (click)="goToOperatorDetailsPage(operatorRow);" title="{{operatorRow.name}}">
                    <div class="topupbox">
                        <img src="{{imgBaseUrl}}operator/{{operatorRow.country.iso_code}}/{{operatorRow.name}}.png" alt="{{operatorRow.name}}"/>
                    </div>
                </a>
            </div>
        </div>
        <div *ngIf="isLoading" id="loader"></div>
        <div class="accordion md-accordion mt-0" id="accordionEx" role="tablist" aria-multiselectable="true" *ngIf="!isLoading && operators.length==0">
            <div class="col-md-12 alert alert-danger">
                Record not found, {{errorMsg}}
            </div>
        </div>
    </div>
</section>

<section class="text-center py-3">
    <div class="container">
        <div class="row d-flex align-items-center justify-content-center">
            <div class="col-md-10 col-lg-10">
                <div class="title-heading">
                    <h5><span class="font-28">Pay with your <b class="text-yellow">cryptos.</b></span> More then 60 cryptocoins are accepted here! <br> Bitcoin, Ethereum, XRP, BNB, Litecoin, Doge.</h5>
                </div>
                <div class="logo-icons">
                    <img src="/assets/img/coins.webp" />
                </div>
                <div class="css-1b9jzri--2">
                    <div style="color: var(--text-primary);" class="css-ezg0pp--2">
                        <div>
                            <img src="/assets/img/images_checkmark.svg" width="21" height="21" />
                        </div>
                        -	Blockchain Based Payments.
                    </div>
                    <div style="color: var(--text-primary);" class="css-ezg0pp--2">
                        <div>
                            <img src="/assets/img/images_checkmark.svg" width="21" height="21" />
                        </div>
                        - Easy, Fast and Secure.
                    </div>
                    <div style="color: var(--text-primary);" class="css-ezg0pp--2">
                        <div>
                            <img src="/assets/img/images_checkmark.svg" width="21" height="21" />
                        </div>
                        -	Pay Instantly, Anywhere and Anytime.
                    </div>
                    <div style="color: var(--text-primary);" class="css-ezg0pp--2">
                        <div>
                            <img src="/assets/img/images_checkmark.svg" width="21" height="21" />
                        </div>
                       - Pay with Stable Coins.
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="gradation-process-area bg-gray section-space--ptb_100 top1">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="gradation-title-wrapper section-space--mb_60">
                    <div class="gradation-title-wrap">
                        <h6 class="section-sub-title text-black mb-20">How Top-up Cards Work</h6>
                        <h4 class="heading">Get Top-up anywhere, anytime - Top-up for 500+ brands</h4>
                    </div>
                    <div class="gradation-sub-heading">
                        <h3 class="heading"><mark>03</mark> Steps</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="ht-gradation style-01">
                    <!-- Single item gradation Start -->
                    <div class="item item1 item-1 animate wow fadeInRight" data-wow-delay="0.1s">
                        <div class="line"></div>
                        <div class="circle-wrap">
                            <div class="mask">
                                <div class="wave-pulse wave-pulse-1"></div>
                                <div class="wave-pulse wave-pulse-2"></div>
                                <div class="wave-pulse wave-pulse-3"></div>
                            </div>
                            <h6 class="circle">1</h6>
                        </div>
                        <div class="content-wrap">
                            <h6 class="heading">01. Enter a phone number & amount</h6>
                            <div class="text">Type in the phone number and the amount you want us to Top-up.</div>
                        </div>
                    </div>
                    <!-- Single item gradation End -->
                    <!-- Single item gradation Start -->
                    <div class="item item1 item-1 animate wow fadeInRight" data-wow-delay="0.15s">
                        <div class="line"></div>
                        <div class="circle-wrap">
                            <div class="mask">
                                <div class="wave-pulse wave-pulse-1"></div>
                                <div class="wave-pulse wave-pulse-2"></div>
                                <div class="wave-pulse wave-pulse-3"></div>
                            </div>
                            <h6 class="circle">2</h6>
                        </div>
                        <div class="content-wrap">
                            <h6 class="heading">02. Pay with <span class="text-yellow">Cryptos</span></h6>
                            <div class="text">Your payment is confirmed the same minute in most Top-ups.</div>
                        </div>
                    </div>
                    <!-- Single item gradation End -->
                    <!-- Single item gradation Start -->
                    <div class="item item1 item-1 animate wow fadeInRight" data-wow-delay="0.20s">
                        <div class="line"></div>
                        <div class="circle-wrap">
                            <div class="mask">
                                <div class="wave-pulse wave-pulse-1"></div>
                                <div class="wave-pulse wave-pulse-2"></div>
                                <div class="wave-pulse wave-pulse-3"></div>
                            </div>
                            <h6 class="circle">3</h6>
                        </div>
                        <div class="content-wrap">
                            <h6 class="heading">03. That's it, here is your card</h6>
                            <div class="text">Once your payment is confirmed, you will get your Top-up card sent out.</div>
                        </div>
                    </div>
                    <!-- Single item gradation End -->
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section-space--ptb_100 more-prduct-bg">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h4>
                    <b>Have more questions? <span>Check out our <a href="https://help.cryptomate.com.cn/" target="_blank" class="yellow-color1">FAQ</a> page </span></b>
                </h4>
            </div>
        </div>
    </div>
</div>
<app-login-modal></app-login-modal>
