import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth-guard/auth.guard';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ContactComponent } from './contact/contact.component';
import { CryptosComponent } from './cryptos/cryptos.component';
import { GiftDesignerComponent } from './gift-designer/gift-designer.component';
import { GiftDetailComponent } from './gift-detail/gift-detail.component';
import { GiftComponent } from './gift/gift.component';
import { LandingComponent } from './landing/landing.component';
import { LoyaltyProgrameDetailComponent } from './loyalty-programe-detail/loyalty-programe-detail.component';
import { LoyaltyProgrameComponent } from './loyalty-programe/loyalty-programe.component';
import { OutsourceComponent } from './outsource/outsource.component';
import { ShopDetailComponent } from './shop-detail/shop-detail.component';
import { ShopComponent } from './shop/shop.component';
import { TopupDetailComponent } from './topup-detail/topup-detail.component';
import { TopupComponent } from './topup/topup.component';
import { WepayComponent } from './wepay/wepay.component';
import { FaqComponent } from './faq/faq.component';
import { CareerComponent } from './career/career.component';
import { AboutComponent } from './about/about.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { BalanceCardComponent } from './balance-card/balance-card.component';
import { OrderConfirmComponent } from './order-confirm/order-confirm.component';
import { ReferAFriendComponent } from './refer-afriend/refer-afriend.component';
import { ProductRequestComponent } from './product-request/product-request.component';
import { OutsourcingJoinComponent } from './outsourcing-join/outsourcing-join.component';
import { LoyaltyProgramTermsComponent } from './loyalty-program-terms/loyalty-program-terms.component';
import { BalanceCardTermsComponent } from './balance-card-terms/balance-card-terms.component';
import { ReferAFriendsTermsConditionComponent } from './refer-afriends-terms-condition/refer-afriends-terms-condition.component';
import { BestSellersComponent } from './best-sellers/best-sellers.component';
import { GameProductsComponent } from './game-products/game-products.component';

const routes: Routes = [
  {path: "",component:LandingComponent},
  {path: "home",component:LandingComponent},
  {path: "signup",component:LandingComponent},
  {path: "shop", component:ShopComponent},
  {path: "shop-detail/:slug", component:ShopDetailComponent},
  {path: "cart", component:CartComponent},
  {path: "checkout", component:CheckoutComponent},
  {path: "games", component:BestSellersComponent},
  {path: "game-products/:slug", component:GameProductsComponent},
  {path: "OOOTC", component:CryptosComponent},
  {path: "contact-us", component:ContactComponent},
  {path: "gift", component:GiftComponent},
  {path: "gift-designer", component:GiftDesignerComponent},
  {path: "gift-detail/:slug", component:GiftDetailComponent},
  {path: "cryptomate-balance-card", component:BalanceCardComponent},
  {path: "cryptomate-balance-card-terms", component:BalanceCardTermsComponent},
  {path: "loyalty-program", component:LoyaltyProgrameComponent},
  {path: "loyalty-program/:slug", component:LoyaltyProgrameDetailComponent},
  {path: "loyalty-program-terms", component:LoyaltyProgramTermsComponent},
  {path: "order-confirm/:orderID", component:OrderConfirmComponent},
  {path: "outsourcing", component:OutsourceComponent},
  {path: "outsourcing-join",component:OutsourcingJoinComponent},
  {path: "topup", component:TopupComponent},
  {path: "topup-detail/:countryISO/:operatorID", component:TopupDetailComponent},
  {path: "weepaay", component:WepayComponent},
  {path: "dashboard",component:LandingComponent,canActivate: [AuthGuard]},
  {path: "faq", component:FaqComponent},
  {path: "career", component:CareerComponent},
  {path: "about-us",component:AboutComponent},
  {path: "terms",component:TermsComponent},
  {path: "product-request",component:ProductRequestComponent},
  {path: "refer-a-friend",component:ReferAFriendComponent},
  {path: "refer-afriends-terms-condition",component:ReferAFriendsTermsConditionComponent},
  {path: "privacy-policy",component:PrivacyComponent},
  {path: "404", component:NotFoundComponent},
  { path: 'user/:any', redirectTo: '/user/:any', pathMatch: 'full' },
  {path: 'user', data: { hideHeader: true }, canActivate: [AuthGuard], loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
  { path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
