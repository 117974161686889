<ul class="list-unstyled bradcam">
    <li>
        <a (click)="goToNavigation('shop');"><img src="/assets/img/ele-icon.png" /> <span>Electronics</span></a>
    </li>
    <li>
        <a (click)="goToNavigation('gift');"><img src="/assets/img/gift-icon.png" /> <span class="active">Gift Card</span></a>
    </li>
    <li>
        <a (click)="goToNavigation('topup');"><img src="/assets/img/top-icon.png" /> <span>Topup</span></a>
    </li>
    <li>
        <a (click)="goToNavigation('games')"
            ><img src="/assets/img/game.svg" style="width: 30px;" /> <span>Games</span></a
        >
    </li>
</ul>