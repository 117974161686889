import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-outsourcing-join',
  templateUrl: './outsourcing-join.component.html',
  styleUrls: ['./outsourcing-join.component.css']
})
export class OutsourcingJoinComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
