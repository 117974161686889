import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/shared/services/helper-service.service';
declare var $: any;

@Component({
  selector: 'app-gift-validation',
  templateUrl: './gift-validation.component.html',
  styleUrls: ['./gift-validation.component.css']
})
export class GiftValidationComponent implements OnInit {
  public lang:any = '';
  constructor(
    private helperService: HelperService
  ) { }

  ngOnInit(): void {
  }

  goToNavigation(url:any) {
    $("#giftvoucher").modal("hide");
    this.helperService.navigate(url, this.lang);
  }
}
