import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { CoreService } from 'src/app/shared/services/core.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { HelperService } from '../../shared/services/helper-service.service';
import { environment } from 'src/environments/environment';
import {ToastrService} from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.css']
})
export class WishlistComponent implements OnInit {

  public imgBaseUrl = environment.AWSImageURL;
  public wishlistForm!: FormGroup;
  public isFormSubmit: boolean = false;
  public isWishlistFatching: boolean = true;
  public wishlistProducts: boolean = false;
  public isLoading: boolean = false;
  public lang: any;
  public wishlistList:any  = [];
  public wishlistDetails:any  = {};
  public wishlistProductList:any  = [];
  public wishlistFormObj: any = {
    isFormSubmit: false
  }
  public queryParam:any = [];

  constructor(
    private toast: ToastrService,
    private fb: FormBuilder,
    public coreService: CoreService,
    public helperService: HelperService,
    private route: ActivatedRoute
  ) {

    this.lang = localStorage.getItem('DefaultLangSet') != null ? localStorage.getItem('DefaultLangSet') : 'en';
    this.route.queryParams.subscribe(params => {
      if (!params) return;
      this.queryParam = { ...params };
      if (this.queryParam.hasOwnProperty('wishlist')) {
        this.wishlistDetails = {
          wishlist_name : this.queryParam.name
        };
        this.wishlistProducts = true;
        this.isWishlistFatching = true;
        const paylaod = {
          'wishlist_id' : this.queryParam.wishlist
        }
        this.coreService.post(`${ENDPOINTS.WISHLIST.PRODUCT_LISTS}`, paylaod).subscribe((res) => {
          this.isWishlistFatching = false;
          if (res.body.success) {
            this.wishlistProductList = res.body.data;
          } else {
            this.wishlistProductList = [];
          }
        }, (err) => {
        });
      }
    });
   }

  ngOnInit(): void {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    this.wishlistForm = this.fb.group({
      wishlist_name: ['', [Validators.required]]
    });
    this.getMyWishlist();
  }

  get get() { return this.wishlistForm.controls; }

  /**
   * @method: createNow
   * @description: Using to create User Wishlist
   * @param formData
   */
   createNow(formData:any) {
    this.wishlistFormObj.isFormSubmit = true;
    if (this.wishlistForm.valid && !this.isLoading) {
      this.isLoading = true;
      this.wishlistFormObj.isFormSubmit = false;
      const paylaod = {
        ...formData
      }
      this.coreService.post(`${ENDPOINTS.WISHLIST.CREATE}`, paylaod).subscribe((res) => {
        this.isLoading = false;
        if(res.body.success){
          this.wishlistList.push(res.body.data);
          this.toast.success(res.body.message, "Success!");
          $("#addnewwishlist").modal("hide");
        }else{
          this.toast.error(res.body.message, "Error!");
        }
      }, (err) => {
        this.isLoading = false;
        this.toast.error(err.body.message, "Error!");
      });
    }
  }

  getMyWishlist(){
    this.isWishlistFatching = true;
    this.coreService.get(`${ENDPOINTS.WISHLIST.LIST}`, {}).subscribe((res) => {
      this.isWishlistFatching = false;
      if (res.body.success) {
        this.wishlistList = res.body.data;
      } else {
        this.wishlistList = [];
      }
    }, (err) => {
    });
  }

  viewDetails(obj:any){
    this.wishlistDetails = obj;
    this.wishlistProducts = true;
    this.isWishlistFatching = true;
    const paylaod = {
      'wishlist_id' : obj.id
    }
    this.coreService.post(`${ENDPOINTS.WISHLIST.PRODUCT_LISTS}`, paylaod).subscribe((res) => {
      this.isWishlistFatching = false;
      if (res.body.success) {
        this.wishlistProductList = res.body.data;
      } else {
        this.wishlistProductList = [];
      }
    }, (err) => {
    });
  }

  goBack(){
    this.wishlistProducts = false;
  }

  deleteWishlist(obj:any){
    let c = confirm("Are you sure?");
    if(c){
      const paylaod = {
        'wishlist_id' : obj.id
      }
      this.coreService.post(`${ENDPOINTS.WISHLIST.DELETE}`, paylaod).subscribe((res) => {
        if (res.body.success) {
          this.toast.success(res.body.message, "Success!");
          this.getMyWishlist();
        }
      }, (err) => {
        this.toast.error(err.body.message, "Error!");
      });
    }
  }

  doDeleteProduct(obj:any){
    let c = confirm("Are you sure?");
    if(c){
      const paylaod = {
        'id' : obj.id
      }
      this.coreService.post(`${ENDPOINTS.WISHLIST.PRODUCT_DELETE}`, paylaod).subscribe((res) => {
        if (res.body.success) {
          this.toast.success(res.body.message, "Success!");
          var index =this.wishlistProductList.indexOf(obj);
			    this.wishlistProductList.splice(index, 1);
        }
      }, (err) => {
        this.toast.error(err.body.message, "Error!");
      });
    }
  }

  goToProductDetailPage(obj:any) {
    //this.helperService.navigate(`/shop-detail/${obj.product_slug}`, this.lang);
    window.location.href = '/shop-detail/'+obj.product_slug;
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang);
  }
}
