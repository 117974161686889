<app-header></app-header>
<section *ngIf="!showDetail" class="text-center mb-2 mt-3 about_new caree">
    <div class="container">
        <div class="row">
            <div class="col-sm-4 text-left newpage">
                 <h1>Career Growth</h1>
                <p>Join our team now, and help us develop the bridge between cryptocurrencies and E-commerce.  

We should replace 
</p>
                <p><a href="javascript:;" (click)="showDetailPage()">Read More</a> </p>
            </div>
        </div>
    </div>
</section>
<section class="mb-2 pt-6 mt-3 mt-50" *ngIf="showDetail">
    <div class="container">

 <div class="col-sm-12">
                <ul class="list-unstyled bradcam">
                    <li><a (click)="goToNavigation('home');">Home</a></li>
                    <li>
                        <a (click)="goToNavigation('career');"><i class="fas fa-angle-double-right iconarrw"></i> Career</a>
                    </li>
                </ul>
            </div>


        <div class="row d-flex align-items-center justify-content-center mt-3">
            <div class="title-heading">
                <!--<h2 class="h1 pb-3" *ngIf="!applyJob">Careers</h2>-->
                <h2 class="h1 pb-3" *ngIf="applyJob">Applying For {{applyJobObj.job_title}}</h2>
            </div>
        </div>

        <div class="row d-flex justify-content-center" *ngIf="!applyJob">

           <div class="col-md-12 " *ngFor="let jobs of jobLists;">
            <div class="jobcenter">
                <h2>{{jobs.job_title}}</h2>
				<div [innerHtml]="jobs.job_description"></div>
				<a (click)="doJobApply(jobs)" class="joinnow-btn waves-effect waves-light mt-3">Apply Now </a>
			</div>
        </div>
            

<div class="col-md-12 notfound ">


                                           <img src="/assets/img/no openings.svg" />


                                           No Openings at the moment, please come back later!<br>

                                           <a (click)="goToNavigation('shop')">Home</a>
                                        </div>


        </div>

        <div class="row d-flex justify-content-center" *ngIf="applyJob">
             <div class="col-md-1 "></div>
            <div class="col-md-10">
                <div class="contat-box">
                    <div class="row d-flex justify-content-center mt-3">
                        <div class="col-sm-12">
                            <h5 class="font-weight-normal mb-1">Drop us a resume</h5>
                        </div>
                        <form class="col-md-12 col-lg-12 save-addreform" [formGroup]="applyFormObj">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="md-form">
                                        <input class="form-control" type="text" id="full_name" name="full_name" required="required" formControlName="full_name" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.full_name?.errors }"/>
                                        <label for="full_name">Full Name*</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="md-form">
                                        <input class="form-control" type="email" id="email" name="email" required="required" formControlName="email" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.email?.errors }"/>
                                        <label for="email">Email*</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="md-form">
                                        <input class="form-control" type="text" id="phone" name="phone" required="required" formControlName="phone" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.phone?.errors }"/>
                                        <label for="phone">Phone*</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="md-form">
                                        <input class="form-control" type="text" id="city" name="city" required="required" formControlName="city" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.city?.errors }"/>
                                        <label for="city">City</label>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="md-form">
                                        <textarea id="form7" class="md-textarea form-control billing-textare" rows="3" required="required" formControlName="message" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.message?.errors }"></textarea>
                                        <label for="form7">Your Message</label>
                                    </div>
                                </div>




								<div class="col-sm-12">
									<div class="file-upload-wrapper">
										<p>Upload a Resume</p>
										<div class="card card-body view file-upload" style="box-shadow:none">
											<div class="card-text file-upload-message">
												<i class="far fa-file-alt"></i>
												<p>
													<span class="imgcolorupload">Drag & drop</span> files here or <br />
													<span class="imgcolorupload">Browse</span> to begin the upload
												</p>
                                                <p><small>Only image/PDF file are accepted ( Max size 1MB )</small></p>
											</div>
											<input type="file" id="input-file-max-fs" class="file_upload" data-max-file-size="2M" (change)="handleInputChange($event)"/>
										</div>
									</div>
								</div>
                                <div class="col-sm-12">
                                    <button [disabled]="isLoading" type="button" class="joinnow-btn waves-effect waves-light mt-3" (click)="doSubmitApplyForm(applyFormObj.value)">Apply Now</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>