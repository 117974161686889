<div id="sticky-anchor"></div>
<a href="javascript:;" id="filterfixed" class="filte-category waves-effect waves-light" data-toggle="modal" data-target="#dashbaordmenu"> <i class="fas fa-bars"></i> Dashboard Menu</a>
<div class="categoerybox-mnone">
    <div class="dashboard-header_conatiner fl-wrap">
        <div class="dashboard-header-avatar">
            <img *ngIf="imageSrc" src="{{imageSrc}}" alt="{{profileObj.full_name}}" />
            <img *ngIf="!imageSrc && profileObj.profile_image" src="{{imgBaseUrl}}profile/{{profileObj.profile_image}}" alt="{{profileObj.full_name}}" />
            <img *ngIf="!imageSrc && !profileObj.profile_image" src="/assets/img/blank-profile.png" alt="{{profileObj.full_name}}" />
            <div class="avatar-edit">
                <input type="file" id="imageUpload" name="avatar" type="file" accept="image/*" (change)="handleInputChange($event)"/>
                <label for="imageUpload"><i class="fas fa-pencil-alt"></i></label>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
    <div class="fixed-bar fl-wrap">
        <div class="user-profile-menu-wrap fl-wrap block_box">
            <div class="user-profile-menu">
                <h3>{{profileObj.full_name}}</h3>
                <h6>{{profileObj.email}}</h6>
                <span class="verifybade" *ngIf="!profileObj.is_document_verified && profileObj.email_validate">Verified <i class="fas fa-check-circle"></i></span>
                <span class="verifybade" *ngIf="profileObj.is_document_verified">Tier 2 <i class="fas fa-check-circle"></i></span>
                <ul class="no-list-style">
                    <li><a (click)="goToNavigation('user/my-products');" routerLink="/user/my-products" routerLinkActive="user-profile-act">My Orders</a></li>
                    <li><a (click)="goToNavigation('user/my-record');" routerLink="/user/my-record" routerLinkActive="user-profile-act">My Record </a></li>
                    <li><a (click)="goToNavigation('user/wallet');" routerLink="/user/wallet" routerLinkActive="user-profile-act">Wallet</a></li>
                    <li><a (click)="goToNavigation('user/wishlist');" routerLink="/user/wishlist" routerLinkActive="user-profile-act">Wishlists</a></li>
                    <li><a (click)="goToNavigation('user/referral');" routerLink="/user/referral" routerLinkActive="user-profile-act">Referrals</a></li>
                    <li><a (click)="goToNavigation('user/redeem-balance-card');" routerLink="/user/redeem-balance-card" routerLinkActive="user-profile-act">Redeem Balance  Card</a></li>
                    <li><a (click)="goToNavigation('user/batch-refill');" routerLink="/user/batch-refill" routerLinkActive="user-profile-act">Batch Refill</a></li>
                    <li><a (click)="goToNavigation('user/verify');" routerLink="/user/verify" routerLinkActive="user-profile-act">Verify Account</a></li>
                    <li><a (click)="goToNavigation('user/settings');" routerLink="/user/settings" routerLinkActive="user-profile-act">Settings</a></li>
                </ul>
            </div>
            <button type="button" (click)="logOut();" class="logout_btn waves-effect waves-light">Sign out</button>
        </div>
    </div>
    <div class="clearfix"></div>
</div>

<!-- dashboard-menu  end-->
<!---================ MOBILE CATEGORY FILTER VIEW HTML ==============------------->
<!-- Modal -->
<div class="modal left fade" id="dashbaordmenu" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="border-radius: 0px;">
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel">Dashboard Menu</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="dashboard-header_conatiner fl-wrap">
                    <div class="dashboard-header-avatar">
                        <img *ngIf="imageSrc" src="{{imageSrc}}" alt="{{profileObj.full_name}}" />
                        <img *ngIf="!imageSrc && profileObj.profile_image" src="{{imgBaseUrl}}profile/{{profileObj.profile_image}}" alt="{{profileObj.full_name}}" />
                        <img *ngIf="!imageSrc && !profileObj.profile_image" src="/assets/img/blank-profile.png" alt="{{profileObj.full_name}}" />
                        <div class="avatar-edit">
                            <input type="file" id="imageUpload" name="avatar" type="file" accept="image/*" (change)="handleInputChange($event)"/>
                            <label for="imageUpload"><i class="fas fa-pencil-alt"></i></label>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="fixed-bar fl-wrap">
                    <div class="user-profile-menu-wrap fl-wrap block_box">
                        <!-- user-profile-menu-->
                        <div class="user-profile-menu">
                            <h3>{{profileObj.full_name}}</h3>
                            <h6>{{profileObj.email}}</h6>
                            <span class="verifybade" *ngIf="profileObj.email_validate">Verified <i class="fas fa-check-circle"></i></span>
                            <ul class="no-list-style">
                                <li><a (click)="goToNavigation('user/my-products');" routerLink="/user/my-products" routerLinkActive="user-profile-act">My Orders</a></li>
                                <li><a (click)="goToNavigation('user/my-record');" routerLink="/user/my-record" routerLinkActive="user-profile-act">My Record </a></li>
                                <li><a (click)="goToNavigation('user/wallet');" routerLink="/user/wallet" routerLinkActive="user-profile-act">Wallet</a></li>
                                <li><a (click)="goToNavigation('user/wishlist');" routerLink="/user/wishlist" routerLinkActive="user-profile-act">Wishlists</a></li>
                                <li><a (click)="goToNavigation('user/referral');" routerLink="/user/referral" routerLinkActive="user-profile-act">Referrals</a></li>
                                <li><a (click)="goToNavigation('user/redeem-balance-card');" routerLink="/user/redeem-balance-card" routerLinkActive="user-profile-act">Redeem Balance Card</a></li>
                                <li><a (click)="goToNavigation('user/batch-refill');" routerLink="/user/batch-refill" routerLinkActive="user-profile-act">Batch Refill</a></li>
                                <li><a (click)="goToNavigation('user/verify');" routerLink="/user/verify" routerLinkActive="user-profile-act">Verify Account</a></li>
                                <li><a (click)="goToNavigation('user/settings');" routerLink="/user/settings" routerLinkActive="user-profile-act">Settings</a></li>
                            </ul>
                        </div>
                        <!-- user-profile-menu end-->
                        <!-- user-profile-menu end-->
                        <button type="button" (click)="logOut();" class="logout_btn waves-effect waves-light">Sign out</button>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <!-- modal-content -->
    </div>
    <!-- modal-dialog -->
</div>
<!-- modal -->
<!---================ END MOBILE CATEGORY FILTER VIEW HTML ==============------------->