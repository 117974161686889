import { Component, OnInit } from '@angular/core';
import { HelperService } from '../../shared/services/helper-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { CoreService } from 'src/app/shared/services/core.service';
import {ToastrService} from 'ngx-toastr';
import { CRYPTO_CURRENCY } from 'src/app/shared/constants/app.crypto-currency.constants';
declare var $: any;
@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})

export class SettingComponent implements OnInit {
  public CyrptCurrency:any = CRYPTO_CURRENCY;
  public lang:any = '';
  public profileObj:any = {
    'email_validate' : true
  };
  public updatePassword!: FormGroup;
  public userProfileForm!: FormGroup;
  public isFormSubmit: boolean = false;
  public isLoading: boolean = false;
  public resetPasswordForm:boolean = false;
  public resetPasswordFormSubmit:boolean = false;
  public otpConfirmForm:boolean = false;
  public verificationCode:string = '';
  public profileFormObj: any = {
    isFormSubmit: false
  }
  public userEmail:any = '';

  constructor(
    private toast: ToastrService,
    private coreService: CoreService,
    private fb: FormBuilder,
    private helperService: HelperService
  ) { }

  ngOnInit(): void {
    let profileObj:any = localStorage.getItem('currentUser') != null ? localStorage.getItem('currentUser') : '{"name":""}';
    this.profileObj = JSON.parse(profileObj);
    
    this.userProfileForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      full_name: ['', [Validators.required]],
      about_info: [''],
    });

    this.updatePassword = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      forgot_code: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });

    this.getMyProfile();
  }

  get profileget() { return this.userProfileForm.controls; }
  get pwdget() { return this.updatePassword.controls; }

  getMyProfile(){
    this.coreService.get(`${ENDPOINTS.USER_PROFILE.GET}`, {}).subscribe((res) => {
      if (res.body.success) {
        this.profileObj = res.body.data;
        this.formPatch(res.body.data);
      } else {
        this.profileObj = {};
      }
    }, (err) => {
    });
  }

  formPatch(data:any) {
    this.userProfileForm.patchValue({
      id: data?.id ? data?.id : data?.user_id?.id,
      full_name: data?.full_name ? data?.full_name : data?.user_id?.full_name,
      email: data?.email ? data?.email : data?.user_id?.email,
      about_info: data?.about_info ? data?.about_info : data?.user_id?.about_info
    });
    this.userEmail = data.email;
    localStorage.setItem('currentUser', JSON.stringify(data));
    //this.userProfileForm.controls["email"].disable();
  }

  doUpdateCurrency(){
    this.profileFormObj.isFormSubmit = true;
    this.isLoading = true;
    const paylaod = {
      'currency_code' : this.profileObj.currency_code
    }
    localStorage.setItem('currency_code', this.profileObj.currency_code);
    this.coreService.post(`${ENDPOINTS.USER_PROFILE.UPDATE}`, paylaod).subscribe((res) => {
      this.profileFormObj.isFormSubmit = false;
      this.isLoading = false;
      this.profileObj = res.body.data;
      this.formPatch(res.body.data);
      this.toast.success(res.body.message, "Success!");
    }, (err) => {
      this.profileFormObj.isFormSubmit = false;
      this.isLoading = false;
      this.toast.success(err.body.message, "Error!");
    });
  }

  updateProfile(formObj:any){
    this.profileFormObj.isFormSubmit = true;
    if (this.userProfileForm.valid) {
      this.isLoading = true;
      const paylaod = {
        ...formObj
      }
      this.coreService.post(`${ENDPOINTS.USER_PROFILE.UPDATE}`, paylaod).subscribe((res) => {
        this.profileFormObj.isFormSubmit = false;
        this.isLoading = false;
        this.profileObj = res.body.data;
        this.formPatch(res.body.data);
        this.toast.success(res.body.message, "Success!");
      }, (err) => {
        this.profileFormObj.isFormSubmit = false;
        this.isLoading = false;
        this.toast.success(err.body.message, "Error!");
      });
    }
  }

  sendForgotLink(){
    const paylaod = {
      'email' : this.userEmail
    }
    this.coreService.post(`${ENDPOINTS.AUTH.FORGOT_PASSWORD}`, paylaod).subscribe((res) => {
      if(res.body.success){
        this.updatePassword.patchValue({
          email: this.userEmail
        });
        this.toast.success(res.body.message, "Success!");
        this.resetPasswordForm = true;
      }
    }, (err) => {
      this.helperService.scrollUpPage(100);
    });
  }

  resetPassword(data:any){
    this.resetPasswordFormSubmit = true;
    if (this.updatePassword.valid) {
      this.isLoading = true;
      const paylaod = {
        ...data
      }
      this.coreService.post(`${ENDPOINTS.AUTH.RESET_PASSWORD}`, paylaod).subscribe((res) => {
        this.resetPasswordFormSubmit = false;
        this.isLoading = false;
        if(res.body.success){
          this.resetPasswordForm = false;
          this.updatePassword.reset();
          this.toast.success(res.body.message, "Success!");
        }
      }, (err) => {
        this.isLoading = false;
        this.resetPasswordFormSubmit = false;
      });
    }
  }

  sendVerificationLink(){
    const paylaod = {
      'email' : this.userEmail,
      'user_id' : this.profileObj.id
    }
    this.coreService.post(`${ENDPOINTS.USER_PROFILE.SEND_VERIFICATION}`, paylaod).subscribe((res) => {
      if(res.body.success){
        this.otpConfirmForm = true;
        this.toast.success(res.body.message, "Success!");
      }
    }, (err) => {
      this.toast.error(err.body.message, "Error!");
      this.helperService.scrollUpPage(100);
    });
  }
  
  confirmVerification(){
    const paylaod = {
      'email' : this.userEmail,
      'forgot_code' : this.verificationCode
    }
    this.coreService.post(`${ENDPOINTS.USER_PROFILE.CONFIRM_VERIFICATION}`, paylaod).subscribe((res) => {
      if(res.body.success){
        this.resetPasswordForm = false;
        this.profileObj.email_validate = true;
        this.toast.success(res.body.message, "Success!");
      }
    }, (err) => {
      this.helperService.scrollUpPage(100);
    });
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang)
  }

  showPassword(str:string){
    $("#"+str).attr("type","text");
    $(".hide_"+str).removeClass("hide");
    $(".show_"+str).addClass("hide");
  }

  hidePassword(str:string){
    $("#"+str).attr("type","password");
    $(".hide_"+str).addClass("hide");
    $(".show_"+str).removeClass("hide");
  }
}
