<app-headers></app-headers>
<section class="text-center mb-2 settingTabs">
    <app-user-breadcrumb></app-user-breadcrumb>
</section>
<section class="mb-5 profileView">
    <div class="container">
        <div class="row">
            <div class="col-md-5 col-lg-3">
                <app-user-left-menu></app-user-left-menu>
            </div>
            <div class="col-md-7 col-lg-9">
                <div class="rightbox-dashbord">
                    <div *ngIf="isLoading" id="loader"></div>
                    <div class="row">
                        

                        <div class="col-sm-12 col-md-12 col-lg-9">
                            <div class="row walletbx-cal">
                                <div class="col-5 col-md-6 col-sm-6 col-lg-3">
                                    <div id="date-picker-example" class="md-form md-outline input-with-post-icon datepicker">
                                        <input placeholder="Select date" type="date" id="example" class="form-control" style="padding-right: 0.2rem !important;" [(ngModel)]="start_date"/>
                                        <label for="example" class="active">Date From</label>
                                    </div>
                                </div>
                                <div class="col-5 col-md-6 col-sm-6 col-lg-3">
                                   <div id="date-picker-example" class="md-form md-outline input-with-post-icon datepicker">
                                        <input placeholder="Select date" type="date" id="example" class="form-control" style="padding-right: 0.2rem !important;" [(ngModel)]="enddate"/>
                                        <label for="example" class="active">Date to</label>                                        
                                    </div>
                                </div>
                                <div class="col-2 m-pad col-md-6 col-sm-6 col-lg-2">
                                    <div class="search-box">
                                       <button class="btn" (click)="doSearchWallet();"> <i class="fas fa-search"></i></button>
                                    </div>
                                </div>
                                <div class="col-12 m-pad col-sm-6 col-md-6 col-lg-4" *ngIf="myTotalWalletBalance>0">
                                    <a (click)="clearLoginForm()" data-toggle="modal" data-target="#withdraw-wallet" class="withdraw-btn waves-effect waves-light">Withdrawal Amount</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3">
                            <h4 class="list-heading"><a (click)="goToNavigation('user/redeem-balance-card');" routerLink="/user/redeem-balance-card">Recharge Wallet</a></h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                            <p class="first-wallet">Your wallet  transactions</p>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6">
                            <p class="seocnd-wallet">Current Balance Amount: <span>{{myTotalWalletBalance | currency}}</span></p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="table-responsive text-nowrap walletbxtable">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">S.No</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Comments</th>
                                            <th scope="col">Date & Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let row of walletLists; let i = index" [ngClass]="{redolor: row.type=='debit'}">
                                            <td scope="row">{{i + 1}}</td>
                                            <td>{{row.amount | currency}}</td>
                                            <td style="text-transform: uppercase; font-weight: 600">{{row.type}}</td>
                                            <td>{{row.comment}} <span *ngIf="row.order_id">#{{row.order_id.order_number}}</span></td>
                                            <td>{{row.createdAt | date:'dd MMM yyyy, hh:mm a':'UTC'}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="5" *ngIf="!isLoading && walletLists.length==0">Record not found.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="modal fade" id="withdraw-wallet" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-body model-pad0 mb-0 text-center">
                <div class="wrap-login100 shopwishlit">
                    <form class="login100-form" style="width: 100%;" [formGroup]="withdrawRequest">
                        <button type="button"  class="btn-close float-right"  data-dismiss="modal" aria-label="Close"></button>
                        <h3 class="text-left mb-3"><b>Withdrawal Amount</b></h3>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="md-form">
                                    <input class="form-control pl-10"  id="w-draw" type="number" name="amount" required="required" formControlName="amount" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.amount?.errors }"/>
                                    <label for="w-draw" class="left0">Withdraw Amount</label>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="md-form">
                                    <input class="form-control pl-10"  id="reason" type="text" name="reason" required="required" formControlName="reason" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.reason?.errors }"/>
                                    <label for="rson" class="left0">Reason</label>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="md-form">
                                    <textarea id="form7" class="md-textarea form-control billing-textare pl-10" required="required" rows="3" formControlName="message" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.message?.errors }"></textarea>
                                    <label for="form7" class="left0">Describe in detail</label>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center mt-4">
                            <div class="col-md-12 text-center">
                                <a (click)="clearLoginForm()" data-dismiss="modal" aria-label="Close" class="contiunebtn waves-effect waves-light"> Cancel</a>
                                <a href="javascript:;" (click)="doSubmitWithdrawRequest(withdrawRequest.value)" class="joinnow-btn waves-effect waves-light"> Submit</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>