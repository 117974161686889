<app-headers></app-headers>
<section class="text-center mb-2 settingTabs">
    <app-user-breadcrumb></app-user-breadcrumb>
</section>
<section class="mb-5 profileView">
    <div class="container">
        <div class="row">
            <!--  dashboard-menu-->
            <div class="col-md-5 col-lg-3">
                <app-user-left-menu></app-user-left-menu>
            </div>
            <div class="col-md-7 col-lg-9">
                <div class="rightbox-dashbord">
                    <div *ngIf="isLoading" id="loader"></div>
                    <div class="row">
                        <div class="col-6 col-sm-6 col-md-6 col-lg-8">
                            <h4 class="list-heading">My Record</h4>
                        </div>
                    </div>
                    <ul class="nav nav-tabs md-tabs nav-justified">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="tab" href="#panel1" role="tab">Wepay</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#panel2" role="tab">OTC Request</a>
                        </li>
						<li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#panel3" role="tab">OTC Reply</a>
                        </li>
                    </ul>
                    <div class="tab-content card dashbaord-table">
                        <div class="tab-pane fade in show active" id="panel1" role="tabpanel">
                            <br />
                            <h5 class="fontbold">Wepay Record</h5>
                            <div class="table-responsive text-nowrap">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Reference No</th>
                                            <th scope="col">Full Name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Item</th>
                                            <th scope="col">Payment Method</th>
                                            <th scope="col">Country</th>
                                            <th scope="col">Message</th>
                                            <th scope="col">Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let row of wepayList;">
                                            <th scope="row">{{row.order_id}}</th>
                                            <td>{{row.full_name}}</td>
                                            <td>{{row.email}}</td>
                                            <td>{{row.item_name}}</td>
                                            <td>{{row.payment_method}}</td>
                                            <td>{{row.country}}</td>
                                            <td class="fixew">{{row.message}}</td>
                                            <td>{{row.createdAt | date:'dd MMM yyyy, hh:mm a':'UTC'}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="8" *ngIf="!isLoading && wepayList.length==0">Record not found.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="panel2" role="tabpanel">
                            <br />
                            <h5 class="fontbold">OTC Request</h5>
                            <div class="table-responsive text-nowrap">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Reference No</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Crypto</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Payment Method</th>
                                            <th scope="col">Country</th>
                                            <th scope="col">Message</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let row of otcRequestList;">
                                            <th scope="row">{{row.order_id}}</th>
                                            <td>{{row.request_type}}</td>
                                            <td>{{row.crypto_method}}</td>
                                            <td>{{row.coin_amount}}</td>
                                            <td>{{row.payment_method}}</td>
                                            <td>{{row.country}}</td>
                                            <td class="fixew">{{row.contect_info}}</td>
                                            <td>
                                                <span *ngIf="row.approved_status==1">Approved</span>
                                                <span *ngIf="row.approved_status==0">Pending</span>
                                            </td>
                                            <td>{{row.createdAt | date:'dd MMM yyyy, hh:mm a':'UTC'}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="9" *ngIf="!isLoading && otcRequestList.length==0">Record not found.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="panel3" role="tabpanel">
                            <br />
                            <h5 class="fontbold">OTC Request Reply</h5>
                            <div class="table-responsive text-nowrap">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Reference No</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Message</th>
                                            <th scope="col">Phone</th>
                                            <th scope="col">Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let row of otcReplyList;">
                                            <th scope="row">{{row.order_id}}</th>
                                            <td>{{row.full_name}}</td>
                                            <td>{{row.email}}</td>
                                            <td>{{row.message}}</td>
                                            <td>{{row.contact}}</td>
                                            <td>{{row.createdAt | date:'dd MMM yyyy, hh:mm a':'UTC'}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="6" *ngIf="!isLoading && otcRequestList.length==0">Record not found.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>