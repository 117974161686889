import { Component, OnInit,Input, Output,EventEmitter } from '@angular/core';
import { CRYPTO_CURRENCY } from 'src/app/shared/constants/app.crypto-currency.constants';
import { CoreService } from '../../shared/services/core.service';
import { HelperService } from '../../shared/services/helper-service.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-crypto-dropdown',
  templateUrl: './crypto-dropdown.component.html',
  styleUrls: ['./crypto-dropdown.component.css']
})
export class CryptoDropdownComponent implements OnInit {
  public profileObj:any = {
    'currency_code' : "BTCUSDT"
  };
  @Input() public inputPrice:any = '';
  @Input() public displayType:any = 'dropdown';
  @Output() public convertedRate:any = new EventEmitter();
  public imgBaseUrl = environment.AWSImageURL;
  public selectedCurrency:any = '';
  public searchText:any = '';
  public CyrptCurrency:any = CRYPTO_CURRENCY;
  public exchangeLog:any = {};
  public convertedValue:any = {};
  public usedCryptoCode:any = [];
  public allRecentCrypto:any = [];
  constructor(
    private coreService: CoreService,
    public helperService: HelperService
  ) { }

  ngOnInit(): void {
    let profileObj:any = localStorage.getItem('currentUser') != null ? localStorage.getItem('currentUser') : '{"currency_code":"BTCUSDT"}';
    this.profileObj = JSON.parse(profileObj);

    let usedCryptoCode:any = (localStorage.getItem('usedCrypto') != null && localStorage.getItem('usedCrypto') != 'undefined') ? localStorage.getItem('usedCrypto') : JSON.stringify([]);
    this.usedCryptoCode = JSON.parse(usedCryptoCode);

    this.profileObj.currency_code = localStorage.getItem('currency_code') != null ? localStorage.getItem('currency_code') : this.profileObj.currency_code;
    if(this.profileObj.currency_code==''){
      this.profileObj.currency_code = 'BTCUSDT';
    }
    let that = this;
    setTimeout(function(){
      that.getUpdatedPrice();
    },100);
  }

  findByMatchingProperties(set:any, properties:any) {
    return set.filter(function (entry:any) {
        return Object.keys(properties).every(function (key) {
            return entry[key] === properties[key];
        });
    });
  }

  selectCrypto(obj:any){
    localStorage.setItem('currency_code', obj.symbol);
    this.exchangeLog = {
      display : parseFloat(obj.calculatedAmount) +" "+obj.code,
      exchange_rate : parseFloat(obj.calculatedAmount),
      currency_code : obj.code
    }
    this.convertedRate.emit(this.exchangeLog);
  }

  getUpdatedPrice(){
    if(this.inputPrice>0){
      if(this.displayType=='dropdown'){
        localStorage.setItem('currency_code', this.profileObj.currency_code);
        this.exchangeLog = {
          display : "Calculating...",
          exchange_rate : 0,
          currency_code : this.selectedCurrency.code
        }
        this.convertedRate.emit(this.exchangeLog);
        this.coreService.get(`${ENDPOINTS.SHOP.CRYPTO_EXCHANGE}?symbol=`+this.profileObj.currency_code, {}).subscribe((res) => {
          if (res.body.success) {
            this.exchangeLog = res.body.data;
            let price = parseFloat(this.exchangeLog.price);
            this.exchangeLog.calculatedAmount = ((1/price)*parseFloat(this.inputPrice)).toFixed(6);
            var results = this.findByMatchingProperties(this.CyrptCurrency, {'symbol':this.profileObj.currency_code});
            if(results.length>0){
              this.selectedCurrency = results[0];
            }
            this.exchangeLog = {
              display : "Price: "+parseFloat(this.exchangeLog.calculatedAmount) +" "+this.selectedCurrency.code,
              exchange_rate : parseFloat(this.exchangeLog.calculatedAmount),
              currency_code : this.selectedCurrency.code
            }
            // if(this.selectedCurrency.code=='NEO'){
            //   console.log(this.exchangeLog);
            // }
            this.convertedRate.emit(this.exchangeLog);
          }
        }, (err) => {

        });
      }
      else{
        this.exchangeLog = {
          display : "Calculating...",
          exchange_rate : 0,
          currency_code : this.selectedCurrency.code
        }
        this.convertedRate.emit(this.exchangeLog);
        this.coreService.get(`${ENDPOINTS.SHOP.CRYPTO_EXCHANGE}`, {}).subscribe((res) => {
          if (res.body.success) {
            let data = res.body.data;
            for (var i = 0; i <this.CyrptCurrency.length; i++) {
              let price = parseFloat(data[this.CyrptCurrency[i].symbol]);
              this.CyrptCurrency[i].calculatedAmount = parseFloat(((1/price)*parseFloat(this.inputPrice)).toFixed(6));
            }
            var results = this.findByMatchingProperties(this.CyrptCurrency, {'symbol':this.profileObj.currency_code});
            if(results.length>0){
              this.selectedCurrency = results[0];
            }
            this.exchangeLog = {
              display : parseFloat(this.selectedCurrency.calculatedAmount) +" "+this.selectedCurrency.code,
              exchange_rate : parseFloat(this.selectedCurrency.calculatedAmount),
              currency_code : this.selectedCurrency.code
            }
            this.convertedRate.emit(this.exchangeLog);

            this.allRecentCrypto = this.CyrptCurrency.filter((curElem:any) => {
              return this.usedCryptoCode.includes(curElem.code);
            });
          }
        }, (err) => {

        });
      }
    }
    else{
      let that = this;
      setTimeout(function(){
        that.getUpdatedPrice();
      },100);
    }
  }
}
