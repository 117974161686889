<app-header></app-header>
<section class="text-center mb-2 mt-3 crypto-bg pt-6 mt-50">
    <div *ngIf="isLoadingData" id="loader"></div>
    <div class="container">
        <div class="row d-flex align-items-center justify-content-center">
            <div class="col-md-8 col-lg-8">
                <div class="row d-flex align-items-center justify-content-center">
                    <div class="title-heading asash">
                        <!--<h2 class="h1 mobilsd">Buy & Sell Cryptocurrencies</h2>-->
                        <h2 class="h1 pb-3 newline">Buy & Sell Cryptocurrencies</h2>
                    </div>
                    <p class="buysell"> With a growing list of charge-free OTC traders in more than 11 countries, people can now trade over the counter-buy and sell their cryptos for fiat, and most importantly, in PERSON</p>
                    <!--If you are interested in buying or selling your cryptos in person, CryptoMate is  here for you! We set up your first appointment with our traders in over 11 countries so you can securely trade your fiats with zero cost.--> 
                    <!--<a (click)="goToNavigation('contact-us');" class="btn btn-outline-white start-btn">Contact us now!</a>-->
                </div>
            </div>
        </div>
        <div class="row d-flex align-items-center justify-content-center mt-3 mb-5">
            <div class="col-md-8 col-lg-8">
                <div class="boxcyrpto">
                    <div class="phonenumber-box fistnew">
                        <select readonly disabled style="opacity: 1;color: #000;">
                            <option>USD</option>
                        </select>
                        <div class="md-form">
                            <input class="form-control" type="text" name="name" required="required" value="1" [(ngModel)]="usd_value"/>
                        </div>
                    </div>
                    <div class="phonenumber-box greenbox">
                        <app-crypto-dropdown [inputPrice]="'1'" [displayType]="'dropdown'" (convertedRate)="cryptoPrice=$event"></app-crypto-dropdown>
                        <div class="md-form">
                            <input class="form-control" type="text" name="name" required="required" value="{{(cryptoPrice.exchange_rate)?(cryptoPrice.exchange_rate*usd_value).toFixed(6):cryptoPrice.display}}" readonly/>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <!--<div class="row d-flex align-items-center justify-content-center mt-3">
            <a (click)="goToNavigation('contact-us');" class="joinnow-btn waves-effect waves-light">Contact us </a>
        </div>-->
    </div>
</section>


<section class="chart-dark-bg">
    <div class="container">
        <div class="row d-flex align-items-center justify-content-center">
            <div class="col-12 col-md-5 col-lg-7 order-lg-12 text-center">
                <img src="/assets/img/white_Big.gif" style="width: 440px; margin-top: 25px; max-width: 100%;" />
            </div>
            <div class="col-12 col-md-7 col-lg-5 order-lg-1">
                <p><b>Submit your request now to find traders in your area.</b></p>
                <div class="classic-tabs mx-2">
                    <ul class="nav tabs-orange" id="myClassicTabOrange" role="tablist">
                        <li class="nav-item greentab">
                            <a class="nav-link active show" id="profile-tab-classic-orange" data-toggle="tab" href="#profile-classic-orange" role="tab" aria-controls="profile-classic-orange" aria-selected="true">Buy</a>
                        </li>
                        <li class="nav-item redtab">
                            <a class="nav-link" id="follow-tab-classic-orange" data-toggle="tab" href="#follow-classic-orange" role="tab" aria-controls="follow-classic-orange" aria-selected="false">Sell</a>
                        </li>
                    </ul>

                    <div class="tab-content otcpage myiphone" id="myClassicTabContentOrange">
                        <div class="tab-pane fade active show" id="profile-classic-orange" role="tabpanel" aria-labelledby="profile-tab-classic-orange">
                            <form class="pt-3 greenform" [formGroup]="otcBuyFormRequest">
                                <div class="row">
                                    <div class="col-sm-6 col-md-6 col-lg-6">
                                        <div class="md-form">
                                            <span class="iconform"><i class="fab fa-bitcoin"></i></span>
                                            <select class="form-control coin-boxselect" required="required" name="crypto_method" formControlName="crypto_method" [ngClass]="{ 'is-invalid': otcBuyFormStatus.isFormSubmit &&  getbuy?.crypto_method?.errors }">
                                                <option>Select Crypto</option>
                                                <option *ngFor="let crypto of cryptosLists;" value="{{crypto}}">{{crypto}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-6">
                                        <div class="md-form">
                                            <span class="iconform"><i class="fas fa-map-marker-alt"></i></span>
                                            <select class="form-control coin-boxselect" required="required" name="country" formControlName="country" [ngClass]="{ 'is-invalid': otcBuyFormStatus.isFormSubmit &&  getbuy?.country?.errors }">
                                                <option>Select Country</option>
                                                <option *ngFor="let country of countryLists;" value="{{country}}">{{country}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-6">
                                        <div class="md-form">
                                            <span class="iconform">
                                                <i> <img src="/assets/img/newotc.svg" /></i>
                                            </span>
                                            <input class="form-control cardpopup-input" id="coin_amount" type="number" name="coin_amount" formControlName="coin_amount" required="required"  [ngClass]="{ 'is-invalid': otcBuyFormStatus.isFormSubmit &&  getbuy?.coin_amount?.errors }"/>
                                            <label for="coin_amount">Amount</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-6">
                                        <div class="md-form">
                                            <span class="iconform"><i class="far fa-credit-card"></i></span>
                                            <select class="form-control coin-boxselect" required="required" name="payment_method" formControlName="payment_method" [ngClass]="{ 'is-invalid': otcBuyFormStatus.isFormSubmit &&  getbuy?.payment_method?.errors }">
                                                <option>Select Payment method</option>
                                                <option *ngFor="let method of paymentMethodsLists;" value="{{method}}">{{method}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-sm-12">
                                        <div class="md-form">
                                            <span class="iconform"><i class="far fa-envelope"></i></span>
                                            <textarea
                                                id="contect_info"
                                                class="md-textarea form-control billing-textare"
                                                rows="3"
                                                style="padding-left: 45px !important;"
                                                name="contect_info"
                                                required="required"
                                                formControlName="contect_info"
                                                [ngClass]="{ 'is-invalid': otcBuyFormStatus.isFormSubmit &&  getbuy?.contect_info?.errors }"
                                            ></textarea>
                                            <label for="contect_info" class="">Contact Info</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 text-left">
                                        <button [disabled]="otcBuyFormStatus.isLoading" type="button" class="joinnow-btn waves-effect waves-light w-100" (click)="submitCryptosEnq(otcBuyFormRequest.value)">
                                            FInd me a seller
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="tab-pane fade" id="follow-classic-orange" role="tabpanel" aria-labelledby="follow-tab-classic-orange">
                            <form class="pt-3 redform" [formGroup]="otcSellFormRequest">
                                <div class="row">
                                    <div class="col-sm-6 col-md-6 col-lg-6">
                                        <div class="md-form">
                                            <span class="iconform"><i class="fab fa-bitcoin"></i></span>
                                            <select class="form-control coin-boxselect" required="required" name="crypto_method" formControlName="crypto_method" [ngClass]="{ 'is-invalid': otcSellFormStatus.isFormSubmit &&  getsell?.crypto_method?.errors }">
                                                <option>Select Crypto</option>
                                                <option *ngFor="let crypto of cryptosLists;" value="{{crypto}}">{{crypto}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-6">
                                        <div class="md-form">
                                            <span class="iconform"><i class="fas fa-map-marker-alt"></i></span>
                                            <select class="form-control coin-boxselect" required="required" name="country" formControlName="country" [ngClass]="{ 'is-invalid': otcSellFormStatus.isFormSubmit &&  getsell?.country?.errors }">
                                                <option>Select Country</option>
                                                <option *ngFor="let country of countryLists;" value="{{country}}">{{country}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-sm-6 col-md-6 col-lg-6">
                                        <div class="md-form">
                                            <span class="iconform">
                                                <i> <img src="/assets/img/newotc.svg" /></i>
                                            </span>
                                            <input class="form-control cardpopup-input" id="coin_amount" type="number" name="coin_amount" formControlName="coin_amount" required="required"  [ngClass]="{ 'is-invalid': otcSellFormStatus.isFormSubmit &&  getsell?.coin_amount?.errors }"/>
                                            <label for="coin_amount">Amount</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-6">
                                        <div class="md-form">
                                            <span class="iconform"><i class="far fa-credit-card"></i></span>
                                            <select class="form-control coin-boxselect" required="required" name="payment_method" formControlName="payment_method" [ngClass]="{ 'is-invalid': otcSellFormStatus.isFormSubmit &&  getsell?.payment_method?.errors }">
                                                <option>Select Payment method</option>
                                                <option *ngFor="let method of paymentMethodsLists;" value="{{method}}">{{method}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-sm-12">
                                        <div class="md-form">
                                            <span class="iconform"><i class="far fa-envelope"></i></span>
                                            <textarea
                                                id="contect_info"
                                                class="md-textarea form-control billing-textare"
                                                rows="3"
                                                style="padding-left: 45px !important;"
                                                name="contect_info"
                                                required="required"
                                                formControlName="contect_info"
                                                [ngClass]="{ 'is-invalid': otcSellFormStatus.isFormSubmit &&  getsell?.contect_info?.errors }"
                                            ></textarea>
                                            <label for="contect_info" class="">Contact Info</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 text-left">
                                        <button [disabled]="otcSellFormStatus.isLoading" type="button" class="joinnow-btn waves-effect waves-light w-100" (click)="submitCryptosSellEnq(otcSellFormRequest.value)">
                                            Find me a buyer
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- Classic tabs -->
                </div>
            </div>
        </div>
       <button class="button" (click)="moveToAvailableOrders()"><img src="/assets/img/bottonarrow.png" alt=""></button>
    </div>
</section>


<section class="chart-set-div">
    <div class="container">
        <div class="row d-flex align-items-center justify-content-center">
            <div class="col-sm-12">
                <div class="chart-box"><iframe src="https://cryptomate.com.cn/trading-cpm.html" style="width: 100%;height: 630px;border: none;"></iframe></div>
                <style type="text/css">
                    .content-pzOKvpP8 {display: none !important;}
                </style>
            </div>
        </div>
    </div>
</section>

<section class="text-center mb-2 mt-3 pb-3">
    <div class="container">
        <div class="row d-flex align-items-center justify-content-center">
            <div class="col-md-8 col-lg-8">
                <div class="row d-flex align-items-center justify-content-center">
                    <div class="title-heading">
                        <h2 class="h1 pt-5 pb-3">How It Works</h2>
                    </div>
                </div>
            </div>
        </div>

        <div class="row d-flex align-items-center justify-content-center">
            <div class="how-itbox-five">
                <div class="how-itbox">
                    <img src="/assets/img/h-contact-form.png" />
                    <h5>Fill out the form and submit it</h5>
                    <h1>01</h1>
                </div>
                <div class="how-itbox-arrow"><i class="fas fa-arrow-right"></i></div>
            </div>
            <div class="how-itbox-five">
                <div class="how-itbox">
                    <img src="/assets/img/h-buy.png" />
                    <h5>Your request gets listed on CryptoMate</h5>
                    <h1>02</h1>
                </div>
                <div class="how-itbox-arrow"><i class="fas fa-arrow-right"></i></div>
            </div>
            <div class="how-itbox-five">
                <div class="how-itbox">
                    <img src="/assets/img/h-customer-service.png" />
                    <h5>We assign you a buyer/seller based on your needs.</h5>
                    <h1>03</h1>
                </div>
                <div class="how-itbox-arrow"><i class="fas fa-arrow-right"></i></div>
            </div>
            <div class="how-itbox-five">
                <div class="how-itbox">
                    <img src="/assets/img/h-conversation.png" />
                    <h5>CryptoMate connects both parties.</h5>
                    <h1>04</h1>
                </div>
                <div class="how-itbox-arrow"><i class="fas fa-arrow-right"></i></div>
            </div>
            <div class="how-itbox-five">
                <div class="how-itbox">
                    <img src="/assets/img/h-stock.png" />
                    <h5>You meet the other party in PERSON and conduct a trade</h5>
                    <h1>05</h1>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="text-center py-4 homeproductbox pt-5 minorbg" >
    <div class="container">
        <div class="row d-flex align-items-center justify-content-center">
            <div class="title-heading">
                <h2 class="h1 pb-3">Blockchain Corner</h2>
            </div>
        </div>
        <div class="row d-flex align-items-center justify-content-center">
            <div class="filter-box">
                <!-- <button class="btn btn-default1 filter-button active"  (click)="viewProducts('all')" id="filter_all">All</button> -->
                <button class="btn btn-default1 filter-button" *ngFor="let categoryRow of productList.category; let k = index"  [ngClass]="{active: k == 0}" (click)="viewProducts(categoryRow.id)" id="filter_{{categoryRow.id}}">{{categoryRow.category_id.category_name}}</button>
            </div>            
            <div *ngFor="let productRow of productList.products" class="col-6 col-md-4 col-sm-6 col-lg-3 mb-5 filter filterclass_{{productRow.recommended_category_id}}">
                <div class="shop-title">
                    <a (click)="goToProductDetailPage(productRow.product_id);">
                        <i class="fa-3x"><img src="{{imgBaseUrl}}products/{{productRow.product_id.product_image}}" alt="{{productRow.product_id.product_name}}"/></i>
                    </a>
                    <h3 class="h4 mt-3">{{productRow.product_id.product_name}}</h3>
                    <h4><b>{{productRow.product_id.regular_price | currency}}</b> <!--<small class="det">$2,000.00</small> --></h4>
                    <div>
                        <a class="btn btn-outline-white shopbuynow-btn" (click)="goToProductDetailPage(productRow.product_id);">Buy Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="otc-btm-bg" id="available_orders">
    <div class="container">
        <div class="row d-flex align-items-center justify-content-center">
            <div class="title-heading">
                <h2 class="h1">Available Orders</h2>
            </div>
        </div>
        <div class="row d-flex align-items-center justify-content-center">
            <div class="col-md-12 col-lg-12 text-center text-md-center p-top">
                <div class="container mybox">
                    <section class="buysellregular slider">
                        <div  *ngFor="let listRow of buySellList; let i = index">
                            <div> 
                                 <div class="order-avilable">
                                    <span *ngIf="listRow.request_type=='buy'" class="buygreen">BUY</span>
                                    <span *ngIf="listRow.request_type=='sell'" class="sellgreen">SELL</span>
                                    <ul>
                                        <li>
                                            Order ID: <span><b>{{listRow?.order_id}}</b></span>
                                        </li>
                                        <li>
                                            {{listRow.crypto_method}} : <span><b>{{listRow.coin_amount}}</b></span>
                                        </li>
                                        <li>
                                            Loaction : <span><b>{{listRow.country}}</b></span>
                                        </li>
                                        <li>
                                            Payment Method : <span><b>{{listRow.payment_method}}</b></span>
                                        </li>
                                    </ul>
                                    <a href="javascript:;" (click)="doReply(listRow)">Reply</a>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="bottom-outsource-bg otc-bottom-outsource-bg">
    <div class="container">
        <div class="row d-flex align-items-center justify-content-center">
            <div class="col-12 col-md-6 col-lg-6 order-lg-12">
                <img src="/assets/img/academy.webp" class="w-100" />
            </div>

            <div class="col-12 col-md-6 col-lg-6 order-lg-1">
               <!-- <img src="/assets/img/online-learning-otc.png" class="mb-3 on-img" />-->
                <div class="col-sm-12">
                    <div class="row d-flex align-items-left justify-content-left">
                        <div class="title-heading-black">
                            <h2 class="h1 btm-title-out">Academy:</h2>
                        </div>
                    </div>
                </div>
                <p class="mt-0">
                  Congratulations, If you're reading this! You found cryptos before the majority of the world. Join us now and learn more about blockchain technology and cryptocurrencies.
                </p>
                <a href="javascript:;" class="shopnownt waves-effect waves-light">Coming soon</a>
            </div>
        </div>
    </div>
</section>

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Take this order</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="newpop" [formGroup]="otcFormReply">
                    <div>
                        <div class="col-md-12">
                            <div class="md-form">
                                <input class="form-control" id="full_name" type="text" formControlName="full_name" name="full_name" required="required" [ngClass]="{ 'is-invalid': otcFormStatus.isFormSubmit &&  getreply?.full_name?.errors }"/>
                                <label for="e-mail">Full Name</label>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="md-form">
                                <input class="form-control" id="mail" type="email" formControlName="email" name="email" required="required"  [ngClass]="{ 'is-invalid': otcFormStatus.isFormSubmit &&  getreply?.email?.errors }"/>
                                <label for="e-mail">email</label>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="md-form">
                                <input class="form-control" id="contacts" type="text" formControlName="contact" name="contact" required="required"  [ngClass]="{ 'is-invalid': otcFormStatus.isFormSubmit &&  getreply?.contact?.errors }"/>
                                <label for="e-mail">Phone No.</label>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="md-form">
                                <input class="form-control" id="message" type="text" name="message" formControlName="message" required="required"  [ngClass]="{ 'is-invalid': otcFormStatus.isFormSubmit &&  getreply?.message?.errors }"/>
                                <label for="e-mail">Message</label>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="col-md-12 text-center">
                            <button [disabled]="otcFormStatus.isLoading" type="submit" class="joinnow-btn waves-effect waves-light" (click)="submitOtcReply(otcFormReply.value)"><i class="fas fa-sign-in-alt"></i> Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
