import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'; 
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './include/header/header.component';
import { FooterComponent } from './include/footer/footer.component';
import { LandingComponent } from './landing/landing.component';

import { environment } from 'src/environments/environment';
import { NgxPayPalModule } from 'ngx-paypal';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AuthGuard } from './auth-guard/auth.guard';
import { AuthService } from './shared/services/auth.service';
import { HelperService } from './shared/services/helper-service.service';
import { ContactComponent } from './contact/contact.component';
import { CartComponent } from './cart/cart.component';
import { ShopComponent } from './shop/shop.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { CryptosComponent } from './cryptos/cryptos.component';
import { GiftComponent } from './gift/gift.component';
import { GiftDesignerComponent } from './gift-designer/gift-designer.component';
import { GiftDetailComponent } from './gift-detail/gift-detail.component';
import { LoyaltyProgrameComponent } from './loyalty-programe/loyalty-programe.component';
import { LoyaltyProgrameDetailComponent } from './loyalty-programe-detail/loyalty-programe-detail.component';
import { OutsourceComponent } from './outsource/outsource.component';
import { ShopDetailComponent } from './shop-detail/shop-detail.component';
import { TopupComponent } from './topup/topup.component';
import { TopupDetailComponent } from './topup-detail/topup-detail.component';
import { WepayComponent } from './wepay/wepay.component';
import { UserModule } from './user/user.module';
import { LoginModalComponent } from './include/modals/login-modal/login-modal.component';
import { ForgotModalComponent } from './include/modals/forgot-modal/forgot-modal.component';
import { FaqComponent } from './faq/faq.component';
import { CareerComponent } from './career/career.component';
import { HttpClientModule } from '@angular/common/http';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SocialLoginModule, SocialAuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { NotFoundComponent } from './not-found/not-found.component';
import { AboutComponent } from './about/about.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { BalanceCardComponent } from './balance-card/balance-card.component';
import { OrderConfirmComponent } from './order-confirm/order-confirm.component';
import { GiftValidationComponent } from './include/modals/gift-validation/gift-validation.component';
import { CryptoDropdownComponent } from './include/crypto-dropdown/crypto-dropdown.component';
import { WishlistModalComponent } from './include/wishlist-modal/wishlist-modal.component';
import { ReplaceXoxoPipe } from './shared/pipe/replace-xoxo.pipe';
import { OrderStatusPipe } from './shared/pipe/order-status.pipe';
import { ReferAFriendComponent } from './refer-afriend/refer-afriend.component';
import { ProductRequestComponent } from './product-request/product-request.component';
import { OutsourcingJoinComponent } from './outsourcing-join/outsourcing-join.component';
import { LoyaltyProgramTermsComponent } from './loyalty-program-terms/loyalty-program-terms.component';
import { BalanceCardTermsComponent } from './balance-card-terms/balance-card-terms.component';
import { I18nModule } from './i18n/i18n.module';
import { ReferAFriendsTermsConditionComponent } from './refer-afriends-terms-condition/refer-afriends-terms-condition.component';
import { FormatTimePipe } from './shared/pipe/format-time.pipe';
import { AutocompleteOffDirective } from './autocomplete-off.directive';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { BestSellersComponent } from './best-sellers/best-sellers.component';
import { GameProductsComponent } from './game-products/game-products.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ModalModule } from 'ngx-bootstrap/modal';  
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LandingComponent,
    ContactComponent,
    CartComponent,
    ShopComponent,
    CheckoutComponent,
    CryptosComponent,
    GiftComponent,
    GiftDesignerComponent,
    GiftDetailComponent,
    LoyaltyProgrameComponent,
    LoyaltyProgrameDetailComponent,
    OutsourceComponent,
    ShopDetailComponent,
    TopupComponent,
    TopupDetailComponent,
    WepayComponent,
    LoginModalComponent,
    ForgotModalComponent,
    FaqComponent,
    CareerComponent,
    NotFoundComponent,
    AboutComponent,
    TermsComponent,
    PrivacyComponent,
    BalanceCardComponent,
    OrderConfirmComponent,
    GiftValidationComponent,
    CryptoDropdownComponent,
    WishlistModalComponent,
    ReplaceXoxoPipe,
    OrderStatusPipe,
    ReferAFriendComponent,
    ProductRequestComponent,
    OutsourcingJoinComponent,
    LoyaltyProgramTermsComponent,
    BalanceCardTermsComponent,
    ReferAFriendsTermsConditionComponent,
    FormatTimePipe,
    AutocompleteOffDirective,
    BestSellersComponent,
    GameProductsComponent
  ],
  imports: [
    BrowserModule,
    Ng2SearchPipeModule,
    NgxPayPalModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    FontAwesomeModule,
    SlickCarouselModule,
    NgxQRCodeModule,
    ModalModule.forRoot(), 
    NgbModule,
    TabsModule.forRoot(),
	  ToastrModule.forRoot({
      closeButton:true,
      tapToDismiss:true,
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    TypeaheadModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    SocialLoginModule,
    InfiniteScrollModule,
    UserModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
    ShareIconsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000'
    }),
    I18nModule
  ],
  providers: [
    AuthService,
    HelperService,
    AuthGuard,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.GOOGLE_KEY
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.FACEBOOK_KEY),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    {provide: 'googleTagManagerId',  useValue: environment.GOOGLE_TAG_KEY}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
