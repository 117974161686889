import { Component,  Input,Output,OnInit } from '@angular/core';
import { HelperService } from '../shared/services/helper-service.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { Router,ActivatedRoute } from '@angular/router';
import { CoreService } from '../shared/services/core.service';
import {ToastrService} from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AuthService } from '../shared/services/auth.service';
declare var $: any;

@Component({
  selector: 'app-gift-detail',
  templateUrl: './gift-detail.component.html',
  styleUrls: ['./gift-detail.component.css']
})
export class GiftDetailComponent implements OnInit {
  public banGiftVouchers:any = [52712,51673,51612,51611,50557,47270,46836,40548,31145,31144,29907,29862,15365,6903,1007,51054,51668,52573,52568,52569,52323,52122,52571,52279,52507,52570,52572,52567,46920,52552,52547,52548,51587,52550,49177,52446,52404,52509,52549,47890,52551,52546,47285,52529,49178,50881,49540,50976,50965,51922,50464,50971,52325,50469,50967,50466,50975,52525,50964,52524,52532,51565,49542,50963,48055,50497,51568,52527,50465,51344,51779,52116,51567,50969,50968,49545,46934,52068,46939,50957,52531,50468,49543,52530,46937,50974,52526,51742,51923,51780,50970,47288,51915,49544,51319,49179,52119,52528,50467,50463,52051,50905,46940,46935,52398,50618,50941,49533,47262,52516,51668,50943,52322,49485,51578,50952,51924,49481,46893,46929,46930,51560,49482,50951,48738,52724,49040,50940,47737,49484,51869,51845,51299,52512,52402,52515,48736,50953,50960,49186,50946,52511,49044,51885,47333,52520,49535,51563,49041,46925,50954,51889,52403,52514,51618,51564,49539,51884,49189,46797,46904,51868,49187,46906,47542,52519,52401,49488,48734,48728,49193,52521,49185,49190,52510,52042,52400,46924,52041,51582,47710,51888,52513,48739,49486,52386,52518,50944,48737,48735,15166,52522,50945,51302,46914,52258,50956,47292,52517,49191,52523,47546,51562,46912,46821,52566,52561,52562,52564,52508,52563,52565,52560,49194,52541,51920,51745,52394,52542,52069,51919,52048,51921,52535,52395,52536,52396,52543,52544,52545,52043,52538,51916,52336,52319,48596,52688,52387,52040,52537,52393,51953,52533,52506,51785,52280,52539,52534,52559,52554,52555,51017,50936,51115,51586,52557,49195,51679,52556,51614,52558,52553,51559];
  public lang:any = '';
  public giftID:any = '';
  public giftObj:any = {};
  public gifts:any = [];
  public quantityLists:any = [];
  public limit_amount = environment.DAILY_PURCHASE_LIMIT;
  public isLoggedIn:boolean = false;
  public isLoading:boolean = false;
  public addingToCart:boolean = false;
  public profileObj:any = '';
  public cryptoPrice:any = {};
  public cartItems:any = '';
  public exchangeRates:any = 0;
  @Input() public cartItemData:any = [];
  constructor(
    private toast: ToastrService,
    private coreService: CoreService,
    private helperService: HelperService,
    private route: ActivatedRoute,
    private _auth : AuthService
  ) { 
    if(this._auth.loggedIn()){
      this.isLoggedIn = true;
    }else{
      this.isLoggedIn = false;
    }    
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.giftID = params['slug'];
      this.getVoucherDetails();
    });
    
    let profileObj:any = localStorage.getItem('currentUser') != null ? localStorage.getItem('currentUser') : '{"name":""}';
    this.profileObj = JSON.parse(profileObj);    
    this.getExchangeRates('USD');
    let gifts:any = localStorage.getItem('voucherLists') != null ? localStorage.getItem('voucherLists') : JSON.stringify([]);
    this.gifts = JSON.parse(gifts);

    this.gifts = this.gifts.filter((curElem:any) => {
      return curElem.productId != this.giftID;
    });
    for (var i = 1; i <21; i++) {
      this.quantityLists.push(i);
    }
  }

  ngOnChanges(){
    this.cartItems = this.cartItemData;
  }
  cartItemLists(){
    if(this.isLoggedIn ){
      this.coreService.get(`${ENDPOINTS.CART.LIST}`, {}).subscribe((res) => {
        this.cartItems = res.body.data;
      }, (err) => {
      });
    }
  }

  getVoucherDetails(){
    this.isLoading = true;
    this.coreService.post(`${ENDPOINTS.GIFT_XOXO.VOUCHERS_DETAIL}`, {'voucher_id':this.giftID}).subscribe((res) => {
      if (res.body.success) {
        if(res.body.data.getVouchers!=undefined){
          if(res.body.data.getVouchers.data.length>0){
            this.giftObj = res.body.data.getVouchers.data[0];
            if(this.banGiftVouchers.includes(this.giftObj.productId)){
              this.giftObj.allow_only_fiat = 1;
            }else{
              this.giftObj.allow_only_fiat = 0;
            }
            this.giftObj.isImageLoading = true;
            this.giftObj.quantity = 1;
            let denominations = this.giftObj.valueDenominations.split(",");
            if(this.giftObj.currencyCode!='USD'){
              let rates:any = this.exchangeRates[this.giftObj.currencyCode];
              this.giftObj.denominations = [];
              denominations.forEach((d: any) => {
                //console.log(d);
                //console.log(environment.GIFT_EXCHANGE_FEE);
                let p:any = parseFloat(d) + (d * environment.GIFT_EXCHANGE_FEE);
                //console.log(rates);
                p = p / rates;
                //console.log(parseFloat(p).toFixed(2));
                this.giftObj.denominations.push({'amount':parseFloat(p).toFixed(2),'denomination':d});
              });
            }else{
              this.giftObj.denominations = [];
              denominations.forEach((d: any) => {
                this.giftObj.denominations.push({'amount':d,'denomination':d});
              });
            }
            //this.giftObj.denominations = denominations;
            if(this.giftObj.denominations.length>0){
              //this.giftObj.price = this.giftObj.denominations[0].amount;
              this.giftObj.price = this.giftObj.denominations[0];
            }
            this.isLoading = false;
          }
        }else{
          this.helperService.navigate('gift', this.lang);
        }
      }else{
        this.helperService.navigate('gift', this.lang);
      }
    }, (err) => {
      this.isLoading = false;
    });
  }

  getExchangeRates(currencyCode:any){
    if(!this.exchangeRates){
      this.coreService.getExchangeRates(currencyCode).subscribe((res) => {
        this.exchangeRates = res.body.data.rates;
      }, (err) => {
        this.exchangeRates = 0;
      });
    }
  }
  
  goTOPage(obj:any) {
    localStorage.setItem('giftObj', JSON.stringify(obj));
    localStorage.setItem('giftFilters', JSON.stringify(this.giftObj.filterSettings));
    this.helperService.navigate(`/gift-detail/${obj.productId}`, this.lang);
    //$("html, body").animate({ scrollTop: 0 }, "slow");
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang);
  }

  findByMatchingProperties(set:any, properties:any) {
    return set.filter(function (entry:any) {
        return Object.keys(properties).every(function (key) {
            return entry[key] === properties[key];
        });
    });
  }

  /**
   * @method: addToCart
   * @description: Using to add item in the cart
   * @param productObj
   */
   addToCart(obj:any){
    if(obj.quantity>0 && obj.price>0 && !this.addingToCart){
      // let priceObj:any = {};
      // var results = this.findByMatchingProperties(obj.denominations,{'amount':obj.price});
      // if(results.length>0){
      //   priceObj = results[0];
      // }
      this.addingToCart = true;
      obj.addingToCart = true;
      var paylaod:any = {
        'allow_only_fiat' : obj.allow_only_fiat,
        'cart_item' : 'gift_card',
        'product_name' : obj.name,
        'gift_card_id' : obj.productId,      
        'quantity' : parseInt(obj.quantity),
        'gift_card_info' : obj,
        'price' : parseFloat(obj.price),
        'denomination' : parseFloat(obj.price)
      }    
      this.coreService.post(`${ENDPOINTS.CART.ADD}`, paylaod).subscribe((res) => {
        this.addingToCart = false;
        obj.addingToCart = false;
        if(res.body.success){
          obj.addedToCart = true;
          this.cartItems = res.body.data;
          this.toast.success(res.body.message, "Success!");
        }else{
          this.toast.error(res.body.message, "Error!");
        }
        setTimeout(function(){
          $("#pol").show();
          $(".cartPopupModal").show();
        },500);
      }, (err) => {
        this.addingToCart = false;
      });
    }else{
      this.toast.error("Please select price and quantity", "Error!");
    }
  }
}
