export const CRYPTO_CURRENCY = [
    {
    "code": "AAVE",
    "display_name": "Aave",
    "symbol": "AAVEUSDT",
    "image": "AAVE.png"
    },
    {
    "code": "ADA",
    "display_name": "Cardano",
    "symbol": "ADAUSDT",
    "image": "ADA.png"
    },
    {
    "code": "ALGO",
    "display_name": "Algorand",
    "symbol": "ALGOUSDT",
    "image": "ALGO.png"
    },
    {
    "code": "ARPA",
    "display_name": "ARPA Chain",
    "symbol": "ARPAUSDT",
    "image": "ARPA.png"
    },
    {
    "code": "ATOM",
    "display_name": "Cosmos",
    "symbol": "ATOMUSDT",
    "image": "ATOM.png"
    },
    {
    "code": "AVAX",
    "display_name": "Avalanche",
    "symbol": "AVAXUSDT",
    "image": "AVAX.png"
    },
    {
    "code": "BAT",
    "display_name": "Basic Attention Token ",
    "symbol": "BATUSDT",
    "image": "BAT.png"
    },
    {
    "code": "BCH",
    "display_name": "Bitcoin Cash",
    "symbol": "BCHUSDT",
    "image": "BCH.png"
    },
    {
    "code": "BEL",
    "display_name": "Bella Protocol",
    "symbol": "BELUSDT",
    "image": "BEL.png"
    },
    {
    "code": "BNBBSC",
    "display_name": "BNBBSC",
    "symbol": "BNBUSDT",
    "image": "BNBBSC.png"
    },
    {
    "code": "BNBMAINNET",
    "display_name": "BNBMAINNENT",
    "symbol": "BNBUSDT",
    "image": "BNBMAINNET.png"
    },
    {
    "code": "BTC",
    "display_name": "Bitcoin",
    "symbol": "BTCUSDT",
    "image": "BTC.png"
    },
    {
    "code": "BTG",
    "display_name": "Bitcoin Gold",
    "symbol": "BTGUSDT",
    "image": "BTG.png"
    },
    {
    "code": "BUSDBSC",
    "display_name": "BUSDBSC",
    "symbol": "BUSDUSDT",
    "image": "BUSDBSC.png"
    },
    {
    "code": "BUSD",
    "display_name": "BUSDERC20",
    "symbol": "BUSDUSDT",
    "image": "BUSDERC20.png"
    },
    {
    "code": "CAKE",
    "display_name": "PancakeSwap",
    "symbol": "CAKEUSDT",
    "image": "CAKE.png"
    },
    {
    "code": "CHR",
    "display_name": "Chromia",
    "symbol": "CHRUSDT",
    "image": "CHR.png"
    },
    {
    "code": "CHZ",
    "display_name": "Chiliz",
    "symbol": "CHZUSDT",
    "image": "CHZ.png"
    },
    {
    "code": "CTSI",
    "display_name": "Cartesi",
    "symbol": "CTSIUSDT",
    "image": "CTSI.png"
    },
    {
    "code": "DAI",
    "display_name": "DAI Coin",
    "symbol": "DAIUSDT",
    "image": "DAI.png"
    },
    {
    "code": "DASH",
    "display_name": "DASH",
    "symbol": "DASHUSDT",
    "image": "DASH.png"
    },
    {
    "code": "DGB",
    "display_name": "DigiByte",
    "symbol": "DGBUSDT",
    "image": "DGB.png"
    },
    {
    "code": "DOGE",
    "display_name": "Dogecoin",
    "symbol": "DOGEUSDT",
    "image": "DOGE.png"
    },
    {
    "code": "DOT",
    "display_name": "Polkadot",
    "symbol": "DOTUSDT",
    "image": "DOT.png"
    },
    {
    "code": "EGLD",
    "display_name": "Elrond",
    "symbol": "EGLDUSDT",
    "image": "EGLD.png"
    },
    {
    "code": "ENJ",
    "display_name": "Enjin Coin",
    "symbol": "ENJUSDT",
    "image": "ENJ.png"
    },
    {
    "code": "EOS",
    "display_name": "EOS",
    "symbol": "EOSUSDT",
    "image": "EOS.png"
    },
    {
    "code": "ETC",
    "display_name": "Ethereum Classic",
    "symbol": "ETCUSDT",
    "image": "ETC.png"
    },
    {
    "code": "ETH",
    "display_name": "Ethereum",
    "symbol": "ETHUSDT",
    "image": "ETH.png"
    },
    {
    "code": "FIL",
    "display_name": "Filecoin",
    "symbol": "FILUSDT",
    "image": "FIL.png"
    },
    {
    "code": "FIRO",
    "display_name": "Firo",
    "symbol": "FIROUSDT",
    "image": "FIRO.png"
    },
    {
    "code": "FRONT",
    "display_name": "Frontier",
    "symbol": "FRONTUSDT",
    "image": "FRONT.png"
    },
    {
    "code": "FTM",
    "display_name": "Fantom",
    "symbol": "FTMUSDT",
    "image": "FTM.png"
    },
    {
    "code": "FTT",
    "display_name": "FTX Token",
    "symbol": "FTTUSDT",
    "image": "FTT.png"
    },
    {
    "code": "FUN",
    "display_name": "FUNToken",
    "symbol": "FUNUSDT",
    "image": "FUN.png"
    },
    {
    "code": "HBAR",
    "display_name": "HBAR",
    "symbol": "HBARUSDT",
    "image": "HBAR.png"
    },
    {
    "code": "HOT",
    "display_name": "Holo",
    "symbol": "HOTUSDT",
    "image": "HOT.png"
    },
    {
    "code": "IOTX",
    "display_name": "IoTeX ",
    "symbol": "IOTXUSDT",
    "image": "IOTX.png"
    },
    {
    "code": "KMD",
    "display_name": "Komodo",
    "symbol": "KMDUSDT",
    "image": "KMD.png"
    },
    {
    "code": "LINK",
    "display_name": "Chainlink",
    "symbol": "LINKUSDT",
    "image": "LINK.png"
    },
    {
    "code": "LTC",
    "display_name": "litecoin",
    "symbol": "LTCUSDT",
    "image": "LTC.png"
    },
    {
    "code": "LUNA",
    "display_name": "Terra",
    "symbol": "LUNAUSDT",
    "image": "LUNA.png"
    },
    {
    "code": "MANA",
    "display_name": "MANA",
    "symbol": "MANAUSDT",
    "image": "MANA.png"
    },
    {
    "code": "MATIC",
    "display_name": "Polygon",
    "symbol": "MATICUSDT",
    "image": "MATIC.png"
    },
    {
    "code": "MIOTA",
    "display_name": "MIOTA",
    "symbol": "IOTAUSDT",
    "image": "MIOTA.png"
    },
    {
    "code": "NANO",
    "display_name": "Nano",
    "symbol": "NANOUSDT",
    "image": "NANO.png"
    },
    {
    "code": "NEAR",
    "display_name": "NEAR Protocol",
    "symbol": "NEARUSDT",
    "image": "NEAR.png"
    },
    {
    "code": "NEO",
    "display_name": "NEO Coin",
    "symbol": "NEOUSDT",
    "image": "NEO.png"
    },
    {
    "code": "OCEAN",
    "display_name": "OCEAN Protocol",
    "symbol": "OCEANUSDT",
    "image": "OCEAN.png"
    },
    {
    "code": "OM",
    "display_name": "MANTRA DAO",
    "symbol": "OMUSDT",
    "image": "OM.png"
    },
    {
    "code": "OMG",
    "display_name": "OMG Network",
    "symbol": "OMGUSDT",
    "image": "OMG.png"
    },
    {
    "code": "QTUM ",
    "display_name": "QTUM ",
    "symbol": "QTUMUSDT",
    "image": "QTUM.png"
    },
    {
    "code": "RVN",
    "display_name": "Ravencoin",
    "symbol": "RVNUSDT",
    "image": "RVN.png"
    },
    {
    "code": "SHIB",
    "display_name": "Shiba Inu",
    "symbol": "SHIBUSDT",
    "image": "SHIB.png"
    },
    {
    "code": "SOL",
    "display_name": "Solana",
    "symbol": "SOLUSDT",
    "image": "SOL.png"
    },
    {
    "code": "STPT",
    "display_name": "Standard Tokenization Protocol",
    "symbol": "STPTUSDT",
    "image": "STPT.png"
    },
    {
    "code": "STRAX",
    "display_name": "Stratis",
    "symbol": "STRAXUSDT",
    "image": "STRAX.png"
    },
    {
    "code": "SUPER",
    "display_name": "SuperFarm",
    "symbol": "SUPERUSDT",
    "image": "SUPER.png"
    },
    {
    "code": "THETA",
    "display_name": "Theata Network",
    "symbol": "THETAUSDT",
    "image": "THETA.png"
    },
    {
    "code": "USDTBSC",
    "display_name": "USDTBSC",
    "symbol": "BUSDUSDT",
    "image": "USDTBSC.png"
    },
    {
    "code": "USDTERC20",
    "display_name": "USDTERC20",
    "symbol": "BUSDUSDT",
    "image": "USDTERC20.png"
    },
    {
    "code": "USDTTRC20",
    "display_name": "USDTTRC20",
    "symbol": "BUSDUSDT",
    "image": "USDTTRC20.png"
    },
    {
    "code": "TRX",
    "display_name": "TRON",
    "symbol": "TRXUSDT",
    "image": "TRX.png"
    },
    {
    "code": "TUSD",
    "display_name": "TrueUSD",
    "symbol": "TUSDUSDT",
    "image": "TUSD.png"
    },
    {
    "code": "UNI",
    "display_name": "Uniswap",
    "symbol": "UNIUSDT",
    "image": "UNI.png"
    },
    {
    "code": "USDC",
    "display_name": "USD Coin",
    "symbol": "USDCUSDT",
    "image": "USDC.png"
    },
    {
    "code": "USDP",
    "display_name": "Pax Dollar",
    "symbol": "USDPUSDT",
    "image": "USDP.png"
    },
    {
    "code": "UST",
    "display_name": "TerraUSD",
    "symbol": "USTUSDT",
    "image": "UST.png"
    },
    {
    "code": "VET",
    "display_name": "VeChain",
    "symbol": "VETUSDT",
    "image": "VET.png"
    },
    {
    "code": "WAVES",
    "display_name": "Waves",
    "symbol": "WAVESUSDT",
    "image": "WAVES.png"
    },
    {
    "code": "XLM",
    "display_name": "Stellar",
    "symbol": "XLMUSDT",
    "image": "XLM.png"
    },
    {
    "code": "XMR",
    "display_name": "Monero",
    "symbol": "XMRUSDT",
    "image": "XMR.png"
    },
    {
    "code": "XRP",
    "display_name": "XRP",
    "symbol": "XRPUSDT",
    "image": "XRP.png"
    },
    {
    "code": "XTZ",
    "display_name": "Tezos",
    "symbol": "XTZUSDT",
    "image": "XTZ.png"
    },
    {
    "code": "XVG",
    "display_name": "Verge",
    "symbol": "XVGUSDT",
    "image": "XVG.png"
    },
    {
    "code": "YFI",
    "display_name": "yearn.finance",
    "symbol": "YFIUSDT",
    "image": "YFI.png"
    },
    {
    "code": "ZEC",
    "display_name": "Zcash",
    "symbol": "ZECUSDT",
    "image": "ZEC.png"
    },
    {
    "code": "ZIL",
    "display_name": "Zilliqa",
    "symbol": "ZILUSDT",
    "image": "ZIL.png"
    }
];