// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  DAILY_PURCHASE_LIMIT : 2000,
  MONTH_PURCHASE_LIMIT : 10000,
  GIFT_EXCHANGE_FEE : 0.045,
  GAME_EXCHANGE_FEE : 0.1,
  EXCHANGE_FEE : 0.15,
  production: false,
  isprod:false,
  cryptoNewsToken : "kaplar28uncc8pyk8qlwslyoylyqppleq4qwwhfu",
  API_ENDPOINT: "https://cryptomate.com.cn:8443/api/v1/",
  //API_ENDPOINT: "http://kratom.technofox.co.in:1337/api/v1/",
  AWSImageURL : "https://dax54a7uq3vbm.cloudfront.net/live/",
  PAYPAL_CLIENT_ID: 'AU8pqs0g89Em1I2s5_QSw9Ydp8F00owB9BPBelJVHKE4ZxAPPK6gAE17Fl4xVn9lEoPn7qzJpSTvMjse',
  FACEBOOK_KEY: "564455438340841",
  GOOGLE_KEY: "401905535910-sfod2ckbhe819oe3mtpr4dv7t07kjpms.apps.googleusercontent.com", //M4FpjAEMaW2_A3FrptqGnRCM
  PAYMENT_REDIRECT: '',
  GOOGLE_TAG_KEY: "GTM-PVTKHSZ", // Test Google Tag manager Container ID provide by Robbert
  FB_PIXEL_KEY: "01234567890", // testing
  WEB_APP: 'http://cpm.technofox.co.in/',
  firebase: {
    apiKey: "AIzaSyA9jftFmGRrY31jH3accHUjq-huS9rhoA0",
    authDomain: "foodimus-beb7e.firebaseapp.com",
    databaseURL: "https://foodimus-beb7e.firebaseio.com",
    projectId: "foodimus-beb7e",
    storageBucket: "foodimus-beb7e.appspot.com",
    messagingSenderId: "1060728529195",
    appId: "1:1060728529195:web:adf39662287de5525a256e",
    measurementId: "G-X2XFGDFN1H"
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
