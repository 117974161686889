export const ENDPOINTS = {
  CONTECTUS : "contect-us/submit",
  LANGUAGELIST : "languages-lists",
  AUTH : {
    VALIDATEEMAIL : "auth/email-validate",
    SIGNUP : "auth/signup",
    LOGIN : "auth/login",
    LOGOUT : "auth/logout",
    FORGOT_PASSWORD : "auth/forgot",
    RESET_PASSWORD : "auth/reset/password",
    VALIDATE_REFERRAL_CODE : "auth/validate-referral-code",
  },
  USER_PROFILE : {
    GET : "user/profile",
    UPDATE : "user/update/profile",
    CHANGE_PASSWORD : "user/change/password",
    SEND_VERIFICATION: 'user/send-verification-email',
    CONFIRM_VERIFICATION: 'user/confirm-email-verification',
    TOTAL_WALLET : 'total-wallet',
    WALLET : 'wallet',
    WALLET_WITDRAW : 'wallet/withdraw-request',
    REDEEM_VOUCHER : 'redeem-code-cart',
    INVITED_USER : 'invited-users',
    WEPAY_REQUEST : 'wepay-my-request',
    OTC_REQUEST : 'otc-my-request',
    OTCT_REPLY_REQUEST : 'otc-my-reply',
    SUBMIT_BATCH_REFIL : 'batch-refill-request',
  },
  UPLOAD_FILE:{
    IMAGE : "image/upload",
    PDF : "pdf/upload",
    GET_DOCUMENT : 'user/verification-document',
    UPLOAD_DOCUMENT : 'user/upload-verification-document'
  },
  LANDING : {
    GET_FEATURED_BRANDS: 'featured-brands',
    AUTOSUGGESTION: 'product-auto-suggestion',
    GIFT_AUTOSUGGESTION: 'gift-card-auto-suggestion',
    SUBSCRIBE_EMAIL: 'subscribe-email'
  },
  SHOP : {
    GET_BANNERS: 'shop-banners',
    GET_BRANDS: 'brands',
    GET_CATEGORY: 'product-category',
    GET_PRODUCTS: 'products/',
    GET_PRODUCTS_DETAILS: 'product/detail/',
    CRYPTO_EXCHANGE: 'crypto-exchange-rate',
  },
  WISHLIST : {
    CREATE: 'wishlist/add',
    LIST: 'wishlist/list',
    PRODUCT_LIST: 'prouct-wishlist/list',
    DELETE: 'wishlist/delete',
    ADD_REMOVE_PRODUCT: 'wishlist/add-product',
    PRODUCT_LISTS: 'wishlist/product-list',
    PRODUCT_DELETE : 'wishlist/product/delete'
  },
  DISCOUNT : {
    APPLY : "verify-coupon",
  },
  CART : {
    ADD: 'cart/add',
    DELETE: 'cart-item/delete',
    LIST: 'cart/list',
    UPDATE: 'cart-item/update',
    UPDATE_PRICE: 'cart-item/update-price',
    PLACE_ORDER : 'place-order'
  },
  CAREER : {
    LIST : 'career/lists',
    APPLY : 'career/apply',
  },
  STATIC : {
    FAQ : 'faq/lists',
    CMSPAGES : 'cms-page/',
  },
  SOLUTION : {
    WEPAY : 'wepay-info',
    WEPAY_REQUEST : 'wepay-request',
    OUTSOURCE : 'outsourcing-brand',
    OTC_BUY_SELL_LIST : 'otc-buy-sell',
    OTC_REQUEST : 'otc-request',
    OTC_REPLY : 'otc-reply',
    RECOMMENDATION : 'recommendation/product'
  },
  USER_ADDRESS : {
    CREATE: 'address/add-update',
    LIST: 'address/list',
    DELETE: 'address/delete',
  },
  GIFT_XOXO : {
    VOUCHERS : 'gift-card-products/',
    VOUCHERS_DETAIL : 'gift-card-products-detail'
  },
  GAME_API : {
    LIST : 'games-products/',
    DETAIL : 'games-products-detail/',
    BEST_SELLER : 'games-best-seller/lists'
  },
  DTONE : {
    OPERATORS : 'operators',
    OPERATOR_DETAIL : 'operator-detail',
    LOOKUP_MOBILE : 'mobile-number-operators',
    PRODUCTS : 'operator/products',
  },
  GIFT_CARD : {
    VOUCHER_DESIGN : 'gift-designs',
    BALANCE_CARD :'balance-card'
  },
  LOYALTY_PROGRAME : {
    LIST: 'loyalty-program',
    DETAIL : 'loyalty-program/',
    ACTIVATE : 'loyalty-program/activate'
  },
  USER_ORDER : {
    ORDER_LIST : 'orders',
    ORDER_DETAIL : 'order/detail/',
    ORDER_PRODUCTS : 'order/products',
    ORDER_PRODUCT_DETAIL : 'order/product/detail/',
  }
}
