import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/shared/services/core.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { HelperService } from '../../shared/services/helper-service.service';
import {ToastrService} from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.css']
})
export class WalletComponent implements OnInit {
  public withdrawRequest!: FormGroup;
  public isLoading:boolean = false;
  public isFormSubmit:boolean = false;
  public lang:any = '';
  public walletLists:any = [];
  public myTotalWalletBalance:any = 0.00;

  public start_date:any = '';
  public enddate:any = '';
  constructor(
    private fb: FormBuilder,
    private helperService: HelperService,
    private toast: ToastrService,
    public coreService: CoreService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.getMyWalletList();
    this.getMyTotalWalletAmount();
  }

  get get() { return this.withdrawRequest.controls; }

  clearLoginForm(){
    this.initForm();
  }

  doSearchWallet(){
    this.isLoading = true;
    this.coreService.post(`${ENDPOINTS.USER_PROFILE.WALLET}`, {'end_date':this.enddate,'start_date':this.start_date}).subscribe((res) => {
      this.isLoading = false;
      if (res.body.success) {
        this.walletLists = res.body.data.items;
      } else {
        this.walletLists = [];
      }
    }, (err) => {
    });
  }

  initForm(){
    this.isFormSubmit = false;
    this.withdrawRequest = this.fb.group({
      amount: ['', [Validators.required]],
      reason: ['', [Validators.required]],
      message: ['', [Validators.required]]
    });
  }

  getMyWalletList(){
    this.isLoading = true;
    this.coreService.post(`${ENDPOINTS.USER_PROFILE.WALLET}`, {}).subscribe((res) => {
      this.isLoading = false;
      if (res.body.success) {
        this.walletLists = res.body.data.items;
      } else {
        this.walletLists = [];
      }
    }, (err) => {
    });
  }

  getMyTotalWalletAmount(){
    this.isLoading = true;
    this.coreService.post(`${ENDPOINTS.USER_PROFILE.TOTAL_WALLET}`, {}).subscribe((res) => {
      this.isLoading = false;
      if (res.body.success) {
        this.myTotalWalletBalance = res.body.data.total_wallet_balance;
      } else {
        this.myTotalWalletBalance = 0.00;
      }
    }, (err) => {
    });
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang)
  }

   /**
   * @method: createNow
   * @description: Using to create User address
   * @param formData
   */
    doSubmitWithdrawRequest(formData:any) {
      this.isFormSubmit = true;
      if (this.withdrawRequest.valid && formData.amount) {
        const paylaod = {
          ...formData
        }
        if(paylaod.amount>this.myTotalWalletBalance){
          this.toast.error("amount unavailable in the wallet if exceeds","Error!");
        }
        else{
          this.coreService.post(`${ENDPOINTS.USER_PROFILE.WALLET_WITDRAW}`, paylaod).subscribe((res) => {
            this.isLoading = false;
            this.toast.success(res.body.message, "Success!");
            this.withdrawRequest.reset();
            $("#withdraw-wallet").modal('hide');
          }, (err) => {
            this.toast.error(err.body.message,"Error!");
          });
        }
      }else{
        this.toast.error("Please enter valid details","Error!");
      }
    }
}
