<app-headers></app-headers>
<section class="text-center mb-2 settingTabs">
    <app-user-breadcrumb></app-user-breadcrumb>
</section>
<section class="mb-5 profileView">
    <div class="container">
        <div class="row">
            <!--  dashboard-menu-->
            <div class="col-md-5 col-lg-3">
                <app-user-left-menu></app-user-left-menu>
            </div>
            <div class="col-md-7 col-lg-9">
                <div class="rightbox-dashbord">
                    <div *ngIf="isLoading" id="loader"></div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                            <h4 class="list-heading">Referrals</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="redeemtopbox">
                                <p>
                                    We're giving our best customers a chance to try our new Refer-a-Friend program before anyone else. After verification of your email refer your friends & family to www.cryptomate.com.cn and earn $5 when they spend $50!
                                    <br />
                                    <span class="fontbold"><a (click)="goToNavigation('refer-afriends-terms-condition');" routerLink="/refer-afriends-terms-condition"> Terms and conditions</a> </span>
                                </p>
                                <div class="row">
                                    <div class="col-md-12 col-lg-10">
                                        <label for="text" class="mb-0">Your refer link</label>
                                        <div class="md-form searchbox coplinkbtn mt-0">
                                            <input class="form-control" type="text" name="name" disabled  [(ngModel)]="refeeralURL" />
                                            <button type="button" (click)="copyURL()" class="applypromo-btn waves-effect waves-light"><i class="fas fa-link"></i> COPY LINK</button>
                                        </div>
                                    </div>
                                </div>
                                <p>Your referral link will appear after verifying your email.</p>
                                <a *ngIf="!profileObj.email_validate" href (click)="goToNavigation('user/settings');"  class="joinnow-btn waves-effect waves-light">Verify Email</a>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <h5 class="fontbold">Lists of invited users</h5>
                            <div class="table-responsive text-nowrap">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Full Name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Order Count</th>
                                            <th scope="col">Date & Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let row of usersList;">
                                            <th scope="row">{{row.full_name}}</th>
                                            <td>{{row.email}}</td>
                                            <td>{{row.order_count}} Order(s)</td>
                                            <td>{{row.createdAt | date}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" *ngIf="!isLoading && usersList.length==0">Record not found.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>