import { Injectable, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { CoreService } from './core.service';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  private loggedUserInfo = new BehaviorSubject<any>(false);
  $loggedUserInfo = this.loggedUserInfo.asObservable();

  public getUserProfile: any;
  public currentUser = localStorage.getItem('currentUser');
  user: any = (this.currentUser ? JSON.parse(this.currentUser) : {});
  private setUserProfile = new BehaviorSubject<any>(this.user);
  $getUserProfile = this.setUserProfile.asObservable();

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private route: ActivatedRoute,
    private router: Router,
    private coreService: CoreService,
    private gtmService: GoogleTagManagerService,
    @Inject(DOCUMENT) private dom:any
  ) { }

  navigate(url:any, lang:any,returnUrl=""){
    let newUrl = url;
    if(lang != 'en') {
      newUrl = url == '/' ? `/${lang}/` : `/${lang}${url}`;
    }
    if(returnUrl){
      const obj = { returnUrl }
      this.router?.navigate([newUrl], { queryParams: obj});
    }else{
      this.router?.navigate([newUrl]);
    }
    $("html,body").scrollTop(0);
    $(".zoomContainer").remove();
  }

  isFloat(n:any){
    return Number(n) === n && n % 1 !== 0;
  }

  /**
    * @method scrollUpPage
    * @description using for scroll page when page change and filter apply.
    */
   scrollUpPage(topView:any) {
    window.scroll({
      top: topView,
      left: 0,
      behavior: 'smooth'
    });
  }

  setSeoKeyword(obj:any, flag:any) {
    this.titleService.setTitle(obj.title ? obj.title : 'Foodimus');
    if (flag) {
      this.metaTagService.addTags([
        { name: 'title', content: obj.title ? obj.title : '' },
        { name: 'og:title', property: 'og:title', content: obj.title ? obj.title : ''  },
        { name: 'description', property: 'description', content: obj.description ? obj.description : '' },
        { name: 'og:description',property: 'og:description', content: obj.description ? obj.description : '' },
        { name: 'og:url', property: 'og:url', content: obj.url ? obj.url : '' },
        { name: 'og:image', property: 'og:image', content: obj.image ? obj.image : '' },
        { name: 'og:image:alt', property: 'og:image:alt', content: obj.title ? obj.title : '' },
        { name: 'twitter:card', property: 'twitter:card', content: 'summary_large_image' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ]
      );
    }
    if (!flag) {
      this.metaTagService.updateTag({ name: 'title', content: obj.title ? obj.title : '' });
      this.metaTagService.updateTag({ name: 'description', content: obj.description ? obj.description : '' });
      this.metaTagService.updateTag({ name: 'og:url', property: 'og:url', content: obj.url ? obj.url : '' });
      this.metaTagService.updateTag({ name: 'og:image', property: 'og:image', content: obj.image ? obj.image : '' });
      this.metaTagService.updateTag({ name: 'og:image:alt', property: 'og:image:alt', content: obj.title ? obj.title : '' });
      this.metaTagService.updateTag({ name: 'twitter:card', property: 'twitter:card', content: 'summary_large_image' });
    }
    this.setCanonicalURL();
  }

  setCanonicalURL(url?: string) {
    const canURL = url == undefined ? this.dom.URL : url;
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canURL);
  }

  /**
  * @method: setLoggedUserInfo
  * @desc: Set Loggedin User details
  * @param val
  */
  setLoggedUserInfo(val: any) {
    this.loggedUserInfo.next(val);
  }

  changeUserProfile(url: any) {
    this.setUserProfile.next(url)
  }

  getGooglePayload(details:any) {
    const paylaod = {
      full_name: details.name,
      firstName: details.firstName,
      lastName: details.lastName,
      email: details.email,
      user_name: details.email,
      is_social: 1,
      signup_from: 'gmail',
      social_user_id: details.id,
      social_token: details.authToken,
    }
    return paylaod;
  }


  getFBPayload(details:any) {
    const paylaod = {
      full_name: details.name,
      firstName: details.firstName,
      lastName: details.lastName,
      email: details.email,
      user_name: details.email,
      is_social: 1,
      signup_from: details.provider.toLowerCase(),
      social_user_id: details.id,
      social_token: details.authToken,
    }
    return paylaod;
  }
}