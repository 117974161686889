<app-header [pageName]="'gift'" [cartItemData]="cartItems"></app-header>

<nav id="guide-template" class="navbar navbar-expand-lg text-center newHea">
  <div class="container">
    <div class="collapse navbar-collapse" id="main_nav">
      <div class="div-shop">
        <div class="title-heading">
          <ul class="list-unstyled three-menu">
            <li>
              <a (click)="goToNavigation('shop')"
                ><img src="/assets/img/ele-icon.png" />
                <span>Electronics</span>
              </a>
            </li>
            <li>
              <a (click)="goToNavigation('gift')"
                ><img src="/assets/img/gift-icon.png" />
                <span class="active">Gift Cards</span></a
              >
            </li>
            <li>
              <a (click)="goToNavigation('topup')"
                ><img src="/assets/img/top-icon.png" /> <span>Top-Up</span></a
              >
            </li>
            <li>
              <a (click)="goToNavigation('games')"
                ><img src="/assets/img/game.svg"  style="width: 30px;"/> <span>Games</span></a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</nav>

<section class="mb-5 spcetop">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-lg-4">
        <h5>
          <select
            class="sort-select conto"
            [(ngModel)]="giftObj.filterSettings.country"
            (change)="filterSelectSubCat()"
          >
            <option
              *ngFor="let country of voucherCountry"
              value="{{ country.filterValueCode }}"
            >
              {{ country.filterValue }}
            </option>
          </select>
        </h5>
        <div class="mobv">
          <a href="javascript:;" onclick="toggler('myContent');"
            ><i class="fa fa-list-ul"></i> CATEGORIES</a
          >
        </div>
        <div id="myContent" class="hiddens">
          <div>
            <h4 class="text-center"><b>CATEGORIES</b></h4>
            <div id="sticky-anchor"></div>
            <a
              href="javascript:;"
              id="filterfixed"
              class="filte-category waves-effect waves-light"
              data-toggle="modal"
              data-target="#category-filter"
            >
              <i class="fas fa-sort-amount-up"></i> FILTER CATEGORIES</a
            >
            <div class="category-box coloured categoerybox-mnone newpage">
              <div class="checkbox coloured">
                <label (click)="goToNavigation('cryptomate-balance-card')">
                  <span class="checkbox-material">
                    <span class="check"></span>
                  </span>
                  <span>Mate Balance Card</span>
                </label>
              </div>
              <div
                class="checkbox coloured"
                *ngFor="let category of voucherCategory"
              >
                <label (click)="doSelectCategory(category, 'categorys_')">
                  <input
                    type="checkbox"
                    id="categorys_{{ category.filterValueCode }}"
                    [(ngModel)]="category.isSelected"
                  />
                  <span class="checkbox-material">
                    <span class="check"></span>
                  </span>
                  <span [innerHtml]="category.filterValue"></span>
                </label>
              </div>
            </div>
            <div
              class="modal left fade"
              id="category-filter"
              tabindex="-1"
              role="dialog"
              aria-labelledby="myModalLabel"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content" style="border-radius: 0px">
                  <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel">CATEGORY</h4>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="checkbox coloured">
                      <label
                        (click)="goToNavigation('cryptomate-balance-card')"
                      >
                        <span class="checkbox-material">
                          <span class="check"></span>
                        </span>
                        <span>Mate Balance Card</span>
                      </label>
                    </div>
                    <div
                      class="checkbox coloured"
                      *ngFor="let category of voucherCategory"
                    >
                      <label (click)="doSelectCategory(category, 'category_')">
                        <input
                          type="checkbox"
                          id="category_{{ category.filterValueCode }}"
                        />
                        <span class="checkbox-material">
                          <span class="check"></span>
                        </span>
                        {{ category.filterValue }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-8">
        <div class="text-center newPashe2">
          <ul>
            <li>
              <a
                href="javascript:;"
                (click)="filterBasedOnCurrency('crypto')"
                [ngClass]="{
                  active: giftObj.filterSettings.currency_type == 'crypto'
                }"
                >Crypto Based</a
              >
            </li>
            <li>
              <a
                href="javascript:;"
                (click)="filterBasedOnCurrency('fiat')"
                [ngClass]="{
                  active: giftObj.filterSettings.currency_type == 'fiat'
                }"
                >Fiat Based</a
              >
            </li>
          </ul>
        </div>
        <div class="row mb-3">
          <div class="col-md-8 col-lg-8">
            <!-- <h5 class="mt-2" *ngIf="giftObj.filterSettings.country && !giftObj.filterSettings.product_name">Showing Results in <span style="text-transform: capitalize;">{{giftObj.filterSettings.country}}</span></h5> -->
            <h5
              class="mt-2"
              *ngIf="
                giftObj.filterSettings.product_name &&
                giftObj.filterSettings.country
              "
            >
              Showing Results for
              <b>"{{ giftObj.filterSettings.product_name }}"</b> in
              <span style="text-transform: capitalize">{{
                giftObj.filterSettings.country
              }}</span>
            </h5>
          </div>
          <div class="col-md-4 col-lg-4">
            <h5>
              <label class="title-sort">Sort By :</label>
              <select
                class="sort-select"
                [(ngModel)]="giftObj.filterSettings.order_type"
                (change)="filterSelectSubCat()"
              >
                <option value="ASC">Name A to Z</option>
                <option value="DESC">Name Z to A</option>
              </select>
            </h5>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-8 col-lg-9">
            <div
              class="chip waves-effect"
              *ngFor="let selected of selectedCategoryList"
            >
              <span [innerHtml]="selected.filterValue"></span>
              <i
                class="close fas fa-times"
                (click)="remveFromList(selected)"
              ></i>
            </div>
          </div>
          <div
            class="col-md-4 col-lg-3 paddok"
            *ngIf="selectedCategoryList.length > 0"
          >
            <a
              href="javascript:;"
              (click)="clearAllFilter()"
              class="clearall waves-effect waves-light"
              >CLEAR ALL</a
            >
          </div>
        </div>
        <div
          *ngIf="giftObj.list.length > 0"
          class="row mb-3 d-flex align-items-center justify-content-center m-lr-0 giftiboxrow"
          infiniteScroll
          [infiniteScrollDistance]="1"
          [infiniteScrollThrottle]="50"
          (scrolled)="onScroll()"
          [scrollWindow]="true"
          style="overflow-y: auto"
        >
          <div
            class="col-6 col-md-4 col-sm-6 col-lg-4"
            *ngFor="let gifts of giftObj.list"
          >
            <div class="gift-boxlist">
              <a href="javascript:;" (click)="goTOPage(gifts)">
                <i class="fa-3x">
                  <!-- <img [hidden]="!gifts.isImageLoading" src="https://media.tenor.com/images/f864cbf3ea7916572605edd3b3fe637f/tenor.gif" /> -->
                  <img
                    src="{{ gifts.imageUrl }}"
                    (load)="hideLoader(gifts)"
                    (error)="hideLoader(gifts)"
                  />
                  <!--  [hidden]="gifts.isImageLoading" -->
                </i>
              </a>
              <h3 (click)="goTOPage(gifts)" class="h4 mt-3">
                {{ gifts.name }}
              </h3>
              <div class="pl-3 pr-3 selec-center">
                <select [(ngModel)]="gifts.price">
                  <option
                    *ngFor="let d of gifts.denominations"
                    selected
                    value="{{ d }}"
                  >
                    {{ gifts.currencyCode }} {{ d }}
                  </option>
                </select>
                <select [(ngModel)]="gifts.quantity">
                  <option>Select QTY</option>
                  <option *ngFor="let q of quantityLists" value="{{ q }}">
                    Qty {{ q }}
                  </option>
                </select>
              </div>
              <!--  *ngIf="isLoggedIn" -->
              <div>
                <!-- <span *ngIf="!profileObj.is_document_verified">
                                    <a *ngIf="!gifts.addingToCart && ((gifts.quantity * gifts.price)>limit_amount)" data-toggle="modal" data-target="#giftpurchae" class="btn btn-outline-white addtocart waves-effect waves-light">
                                        Add to cart
                                    </a>
                                    <a *ngIf="!gifts.addingToCart && ((gifts.quantity * gifts.price)<=limit_amount)" (click)="addToCart(gifts)" class="btn btn-outline-white addtocart waves-effect waves-light">Add to cart</a>
                                    <a *ngIf="gifts.addingToCart" class="btn btn-outline-white addtocart waves-effect waves-light">Please Wait...</a>
                                </span> *ngIf="profileObj.is_document_verified" -->
                <span *ngIf="!gifts.addedToCart">
                  <a
                    *ngIf="!gifts.addingToCart"
                    (click)="addToCart(gifts)"
                    class="btn btn-outline-white addtocart waves-effect waves-light"
                    >Add to cart</a
                  >
                  <a
                    *ngIf="gifts.addingToCart"
                    class="btn btn-outline-white addtocart waves-effect waves-light"
                    >Please Wait...</a
                  >
                </span>
                <span *ngIf="gifts.addedToCart">
                  <a
                    class="btn btn-outline-white addtocart waves-effect waves-light"
                    style="
                      background: #ffd91d73 !important;
                      cursor: not-allowed;
                    "
                    >Added to cart</a
                  >
                </span>
              </div>
              <!-- <div *ngIf="!isLoggedIn">
                                <a data-toggle="modal" data-target="#login" class="btn btn-outline-white addtocart waves-effect waves-light">Add to cart</a>
                            </div> -->
            </div>
          </div>
        </div>
        <div
          *ngIf="giftObj.list.length == 0 && !giftObj.loadStatus"
          class="row mb-3 d-flex align-items-center justify-content-center mypast"
        >
          <div class="col-md-12 alert alert-danger">
            Record not found, Please try again.
          </div>
        </div>
        <div
          *ngIf="giftObj.list.length == 0 && giftObj.loadStatus"
          class="row mb-3 d-flex align-items-center justify-content-center mypast"
        >
          <div class="col-md-12 alert alert-info" id="loader"></div>
          <!-- We are fatching gifts vouchers <i class="fas fa-ellipsis-h"></i> -->
        </div>
        <div
          class="row mb-3 d-flex align-items-center justify-content-center mypast"
          *ngIf="giftObj.list.length > 0 && giftObj.loadStatus"
        >
          <div class="col-md-4" style="text-align: center">
            <a href="javascript:;" class="loadmore waves-effect waves-light"
              >Loading more <i class="fas fa-ellipsis-h"></i
            ></a>
            <!-- <div class="spinner-border text-warning" role="status">
                            <span class="sr-only">Loading...</span>
                        </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-gift-validation></app-gift-validation>
<app-login-modal></app-login-modal>
