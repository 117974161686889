<app-header></app-header>
<div *ngIf="isLoading" id="loader"></div>
<section *ngIf="!showDetail" class="text-center mb-2 mt-3 pt-6 mt-50 about_new refer">
    <div class="container">
        <div class="row">
            <div class="col-sm-4 text-left newpage">
                 <h1>Refer A Friend</h1>
                <p>Get $5 for every friend you send our way. </p>
                <p *ngIf="!isLoggedIn"><a  data-toggle="modal" data-target="#login">Learn More</a></p>
                <!-- <p *ngIf="isLoggedIn"><a  href="javascript:;" (click)="showDetailPage()">Learn More</a></p> -->
                <p *ngIf="isLoggedIn"><a  href="javascript:;"  (click)="goToNavigation('user/referral');">Learn More</a></p>
            </div>
        </div>
    </div>
</section>
<section class="mb-2 pt-4 mt-3 mt-50" *ngIf="showDetail">
    <div class="container">
        <div class="col-sm-12">
            <ul class="list-unstyled bradcam">
                <li><a (click)="goToNavigation('home');">Home</a></li>
                <li>
                    <a (click)="showDetailPage();"><i class="fas fa-angle-double-right iconarrw"></i> Refer A Friend</a>
                </li>
            </ul>
        </div>
        <div class="row d-flex align-items-center justify-content-center mt-3">
            <div class="title-heading">
               <h2 class="h1 pb-3">{{pageObj?.title}}</h2>
            </div>
        </div>
        <div class="row d-flex justify-content-center">
            <div class="col-md-2 "></div>
            <div class="col-md-8  ">
                <h2>{{pageObj?.sub_title}}</h2>
				<div [innerHtml]="pageObj.description"></div>
			</div>
             <div class="col-md-2 "></div>			
        </div>
    </div>
</section>