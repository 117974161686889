<app-header [cartItemData]="cartItems"></app-header>
<section class="text-center mb-2 mt-3 pt-4 mt-50">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <ul class="list-unstyled mt-3 bradcam">
                    <li><a (click)="goToNavigation('home');">Home</a></li>
                    <li *ngIf="productDetails.product_brand_id">
                        <a (click)="goToBrandPage(productDetails.product_brand_id);"><i class="fas fa-angle-double-right iconarrw"></i> {{(productDetails.product_brand_id)?productDetails.product_brand_id.brand_name:''}}</a>
                    </li>
                    <li><i class="fas fa-angle-double-right iconarrw"></i> {{(productDetails.updated_product_name)?productDetails.updated_product_name:productDetails.product_name}}</li>
                </ul>
            </div>
        </div>
    </div>
</section>

<section class="mt-4">
    <div class="container">
        <div *ngIf="isLoading" id="loader"></div>
        <div class="row">
            <div class="col-md-12 col-lg-6">
                <div class="left-thumbnailslider">
                    <!--<div id="carousel-thumb" class="carousel slide carousel-fade carousel-thumbnails" data-ride="carousel">
                        <div class="carousel-inner" role="listbox">
                            <div class="carousel-item" *ngFor="let image2 of productDetails.product_images; let j = index" [ngClass]="{active: image2.default_image}">
                                <img class="d-block w-100" src="{{imgBaseUrl}}products/color_images/{{image2.image_name}}" alt="First slide" />
                            </div>
                        </div>
                        <a class="carousel-control-prev" href="#carousel-thumb" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon fas fa-arrow-left" aria-hidden="true"></span>
                        </a>
                        <a class="carousel-control-next" href="#carousel-thumb" role="button" data-slide="next">
                            <span class="carousel-control-next-icon fas fa-arrow-right" aria-hidden="true"></span>
                        </a>
                        <ol class="carousel-indicators">
                            <li data-target="#carousel-thumb" *ngFor="let image of productDetails.product_images; let i = index" [ngClass]="{active: image.default_image}" data-slide-to="i">
                                <img src="{{imgBaseUrl}}products/color_images/{{image.image_name}}" width="100" />
                            </li>
                        </ol>
                    </div>-->
                    <div class="pdp-image-gallery-block clearfix">
                        <!-- Gallery  Thumbnail Images-->
                        <div class="gallery_pdp_container">
                            <div id="gallery_pdp">
                                <a href="javascript:;" *ngFor="let image2 of productDetails.product_images; let j = index" class="image_sliders_{{image2.id}}" id="image_sliders_id">
                                    <img id="image_sliders_{{image2.id}}" src="{{imgBaseUrl}}products/color_images/{{image2.image_name}}" />
                                </a>
                                <!-- <a href="#" data-image="https://dax54a7uq3vbm.cloudfront.net/live/products/color_images/832E56D0-644A-2DE8-08A8-A46563B559CD.png" data-zoom-image="https://dax54a7uq3vbm.cloudfront.net/live/products/color_images/832E56D0-644A-2DE8-08A8-A46563B559CD.png">
                                    <img id="" src="https://dax54a7uq3vbm.cloudfront.net/live/products/color_images/832E56D0-644A-2DE8-08A8-A46563B559CD.png" />
                                </a> 
                                <a href="#" data-image="https://dax54a7uq3vbm.cloudfront.net/live/products/color_images/D4A40C18-27B8-FFAE-7D7F-A7895CC778D7.jpg" data-zoom-image="https://dax54a7uq3vbm.cloudfront.net/live/products/color_images/D4A40C18-27B8-FFAE-7D7F-A7895CC778D7.jpg">
                                    <img id="" src="https://dax54a7uq3vbm.cloudfront.net/live/products/color_images/D4A40C18-27B8-FFAE-7D7F-A7895CC778D7.jpg" />
                                </a>
                                
                                <a href="#" data-image="https://dax54a7uq3vbm.cloudfront.net/live/products/color_images/832E56D0-644A-2DE8-08A8-A46563B559CD.png" data-zoom-image="https://dax54a7uq3vbm.cloudfront.net/live/products/color_images/832E56D0-644A-2DE8-08A8-A46563B559CD.png">
                                    <img id="" src="https://dax54a7uq3vbm.cloudfront.net/live/products/color_images/832E56D0-644A-2DE8-08A8-A46563B559CD.png" />
                                </a> 
                                <a href="#" data-image="https://dax54a7uq3vbm.cloudfront.net/live/products/color_images/D4A40C18-27B8-FFAE-7D7F-A7895CC778D7.jpg" data-zoom-image="https://dax54a7uq3vbm.cloudfront.net/live/products/color_images/D4A40C18-27B8-FFAE-7D7F-A7895CC778D7.jpg">
                                    <img id="" src="https://dax54a7uq3vbm.cloudfront.net/live/products/color_images/D4A40C18-27B8-FFAE-7D7F-A7895CC778D7.jpg" />
                                </a>
                                
                                <a href="#" data-image="https://dax54a7uq3vbm.cloudfront.net/live/products/color_images/832E56D0-644A-2DE8-08A8-A46563B559CD.png" data-zoom-image="https://dax54a7uq3vbm.cloudfront.net/live/products/color_images/832E56D0-644A-2DE8-08A8-A46563B559CD.png">
                                    <img id="" src="https://dax54a7uq3vbm.cloudfront.net/live/products/color_images/832E56D0-644A-2DE8-08A8-A46563B559CD.png" />
                                </a> 
                                
                                <a href="#" data-image="https://dax54a7uq3vbm.cloudfront.net/live/products/color_images/D4A40C18-27B8-FFAE-7D7F-A7895CC778D7.jpg" data-zoom-image="https://dax54a7uq3vbm.cloudfront.net/live/products/color_images/D4A40C18-27B8-FFAE-7D7F-A7895CC778D7.jpg">
                                    <img id="" src="https://dax54a7uq3vbm.cloudfront.net/live/products/color_images/D4A40C18-27B8-FFAE-7D7F-A7895CC778D7.jpg" />
                                </a>
                                
                                <a href="#" data-image="https://dax54a7uq3vbm.cloudfront.net/live/products/color_images/832E56D0-644A-2DE8-08A8-A46563B559CD.png" data-zoom-image="https://dax54a7uq3vbm.cloudfront.net/live/products/color_images/832E56D0-644A-2DE8-08A8-A46563B559CD.png">
                                    <img id="" src="https://dax54a7uq3vbm.cloudfront.net/live/products/color_images/832E56D0-644A-2DE8-08A8-A46563B559CD.png" />
                                </a> -->
                            </div>
                            <!-- Up and down button for vertical carousel -->
                            <!-- <a href="javascript:;" id="ui-carousel-next" style="display: inline;"></a>
                            <a href="javascript:;" id="ui-carousel-prev" style="display: inline;"></a> -->
                            <button id="ui-carousel-hide" style="display: none;"></button>
                        </div>

                        <!-- Gallery -->

                        <!-- gallery Viewer medium image -->
                        <div class="gallery-viewer">
                            <img id="zoom_10" src="{{imgBaseUrl}}products/color_images/{{(productDetails.product_images)?productDetails.product_images[0].image_name:''}}" class="img-fluid" />
                        </div>

                        <!-- gallery Viewer -->
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-6">
                <div class="shopdetail_title">
                    <h2><b>{{(productDetails.updated_product_name)?productDetails.updated_product_name:productDetails.product_name}}</b></h2>
                    <div class="row">
                        <div class="col-6">
                            <span class="float-left" *ngIf="selectedProductVariation.current_stock>0">In stock</span>
                            <span class="float-left noavailble-tag" *ngIf="selectedProductVariation.current_stock==0">Out of stock</span>
                        </div>
                        <div class="col-6">
                            <app-wishlist-modal
                                [productDetails]="productDetails"
                                [productName]="(productDetails.updated_product_name)?productDetails.updated_product_name:productDetails.product_name"
                                [productType]="'product'"
                            ></app-wishlist-modal>
                        </div>
                    </div>
                    <div class="dropdown-divider-new"></div>
                    <div [innerHtml]="productDetails.product_short_description"></div>
                    <div class="row mt-3 price-shop" [hidden]="selectedProductVariation.current_stock==0">
                        <div class="col-sm-8 col-md-8 col-lg-8">
                            <h3 *ngIf="selectedProductVariation.price!='NA' && !selectedProductVariation.sale_price"><b>{{selectedProductVariation.price | currency}}</b></h3>
                            <h3 *ngIf="selectedProductVariation.price!='NA' && selectedProductVariation.sale_price">
                                <b>{{selectedProductVariation.sale_price | currency}}</b> <span class="leftprice-cut1">{{selectedProductVariation.price | currency}}</span>
                            </h3>
                            <p>{{cryptoPrice.display}}</p>
                        </div>

                        <div class="col-sm-6 col-md-6 col-lg-6">
                            <app-crypto-dropdown [inputPrice]="selectedProductVariation.price" [displayType]="'dropdown'" (convertedRate)="cryptoPrice=$event"></app-crypto-dropdown>
                            <select class="selebitcoin-qty" [(ngModel)]="cart.quantity">
                                <option>Select QTY</option>
                                <option *ngFor="let q of quantityLists;" value="{{q}}">{{q}}</option>
                            </select>
                        </div>
                    </div>
                    <span *ngIf="productDetails?.product_color?.length>0" class="float-left">Color : <b class="font-darkbold">{{defaultColorObj.color_name}}</b></span>
                    <div *ngIf="productDetails?.product_color?.length>0" class="price-box newprice">
                        <label class="pure-material-radio {{colors.color_name}}radio" *ngFor="let colors of productDetails.product_color" (click)="doChangeColor(colors)" [ngClass]="{active: colors.default_color == 1}">
                            <i class="btbottom" style="width: 23px; height: 23px; text-align: center; display: inline-block; border-radius: 50%; cursor: pointer; border: 1px solid #ccc;" [ngStyle]="{'background-color': colors.color_code }">
                                <b></b>
                            </i>
                            <u>{{colors.color_name}}</u>
                        </label>
                    </div>
                    <span *ngFor="let attribute of productDetails.attribute">
                        <span class="float-left fontbold">{{attribute.attributes_name}}</span>
                        <div class="btnstorage mb-2">
                            <span *ngFor="let options of attribute.attribute_options">
                                <span>
                                    <label (click)="getProductValue(options,attribute)" class="for-checkbox-tools waves-effect waves-light" for="tool-{{attribute.attributes_name}}_{{options.id}}" [ngClass]="{active: options.is_selected}">
                                        {{options.attribute_value}}
                                    </label>
                                </span>
                            </span>
                        </div>
                    </span>
                    <div class="row col-sm-12">
                        <span class="mr-3"><b class="fontbold">Categories: </b> {{productDetails.product_category_list}}</span>
                    </div>
                    <div class="row col-sm-12">
                        <span class="mr-3"><b class="fontbold">SKU: </b> {{productDetails.product_sku}}</span>
                    </div>
                    <div class="clearfix"></div>
                    <div class="row mt-3">
                        <!-- <button type="button" class="waves-effect waves-light purchase-btn ntp" data-toggle="modal" data-target="#exampleModal" style="display:none" >Add to Cart</button>  -->
                        <div class="col-sm-6" *ngIf="!isLoggedIn">
                            <a *ngIf="selectedProductVariation.current_stock>0" class="waves-effect waves-light purchase-btn" data-toggle="modal" data-target="#login" id="button">Add to Cart</a>
                            <a *ngIf="selectedProductVariation.current_stock==0" class="waves-effect waves-light purchase-btn" data-toggle="modal" data-target="#login" style="background: #ffd91d73 !important; cursor: not-allowed;">
                                Out of stock
                            </a>
                        </div>
                        <div class="col-sm-6" *ngIf="isLoggedIn">
                            <a *ngIf="selectedProductVariation.current_stock>0 && selectedProductVariation.is_added_cart" class="waves-effect waves-light purchase-btn" style="background: #ffd91d73 !important; cursor: not-allowed;">
                                Added to cart
                            </a>
                            <a (click)="addToCart();" *ngIf="!isLoading && selectedProductVariation.current_stock>0 && !selectedProductVariation.is_added_cart" class="waves-effect waves-light purchase-btn">Add to cart</a>
                            <a *ngIf="selectedProductVariation.current_stock==0" class="waves-effect waves-light purchase-btn" style="background: #ffd91d73 !important; cursor: not-allowed;">Out of stock</a>
                        </div>
                        <div class="col-sm-6 socibtn myshopes">
                            <span class="fontbold">Share:</span>
                            <share-buttons [include]="['facebook','twitter','linkedin','telegram','copy']" [autoSetMeta]="true"></share-buttons>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!isLoading && !productDetails">
            <div class="col-md-12 alert alert-danger">
                Record not found
            </div>
        </div>
    </div>
</section>

<div class="section-space--ptb_100" *ngIf="!isLoading && productDetails">
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-lg-3" [hidden]="!crossSaleProducts">
                <div class="left-slider-product likeProductSlider">
                    <h5 class="fontbold mb-3">You might also like</h5>

                    <div class="slider like-slider">
                        <div *ngFor="let crossProducts of productDetails.cross_sale_products; let cross = index">
                            <div class="mb-4">
                                <div class="media d-block d-md-flex list-product" (click)="goToProductDetailPage(crossProducts);">
                                    <img class="card-img-64 d-flex mx-auto mb-3" src="{{imgBaseUrl}}products/{{crossProducts.product_image}}" alt="{{crossProducts.product_name}}" />
                                    <div class="media-body text-center text-md-left ml-md-3 ml-0">
                                        <h5>{{crossProducts.product_name}}</h5>
                                        <h6 *ngIf="!crossProducts.special_price">{{crossProducts.regular_price | currency}}</h6>
                                        <h6 *ngIf="crossProducts.special_price">{{crossProducts.special_price | currency}}</h6>
                                        <div class="reviewb">
                                            <a href="javascript:;" class="active"><i class="fas fa-star"></i></a>
                                            <a href="javascript:;" class="active"><i class="fas fa-star"></i></a>
                                            <a href="javascript:;" class="active"><i class="fas fa-star"></i></a>
                                            <a href="javascript:;"><i class="fas fa-star"></i></a>
                                            <a href="javascript:;"><i class="fas fa-star"></i></a>
                                            <a href="javascript:;">(10)</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8 col-lg-9" [ngClass]="{'col-md-12 col-lg-12': !crossSaleProducts}">
                <div class="review-descritiopn">
                    <ul class="nav nav-tabs md-tabs nav-justified">
                        <li class="nav-item" *ngIf="productDetails.product_description!=''">
                            <a class="nav-link active" data-toggle="tab" href="#panel1" role="tab">Description</a>
                        </li>
                        <li class="nav-item" *ngIf="productDetails.product_specification!=''">
                            <a class="nav-link" [ngClass]="{active: productDetails.product_description==''}" data-toggle="tab" href="#panel2" role="tab">Specification</a>
                        </li>
                    </ul>
                    <div class="tab-content card">
                        <div class="tab-pane fade in show active" id="panel1" role="tabpanel" *ngIf="productDetails.product_description!=''">
                            <br />
                            <div [innerHtml]="productDetails.product_description"></div>
                        </div>
                        <div class="tab-pane fade" [ngClass]="{'in show active': productDetails.product_description==''}" id="panel2" role="tabpanel" *ngIf="productDetails.product_specification!=''">
                            <br />
                            <div [innerHtml]="productDetails.product_specification"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="section-space--ptb_100 more-prduct-shopage" [hidden]="!realtedProducts">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h3>Related Products</h3>
            </div>
        </div>
        <div class="container">
            <section class="regularrealted slider">
                <div *ngFor="let realtedProduct of productDetails.related_products; let r = index">
                    <div class="shoplist-img" (click)="goToProductDetailPage(realtedProduct);">
                        <i class="fa-3x"><img src="{{imgBaseUrl}}products/{{realtedProduct.product_image}}" /></i>
                        <!-- <ul class="social">
                            <li>
                                <a><i class="fa fa-heart"></i></a>
                            </li>
                            <li>
                                <a><i class="fa fa-shopping-cart"></i></a>
                            </li>
                        </ul> -->
                        <h3 class="h4 mt-3">{{realtedProduct.product_name}}</h3>
                        <div>
                            <a class="btn btn-outline-white addtocart waves-effect waves-light" *ngIf="!realtedProduct.special_price">{{realtedProduct.regular_price | currency}}</a>
                            <a class="btn btn-outline-white addtocart waves-effect waves-light" *ngIf="realtedProduct.special_price">{{realtedProduct.special_price | currency}}</a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>