<app-headers></app-headers>
<section class="text-center mb-2 settingTabs">
    <app-user-breadcrumb></app-user-breadcrumb>
</section>
<section class="mb-5 profileView">
    <div class="container">
        <div class="row">
            <!--  dashboard-menu-->
            <div class="col-md-5 col-lg-3">
                <app-user-left-menu></app-user-left-menu>
            </div>
            <div class="col-md-7 col-lg-9">
                <div class="rightbox-dashbord">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                            <h4 class="list-heading">Verify Account - Tier 2</h4>
                            <span *ngIf="(isReupload || profileObj.document_name=='') || (!isReupload && (profileObj.status==2 || profileObj.status==0))">
                                <h5 class="fontbold mt-1 mb-2">Upload Document</h5>
                                <label class="block">Select Document Type</label>
                            </span>
                            <select *ngIf="isReupload || profileObj.document_name==''" class="categorcss" [(ngModel)]="formData.document_type">
                                <option value="passport">Passport</option>
                                <option value="driving_licence">Driving Licence</option>
                                <option value="other">Other</option>
                            </select>
                            <div *ngIf="!isReupload && (profileObj.status==2 || profileObj.status==0)" class="categorcss">
                                <span *ngIf="formData.document_type=='passport'">Passport</span>
                                <span *ngIf="formData.document_type=='driving_licence'" value="driving_licence">Driving Licence</span>
                                <span *ngIf="formData.document_type=='other'" value="other">Other</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!isLoading">
                        <div class="row">
                            <div class="col-sm-12">
                                <h6 *ngIf="profileObj.status==1" class="alert alert-success">Your account is already verified for Tier 2</h6>
                                <h6 *ngIf="profileObj.status==0" class="alert alert-info">Your document is pending to verified for Tier 2</h6>
                                <h6 *ngIf="profileObj.status==2" class="alert alert-danger">Your document was disapproved to verified for Tier 2</h6>
                                <img *ngIf="formData.document_name!=''" class="document-image" src="{{imgBaseUrl}}profile/{{formData.document_name}}" alt="{{profileObj.full_name}}" />
                                <img *ngIf="formData.document_name=='' && (profileObj.document_name && profileObj.status!=1)" class="document-image" src="{{imgBaseUrl}}profile/{{profileObj.document_name}}" alt="{{profileObj.full_name}}" />
                                <div *ngIf="formData.document_name!='' && profileObj.document_name==''" class="de-re"><a (click)="removeImage('document_name');" class="delete-bt"><i class="fa fa-trash" aria-hidden="true"></i></a></div>
                            </div>
                            <div class="col-sm-12" *ngIf="isReupload || profileObj.document_name=='' && formData.document_name==''">
                                <div class="file-upload-wrapper">
                                    <p>Only image/PDF file are accepted ( Max size 1MB )</p>
                                    <div class="card card-body view file-upload">
                                        <div class="card-text file-upload-message">
                                            <i class="far fa-file-image"></i>
                                            <p>
                                                <span class="imgcolorupload">Drag & drop</span> files here or <br />
                                                <span class="imgcolorupload">Browse</span> to begin the upload
                                            </p>
                                        </div>
                                        <input type="file" id="input-file-max-fs" class="file_upload" data-max-file-size="1M" (change)="handleInputChange($event,'document')" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <h5 *ngIf="isReupload || profileObj.selfie_image==''"><b>Upload</b> Selfie with date <a href="javascript:;" data-toggle="modal" data-target="#sampleimg" class="sampleimg-btn">Sample Image </a></h5>
                                <h5 *ngIf="!isReupload && (profileObj.selfie_image!='' && profileObj.status!=1)">Your <b>Uploaded</b> Selfie</h5>
                                <div *ngIf="profileObj.selfie_image!='' || formData.selfie_image!=''">
                                    <img *ngIf="formData.selfie_image!=''" class="document-image" src="{{imgBaseUrl}}profile/{{formData.selfie_image}}" alt="{{profileObj.full_name}}" />
                                    <img *ngIf="formData.selfie_image=='' && (profileObj.selfie_image && profileObj.status!=1)" class="document-image" src="{{imgBaseUrl}}profile/{{profileObj.selfie_image}}" alt="{{profileObj.full_name}}" />                                    
                                </div>
                                <div *ngIf="formData.selfie_image!='' && profileObj.selfie_image==''" class="de-re"><a (click)="removeImage('selfie_image');" class="delete-bt"><i class="fa fa-trash" aria-hidden="true"></i></a></div>
                                <div class="file-upload-wrapper" *ngIf="isReupload || profileObj.selfie_image=='' && formData.selfie_image==''">
                                    <p>Only image/PDF file are accepted ( Max size 1MB )</p>
                                    <div class="card card-body view file-upload">
                                        <div class="card-text file-upload-message">
                                            <i class="far fa-file-image"></i>
                                            <p>
                                                <span class="imgcolorupload">Drag & drop</span> files here or <br />
                                                <span class="imgcolorupload">Browse</span> to begin the upload
                                            </p>
                                        </div>
                                        <input type="file" id="input-file-max-fs" class="file_upload" data-max-file-size="1M" (change)="handleInputChange($event,'selfie')" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex align-items-center justify-content-center" *ngIf="isReupload || profileObj.selfie_image==''">
                            <button [disabled]="isLoading || (formData.selfie_image=='' && formData.document_name=='')" (click)="doUploadDocument()" class="joinnow-btn waves-effect waves-light">Upload now</button>
                        </div>
                        <div class="row d-flex align-items-center justify-content-center" *ngIf="profileObj.status==2 && !isReupload">
                            <button (click)="reUploadDocument()" class="joinnow-btn waves-effect waves-light">Re Upload</button>
                        </div>
                    </div>
                    <div *ngIf="isLoading">
                        <div class="col-md-12 alert alert-info">We are fatching your record <i class="fas fa-ellipsis-h"></i></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="modal fade" id="sampleimg" tabindex="-1">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body text-center">
                <img src="/assets/img/verification1.png" class="w-100" />
            </div>
        </div>
    </div>
</div>
