import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoreService } from '../shared/services/core.service';
import { HelperService } from '../shared/services/helper-service.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { METHODS } from 'src/app/shared/constants/app.paymentmethods.constants';
import { COUNTRY } from 'src/app/shared/constants/app.country.constants';
import { environment } from 'src/environments/environment';

import {ToastrService} from 'ngx-toastr';
declare var $: any;
@Component({
  selector: 'app-wepay',
  templateUrl: './wepay.component.html',
  styleUrls: ['./wepay.component.css']
})
export class WepayComponent implements OnInit {
  public imgBaseUrl = environment.AWSImageURL;
  public lang:any = '';
  public document_type:any = '';
  public documents:any = '';
  public pageObj:any = {
    'category_brands' : {
      'health' : [],
      'ecommerce' : [],
      'education' : [],
      'flight' : [],
      'hotel' : []
    },
    'channels' : []
  };
  public wePayEnqForm!: FormGroup;
  public paymentMethodsLists:any = METHODS;
  public countryLists:any = COUNTRY;
  public isFormSubmit:boolean = false;
  public isLoading:boolean = false;
  public isLoadingPage:boolean = true;
  constructor(
    private toast: ToastrService,
    private fb: FormBuilder,
    private coreService: CoreService,
    public helperService: HelperService
  ) { }

  ngOnInit(): void {
    this.wePayEnqForm = this.fb.group({
      item_name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      full_name: ['', [Validators.required]],
      message: ['', [Validators.required]],
      payment_method: [''],
      country: ['']
    });
    this.getWePayInfo();
  }
  get get() { return this.wePayEnqForm.controls; }

  getWePayInfo(){    
    this.coreService.get(`${ENDPOINTS.SOLUTION.WEPAY}`, {}).subscribe((res) => {
      if (res.body.success) {
        this.pageObj = res.body.data;
        let that = this;
        setTimeout(function(){
          let langCode = (localStorage.getItem("lang_direction")!='' && localStorage.getItem("lang_direction")!=null)?localStorage.getItem("lang_direction"):'ltr';
          $(".regular").slick({
            dots: true,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            rtl: (langCode=='rtl')?true:false,
            responsive: [{
                breakpoint: 1024,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
                }
              }
              // You can unslick at a given breakpoint now by adding:
              // settings: "unslick"
              // instead of a settings object
            ]
			
          });
		  
		  $("#profile-tab-classic-orange").click(function(){
			$('.regular').slick('refresh');
			});
			
			$("#follow-tab-classic-orange").click(function(){
			$('.regular').slick('refresh');
			});
			
			$("#contact-tab-classic-orange").click(function(){
			$('.regular').slick('refresh');
			});
			
			$("#awesome-tab-classic-orange").click(function(){
			$('.regular').slick('refresh');
			});
			
			$("#Health-tab-classic-orange").click(function(){
			$('.regular').slick('refresh');
			});
		  
          that.isLoadingPage = false;
        },500);
      } else {
        this.isLoadingPage = false;
        this.pageObj = [];
      }
    }, (err) => {
      this.isLoadingPage = false;
    });
  }

  /**
   * @method: submitEnq
   * @description: using for to submit  form
   * @param formData
   */
   submitEnq(formData:any) {
    this.isFormSubmit = true;
    if (this.wePayEnqForm.valid && !this.isLoading) {
      this.isLoading = true;
      this.isFormSubmit = false;
      const paylaod = {
        ...formData,
        document_type: this.document_type,
        documents: this.documents
      }
      this.coreService.post(`${ENDPOINTS.SOLUTION.WEPAY_REQUEST}`, paylaod).subscribe((res) => {
        this.isLoading = false;
        if(res.body.success){
          this.toast.success(res.body.message, "Success!");
          this.wePayEnqForm.reset();
        }else{
          this.toast.error(res.body.message, "Error!");
        }
      }, (err) => {
        this.isLoading = false;
        this.toast.error(err.body.message, "Error!");
      });
    }else{
      this.isLoading = true;
    }
  }

  handleInputChange(e:any){
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (file.type.match(pattern)) {
      reader.onload = this.imageUIpload.bind(this);
      reader.readAsDataURL(file);
    }else{
      if (file.type.match('pdf')) {
        reader.onload = this.pdfUpload.bind(this);
        reader.readAsDataURL(file);
      }else{
        this.toast.error("File not supported!", "Error!");
      }
    }
  }
  
  imageUIpload(e:any) {
    this.isLoading = true;
    let reader = e.target;
    this.document_type = 'image';
    this.coreService.post(`${ENDPOINTS.UPLOAD_FILE.IMAGE}`, {'type':'wepay','image':reader.result}).subscribe((res) => {
      this.isLoading = false;
      if (res.body.success) {
        this.documents = res.body.data;
        this.toast.success(res.body.message, "Success!");
      } else {
        this.toast.error("File uploading failed!", "Error!");
      }
    }, (err) => {
      this.isLoading = false;
    });
  }

  pdfUpload(e:any) {
    this.isLoading = true;
    this.document_type = 'pdf';
    let reader = e.target;
    this.coreService.post(`${ENDPOINTS.UPLOAD_FILE.PDF}`, {'type':'wepay','file':reader.result}).subscribe((res) => {
      this.isLoading = false;
      if (res.body.success) {
        this.documents = res.body.data;
        this.toast.success(res.body.message, "Success!");
      } else {
        this.toast.error("File uploading failed!", "Error!");
      }
    }, (err) => {
      this.isLoading = false;
    });
  }

  moveTo(str:string){
    var elmnt:any = document.getElementById(str);
    elmnt.scrollIntoView();
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang)
  }
}
