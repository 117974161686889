<span [hidden]="!isLoggedIn" class="float-right" id="addwishlist">
    <a class="addwishlist mr-4" (click)="fatchWishlist();" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="fas fa-heart"></i> Wishlist</a>
    <div class="dropdown-menu addwishlistopen">
        <span *ngFor="let wishlist of wishlistList">
            <a class="dropdown-item checkdropdown">
                <div class="checkbox coloured width150check">
                    <label class="mb-0">
                        <input type="checkbox" name="wishlist_name_{{wishlist.id}}" value="{{wishlist.id}}" (click)="addProductInWishlist(wishlist)" [(ngModel)]="wishlist.is_already_added" />
                        <span class="checkbox-material">
                            <span class="check"></span>
                        </span>
                        {{wishlist.wishlist_name}}
                    </label>
                </div>
            </a>
            <a (click)="goToWishlistDetail(wishlist);" class="viewbtn">View</a>
            <hr style="margin-top: 20px; margin-bottom: 0.5rem;" />
        </span>
        <span class="dropdown-item text-center" *ngIf="isWishlistFatching && wishlistList.length==0">Please wait...</span>
        <a *ngIf="isLoggedIn" class="dropdown-item text-center" href="#" data-toggle="modal" data-dismiss="modal" data-target="#addnewwishlist"> <i class="far fa-plus-square"></i> New Wishlist</a>
        <a *ngIf="!isLoggedIn" class="dropdown-item text-center" type="button" data-toggle="modal" data-target="#login"> <i class="far fa-plus-square"></i> New Wishlist</a>
    </div>
</span>

<span [hidden]="isLoggedIn" class="float-right">
    <a class="mr-4" data-toggle="modal" data-target="#login"> <i class="fas fa-heart"></i> Wishlist</a>
</span>

<div class="modal fade" id="addnewwishlist" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-body model-pad0 mb-0 text-center">
                <div class="wrap-login100 shopwishlit">
                    <div class="login100-form" style="width: 100%;">
                        <h3 class="text-left mb-3"><b>Add to wishlist</b><button type="button" class="btn-close float-right" data-dismiss="modal" aria-label="Close"></button></h3>
                        <div>
                            <div class="col-md-12">
                                <span *ngFor="let wishlist of wishlistList">
                                    <a class="dropdown-item checkdropdown">
                                        <div class="checkbox coloured width150check">
                                            <label class="mb-0">
                                                <input type="checkbox" value="{{wishlist.id}}" (click)="addProductInWishlist(wishlist)" [(ngModel)]="wishlist.is_already_added" />
                                                <span class="checkbox-material">
                                                    <span class="check"></span>
                                                </span>
                                                {{wishlist.wishlist_name}}
                                            </label>
                                        </div>
                                    </a>
                                    <a (click)="goToWishlistDetail(wishlist);" class="viewbtn">View</a>
                                    <hr style="margin-top: 20px; margin-bottom: 0.5rem;" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <form class="login100-form" style="width: 100%;" [formGroup]="wishlistForm">
                        <div>
                            <div class="col-md-12">
                                <div class="md-form">
                                    <span class="iconform"><i class="far fa-user"></i></span>
                                    <input class="form-control" type="text" id="wishlist" name="wishlist_name" required="required" formControlName="wishlist_name" [ngClass]="{ 'is-invalid': wishlistFormObj.isFormSubmit &&  get?.wishlist_name?.errors }"/>
                                    <label for="wishlist">Create new wishlist</label>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center mt-4">
                            <div class="col-md-12 text-center">
                                <button class="contiunebtn waves-effect waves-light" data-dismiss="modal" aria-label="Close" type="button">Cancel</button>
                                <button type="button" [disabled]="isLoading" (click)="createNow(wishlistForm.value)" class="joinnow-btn waves-effect waves-light">Create</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>