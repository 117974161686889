<app-header></app-header>
<section *ngIf="!showDetail" class="text-center mb-2 pt-6 mt-3 mt-50 about_new balc">
    <div class="container">
        <div class="row">
            <div class="col-sm-4 text-left newpage">                
                <h1>{{pageObj?.title}}</h1>
                <p>With Mate Balance Card, instant purchases of over 5000 products and services across 165 countries. </p>
                <p><a href="javascript:;" (click)="showDetailPage()">Learn More</a></p>
            </div>
        </div>
    </div>
</section>
<section class="mb-2 pt-6 mt-3 mt-50" *ngIf="showDetail">
    <div class="container">
         <div class="row">
            <div class="col-sm-12">
                <ul class="list-unstyled bradcam">
                    <li><a (click)="goToNavigation('home');">Home</a></li>
                    <li>
                        <a (click)="showDetailPage();"><i class="fas fa-angle-double-right iconarrw"></i> {{pageObj?.title}}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row d-flex align-items-center justify-content-center mt-3">
            <div class="title-heading">
                <h2 class="h1 pb-3">{{pageObj?.title}}</h2>
            </div>
        </div>
        <div class="row d-flex justify-content-center">
            <div class="col-md-2 "></div>
            <div class="col-md-8  ">
                <h2>{{pageObj?.sub_title}}</h2>
				<div [innerHtml]="pageObj.description"></div>
			</div>	
            <div class="col-md-2 "></div>		
        </div>
    </div>
</section>