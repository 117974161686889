import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from '../shared/services/core.service';
import { HelperService } from '../shared/services/helper-service.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { environment } from 'src/environments/environment';
import { AuthService } from '../shared/services/auth.service';
import {ToastrService} from 'ngx-toastr';
declare var $: any;
declare var document: any;

@Component({
  selector: 'app-shop-detail',
  templateUrl: './shop-detail.component.html',
  styleUrls: ['./shop-detail.component.css']
})
export class ShopDetailComponent implements OnInit {
  public slug: string = '';
  public imgBaseUrl = environment.AWSImageURL;
  public productDetails: any = {};
  public lang:any = '';
  public realtedProducts :boolean = false;
  public crossSaleProducts :boolean = false;
  public pageurl:string = environment.WEB_APP;
  public defaultimage:string = environment.WEB_APP+'assets/img/CPM-logo.png';
  
  public isFormSubmit: boolean = false;
  public isWishlistFatching: boolean = true;
  public wishlistProducts: boolean = false;
  public isLoggedIn:boolean = false;
  public isLoading:boolean = false;
  public wishlistDetails:any  = {}; 
  public wishlistProductList:any  = [];
  public defaultColorObj:any = {};
  public allSelectedAttributes:any = [];
  public cartItems:any = [];
  public cryptoPrice:any = '';
  public selectedProductVariation:any = {
    price : 0
  };
 
  public cart: any = {
    'quantity' : 1
  }
  public quantityLists:any = [];

  constructor(
    private toast: ToastrService,
    private coreService: CoreService,
    public helperService: HelperService,
    private route: ActivatedRoute,
    private _auth : AuthService,
    private router: Router,
  ) { 
    if(this._auth.loggedIn()){
      this.isLoggedIn = true;
    }else{
      this.isLoggedIn = false;
    }
    this.route.params.subscribe(params => {
      this.slug = params['slug'];
      this.getProductDetails();
    });
    this.pageurl = this.pageurl + "shop-detail/"+this.slug;
  }

  ngOnInit(): void {
     for (var i = 1; i <21; i++) {
       this.quantityLists.push(i);
     }
  }

  getProductDetails(){
    this.isLoading =true;
    this.coreService.get(`${ENDPOINTS.SHOP.GET_PRODUCTS_DETAILS}${this.slug}`, {}).subscribe((res) => {
      if (res.body.success) {
        this.productDetails = res.body.data;
        this.productDetails.product_category_list = this.productDetails.product_category_list.toString();
        this.productDetails.product_tag_list = this.productDetails.product_tag_list.toString();
        this.defaultimage = this.productDetails.product_image;
        if(this.productDetails.product_images.length==0){
          this.productDetails.product_images = [{'image_name':''}];
        }
        if(this.productDetails.product_type=='single'){
          this.selectedProductVariation = {
            "alert_stock": this.productDetails.product_alert_quantity,
            "current_stock": this.productDetails.product_quantity,
            "default_attribute_set": true,
            "id": this.productDetails.id,
            "price": this.productDetails.regular_price,
            "product_id": this.productDetails.id,
            "sale_price": this.productDetails.special_price
          };  
          this.isLoading = false;        
        }else{
          if(this.productDetails.product_color.length>0){
            for (var i = 0; i <this.productDetails.product_color.length; i++) {
                if(this.productDetails.product_color[i].default_color){
                  this.productDetails.product_color[0].default_color = "1";
                  this.defaultColorObj = this.productDetails.product_color[i];
                  this.allSelectedAttributes['color'] = this.defaultColorObj.color_name;
                  this.productDetails.product_images = this.productDetails.product_color[i].images;
                }
            }
          }
          
          let current = false;
          if(this.productDetails.product_attribute_combination.length>0){
            for (var i = 0; i <this.productDetails.product_attribute_combination.length; i++) {
              let attributeValue = this.productDetails.product_attribute_combination[i];
              if(attributeValue.default_attribute_set || i==0){
                this.selectedProductVariation = attributeValue;
                this.selectedProductVariation.current_stock = parseFloat(this.selectedProductVariation.current_stock);
                current = true;
              }
            }
          }
          this.updateVariationInit(current);
        }

        if(this.productDetails.product_images.length==0 && this.productDetails.product_color){
          if(this.productDetails.product_color.length>0){
            this.productDetails.product_color[0].default_color = "1";
            this.defaultColorObj = this.productDetails.product_color[0];
            this.allSelectedAttributes['color'] = this.defaultColorObj.color_name;
            this.productDetails.product_images = this.productDetails.product_color[0].images;
          }
        }
        
        let isDefault = false;
        for (var i = 0; i <this.productDetails.product_images.length; i++) {
          if(this.productDetails.product_images.default_image){
            isDefault =true;
          }
        }

        if(!isDefault && this.productDetails.product_images.length>0){
          this.productDetails.product_images[0].default_image = 1;
        }
       
        if(this.productDetails.product_cross_sale.length>0){
          this.crossSaleProducts = true;
        }

        if( this.productDetails.related_products.length>0){
          this.realtedProducts = true;
        }

        this.isLoading = false;
        this.doUpdateProductName();
        
        if (this.productDetails.product_cross_sale.length > 4) {
          setTimeout(function () {
              $("#carousel").waltzer({
                  scroll: 3,
                  vertical: true,
                  speed: 500,
                  left_nav_btn: ".lefttNav",
                  right_nav_btn: ".rightNav",
              });
              $('.like-slider').slick({
                infinite: true,
                dots: false,
                arrows: true,
                rows: 4,
                slidesToShow:1,
                slidesToScroll: 1
              });
          }, 150);
        }		

        if( this.productDetails.related_products.length>0){
          setTimeout(function () {
            let langCode = localStorage.getItem("lang_direction") != "" && localStorage.getItem("lang_direction") != null ? localStorage.getItem("lang_direction") : "ltr";
            if ($('.regularrealted').hasClass('slick-initialized')) {
              $('.regularrealted').slick('destroy');
            } 
            $(".regularrealted").slick({
                dots: true,
                infinite: true,
                slidesToShow: 4,
                slidesToScroll: 4,
                rtl: langCode == "rtl" ? true : false,          
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 1,
                            infinite: true,
                            dots: true,
                        },
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                ],
            });
          }, 150);		           
        }
		

        $(document).ready(function () {
          $(".owl-carousel").owlCarousel({
              items: 4,
              loop: false,
              mouseDrag: false,
              touchDrag: false,
              pullDrag: false,
              rewind: true,
              autoplay: true,
              margin: 0,
              nav: true,
          });
        });        
      } else {
        this.isLoading = false;
        this.productDetails = false;
      }
    }, (err) => {
      this.isLoading = false;
    });
  }

  updateVariationInit(current:boolean){
    // if(this.productDetails.product_attribute_combination.length>0){
    //   let allObjects = [];
    //   let combinationFirst = this.productDetails.product_attribute_combination[0];
    //   for (let [t, value] of Object.entries(combinationFirst)) {
    //     if(t!='alert_stock' && t!='createdAt' && t!='current_stock' && t!='default_attribute_set' && t!='id' && t!='price' && t!='sale_price' && t!='product_id'){
    //       allObjects.push(t);
    //     }
    //   }
    //   if(this.productDetails.attribute.length>0){
    //     for (var i = 0; i <this.productDetails.attribute.length; i++){
    //       this.productDetails.attribute[i].attributes_name = allObjects[i];
    //     }
    //   }
    //   //console.log(this.productDetails.attribute);
    // }
    this.updateVariationProductStep1(current);
  }

  updateVariationProductStep1(current:boolean){
    this.productDetails.attributeOptions = [];
    for (var i = 0; i <this.productDetails.product_attribute_option.length; i++) {
      let attribute_id = '';
      if(this.productDetails.product_attribute_option[i].attributes_type=='attribute'){
          attribute_id = this.productDetails.product_attribute_option[i].attribute_id;
      }else{
          attribute_id = 'color';
      }
      if(this.productDetails.attributeOptions[attribute_id]){
        this.productDetails.attributeOptions[attribute_id].push({
          "attribute_value" : this.productDetails.product_attribute_option[i].option_name,
          "id" : this.productDetails.product_attribute_option[i].option_id
        });
      }else{
        this.productDetails.attributeOptions[attribute_id] = [];
        this.productDetails.attributeOptions[attribute_id].push({
          "attribute_value" : this.productDetails.product_attribute_option[i].option_name,
          "id" : this.productDetails.product_attribute_option[i].option_id
        });
      }
    }
    this.updateVariationProductStep2(current);
  }

  updateVariationProductStep2(current:boolean){
    if(this.productDetails.attribute.length>0){
      for (var i = 0; i <this.productDetails.attribute.length; i++){
        this.productDetails.attribute[i].attribute_options = this.productDetails.attributeOptions[this.productDetails.attribute[i].id];
        for (var j = 0; j <this.productDetails.attribute[i].attribute_options.length; j++){
          if(this.productDetails.attribute[i].attribute_options[j].attribute_value==this.selectedProductVariation[this.productDetails.attribute[i].attributes_name]){
            this.productDetails.attribute[i].attribute_options[j].is_selected = 1;
            this.allSelectedAttributes[this.productDetails.attribute[i].attributes_name] = this.productDetails.attribute[i].attribute_options[j].attribute_value;
          }else{
            this.productDetails.attribute[i].attribute_options[j].is_selected = 0;
          }
        }            
      }
    }
    var results = this.findByMatchingProperties(this.productDetails.product_attribute_combination, this.allSelectedAttributes);
    if(results.length>0 && !current){
      this.selectedProductVariation = results[0];
    }
    else if(!current){
      this.selectedProductVariation = {'price':"NA",'current_stock':0};
    }

    var results = this.findByMatchingProperties(this.productDetails.product_color, {'color_name':this.selectedProductVariation.color});
    if(results.length>0){
      let that = this;
      setTimeout(function(){
        that.doChangeColor(results[0]);
      },100);
    }
  }

  getProductValue(optObj:any,attribute:any){
    let atrname = attribute.attributes_name;
    this.allSelectedAttributes[atrname] = optObj.attribute_value;
    
    for (var j = 0; j <attribute.attribute_options.length; j++) {
      if(attribute.attribute_options[j].attribute_value==this.allSelectedAttributes[atrname]){
        attribute.attribute_options[j].is_selected = 1;
      }else{
        attribute.attribute_options[j].is_selected = 0;
      }
    }
    var results = this.findByMatchingProperties(this.productDetails.product_attribute_combination, this.allSelectedAttributes);
    if(results.length>0)
      this.selectedProductVariation = results[0];
    else
      this.selectedProductVariation = {'price':"NA",'current_stock':0};
    this.doUpdateProductName();
  }

  findByMatchingProperties(set:any, properties:any) {
    return set.filter(function (entry:any) {
        return Object.keys(properties).every(function (key) {
            return entry[key] === properties[key];
        });
    });
  }

  doChangeColor(color:any){
    let isDefault = false;
    for (var i = 0; i <this.productDetails.product_color.length; i++) {
      this.productDetails.product_color[i].default_color = "0";
    }
    this.defaultColorObj = color;
    color.default_color = "1";
    this.productDetails.product_images = color.images;
    for (var i = 0; i <this.productDetails.product_images.length; i++) {
      if(this.productDetails.product_images.default_image){
        isDefault =true;
      }
    }
    if(!isDefault && this.productDetails.product_images.length>0){
      this.productDetails.product_images[0].default_image = 1;
    }
    this.allSelectedAttributes['color'] = this.defaultColorObj.color_name;
    var results = this.findByMatchingProperties(this.productDetails.product_attribute_combination, this.allSelectedAttributes);
    if(results.length>0)
      this.selectedProductVariation = results[0];
    else
      this.selectedProductVariation = {'price':"NA",'current_stock':0};
    this.doUpdateProductName();
  }

  doUpdateProductName(){
    this.productDetails.updated_product_name = this.productDetails.product_name;
    if(this.selectedProductVariation.color){
      this.productDetails.updated_product_name = this.productDetails.product_name +" "+ this.selectedProductVariation.color;
    }
    for (const [key, value] of Object.entries(this.selectedProductVariation)) {
      if(key!='alert_stock' && key!='color' && key!='createdAt' && key!='current_stock' && key!='default_attribute_set' && key!='id' && key!='product_id' && key!='price' && key!='sale_price'){
        this.productDetails.updated_product_name = this.productDetails.updated_product_name +" "+ value;
      }
    }
    if(this.productDetails.product_images.length>0){
      let that = this;
      setTimeout(function(){
        for (var i = 0; i <that.productDetails.product_images.length; i++) {
          $(".image_sliders_"+that.productDetails.product_images[i].id).attr('data-image',$("#image_sliders_"+that.productDetails.product_images[i].id).attr("src"));
          $(".image_sliders_"+that.productDetails.product_images[i].id).attr('data-zoom-image',$("#image_sliders_"+that.productDetails.product_images[i].id).attr("src"));
        }
        $("#zoom_10").attr('data-zoom-image',$("#zoom_10").attr("src"));
        $("#ui-carousel-hide").trigger('click');
        setTimeout(function(){
          document.getElementById('image_sliders_id').click();
        },200);
      },800);
    }
  }

  goToProductDetailPage(obj:any) {
    $(".regularrealted").slick('reinit');
    //this.helperService.navigate(`/shop-detail/${obj.product_slug}`, this.lang);
    window.location.href = '/shop-detail/'+obj.product_slug;
  }

  goToBrandPage(obj:any){
    this.router?.navigate(['/shop'], { queryParams: {'brand':obj.id}});
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang)
  }

  /**
   * @method: addToCart
   * @description: Using to add item in the cart
   * @param productObj
   */
  addToCart(){
    if(this.selectedProductVariation.current_stock>0 && !this.isLoading){
      this.isLoading =true;
      this.selectedProductVariation.is_added_cart = true;
      var paylaod:any = {
        'allow_only_fiat' : 0,
        'product_name' : (this.productDetails.updated_product_name)?this.productDetails.updated_product_name:this.productDetails.product_name,
        'cart_item' : 'product',
        'product_id' : this.productDetails.id,      
        'quantity' : parseInt(this.cart.quantity),
        'product_type' : this.productDetails.product_type,
        'price' : (this.selectedProductVariation.sale_price)?parseFloat(this.selectedProductVariation.sale_price):parseFloat(this.selectedProductVariation.price)
      }
      if(this.productDetails.product_type!='single'){
        if(this.selectedProductVariation.id){
          paylaod['combination_id'] = this.selectedProductVariation.id;
        }
      }
      this.coreService.post(`${ENDPOINTS.CART.ADD}`, paylaod).subscribe((res) => {
        if(res.body.success){
          this.cartItems = res.body.data;
          this.isLoading = false;
          this.toast.success(res.body.message, "Success!");
          this.selectedProductVariation.is_added_cart = true; 
        }else{
          this.selectedProductVariation.is_added_cart = false;
          this.isLoading = false;
          this.toast.error(res.body.message, "Error!");
        }
        setTimeout(function(){
          $("#pol").show();
          $(".cartPopupModal").show();
        },500);
      }, (err) => {
      });
    }
  }
}
