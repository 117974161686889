<app-header [cartItemData]="cartItems"></app-header>
<section class="text-center mb-2 mt-3 pt-4 mt-50">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <ul class="list-unstyled mt-3 bradcam">
                    <li><a (click)="goToNavigation('/');">Home</a></li>
                    <li>
                        <a (click)="goToNavigation('gift');"><i class="fas fa-angle-double-right iconarrw"></i> Gift Vouchers</a>
                    </li>
                    <li><i class="fas fa-angle-double-right iconarrw"></i> {{giftObj.name}}</li>
                    <li *ngIf="!isLoading"><i class="fas fa-angle-double-right iconarrw"></i> <span [innerHtml]="giftObj.categories | replaceXoxo"></span></li>
                </ul>
            </div>
        </div>
    </div>
</section>
<div *ngIf="isLoading" id="loader"></div>
<section class="mb-5" *ngIf="!isLoading">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-6">
                <a href="javascript:;">
                    <i class="fa-3x"><img src="{{giftObj.imageUrl}}" class="img-gift-detail newchangeds" /></i>
                </a>
            </div>
            <div class="col-md-12 col-lg-6">
                <div class="content-detail">
                    <span class="instant-img checkinstant"><i class="far fa-check-circle"></i> Instant Delivery</span>
                    <h2><b>{{giftObj.name}}</b></h2>

                    <div class="row">
                        <div class="col-6">
                            <span class="float-left">Validity: <label [innerHtml]="giftObj.expiryAndValidity | replaceXoxo"></label></span>
                        </div>
                        <div class="col-6">
                            <app-wishlist-modal [giftCardDetail]="giftObj" [productName]="giftObj.name" [productType]="'gift_card'"></app-wishlist-modal>
                        </div>
                    </div>

                    <div class="dropdown-divider-new"></div>
                    <span class="float-left">Select amount</span>
                    <div class="price-box mypricebox">
                        <!-- <label class="pure-material-radio" *ngFor="let d of giftObj.denominations">
                            <input type="radio" name="group" [(ngModel)]="giftObj.price" value="{{d}}" />
                            <span>{{giftObj.currencyCode}} {{d}}</span>
                            <small [hidden]="giftObj.allow_only_fiat" *ngIf="cryptoPrice.exchange_rate==0">{{cryptoPrice.display}}</small>
                            <small [hidden]="giftObj.allow_only_fiat" *ngIf="cryptoPrice.exchange_rate!=0">{{(cryptoPrice.exchange_rate * (d)).toFixed(6)}} {{cryptoPrice.currency_code}}</small>
                        </label> -->

                        <label class="pure-material-radio" *ngFor="let d of giftObj.denominations">
                            <input type="radio" name="group" [(ngModel)]="giftObj.price" value="{{d.denomination}}" />
                            <span>{{giftObj.currencyCode}} {{d.denomination}}</span>
                            <small [hidden]="giftObj.allow_only_fiat" *ngIf="cryptoPrice.exchange_rate==0">{{cryptoPrice.display}}</small>
                            <small [hidden]="giftObj.allow_only_fiat" *ngIf="cryptoPrice.exchange_rate!=0">{{(cryptoPrice.exchange_rate * (d.amount)).toFixed(6)}} {{cryptoPrice.currency_code}}</small>
                        </label>
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <span [hidden]="giftObj.allow_only_fiat">
                                <app-crypto-dropdown [inputPrice]="'1'" [displayType]="'dropdown'" (convertedRate)="cryptoPrice=$event"></app-crypto-dropdown>
                            </span>
                            <select class="selebitcoin-qty" [(ngModel)]="giftObj.quantity">
                                <option>Select QTY</option>
                                <option *ngFor="let q of quantityLists;" value="{{q}}">Qty {{q}}</option>
                            </select>
                        </div>
                        <!--  *ngIf="isLoggedIn" -->
                        <div class="col-sm-12 col-md-12 col-lg-12" style="margin-top: 15px;">
                            <!-- <span *ngIf="!profileObj.is_document_verified">
                                <a *ngIf="!addingToCart && ((giftObj.quantity * giftObj.price)>limit_amount)" data-toggle="modal" data-target="#giftpurchae" class="waves-effect waves-light giftpurchase-btn">Purchase</a>
                                <a *ngIf="!addingToCart && ((giftObj.quantity * giftObj.price)<=limit_amount)" (click)="addToCart(giftObj)" class="waves-effect waves-light giftpurchase-btn giftnew">Purchase</a>
                                <a data-toggle="modal" data-target="#exampleModa6" class="waves-effect waves-light giftpurchase-btn giftcardn"> <img src="/assets/img/gift01.png" /> </a>
                                <a *ngIf="addingToCart" class="waves-effect waves-light giftpurchase-btn">Please wait...</a>
                            </span> *ngIf="profileObj.is_document_verified"-->
                            <span *ngIf="!giftObj.addedToCart">
                                <a *ngIf="!addingToCart" (click)="addToCart(giftObj)" class="waves-effect waves-light giftpurchase-btn giftnew">Purchase</a>
                                <a *ngIf="addingToCart" class="waves-effect waves-light giftpurchase-btn giftnew">Please wait...</a>                                
                            </span>
                            <span *ngIf="giftObj.addedToCart">
                                <a class="waves-effect waves-light giftpurchase-btn giftnew" style="background: #ffd91d73 !important; cursor: not-allowed;">Added to cart</a>
                            </span>
                            <a data-toggle="modal" data-target="#exampleModa6" class="waves-effect waves-light giftpurchase-btn giftcardn">Purchase as a gift</a>
                        </div>
                        <!-- <div class="col-sm-6 col-md-6 col-lg-6" *ngIf="!isLoggedIn">
                            <a data-toggle="modal" data-target="#login" class="waves-effect waves-light giftpurchase-btn giftnew">Purchase</a>
                            <a data-toggle="modal" data-target="#exampleModa6" class="waves-effect waves-light giftpurchase-btn giftcardn"> <img src="/assets/img/gift01.png" /> </a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="pb-5 minorbg1" *ngIf="!isLoading">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <ul class="nav nav-tabs md-tabs nav-justified gift-detailtab" style="border-bottom: none;">
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#panel1" role="tab" style="box-shadow: none;">About</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#panel2" role="tab" style="box-shadow: none;">How to Redeem/Use</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#panel3" role="tab" style="box-shadow: none;">Terms and Conditions</a>
                    </li>
                </ul>
            </div>
            <div class="col-sm-1"></div>
            <div class="col-md-10">
                <div class="tab-content card dashbaord-table" style="box-shadow: none; background: transparent;">
                    <div class="tab-pane fade in show active" id="panel1" role="tabpanel" style="box-shadow: none;">
                        <div class="card-body pl-0 pr-0" style="box-shadow: none;">
                            <p [innerHtml]="giftObj.description | replaceXoxo"></p>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="panel2" role="tabpanel" style="box-shadow: none;">
                        <div class="card-body pl-0 pr-0" style="box-shadow: none;">
                            <p [innerHtml]="giftObj.redemptionInstructions | replaceXoxo"></p>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="panel3" role="tabpanel" style="box-shadow: none;">
                        <div class="card-body pl-0 pr-0" style="box-shadow: none;">
                            <p [innerHtml]="giftObj.termsAndConditionsInstructions | replaceXoxo"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="gradation-process-area bg-gray section-space--ptb_100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="gradation-title-wrapper section-space--mb_60">
                    <div class="gradation-title-wrap">
                        <h6 class="section-sub-title text-black mb-20">How Gift Cards Work</h6>
                        <h4 class="heading">Get gift cards anywhere, anytime - Gift Cards for 3000+ services</h4>
                    </div>
                    <div class="gradation-sub-heading">
                        <h3 class="heading"><mark>03</mark> Steps</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="ht-gradation style-01">
                    <div class="item new1 item-1 animate wow fadeInRight" data-wow-delay="0.1s">
                        <div class="line"></div>
                        <div class="circle-wrap">
                            <div class="mask">
                                <div class="wave-pulse wave-pulse-1"></div>
                                <div class="wave-pulse wave-pulse-2"></div>
                                <div class="wave-pulse wave-pulse-3"></div>
                            </div>
                            <h6 class="circle">1</h6>
                        </div>
                        <div class="content-wrap">
                            <h6 class="heading">01. Selecte & add to cart</h6>
                            <div class="text">Select the voucher amount, and add it to cart.</div>
                        </div>
                    </div>
                    <div class="item new1 item-1 animate wow fadeInRight" data-wow-delay="0.15s">
                        <div class="line"></div>
                        <div class="circle-wrap">
                            <div class="mask">
                                <div class="wave-pulse wave-pulse-1"></div>
                                <div class="wave-pulse wave-pulse-2"></div>
                                <div class="wave-pulse wave-pulse-3"></div>
                            </div>
                            <h6 class="circle">2</h6>
                        </div>
                        <div class="content-wrap">
                            <h6 class="heading">02. Pay with <span class="text-yellow">Cryptos</span></h6>
                            <div class="text">Your payment is confirmed, the same minute in most cases.</div>
                        </div>
                    </div>
                    <div class="item new1 item-1 animate wow fadeInRight" data-wow-delay="0.20s">
                        <div class="line"></div>
                        <div class="circle-wrap">
                            <div class="mask">
                                <div class="wave-pulse wave-pulse-1"></div>
                                <div class="wave-pulse wave-pulse-2"></div>
                                <div class="wave-pulse wave-pulse-3"></div>
                            </div>
                            <h6 class="circle">3</h6>
                        </div>
                        <div class="content-wrap">
                            <h6 class="heading">03. That's it, redeem your code!</h6>
                            <div class="text">Once your payment is confirmed, the voucher code will be sent out to you.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section-space--ptb_100 more-prduct-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>More products on <span class="yellow-color1">Mate</span></h2>
            </div>
        </div>
        <div class="row mb-3 d-flex align-items-center justify-content-center giftiboxrow">
            <div class="col-6 col-md-6 col-sm-6 col-lg-3" *ngFor="let giftRow of gifts.slice(0,4)">
                <div class="gift-boxlist">
                    <a href="javascript:;" (click)="goTOPage(giftRow);">
                        <i class="fa-3x"><img src="{{giftRow.imageUrl}}" /></i>
                    </a>
                    <h3 (click)="goTOPage(giftRow);" class="h4 mt-3">{{giftRow.name}}</h3>
                    <div class="pl-3 pr-3 selec-center">
                        <select [(ngModel)]="giftRow.price">
                            <option *ngFor="let d of giftRow.denominations" selected value="{{d}}">{{giftRow.currencyCode}} {{d}}</option>
                        </select>
                        <select [(ngModel)]="giftRow.quantity">
                            <option>Select QTY</option>
                            <option *ngFor="let q of quantityLists;" value="{{q}}">Qty {{q}}</option>
                        </select>
                    </div>
                    <div>
                        <!-- <span *ngIf="!profileObj.is_document_verified">
                            <a *ngIf="!giftRow.addingToCart && ((giftRow.quantity * giftRow.price)>limit_amount)" data-toggle="modal" data-target="#giftpurchae" class="btn btn-outline-white addtocart waves-effect waves-light">
                                Add to cart
                            </a>
                            <a *ngIf="!giftRow.addingToCart && ((giftRow.quantity * giftRow.price)<=limit_amount)" (click)="addToCart(giftRow)" class="btn btn-outline-white addtocart waves-effect waves-light">Add to cart</a>
                            <a *ngIf="giftRow.addingToCart" class="btn btn-outline-white addtocart waves-effect waves-light">Please Wait...</a>
                        </span>  *ngIf="profileObj.is_document_verified"-->
                        <span>
                            <a *ngIf="!giftRow.addingToCart" (click)="addToCart(giftRow)" class="btn btn-outline-white addtocart waves-effect waves-light">Add to cart</a>
                            <a *ngIf="giftRow.addingToCart" class="btn btn-outline-white addtocart waves-effect waves-light">Please Wait...</a>
                        </span>
                    </div>
                </div>
            </div>

            <div class="col-sm-12">
                <a href="javascript:;" (click)="goToNavigation('gift');" class="Seeall waves-effect waves-light">See all <i class="fas fa-angle-right"></i></a>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-9">
                <h4>
                    <b>
                        Have more questions? <span>Check out our <a href="https://help.cryptomate.com.cn/" target="_blank" class="yellow-color1">FAQ</a> page</span>
                    </b>
                </h4>
            </div>
        </div>
    </div>
</div>

<div class="modal checkoutpop fade" id="exampleModa6" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content mydo">
            <div class="modal-body">
                <div class="bottom-bt gifpop">
                   <div class="text-center"><img src="/assets/img/giftcard.webp" /> </div>
                    <!--<h5>Create a design to impress your loved ones</h5>-->
                    <ul>
                        <li><strong>-</strong> Obtain the Gift Card voucher</li>
                        <li><strong>-</strong> Go to Card Designer to create your own design.</li>
                        <li><strong>-</strong> Generate the Gift and download it.</li>
                    </ul>
                    <div class="text-center"><a data-toggle="modal" (click)="goToNavigation('gift-designer');" routerLink="/gift-designer" routerLinkActive="active"  class="waves-effect waves-light giftpurchase-btn giftcardn" style="float: none;">Design a gift</a></div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-gift-validation></app-gift-validation>