import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoreService } from '../shared/services/core.service';
import { HelperService } from '../shared/services/helper-service.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import {ToastrService} from 'ngx-toastr';
declare var $: any;
@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.css']
})
export class CareerComponent implements OnInit {
  public applyJob:boolean = false;
  public applyFormObj!: FormGroup;
  public lang:any = '';
  public jobLists:any = [];
  public applyJobObj:any = {};
  public isFormSubmit:boolean = false;
  public isLoading:boolean = false;
  public showDetail:boolean = false;
  public document_type:any = '';
  public documents:any = '';
  constructor(
    private fb: FormBuilder,
    private toast: ToastrService,
    private coreService: CoreService,
    public helperService: HelperService
  ) { }

  ngOnInit(): void {
    this.showDetail = false;
    this.applyFormObj = this.fb.group({
      job_id: ['', [Validators.required]],
      full_name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      city: [''],
      message: [''],
      documents: ['']
    });
    this.getJobLists();
  }

  showDetailPage(){
    this.showDetail = true;
  }

  get get() { return this.applyFormObj.controls; }

  getJobLists(){
    this.coreService.get(`${ENDPOINTS.CAREER.LIST}`, {}).subscribe((res) => {
      if (res.body.success) {
        this.jobLists = res.body.data;
      } else {
        this.jobLists = [];
      }
    }, (err) => {
    });
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang)
  }

  doJobApply(obj:any){
    this.applyFormObj.patchValue({
      job_id: obj?.id,
      full_name: '',
      email: '',
      phone: '',
      city: '',
      message: '',
      documents: ''
    }); 
    $("html, body").animate({ scrollTop: 0 }, "slow");
    this.applyJobObj = obj;
    this.applyJob = true;
  }

  /**
   * @method: doSubmitApplyForm
   * @param formData
   */
   doSubmitApplyForm(formData:any) {
    this.isFormSubmit = true;
    if (this.applyFormObj.valid && !this.isLoading) {
      this.isLoading = true;
      this.isFormSubmit = false;
      const paylaod = {
        ...formData,
        document_type: this.document_type,
        documents: this.documents
      }
      this.coreService.post(`${ENDPOINTS.CAREER.APPLY}`, paylaod).subscribe((res) => {
        this.isLoading = false;
        if(res.body.success){
          this.toast.success(res.body.message, "Success!");
          this.applyFormObj.reset();
        }else{
          this.toast.error(res.body.message, "Error!");
        }
      }, (err) => {
        this.isLoading = false;
        this.toast.error(err.body.message, "Error!");
      });
    }
  }
  

  handleInputChange(e:any){
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (file.type.match(pattern)) {
      reader.onload = this.imageUIpload.bind(this);
      reader.readAsDataURL(file);
    }else{
      if (file.type.match('pdf')) {
        reader.onload = this.pdfUpload.bind(this);
        reader.readAsDataURL(file);
      }else{
        this.toast.error("File not supported!", "Error!");
      }
    }
  }
  
  imageUIpload(e:any) {
    this.isLoading = true;
    let reader = e.target;
    this.document_type = 'image';
    this.coreService.post(`${ENDPOINTS.UPLOAD_FILE.IMAGE}`, {'type':'job_resume','image':reader.result}).subscribe((res) => {
      this.isLoading = false;
      if (res.body.success) {
        this.documents = res.body.data;
        this.toast.success(res.body.message, "Success!");
      } else {
        this.toast.error("File uploading failed!", "Error!");
      }
    }, (err) => {
      this.isLoading = false;
    });
  }

  pdfUpload(e:any) {
    this.isLoading = true;
    this.document_type = 'pdf';
    let reader = e.target;
    this.coreService.post(`${ENDPOINTS.UPLOAD_FILE.PDF}`, {'type':'job_resume','file':reader.result}).subscribe((res) => {
      this.isLoading = false;
      if (res.body.success) {
        this.documents = res.body.data;
        this.toast.success(res.body.message, "Success!");
      } else {
        this.toast.error("File uploading failed!", "Error!");
      }
    }, (err) => {
      this.isLoading = false;
    });
  }

  backToList(){
    this.applyJob = false;
  }
}
