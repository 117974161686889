<app-header [pageName]="'shop'" [cartItemData]="cartItems"></app-header>
<!-- ============= COMPONENT ============== -->
<nav id="guide-template" class="navbar navbar-expand-lg text-center newHea">
    <div class="container">
        <div class="collapse navbar-collapse" id="main_nav">
            <div class="div-shop">
                <div class="title-heading">
                    <ul class="list-unstyled mt-3 three-menu">
                        <li>
                            <a (click)="goToNavigation('shop');"><img src="/assets/img/ele-icon.png" /> <span class="active">Electronics</span></a>
                        </li>
                        <li>
                            <a (click)="goToNavigation('gift');"><img src="/assets/img/gift-icon.png" /> <span>Gift Cards</span></a>
                        </li>
                        <li>
                            <a (click)="goToNavigation('topup');"><img src="/assets/img/top-icon.png" /> <span>Top-Up</span></a>
                        </li>
                        <li>
                            <a (click)="goToNavigation('games')"><img src="/assets/img/game.svg"  style="width: 30px;"/> <span>Games</span></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- navbar-collapse.// -->
    </div>
    <!-- container-fluid.// -->
</nav>
<div *ngIf="isLoaderLoading" id="loader" class="bgdarkloader"></div>
<section class="inshop ShopPage">
    <div class="container">
        <div>
            <div class="slider cate-slider">
                <div *ngFor="let banner of shopBanners; let i = index">
                    <div class="shop-slider-top">
                        <div class="shopbox">
                            <a href="javascript:;" data-toggle="modal" data-target="#instruction" (click)="bannerDetails(banner);">
                                <img class="img-fluid rounded mb-3" src="{{imgBaseUrl}}offer/{{banner.offer_image}}" alt="{{banner.offer_title}}" />
                                <div class="content-shop">
                                    <h1>{{banner.offer_title}}</h1>
                                    <p>{{banner.offer_description}}</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-lg-4">
                <div class="mobv">
                    <a href="javascript:;" onclick="toggler('myContent');"><i class="fa fa-list-ul"></i> CATEGORIES & BRANDS</a>
                </div>
                <div id="myContent" class="hiddens">
                    <div>
                        <h4 class="text-center"><b>CATEGORIES & BRANDS</b></h4>
                        <div id="sticky-anchor"></div>
                        <a href="javascript:;" id="filterfixed" class="filte-category waves-effect waves-light" data-toggle="modal" data-target="#category-filter"> <i class="fas fa-sort-amount-up"></i> FILTER CATEGORIES & BRANDS</a>
                        <div class="category-box coloured categoerybox-mnone">
                            <!-- <h5><b>Select Category</b></h5>-->
                            <div class="shoptexta">
                                <div class="checkbox coloured" *ngFor="let cat of category;">
                                    <label class="catelistbox" (click)="doSelectCategory(cat);" [ngClass]="{active: cat.is_selected}">
                                        {{cat.category_name}}
                                    </label>
                                </div>
                            </div>
                            <hr />
                            <h5><b>Shop by brands</b></h5>
                            <div class="shoptext">
                                <div class="checkbox coloured" *ngFor="let brand of brands;">
                                    <label (click)="filterSelectSubCat($event,'',brand)">
                                        <input type="checkbox" name="brand" id="brand_{{brand.id}}" value="{{brand.id}}" [(ngModel)]="brand.isSelected" />
                                        <span class="checkbox-material">
                                            <span class="check"></span>
                                        </span>
                                        {{brand.brand_name}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!---================ MOBILE CATEGORY FILTER VIEW HTML ==============------------->
                <!-- Modal -->
                <div class="modal left fade" id="category-filter" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content" style="border-radius: 0px;">
                            <div class="modal-header">
                                <h4 class="modal-title" id="myModalLabel">CATEGORY</h4>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <h5><b>Select Category</b></h5>
                                <div class="checkbox mobilebt coloured" *ngFor="let cat of category;">
                                    <label class="close" data-dismiss="modal" aria-label="Close" (click)="doSelectCategory(cat);" [ngClass]="{active: cat.is_selected}"> <input type="radio" name="" /> {{cat.category_name}} </label>
                                </div>
                                <hr />
                                <h5><b>Shop by brands</b></h5>
                                <div class="checkbox coloured" *ngFor="let brand of brands;">
                                    <label data-dismiss="modal" aria-label="Close" (click)="filterSelectSubCat($event,'',brand)">
                                        <input type="checkbox" name="brand" id="brand_mobile_{{brand.id}}" value="{{brand.id}}" [(ngModel)]="brand.isSelected" />
                                        <span class="checkbox-material">
                                            <span class="check"></span>
                                        </span>
                                        {{brand.brand_name}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <!-- modal-content -->
                    </div>
                </div>
                <!-- modal -->
                <!---================ END MOBILE CATEGORY FILTER VIEW HTML ==============------------->
            </div>
            <div class="col-md-12 col-lg-8">
                <div class="row mb-3">
                    <div class="col-md-8 col-lg-8" [style.visibility]="productObj.filterSettings.search ? 'visible' : 'hidden'">
                        <h5 class="mt-1">Showing Results for <b>"{{productObj.filterSettings.search}}"</b></h5>
                    </div>
                    <div class="col-md-4 col-lg-4">
                        <h5>
                            <label class="title-sort">Sort By :</label>
                            <select class="sort-select" name="name" [(ngModel)]="productObj.filterSettings.order_by" (change)="changeOrderBy()">
                                <option value="createdAt DESC">Newest to oldest</option>
                                <option value="product_name ASC">A to Z</option>
                                <option value="product_name DESC">Z to A</option>
                                <option value="regular_price ASC">Price Low to high</option>
                                <option value="regular_price DESC">Price High to low</option>
                            </select>
                        </h5>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-12 col-lg-12 subcategorys" *ngFor="let cat of category;" id="{{cat.id}}">
                        <div *ngIf="cat.sub_categories.length<7" class="carousel slide carousel-multi-item v-2 catelist-shop1 subcatelist">
                            <h6 *ngIf="productObj.filterSettings.sub_category_name">Sub Categories: <b> {{productObj.filterSettings.sub_category_name}}</b></h6>
                            <div class="carousel-inner v-2 catelist-shop" role="listbox">
                                <div class="carousel-item active subcatelist1">
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-2" *ngFor="let subCat of cat.sub_categories; let j = index">
                                        <div class="save-adress" (click)="filterSelectSubCat($event,'category',subCat)">
                                            <input class="checkbox-tools" type="radio" name="tools" id="tool-{{subCat.id}}" />
                                            <label class="for-checkbox-tools waves-effect waves-light {{subCat.id}}" for="tool-{{subCat.id}}" [ngClass]="{active: subCat.is_selected}">
                                                <img *ngIf="subCat.category_image" src="{{imgBaseUrl}}category/{{subCat.category_image}}" class="cate-img" alt="{{subCat.category_name}}" />
                                                <img *ngIf="!subCat.category_image" src="/assets/img/shop-8.png" class="cate-img" alt="{{subCat.category_name}}" />
                                            </label>
                                            <p class="currancyname text-center">{{subCat.category_name}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="container" *ngIf="cat.sub_categories.length>6">
                            <h6 *ngIf="productObj.filterSettings.sub_category_name">Sub Categories: <b> {{productObj.filterSettings.sub_category_name}}</b></h6>
                            <section class="regular slider mobilespaceremove">
                                <div class="subcatelist1" *ngFor="let subCat of cat.sub_categories;">
                                    <div class="save-adress" (click)="filterSelectSubCat($event,'category',subCat)">
                                        <input class="checkbox-tools" type="radio" name="tools" id="tool-{{subCat.id}}" />
                                        <label class="for-checkbox-tools waves-effect waves-light {{subCat.id}}" for="tool-{{subCat.id}}" [ngClass]="{active: subCat.is_selected}">
                                            <img *ngIf="subCat.category_image" src="{{imgBaseUrl}}category/{{subCat.category_image}}" class="cate-img" alt="{{subCat.category_name}}" />
                                            <img *ngIf="!subCat.category_image" src="/assets/img/shop-8.png" class="cate-img" alt="{{subCat.category_name}}" />
                                        </label>
                                        <p class="currancyname text-center">{{subCat.category_name}}</p>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="productObj.list.length>0"
                    class="row mb-3 d-flex align-items-center justify-content-center m-lr-0"
                    infiniteScroll
                    [infiniteScrollDistance]="1"
                    [infiniteScrollThrottle]="50"
                    (scrolled)="onScroll()"
                    [scrollWindow]="true"
                >
                    <div class="col-6 col-md-4 col-sm-6 col-lg-4" *ngFor="let product of productObj.list">
                        <div class="shoplist-img">
                            <ul class="social" *ngIf="isLoggedIn">
                                <li>
                                    <a href="javascript:;" (click)="doOpenWishlist(product)"><i class="fa fa-heart"></i></a>
                                </li>
                                <li style="cursor: pointer;" *ngIf="product.product_quantity>0">
                                    <a style="cursor: pointer;" (click)="goToProductCart(product);"><i class="fa fa-shopping-cart"></i></a>
                                </li>
                            </ul>
                            <ul class="social" *ngIf="!isLoggedIn">
                                <li>
                                    <a href="javascript:;" data-toggle="modal" data-target="#login" id="button"><i class="fa fa-heart"></i></a>
                                </li>
                                <li style="cursor: pointer;" *ngIf="product.product_quantity>0">
                                    <a style="cursor: pointer;" data-toggle="modal" data-target="#login" id="button"><i class="fa fa-shopping-cart"></i></a>
                                </li>
                            </ul>
                            <div class="product-Detail clearfix">
                                <i class="fa-3x" (click)="goToProductDetailPage(product);">
                                    <img [hidden]="!product.isImageLoading" src="https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/cupertino_activity_indicator_square_medium.gif" />
                                    <img [hidden]="product.isImageLoading" src="{{imgBaseUrl}}products/{{product.product_image}}" (load)="hideLoader(product)" (error)="hideLoader(product)" />
                                </i>

                                <h3 class="h4 mt-3" (click)="goToProductDetailPage(product);">{{product.product_name}}</h3>
                                <div class="storage-boxprice" (click)="goToProductDetailPage(product);">
                                    <span class="monthprice">&nbsp;</span>
                                    <span class="leftprice-rate" *ngIf="product.product_quantity>0 && !product.special_price">{{product.regular_price | currency}}</span>
                                    <span class="leftprice-rate" *ngIf="product.product_quantity>0 && product.special_price">{{product.special_price | currency}}</span>
                                    <span class="leftprice-cut" *ngIf="product.product_quantity>0 && product.special_price">{{product.regular_price | currency}}</span>
                                    <span class="leftprice-rate" *ngIf="product.product_quantity==0" style="background: #ffd91d73 !important; cursor: not-allowed;">Out of stock</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3 d-flex align-items-center justify-content-center mypast" id="loader-EShopProductsList" *ngIf="productObj.loadStatus">
                        <div class="spinner-border text-warning" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="productObj.list.length==0 && !productObj.loadStatus" class="row mb-3 d-flex align-items-center justify-content-center mypast">
                    <div class="col-md-12 alert alert-danger">
                        No Active product.
                    </div>
                </div>
                <div *ngIf="productObj.list.length==0 && productObj.loadStatus" class="row mb-3 d-flex align-items-center justify-content-center mypast">
                    <div *ngIf="isLoading" id="loader"></div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="modal fade" id="instruction" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-body mb-0 text-left">
                <button type="button" class="btn-close float-right" data-dismiss="modal" aria-label="Close" style="top: 10px; position: relative;"></button>
                <h3 class=""><b>Details</b></h3>
                <div class="row border-top py-3">
                    <div class="col-md-12">
                        <!--<h5>Conditions</h5>-->
                        <div [innerHtml]="bannerTerms"></div>
                        <div class="text-center">
                            <a href="{{learnMoreUrl}}" target="_blank" class="learnmore" aria-label="Close" style="top: 10px; position: relative;">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addnewwishlist" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-body model-pad0 mb-0 text-center">
                <div class="wrap-login100 shopwishlit">
                    <div class="login100-form" style="width: 100%;">
                        <h3 class="text-left mb-3"><b>Add to wishlist</b><button type="button" class="btn-close float-right" data-dismiss="modal" aria-label="Close"></button></h3>
                        <div *ngIf="!isWishlistFatching">
                            <div class="col-md-12">
                                <span *ngFor="let wishlist of wishlistList">
                                    <a class="dropdown-item checkdropdown">
                                        <div class="checkbox coloured width150check">
                                            <label class="mb-0">
                                                <input type="checkbox" value="{{wishlist.id}}" (click)="addProductInWishlist(wishlist)" [(ngModel)]="wishlist.is_already_added" />
                                                <span class="checkbox-material">
                                                    <span class="check"></span>
                                                </span>
                                                {{wishlist.wishlist_name}}
                                            </label>
                                        </div>
                                    </a>
                                    <a (click)="goToWishlistDetail(wishlist);" class="viewbtn">View</a>
                                    <hr style="margin-top: 20px; margin-bottom: 0.5rem;" />
                                </span>
                            </div>
                        </div>
                        <div class="alert alert-info" *ngIf="wishlistList.length==0 && isWishlistFatching">
                            We are fatching your wishlist...
                        </div>
                    </div>
                    <form class="login100-form" style="width: 100%;" [formGroup]="wishlistForm">
                        <div>
                            <div class="col-md-12">
                                <div class="md-form">
                                    <span class="iconform"><i class="far fa-user"></i></span>
                                    <input
                                        class="form-control"
                                        type="text"
                                        id="wishlist"
                                        name="wishlist_name"
                                        required="required"
                                        formControlName="wishlist_name"
                                        [ngClass]="{ 'is-invalid': wishlistFormObj.isFormSubmit &&  get?.wishlist_name?.errors }"
                                    />
                                    <label for="wishlist">Create new wishlist</label>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center mt-4">
                            <div class="col-md-12 text-center">
                                <button class="contiunebtn waves-effect waves-light" data-dismiss="modal" aria-label="Close" type="button">Cancel</button>
                                <button type="button" [disabled]="isLoading" (click)="createNow(wishlistForm.value)" class="joinnow-btn waves-effect waves-light">Create</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<app-login-modal></app-login-modal>