export const TIMEZONES = [
  {
    "timezone": "Pacific/Midway",
    "value": "(GMT-11:00) Midway Island"
  },
  {
    "timezone": "Pacific/Pago_Pago",
    "value": "(GMT-11:00) Samoa"
  },
  {
    "timezone": "Pacific/Honolulu",
    "value": "(GMT-10:00) Hawaii"
  },
  {
    "timezone": "America/Anchorage",
    "value": "(GMT-09:00) Alaska"
  },
  {
    "timezone": "America/Los_Angeles",
    "value": "(GMT-08:00) Pacific Time (US & Canada)"
  },
  {
    "timezone": "America/Tijuana",
    "value": "(GMT-08:00) Tijuana"
  },
  {
    "timezone": "America/Phoenix",
    "value": "(GMT-07:00) Arizona"
  },
  {
    "timezone": "America/Denver",
    "value": "(GMT-07:00) Mountain Time (US & Canada)"
  },
  {
    "timezone": "America/Chihuahua",
    "value": "(GMT-07:00) Chihuahua"
  },
  {
    "timezone": "America/Mazatlan",
    "value": "(GMT-07:00) Mazatlan"
  },
  {
    "timezone": "America/Mexico_City",
    "value": "(GMT-06:00) Mexico City"
  },
  {
    "timezone": "America/Monterrey",
    "value": "(GMT-06:00) Monterrey"
  },
  {
    "timezone": "Canada/Saskatchewan",
    "value": "(GMT-06:00) Saskatchewan"
  },
  {
    "timezone": "America/Chicago",
    "value": "(GMT-06:00) Central Time (US & Canada)"
  },
  {
    "timezone": "America/New_York",
    "value": "(GMT-05:00) Eastern Time (US & Canada)"
  },
  {
    "timezone": "America/Indiana/Indianapolis",
    "value": "(GMT-05:00) Indiana (East)"
  },
  {
    "timezone": "America/Bogota",
    "value": "(GMT-05:00) Bogota"
  },
  {
    "timezone": "America/Lima",
    "value": "(GMT-05:00) Lima"
  },
  {
    "timezone": "America/Caracas",
    "value": "(GMT-04:30) Caracas"
  },
  {
    "timezone": "Canada/Atlantic",
    "value": "(GMT-04:00) Atlantic Time (Canada)"
  },
  {
    "timezone": "America/La_Paz",
    "value": "(GMT-04:00) La Paz"
  },
  {
    "timezone": "America/Santiago",
    "value": "(GMT-04:00) Santiago"
  },
  {
    "timezone": "Canada/Newfoundland",
    "value": "(GMT-03:30) Newfoundland"
  },
  {
    "timezone": "America/Buenos_Aires",
    "value": "(GMT-03:00) Buenos Aires"
  },
  {
    "timezone": "Atlantic/Stanley",
    "value": "(GMT-02:00) Stanley"
  },
  {
    "timezone": "Atlantic/Azores",
    "value": "(GMT-01:00) Azores"
  },
  {
    "timezone": "Atlantic/Cape_Verde",
    "value": "(GMT-01:00) Cape Verde Is."
  },
  {
    "timezone": "Africa/Casablanca",
    "value": "(GMT) Casablanca"
  },
  {
    "timezone": "Europe/Dublin",
    "value": "(GMT) Dublin"
  },
  {
    "timezone": "Europe/Lisbon",
    "value": "(GMT) Lisbon"
  },
  {
    "timezone": "Europe/London",
    "value": "(GMT) London"
  },
  {
    "timezone": "Africa/Monrovia",
    "value": "(GMT) Monrovia"
  },
  {
    "timezone": "Europe/Amsterdam",
    "value": "(GMT+01:00) Amsterdam"
  },
  {
    "timezone": "Europe/Belgrade",
    "value": "(GMT+01:00) Belgrade"
  },
  {
    "timezone": "Europe/Berlin",
    "value": "(GMT+01:00) Berlin"
  },
  {
    "timezone": "Europe/Bratislava",
    "value": "(GMT+01:00) Bratislava"
  },
  {
    "timezone": "Europe/Brussels",
    "value": "(GMT+01:00) Brussels"
  },
  {
    "timezone": "Europe/Budapest",
    "value": "(GMT+01:00) Budapest"
  },
  {
    "timezone": "Europe/Copenhagen",
    "value": "(GMT+01:00) Copenhagen"
  },
  {
    "timezone": "Europe/Ljubljana",
    "value": "(GMT+01:00) Ljubljana"
  },
  {
    "timezone": "Europe/Madrid",
    "value": "(GMT+01:00) Madrid"
  },
  {
    "timezone": "Europe/Paris",
    "value": "(GMT+01:00) Paris"
  },
  {
    "timezone": "Europe/Prague",
    "value": "(GMT+01:00) Prague"
  },
  {
    "timezone": "Europe/Rome",
    "value": "(GMT+01:00) Rome"
  },
  {
    "timezone": "Europe/Sarajevo",
    "value": "(GMT+01:00) Sarajevo"
  },
  {
    "timezone": "Europe/Skopje",
    "value": "(GMT+01:00) Skopje"
  },
  {
    "timezone": "Europe/Stockholm",
    "value": "(GMT+01:00) Stockholm"
  },
  {
    "timezone": "Europe/Vienna",
    "value": "(GMT+01:00) Vienna"
  },
  {
    "timezone": "Europe/Warsaw",
    "value": "(GMT+01:00) Warsaw"
  },
  {
    "timezone": "Europe/Zagreb",
    "value": "(GMT+01:00) Zagreb"
  },
  {
    "timezone": "Europe/Athens",
    "value": "(GMT+02:00) Athens"
  },
  {
    "timezone": "Europe/Bucharest",
    "value": "(GMT+02:00) Bucharest"
  },
  {
    "timezone": "Africa/Cairo",
    "value": "(GMT+02:00) Cairo"
  },
  {
    "timezone": "Africa/Harare",
    "value": "(GMT+02:00) Harare"
  },
  {
    "timezone": "Europe/Helsinki",
    "value": "(GMT+02:00) Helsinki"
  },
  {
    "timezone": "Europe/Istanbul",
    "value": "(GMT+02:00) Istanbul"
  },
  {
    "timezone": "Asia/Jerusalem",
    "value": "(GMT+02:00) Jerusalem"
  },
  {
    "timezone": "Europe/Kiev",
    "value": "(GMT+02:00) Kyiv"
  },
  {
    "timezone": "Europe/Minsk",
    "value": "(GMT+02:00) Minsk"
  },
  {
    "timezone": "Europe/Riga",
    "value": "(GMT+02:00) Riga"
  },
  {
    "timezone": "Europe/Sofia",
    "value": "(GMT+02:00) Sofia"
  },
  {
    "timezone": "Europe/Tallinn",
    "value": "(GMT+02:00) Tallinn"
  },
  {
    "timezone": "Europe/Vilnius",
    "value": "(GMT+02:00) Vilnius"
  },
  {
    "timezone": "Asia/Baghdad",
    "value": "(GMT+03:00) Baghdad"
  },
  {
    "timezone": "Asia/Kuwait",
    "value": "(GMT+03:00) Kuwait"
  },
  {
    "timezone": "Africa/Nairobi",
    "value": "(GMT+03:00) Nairobi"
  },
  {
    "timezone": "Asia/Riyadh",
    "value": "(GMT+03:00) Riyadh"
  },
  {
    "timezone": "Europe/Moscow",
    "value": "(GMT+03:00) Moscow"
  },
  {
    "timezone": "Asia/Tehran",
    "value": "(GMT+03:30) Tehran"
  },
  {
    "timezone": "Asia/Baku",
    "value": "(GMT+04:00) Baku"
  },
  {
    "timezone": "Europe/Volgograd",
    "value": "(GMT+04:00) Volgograd"
  },
  {
    "timezone": "Asia/Muscat",
    "value": "(GMT+04:00) Muscat"
  },
  {
    "timezone": "Asia/Tbilisi",
    "value": "(GMT+04:00) Tbilisi"
  },
  {
    "timezone": "Asia/Yerevan",
    "value": "(GMT+04:00) Yerevan"
  },
  {
    "timezone": "Asia/Kabul",
    "value": "(GMT+04:30) Kabul"
  },
  {
    "timezone": "Asia/Karachi",
    "value": "(GMT+05:00) Karachi"
  },
  {
    "timezone": "Asia/Tashkent",
    "value": "(GMT+05:00) Tashkent"
  },
  {
    "timezone": "Asia/Kolkata",
    "value": "(GMT+05:30) Kolkata"
  },
  {
    "timezone": "Asia/Kathmandu",
    "value": "(GMT+05:45) Kathmandu"
  },
  {
    "timezone": "Asia/Yekaterinburg",
    "value": "(GMT+06:00) Ekaterinburg"
  },
  {
    "timezone": "Asia/Almaty",
    "value": "(GMT+06:00) Almaty"
  },
  {
    "timezone": "Asia/Dhaka",
    "value": "(GMT+06:00) Dhaka"
  },
  {
    "timezone": "Asia/Novosibirsk",
    "value": "(GMT+07:00) Novosibirsk"
  },
  {
    "timezone": "Asia/Bangkok",
    "value": "(GMT+07:00) Bangkok"
  },
  {
    "timezone": "Asia/Jakarta",
    "value": "(GMT+07:00) Jakarta"
  },
  {
    "timezone": "Asia/Krasnoyarsk",
    "value": "(GMT+08:00) Krasnoyarsk"
  },
  {
    "timezone": "Asia/Chongqing",
    "value": "(GMT+08:00) Chongqing"
  },
  {
    "timezone": "Asia/Hong_Kong",
    "value": "(GMT+08:00) Hong Kong"
  },
  {
    "timezone": "Asia/Kuala_Lumpur",
    "value": "(GMT+08:00) Kuala Lumpur"
  },
  {
    "timezone": "Australia/Perth",
    "value": "(GMT+08:00) Perth"
  },
  {
    "timezone": "Asia/Singapore",
    "value": "(GMT+08:00) Singapore"
  },
  {
    "timezone": "Asia/Taipei",
    "value": "(GMT+08:00) Taipei"
  },
  {
    "timezone": "Asia/Ulaanbaatar",
    "value": "(GMT+08:00) Ulaan Bataar"
  },
  {
    "timezone": "Asia/Urumqi",
    "value": "(GMT+08:00) Urumqi"
  },
  {
    "timezone": "Asia/Irkutsk",
    "value": "(GMT+09:00) Irkutsk"
  },
  {
    "timezone": "Asia/Seoul",
    "value": "(GMT+09:00) Seoul"
  },
  {
    "timezone": "Asia/Tokyo",
    "value": "(GMT+09:00) Tokyo"
  },
  {
    "timezone": "Australia/Adelaide",
    "value": "(GMT+09:30) Adelaide"
  },
  {
    "timezone": "Australia/Darwin",
    "value": "(GMT+09:30) Darwin"
  },
  {
    "timezone": "Asia/Yakutsk",
    "value": "(GMT+10:00) Yakutsk"
  },
  {
    "timezone": "Australia/Brisbane",
    "value": "(GMT+10:00) Brisbane"
  },
  {
    "timezone": "Australia/Canberra",
    "value": "(GMT+10:00) Canberra"
  },
  {
    "timezone": "Pacific/Guam",
    "value": "(GMT+10:00) Guam"
  },
  {
    "timezone": "Australia/Hobart",
    "value": "(GMT+10:00) Hobart"
  },
  {
    "timezone": "Australia/Melbourne",
    "value": "(GMT+10:00) Melbourne"
  },
  {
    "timezone": "Pacific/Port_Moresby",
    "value": "(GMT+10:00) Port Moresby"
  },
  {
    "timezone": "Australia/Sydney",
    "value": "(GMT+10:00) Sydney"
  },
  {
    "timezone": "Asia/Vladivostok",
    "value": "(GMT+11:00) Vladivostok"
  },
  {
    "timezone": "Asia/Magadan",
    "value": "(GMT+12:00) Magadan"
  },
  {
    "timezone": "Pacific/Auckland",
    "value": "(GMT+12:00) Auckland"
  },
  {
    "timezone": "Pacific/Fiji",
    "value": "(GMT+12:00) Fiji"
  }
];