import { Component, OnInit } from '@angular/core';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { CoreService } from 'src/app/shared/services/core.service';
import {ToastrService} from 'ngx-toastr';

import * as XLSX from 'xlsx';  

@Component({
  selector: 'app-batch-refill',
  templateUrl: './batch-refill.component.html',
  styleUrls: ['./batch-refill.component.css']
})
export class BatchRefillComponent implements OnInit {
  storeData: any;  
  public jsonData: any = [];
  fileUploaded: any;  
  worksheet: any;
  public isLoading:boolean =false;
  public total_amount:any = 0;
  constructor(
    private toast: ToastrService,
    private coreService: CoreService,
  ) { }

  ngOnInit(): void {
  }

  uploadedFile(event:any) {  
    this.fileUploaded = event.target.files[0];  
    this.readExcel();  
  }

  readExcel() {
    this.isLoading = true;
    let readFile = new FileReader();  
    readFile.onload = (e) => {  
      this.storeData = readFile.result;  
      var data = new Uint8Array(this.storeData);  
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);  
      var bstr = arr.join("");  
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];  
      this.worksheet = workbook.Sheets[first_sheet_name];  
      this.readAsJson();
    }  
    readFile.readAsArrayBuffer(this.fileUploaded);  
  }

  readAsJson() {  
    this.jsonData = XLSX.utils.sheet_to_json(this.worksheet, { raw: false });  
    for (var i = 0; i <this.jsonData.length; i++){
      if(!this.jsonData[i].mobile || !this.jsonData[i].operator){
        this.toast.error("Seems the file is not correct","Error!");
      }
    }
    this.isLoading = false;
  }

  submitFile(){
    if(this.jsonData.length>0){
      this.isLoading = true;
      this.total_amount = 0;
      for (var i = 0; i <this.jsonData.length; i++){
        this.total_amount = parseFloat(this.total_amount) + parseFloat(this.jsonData[i].amount);  
      }
      const paylaod = {
        'json_data' : this.jsonData,
        'total_amount' : this.total_amount
      }
      this.coreService.post(`${ENDPOINTS.USER_PROFILE.SUBMIT_BATCH_REFIL}`, paylaod).subscribe((res) => {
        this.isLoading = false;
        this.jsonData = [];
        if(res.body.success){
          this.toast.success(res.body.message, "Success!");
        }else{
          this.toast.error(res.body.message, "Error!");
        }
      }, (err) => {
        this.isLoading = false;
        this.toast.error(err.body.message,"Error!");
      });
    }
  }
}
