<app-header></app-header>
<section class="text-center mb-2 mt-3 pt-4 mt-50" >
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <ul class="list-unstyled bradcam">
                    <li><a (click)="goToNavigation('home');">Home</a></li>
                    <li>
                        <a (click)="goToNavigation('loyalty-program');"><i class="fas fa-angle-double-right iconarrw"></i> Loyalty Program</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
<section class="text-left titleloyalty">
    <div class="container">
        <div class="row">
            <div class="col-md-2 "></div>
            <div class="col-md-8">
                <div class="title-heading">
                    <h6>
                       The CryptoMate Loyalty Program is a cash-back reward that can be used to instantly purchase any of our products, digital vouchers and mobile top-ups from anywhere in the world.
                    </h6>
                    <a (click)="goToNavigation('loyalty-program-terms');">Terms & Condition might apply</a>
                </div>
            </div>
             <div class="col-md-2 "></div>
        </div>
    </div>
</section>
<section class="mb-5">
    <div class="container">
        <div class="row pricing newupdates">
            <div *ngFor="let row of programeList;" class="col-12 col-sm-8 col-md-6 col-lg-4 mb-4">
                <div class="card pricebox-1 bg-oneplan" [ngClass]="{'bg-twoplan': row.is_featured == 1}">
                    <div class="card-body">
                        <a *ngIf="row.is_activated" (click)="goToLPDetail(row);" style="position: absolute;height: 100%;width: 100%;left: 0;margin-top: -20px;">&nbsp;</a>
                        <div class="shape-circlce" (click)="goToLPDetail(row);">
                            <h5 class="">{{row.offer_title}}</h5>
                            <h2 class="font-weight-bold">{{row.coupon_amount | currency}}</h2>
                            <p>Per month</p>
                        </div>
                        <p class="grey-text mt-3">Place {{row.number_of_time}} orders of min {{row.min_amount}} dollar</p>
                        <div class="linedot"></div>
                        <div class="fixhe">
                            <p *ngIf="!row.is_activated && row.order_count==0 && isLoggedIn" class="">Please activate this loyalty program to avail of the benefit</p>
                        </div>
                        <a *ngIf="row.order_count>0 && row.is_availed==0 && isLoggedIn" class="planbtn-2 waves-effect waves-light">In-Progress</a>
                        <a *ngIf="!row.is_activated && row.order_count==0 && isLoggedIn" (click)="doActivatePrograme(row)" class="planbtn-1 waves-effect waves-light">Activate</a>
                        <a *ngIf="row.is_activated && row.is_availed==0 && row.order_count==0" class="planbtn-1 waves-effect waves-light">Activated</a>
                        <button *ngIf="row.is_availed==1" class="planbtn-1 waves-effect waves-light" disabled style="border: none;">Availed</button>
                        <a *ngIf="!isLoggedIn" class="planbtn-1 waves-effect waves-light" data-toggle="modal" data-target="#login">Activate</a>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="isLoading" id="loader"></div>
        <div class="accordion md-accordion mt-0" id="accordionEx" role="tablist" aria-multiselectable="true" *ngIf="!isLoading && programeList.length==0">
            <div class="col-md-12 alert alert-danger">
                Record not found
            </div>
        </div>
    </div>
</section>
<section class="text-center py-3">
    <div class="container">
        <div class="row d-flex align-items-center justify-content-center">
            <div class="col-md-8 col-lg-8">
                <div class="title-heading">
                    <h5><span class="font-28">Pay with your <b class="text-yellow">cryptos.</b></span> More than 50 cryptocoins are accepted here! <br> Bitcoin, Ethereum, XRP, BNB, Litecoin, Doge.</h5>
                </div>
                <div class="logo-icons">
                    <img src="/assets/img/coins.webp" />
                </div>
                <div class="css-1b9jzri--2">
                    <div style="color: var(--text-primary);" class="css-ezg0pp--2">
                        <div>
                            <img src="/assets/img/images_checkmark.svg" width="21" height="21" />
                        </div>
                        -	Blockchain Based Payments.
                    </div>
                    <div style="color: var(--text-primary);" class="css-ezg0pp--2">
                        <div>
                            <img src="/assets/img/images_checkmark.svg" width="21" height="21" />
                        </div>
                        - Easy, Fast and Secure.
                    </div>
                    <div style="color: var(--text-primary);" class="css-ezg0pp--2">
                        <div>
                            <img src="/assets/img/images_checkmark.svg" width="21" height="21" />
                        </div>
                        -	Pay Instantly, Anywhere and Anytime.
                    </div>
                    <div style="color: var(--text-primary);" class="css-ezg0pp--2">
                        <div>
                            <img src="/assets/img/images_checkmark.svg" width="21" height="21" />
                        </div>
                       - Pay with Stable Coins.
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>