import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/shared/services/core.service';
import { HelperService } from '../../shared/services/helper-service.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { environment } from 'src/environments/environment';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-user-left-menu',
  templateUrl: './user-left-menu.component.html',
  styleUrls: ['./user-left-menu.component.css']
})
export class UserLeftMenuComponent implements OnInit {
  public imgBaseUrl = environment.AWSImageURL;
  public profileObj:any = {};
  public imageSrc:any = '';
  public lang:any = '';
  constructor(
    public coreService: CoreService,
    private toast: ToastrService,
    private helperService: HelperService,
  ) { }

  ngOnInit(): void {
    let profileObj:any = localStorage.getItem('currentUser') != null ? localStorage.getItem('currentUser') : '{"name":""}';
    this.profileObj = JSON.parse(profileObj);
    
    this.getMyProfile();
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang)
  }

  logOut() {
    this.coreService.get(`${ENDPOINTS.AUTH.LOGOUT}`, {}).subscribe((res) => {
      this.toast.success(res.body.message, "Success!");
      this.cbLogout();
    }, (err) => {
      this.cbLogout();
    });
  }

  getMyProfile(){
    this.coreService.get(`${ENDPOINTS.USER_PROFILE.GET}`, {}).subscribe((res) => {
      if (res.body.success) {
        this.profileObj = res.body.data;
        localStorage.setItem('currentUser', JSON.stringify(res.body.data));
        if(this.profileObj.profile_image){
          if(this.profileObj.profile_image==''){
            this.profileObj.profile_image = false;
          }
        }
      } else {
        this.profileObj = {};
      }
    }, (err) => {
    });
  }

  handleInputChange(e:any){
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.toast.error("invalid format");
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  
  _handleReaderLoaded(e:any) {
    let reader = e.target;
    this.imageSrc = reader.result;
    //this.profileObj.profile_image  = reader.result;
    this.coreService.post(`${ENDPOINTS.UPLOAD_FILE.IMAGE}`, {'type':'profile','image':reader.result}).subscribe((res) => {
      if (res.body.success) {
        this.coreService.post(`${ENDPOINTS.USER_PROFILE.UPDATE}`, {'profile_image':res.body.data}).subscribe((res) => {
          this.profileObj = res.body.data;
          localStorage.setItem('currentUser', JSON.stringify(res.body.data));
          this.toast.success(res.body.message, "Success!");
        }, (err) => {
          this.toast.success(err.body.message, "Error!");
        });
      } else {
        this.profileObj = {};
      }
    }, (err) => {
    });
  }

  cbLogout() {
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('checkoutInfo');
    this.helperService.changeUserProfile(null);
    this.helperService.setLoggedUserInfo(false) ;
    this.helperService.navigate('/home', this.lang);
  }
}
