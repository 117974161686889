import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { CoreService } from 'src/app/shared/services/core.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { environment } from 'src/environments/environment';
import {ToastrService} from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-myreport',
  templateUrl: './myreport.component.html',
  styleUrls: ['./myreport.component.css']
})
export class MyreportComponent implements OnInit {
  public isLoading:boolean = false;
  public wepayList:any = [];
  public otcRequestList:any = [];
  public otcReplyList:any = [];

  constructor(
    private toast: ToastrService,
    public coreService: CoreService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getWePayRequestList();
    this.getOTCRequestList();
    this.getOTCReplyList();
  }

  getWePayRequestList(){
    this.isLoading = true;
    this.coreService.post(`${ENDPOINTS.USER_PROFILE.WEPAY_REQUEST}`, {}).subscribe((res) => {
      this.isLoading = false;
      if (res.body.success) {
        this.wepayList = res.body.data.items;
      } else {
        this.wepayList = [];
      }
    }, (err) => {
    });
  }

  getOTCRequestList(){
    this.isLoading = true;
    this.coreService.post(`${ENDPOINTS.USER_PROFILE.OTC_REQUEST}`, {}).subscribe((res) => {
      this.isLoading = false;
      if (res.body.success) {
        this.otcRequestList = res.body.data.items;
      } else {
        this.otcRequestList = [];
      }
    }, (err) => {
    });
  }

  getOTCReplyList(){
    this.isLoading = true;
    this.coreService.post(`${ENDPOINTS.USER_PROFILE.OTCT_REPLY_REQUEST}`, {}).subscribe((res) => {
      this.isLoading = false;
      if (res.body.success) {
        this.otcReplyList = res.body.data.items;
      } else {
        this.otcReplyList = [];
      }
    }, (err) => {
    });
  }
}
