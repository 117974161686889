<app-header></app-header>
<!-- Intro Section-->
<section class="view hm-gradient" id="outsourcing-bg">
    <div *ngIf="isLoadingData" id="loader"></div>
    <div class="container">
        <div class="row no-gutters">
            <div class="col-md-12 col-lg-7 order-lg-12">
                <img src="assets/img/outsourcingpage.webp" class="right-outsourcingimg" />
            </div>
            <div class="col-md-12 col-lg-5 text-left text-md-left order-lg-1">
                <div class="white-text padd-top170">
                    <div>
                        <h5 class="mt-sm-5">
                            By using the Blockchain-based outsourcing, your company can import high-quality products at low prices. In addition to our flexible, comprehensive outsourcing services to supplement your current workflow, our team is also committed to finding win-win solutions.

                        </h5>
                    </div>
                    <div>
                        <a class="btn btn-outline-white start-btn" (click)="goToNavigation('outsourcing-join')">Outsource Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="text-center py-4 mobline">
    <div class="container">
        <div class="row d-flex align-items-center justify-content-center">
            <div class="title-heading">
                <h2 class="h1 pt-5 pb-3">How It Goes</h2>
            </div>
        </div>
        <div class="row h-second-fold timeline-oursource">
            <div class="col-12 col-md-4 col-sm-12 col-lg-4">
                <div class="cando outsorce-mt-50 s1">
                    <div class="ht-gradation style-01 mobilesho">
                    <div class="item item1 item-1 animate wow fadeInRight " data-wow-delay="0.1s">
                       
                        <div class="circle-wrap">
                            <div class="mask">
                                <div class="wave-pulse wave-pulse-1"></div>
                                <div class="wave-pulse wave-pulse-2"></div>
                                <div class="wave-pulse wave-pulse-3"></div>
                            </div>
                            <h6 class="circle">1</h6>
                        </div>
                       
                    </div>
                </div>
                 
                    <i class="fa-3x"><img src="assets/img/outsourcing1.webp" class="w-100" /></i>
                    <h3 class="h4 mt-3 text-right bluecolor">Determine you Product Specifications and tell Us more</h3>
                    <p class="mt-3 blue-grey-text text-right">
                        If you are still in the project development phase, we can work together to design a product that suits your customers' needs.
                    </p>
                </div>

                 <div class="cando mt-200 webhide">
                    <div class="ht-gradation style-01 mobilesho">
                     <div class="item item1 item-1 animate wow fadeInRight mobilesho" data-wow-delay="0.1s">
                       
                        <div class="circle-wrap">
                            <div class="mask">
                                <div class="wave-pulse wave-pulse-1"></div>
                                <div class="wave-pulse wave-pulse-2"></div>
                                <div class="wave-pulse wave-pulse-3"></div>
                            </div>
                            <h6 class="circle">2</h6>
                        </div>
                       
                    </div>
                </div>
                    <i class="fa-3x"><img src="assets/img/outsourcing2.webp" class="w-100" /></i>
                    <h3 class="h4 mt-3 text-left bluecolor">Quote/Meeting With Our Team</h3>
                    <p class="blue-grey-text text-left">
                        Once your design specifications are finalized, it is time to find the source. We talk with our fully-vetted network of manufacturers and suppliers to deliver you several quotes.
                    </p>
                </div>

                <div class="cando outsorce-mt-50 mobnum">
                    <div class="ht-gradation style-01 mobilesho">
                    <div class="item item1 item-1 animate wow fadeInRight mobilesho" data-wow-delay="0.1s">
                       
                        <div class="circle-wrap">
                            <div class="mask">
                                <div class="wave-pulse wave-pulse-1"></div>
                                <div class="wave-pulse wave-pulse-2"></div>
                                <div class="wave-pulse wave-pulse-3"></div>
                            </div>
                            <h6 class="circle">3</h6>
                        </div>
                       
                    </div>
                </div>
                   
                    <i class="fa-3x"><img src="assets/img/outsourcing3.webp" class="w-100" /></i>
                    <h3 class="h4 mt-3 text-right bluecolor">Order & Sample Review</h3>
                    <p class="mt-3 blue-grey-text text-right">
                        Upon choosing a manufacturer/supplier, the details get negotiated with the selected factory/distributor and your order is placed. We then arrange for a sample for your approval and be used as the prototype for further production.
                    </p>
                </div>

                 <div class="cando outsorce-mt-50 webhide moredown">
                    <div class="ht-gradation style-01 mobilesho">
                    <div class="item item1 item-1 animate wow fadeInRight mobilesho" data-wow-delay="0.1s">
                       
                        <div class="circle-wrap">
                            <div class="mask">
                                <div class="wave-pulse wave-pulse-1"></div>
                                <div class="wave-pulse wave-pulse-2"></div>
                                <div class="wave-pulse wave-pulse-3"></div>
                            </div>
                            <h6 class="circle">4</h6>
                        </div>
                       
                    </div>
                </div>
                    <i class="fa-3x"><img src="assets/img/outsourcing4.webp" class="w-100" /></i>
                    <h3 class="h4 mt-3 text-left bluecolor">Production & in-process inspection</h3>
                    <p class="blue-grey-text text-left">
                        Upon approval of the samples, the factory begins production. Your case manager and our team conduct multiple inspections during production and sends you quality assurance reports.
                    </p>
                </div>



                <div class="cando outsorce-mt-50 img-sho">
                      <div class="ht-gradation style-01 mobilesho">
                    <div class="item item1 item-1 animate wow fadeInRight mobilesho" data-wow-delay="0.1s">
                       
                        <div class="circle-wrap">
                            <div class="mask">
                                <div class="wave-pulse wave-pulse-1"></div>
                                <div class="wave-pulse wave-pulse-2"></div>
                                <div class="wave-pulse wave-pulse-3"></div>
                            </div>
                            <h6 class="circle">5</h6>
                        </div>
                       
                    </div>
                </div>
                    <i class="fa-3x"><img src="assets/img/outsourcing5.webp" class="w-100" /></i>
                    <h3 class="h4 mt-3 text-right bluecolor">Shipping & Logistics</h3>
                    <p class="mt-3 blue-grey-text text-right">
                        After manufacturing is completed, our team handles all of the freight forwarding, shipping, and customs clearance needed from production lines to your chosen destination.
                    </p>
                </div>
            </div>

            <div class="col-12 col-md-4 col-sm-4 col-lg-4 mt-7">
                <div class="cando middel-imgout hideboxss">
                  <div class="item item1 item-1 animate wow fadeInRight mobilesho" data-wow-delay="0.1s">
                       
                        <div class="circle-wrap">
                            <div class="mask">
                                <div class="wave-pulse wave-pulse-1"></div>
                                <div class="wave-pulse wave-pulse-2"></div>
                                <div class="wave-pulse wave-pulse-3"></div>
                            </div>
                            <h6 class="circle">3</h6>
                        </div>
                       
                    </div>
                    <i><img src="assets/img/outsourcetimeline.svg" class="w-100" /></i>
<div class="ht-gradation style-01">


<div class="item item1 item-1 animate wow fadeInRight box01" data-wow-delay="0.1s">
                       
                        <div class="circle-wrap">
                            <div class="mask">
                                <div class="wave-pulse wave-pulse-1"></div>
                                <div class="wave-pulse wave-pulse-2"></div>
                                <div class="wave-pulse wave-pulse-3"></div>
                            </div>
                            <h6 class="circle">1</h6>
                        </div>
                       
                    </div>


                    <div class="item item1 item-1 animate wow fadeInRight box01 box2" data-wow-delay="0.1s">
                       
                        <div class="circle-wrap">
                            <div class="mask">
                                <div class="wave-pulse wave-pulse-1"></div>
                                <div class="wave-pulse wave-pulse-2"></div>
                                <div class="wave-pulse wave-pulse-3"></div>
                            </div>
                            <h6 class="circle">2</h6>
                        </div>
                       
                    </div>

                      <div class="item item1 item-1 animate wow fadeInRight box01 box3" data-wow-delay="0.1s">
                       
                        <div class="circle-wrap">
                            <div class="mask">
                                <div class="wave-pulse wave-pulse-1"></div>
                                <div class="wave-pulse wave-pulse-2"></div>
                                <div class="wave-pulse wave-pulse-3"></div>
                            </div>
                            <h6 class="circle">3</h6>
                        </div>
                       
                    </div>

                       <div class="item item1 item-1 animate wow fadeInRight box01 box4" data-wow-delay="0.1s">
                       
                        <div class="circle-wrap">
                            <div class="mask">
                                <div class="wave-pulse wave-pulse-1"></div>
                                <div class="wave-pulse wave-pulse-2"></div>
                                <div class="wave-pulse wave-pulse-3"></div>
                            </div>
                            <h6 class="circle">4</h6>
                        </div>
                       
                    </div>

                         <div class="item item1 item-1 animate wow fadeInRight box01 box5" data-wow-delay="0.1s">
                       
                        <div class="circle-wrap">
                            <div class="mask">
                                <div class="wave-pulse wave-pulse-1"></div>
                                <div class="wave-pulse wave-pulse-2"></div>
                                <div class="wave-pulse wave-pulse-3"></div>
                            </div>
                            <h6 class="circle">5</h6>
                        </div>
                       
                    </div>

                        <div class="item item1 item-1 animate wow fadeInRight box01 box6" data-wow-delay="0.1s">
                       
                        <div class="circle-wrap">
                            <div class="mask">
                                <div class="wave-pulse wave-pulse-1"></div>
                                <div class="wave-pulse wave-pulse-2"></div>
                                <div class="wave-pulse wave-pulse-3"></div>
                            </div>
                            <h6 class="circle">6</h6>
                        </div>
                       
                    </div>








</div>

  

                </div>
            </div>

            <div class="col-12 col-md-4 col-sm-12 col-lg-4">
                <div class="cando mt-200 mob-hide">
<div class="ht-gradation style-01 mobilesho">
                     <div class="item item1 item-1 animate wow fadeInRight " data-wow-delay="0.1s">
                       
                        <div class="circle-wrap">
                            <div class="mask">
                                <div class="wave-pulse wave-pulse-1"></div>
                                <div class="wave-pulse wave-pulse-2"></div>
                                <div class="wave-pulse wave-pulse-3"></div>
                            </div>
                            <h6 class="circle">2</h6>
                        </div>
                       
                    </div>
                </div>
                    <i class="fa-3x"><img src="assets/img/outsourcing2.webp" class="w-100" /></i>
                    <h3 class="h4 mt-3 text-left bluecolor">Quote/Meeting With Our Team</h3>
                    <p class="blue-grey-text text-left">
                        Once your design specifications are finalized, it is time to find the source. We talk with our fully-vetted network of manufacturers and suppliers to deliver you several quotes.
                    </p>
                </div>
                <div class="cando outsorce-mt-50 mob-hide">
                    <div class="ht-gradation style-01 mobilesho">
                       <div class="item item1 item-1 animate wow fadeInRight mobilesho" data-wow-delay="0.1s">
                       
                        <div class="circle-wrap">
                            <div class="mask">
                                <div class="wave-pulse wave-pulse-1"></div>
                                <div class="wave-pulse wave-pulse-2"></div>
                                <div class="wave-pulse wave-pulse-3"></div>
                            </div>
                            <h6 class="circle">4</h6>
                        </div>
                       
                    </div>
                </div>
                    
                    <i class="fa-3x"><img src="assets/img/outsourcing4.webp" class="w-100" /></i>
                    <h3 class="h4 mt-3 text-left bluecolor">Production & in-process inspection</h3>
                    <p class="blue-grey-text text-left">
                        Upon approval of the samples, the factory begins production. Your case manager and our team conduct multiple inspections during production and sends you quality assurance reports.
                    </p>
                </div>
                <div class="cando outsorce-mt-50 deliverybos">
<div class="ht-gradation style-01 mobilesho">
                     <div class="item item1 item-1 animate wow fadeInRight" data-wow-delay="0.1s">
                       
                        <div class="circle-wrap">
                            <div class="mask">
                                <div class="wave-pulse wave-pulse-1"></div>
                                <div class="wave-pulse wave-pulse-2"></div>
                                <div class="wave-pulse wave-pulse-3"></div>
                            </div>
                            <h6 class="circle">6</h6>
                        </div>
                       
                    </div>
                </div>
                  
                    <i class="fa-3x"><img src="assets/img/outsourcing6.webp" class="w-100" /></i>
                    <h3 class="h4 mt-3 text-left bluecolor">Delivery</h3>
                    <p class="blue-grey-text text-left">
                        Contracting with our global shipping and freight part-ners to get you the lowest prices possible, we find the best method to safely transport your orders safely.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="text-center py-4 homeproductbox pt-5 minorbg">
    <div class="container">
        <div class="row d-flex align-items-center justify-content-center">
            <div class="title-heading">
                <h2 class="h1 pb-3">New Ideas</h2>
            </div>
        </div>
        <div class="row d-flex align-items-center justify-content-center">
            <div class="filter-box">
                <!-- <button class="btn btn-default1 filter-button active"  (click)="viewProducts('all')" id="filter_all">All</button> -->
                <button class="btn btn-default1 filter-button" *ngFor="let categoryRow of productList.category; let i = index"  [ngClass]="{active: i == 0}" (click)="viewProducts(categoryRow.id)" id="filter_{{categoryRow.id}}">{{categoryRow.category_id.category_name}}</button>
            </div>            
            <div *ngFor="let productRow of productList.products" class="col-6 col-md-4 col-sm-6 col-lg-3 mb-5 filter filterclass_{{productRow.recommended_category_id}}">
                <div class="shop-title">
                    <a (click)="goToProductDetailPage(productRow.product_id);">
                        <i class="fa-3x"><img src="{{imgBaseUrl}}products/{{productRow.product_id.product_image}}" alt="{{productRow.product_id.product_name}}"/></i>
                    </a>
                    <h3 class="h4 mt-3">{{productRow.product_id.product_name}}</h3>
                    <h4><b>{{productRow.product_id.regular_price | currency}}</b> <!--<small class="det">$2,000.00</small> --></h4>
                    <div>
                        <a class="btn btn-outline-white shopbuynow-btn" (click)="goToProductDetailPage(productRow.product_id);">Buy Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="partner-bg soutso">
    <div class="container">
        <div class="row d-flex align-items-center justify-content-center">
            <h2 class="h1">Service Providers</h2>
        </div>
        <div class="container mybox">
            <section class="regular slider">
                <div class="mystyle" *ngFor="let channelRow of brandLists">
                    <a href="javascript:;">
                        <div class="wap">
                            <img src="{{imgBaseUrl}}brand/{{channelRow.brand_logo}}" alt="{{channelRow.brand_name}}"/>
                        </div>
                    </a>
                </div>
            </section>
        </div>
    </div>
</section>
<section class="bottom-outsource-bg">
    <div class="container">
        <div class="row d-flex align-items-center justify-content-center">
            <div class="col-12 col-md-5 col-lg-5 order-lg-12">
                <img src="/assets/img/electronics2.webp" class="w-100" />
            </div>

            <div class="col-12 col-md-7 col-lg-7 order-lg-1">
                <div class="col-sm-12">
                    <div class="row d-flex align-items-left justify-content-left">
                        <div class="title-heading-black">
                            <h2 class="h1 btm-title-out myelc">Electronics:</h2>
                        </div>
                    </div>
                </div>
             
                <p>If you are a crypto enthusiast, you can now utilize your ₿itcoin to buy mobile phones, computers, and thousands of other electronics from over 17 different brands worldwide.</p>
                <a (click)="goToNavigation('shop')" class="shopnownt waves-effect waves-light">Shop Now</a>
            </div>
        </div>
    </div>
</section>
