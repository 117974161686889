import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelperService } from '../../shared/services/helper-service.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { CoreService } from 'src/app/shared/services/core.service';
import { COUNTRY } from 'src/app/shared/constants/app.country.constants';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {
  public addressForm!: FormGroup;
  public lang:any = '';
  public countryLists:any = COUNTRY;
  public isAddressList:boolean = true;
  public isLoading:boolean = false;
  public isFormSubmit:boolean = false;
  public addressList:any = [];
  constructor(
    private toast: ToastrService,
    private fb: FormBuilder,
    private coreService: CoreService,
    private helperService: HelperService
  ) { }

  ngOnInit(): void {
    this.addressForm = this.fb.group({
      address_type: ['', [Validators.required]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      phone_number: ['', [Validators.required]],
      address: ['', [Validators.required]],
      landmark: [''],
      city: [''],
      zipcode: [''],
      country: [''],
      state: [''],
      id: ['']
    });
    this.addressForm.patchValue({
      address_type: 'HOME',
    });
    this.getAddressList();
  }

  get get() { return this.addressForm.controls; }

  getAddressList(){
    this.isAddressList = true;
    this.coreService.get(`${ENDPOINTS.USER_ADDRESS.LIST}`, {}).subscribe((res) => {
      this.isAddressList = false;
      if (res.body.success) {
        this.addressList = res.body.data;
      } else {
        this.addressList = [];
      }
    }, (err) => {
    });
  }

  editAddress(addressObj:any){
    this.addressForm.patchValue({
      id: addressObj?.id,
      address_type: addressObj?.address_type,
      first_name: addressObj?.first_name,
      last_name: addressObj?.last_name,
      phone_number: addressObj?.phone_number,
      address: addressObj?.address,
      landmark: addressObj?.landmark,
      city: addressObj?.city,
      zipcode: addressObj?.zipcode,
      country: addressObj?.country,
      state: addressObj?.state
    }); 
  }

  deleteAddress(addressObj:any){
    var c = confirm("Are you sure?");
    if(c){
      const paylaod = {
        'address_id' : addressObj.id
      }
      this.coreService.post(`${ENDPOINTS.USER_ADDRESS.DELETE}`, paylaod).subscribe((res) => {
        this.addressList = res.body.data;
        this.toast.success(res.body.message, "Success!");
      }, (err) => {
        this.toast.error(err.body.message,"Error!");
      });
    }
  }

  /**
   * @method: createNow
   * @description: Using to create User address
   * @param formData
   */
   createNow(formData:any) {
    this.isFormSubmit = true;
    if (this.addressForm.valid) {
      this.isLoading = true;
      const paylaod = {
        ...formData
      }
      this.coreService.post(`${ENDPOINTS.USER_ADDRESS.CREATE}`, paylaod).subscribe((res) => {
        this.isFormSubmit = false;
        this.isLoading = false;
        if(res.body.success){
          this.addressList = res.body.data;
          this.toast.success(res.body.message, "Success!");
          this.addressForm.reset();
          this.addressForm.patchValue({
            address_type: 'HOME',
          });
        }else{
          this.toast.error(res.body.message, "Error!");
        }
      }, (err) => {
        this.isFormSubmit = false;
        this.isLoading = false;
        this.toast.error(err.body.message,"Error!");
      });
    }
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang)
  }

}
