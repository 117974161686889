<app-headers></app-headers>
<section class="text-center mb-2 settingTabs">
    <app-user-breadcrumb></app-user-breadcrumb>
</section>
<section class="mb-5 profileView">
    <div class="container">
        <div class="row">
            <!--  dashboard-menu-->
            <div class="col-md-5 col-lg-3">
                <app-user-left-menu></app-user-left-menu>
            </div>
            <div class="col-md-7 col-lg-9">
                <div class="rightbox-dashbord">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <h4 class="list-heading">Redeem Balance Card</h4>
                        </div>
                    </div>

                            <div class="redeemtopbox">
                                 
                                        <div class="md-form searchbox">
                                            <button [disabled]="!voucherCode || isLoading" (click)="doRedeemVoucher()" type="button" class="redeembtnow">REDEEM NOW</button>
                                            <input class="form-control" type="text" id="redm" [(ngModel)]="voucherCode" name="name"/>
                                            <label for="redm">Enter your code</label>
                                        
                                </div>
                            </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <p class="tag-predeem">
                                <span class="mr-1"><img src="/assets/img/stopicon.png" /></span>
                                Balance Cards let you top up your USD balance to instantly purchase any of our products.
                                <!-- <a href="https://help.cryptomate.com.cn/category/gift-cards" target="_blank" class="leanmore">Learn more</a> -->
                                <a (click)="goToNavigation('cryptomate-balance-card');" class="leanmore">Get Mate Balance Card</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>