<app-header [cartItemData]="cartItems"></app-header>

<!-- first page -->
<div *ngIf="isLoading" id="loader"></div>
<!-- =========================
  breadcrumb html start from here
  ============================== -->
<section class="breadcrumb bg-transparent">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="bread-menu">
          <ul>
            <li><a (click)="goToNavigation('/');">home</a></li>
            <li><a  (click)="goToNavigation('games');">gift vouchers</a></li>
            <li>
              <a href="javascript:void(0)" class="active">{{productDetails.name}}</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- =======================
  breadcrumb html end from here
  ============================-->

<!-- =========================
  slider section html start from here
  ============================== -->
<section class="slider-wrapper" *ngIf="!isLoading">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h4 class="slider-title">
          {{productDetails.name}}
        </h4>
      </div>
      <div class="col-md-12 col-lg-7">
        <ngx-slick-carousel
          class="carousel video-sub-slider"
          #slickModal="slick-carousel"
          [config]="slideConfigmain"
          (init)="slickInit($event)"
          (breakpoint)="breakpoint($event)"
          (afterChange)="afterChange($event)"
          (beforeChange)="beforeChange($event)"
        >
        <div ngxSlickItem *ngFor="let slide of productDetails.videos" class="slide">
          <iframe id="youtube_videos" width="100%" [src]="slide.videoUrl" height="350"  title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media;  gyroscope; picture-in-picture"  allowfullscreen></iframe>
          <!-- <video height="350" width="100%" loop autoplay muted controls>
            <source src="assets/games/mov_bbb.mp4" type="video/mp4">
            <source src="assets/games/mov_bbb.ogg" type="video/ogg">
            Your browser does not support the video tag.
          </video> -->
        </div>
          <div ngxSlickItem *ngFor="let slide of productDetails.images.screenshots" class="slide">
            <img src="{{ slide.url }}" alt="" width="100%" />
          </div>
          
        </ngx-slick-carousel>

        <ngx-slick-carousel
          class="carousel video-sub-slider"
          #slickModal="slick-carousel"
          [config]="slideConfig"
          (init)="slickInit($event)"
          (breakpoint)="breakpoint($event)"
          (afterChange)="afterChange($event)"
          (beforeChange)="beforeChange($event)"
        >
        <div ngxSlickItem *ngFor="let slide of productDetails.videos" class="slide">
          <iframe id="youtube_videos" width="100%" [src]="slide.videoUrl" height="80"  title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media;  gyroscope; picture-in-picture"  allowfullscreen></iframe>
        </div>
          <div ngxSlickItem *ngFor="let slide of productDetails.images.screenshots" class="slide">
            <img src="{{ slide.thumbnail }}" alt="" width="100%" />
          </div>
        </ngx-slick-carousel>
        <div class="slider-button-block mt-1">
          <button class="btn action-btn" *ngFor="let row of productDetails.tags" title="{{row}}">{{row}}</button>
          <div class="flag-block">
            <ul>
              <li *ngFor="let row of productDetails.languages" title="{{row}}">
                <a href="javascript:void(0)"><img src="assets/games/languages/{{row.toLowerCase()}}.png" style="width: 36px;"/></a>
              </li>
              <!-- <li><a href="javascript:void(0)">+6</a></li> -->
            </ul>
          </div>
        </div>
      </div>

      <div class="col-md-12 col-lg-5">
        <div class="slider-content mt-5 mt-md-0">
          <div class="slider-icons">
            <span title="{{productDetails.platform}}">
              <img src="assets/games/platoforms/{{productDetails.platform.toLowerCase()}}.svg" style="width: 30px;"/>
            </span>
            <span title="{{productDetails.regionalLimitations}}" data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            (click)="open(mymodal)">
              <img src="assets/games/region/{{productDetails.regionalLimitations.toLowerCase()}}.svg" style="width: 30px;"/>
            </span>
          </div>

          <div>
          <share-buttons [include]="['facebook','twitter','linkedin','telegram','copy']" [autoSetMeta]="true"></share-buttons>
            <div class="slider-dropdown">
              <app-crypto-dropdown [inputPrice]="'1'" [displayType]="'dropdown'" (convertedRate)="cryptoPrice=$event"></app-crypto-dropdown>
            </div>
          </div>
        </div>
        <div class="wish mt-1 mb-1">
              <app-wishlist-modal [giftCardDetail]="productDetails" [productName]="productDetails.name" [productType]="'gift_card'"></app-wishlist-modal>
        </div>
            
        <div class="slider-text mt-3 mt-md-5" [ngClass]="{'not-active-country': !canActivated}">
          <p>
            <i class="fa-solid fa-check-circle me-1"></i><span *ngIf="!canActivated"> Cannot be activated in </span><span *ngIf="canActivated"> Can be activated in </span>
            <strong>{{country}}</strong>
          </p>
          <a
            href="javascript:void(0)"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            (click)="open(mymodal)"
            >check country restriction</a
          >
        </div>

        <div class="price my-3 my-md-5 text-center">
          <span>{{productDetails.price | currency}}</span>
          <p *ngIf="cryptoPrice.exchange_rate==0">{{cryptoPrice.display}}</p>
          <p *ngIf="cryptoPrice.exchange_rate!=0">{{(cryptoPrice.exchange_rate * (productDetails.price)).toFixed(6)}} {{cryptoPrice.currency_code}}</p>
        </div>

        <div class="button-block text-center">
          <button class="btn cart-btn" (click)="addToCart(productDetails)">
            add to cart <i class="fa-solid fa-cart-shopping"></i>
          </button>
          <button class="btn gift-btn bg-transparent">
            buy as a gift <i class="fa-solid fa-gift"></i>
          </button>
        </div>
        
      </div>
    </div>
  </div>
</section>

<!-- =========================
  slider section html end here
  ============================== -->

<!-- =====================
  modal html start here
  ==========================-->
<ng-template #mymodal let-modal>
  <div class="modal-body countrylist modalwindowCountry">
    <button
      type="button"
      class="close btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
    <h4>Product activation check</h4>
      <p [ngClass]="{'no-found-country': !canActivated}">
      <i class="fa-solid fa-check-circle me-1"></i> This product
      <strong><span *ngIf="!canActivated">Cannot be activated in </span><span *ngIf="canActivated">Can be activated in </span>: {{country}}</strong>
    </p>
    <span>List of allowed countries for this product version:</span>
    <div class="country-list">
      <ul>
        <li *ngFor="let row of countryLists.country">{{row.name}}</li>
      </ul>
    </div>
  </div>
</ng-template>
<!-- 
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header border-bottom-0">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <h4>Product activation check</h4>
        <p class="modelactive">
          <i class="fa-solid fa-circle-check me-1"></i> This product
          <strong>can be activated in: India</strong>
        </p>
        <span>List of allowed countries for this product version:</span>
        <div class="country-list">
          <ul>
            <li>Global</li>
            <li>Europe</li>
            <li>United States</li>
            <li>Other</li>
            <li>Outside Europe</li>
            <li>RU VPN</li>
            <li>Russia</li>
            <li>United Kingdom</li>
            <li>China</li>
            <li>ROW (Rest of World)</li>
            <li>Latin America</li>
          </ul>
          <ul>
            <li>Asia</li>
            <li>Germany</li>
            <li>Australia</li>
            <li>Brazil</li>
            <li>India</li>
            <li>Japan</li>
            <li>North America</li>
          </ul>
        </div>
      </div>
      <div class="modal-footer d-none">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div> -->

<!-- =====================
  modal html end here
  ==========================-->

<!-- ======================
  tabs html end here
  ==========================-->

<section class="tabs my-5">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <tabset class="nav nav-tabs custom-nav-tabs new-nav-tabs">
          <tab class="nav-item custom-nav-item" heading="Description">
            <p [innerHtml]="productDetails.description"></p>
          </tab>
          <tab class="nav-item custom-nav-item" heading="System Requirements">
            <div *ngFor="let row of productDetails.systemRequirements">
              <div>
                  <h4>{{row.system}}</h4>
                  <p [innerHtml]="row.requirement"></p>
              </div>
            </div>
          </tab>
          <tab class="nav-item custom-nav-item" heading="Activation Details">
            <p [innerHtml]="productDetails.activationDetails"></p>
          </tab>
        </tabset>
      </div>
    </div>
  </div>
</section>

<!-- =====================
  tabs html end here
  ==========================-->
