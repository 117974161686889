<app-header></app-header>
<section class="text-center mb-2 pt-4 mt-3 mt-50">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <ul class="list-unstyled bradcam">
                    <li><a (click)="goToNavigation('home');">Home</a></li>
                    <li><i class="fas fa-angle-double-right iconarrw"></i> Contact Us</li>
                </ul>
            </div>
        </div>
    </div>
</section>
<section class="mb-5">
    <div class="container">
        <div class="row d-flex align-items-center justify-content-center mt-3">
            <div class="title-heading">
                <h2 class="h1 pb-3">Contact Us</h2>
            </div>
        </div>
        <div *ngIf="isLoading" id="loader"></div>
        <div class="row d-flex justify-content-center">
            <div class="col-md-12 col-lg-9">
                <div class="contat-box">
                    <div class="row d-flex justify-content-center">
                        <div class="col-sm-12">
                            <h5 class="font-weight-normal mb-1">Drop us a message</h5>
                        </div>
                        <form class="col-md-12 col-lg-12 save-addreform contactpas"  [formGroup]="contectForm">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="md-form">
                                        <input class="form-control" type="text" id="text" name="full_name" required="required" formControlName="full_name" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.full_name?.errors }"/>
                                        <label for="text">Full Name*</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="md-form">
                                        <input class="form-control" type="text" id="email" name="email" required="required" formControlName="email" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.email?.errors }"/>
                                        <label for="email">Email*</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="md-form">
                                        <input class="form-control" type="text" id="phone" name="phone" required="required" formControlName="phone" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.phone?.errors }"/>
                                        <label for="phone">Phone*</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="md-form">
                                        <input class="form-control" type="text" id="subject" name="subject" required="required" formControlName="subject" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.subject?.errors }"/>
                                        <label for="subject">Subject*</label>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="md-form">
                                        <textarea id="form7" name="message" class="md-textarea form-control billing-textare" rows="3" required="required" formControlName="message" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.message?.errors }"></textarea>
                                        <label for="form7">Your Message</label>
                                    </div>
                                </div>
                                <div class="col-sm-12 text-center">
                                    <button [disabled]="isLoading" type="button" class="joinnow-btn waves-effect waves-light w-100" (click)="submitContectUs(contectForm.value)">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="row d-flex align-items-center justify-content-center mt-3 mob-center vfg">
                    <div class="title-heading">
                        <h2 class="h1 pb-3 mb-0">Let’s talk!</h2>
                    </div>
                </div>
                <div class="row justify-content-center d-flex text-center vfg">
                    <div class="col-sm-6 col-lg-6">
                        <div class="contat-box-1">
                            <a href="https://help.cryptomate.com.cn/" target="_blank" ><span class="iconcont"><i class="fas fa-headset"></i></span>
                            <h6><b>Help Center</b></h6></a>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-6">
                        <div class="contat-box-1">
                            <a href="mailto:help@cryptomate.com.cn"><span class="iconcont"><i class="far fa-envelope"></i></span>
                            <h6><b>Let's Talk</b></h6></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>