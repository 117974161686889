<app-header [cartItemData]="cartItems"></app-header>
<section class="text-center mb-2 mt-3 pt-4 mt-50">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <ul class="list-unstyled mt-3 bradcam">
                    <li><a (click)="goToNavigation('/');">Home</a></li>
                    <li>
                        <a (click)="goToNavigation('gift');"><i class="fas fa-angle-double-right iconarrw"></i> Gift Vouchers</a>
                    </li>
                    <li><i class="fas fa-angle-double-right iconarrw"></i> {{giftObj.card_name}}</li>
                </ul>
            </div>
        </div>
    </div>
</section>
<section class="mb-5">
    <div class="container">
        <div *ngIf="isLoader" id="loader"></div>
        <div class="row" *ngIf="!isLoader">
            <div *ngIf="isLoggedIn && !profileObj.is_document_verified && ((giftObj.quantity * giftObj.price)>limit_amount)" class="col-md-12 alert alert-danger">
                Please verify your account to purchase voucher more than {{limit_amount | currency}}
            </div>
            <div class="col-md-12 col-lg-6">
                <a>
                    <i class="fa-3x"><img src="{{imgBaseUrl}}giftcard/{{giftObj.card_logo}}" class="img-gift-detail" /></i>
                </a>
            </div>
            <div class="col-md-12 col-lg-6">
                <div class="content-detail newdost">
                    <span class="instant-img checkinstant"><i class="far fa-check-circle"></i> Instant Delivery</span>
                    <h2><b>{{giftObj.card_name}}</b></h2>

                    <div class="row">
                        <div class="col-6">
                            
                        </div>
                        <div class="col-6">
                            <app-wishlist-modal [balanceCardDetail]="giftObj" [productName]="giftObj.card_name" [productType]="'balance_card'"></app-wishlist-modal>
                        </div>
                    </div>
                    <div class="dropdown-divider-new"></div>
                    <span class="float-left">Select amount</span>
                    <div class="price-box">
                        <label class="pure-material-radio" *ngFor="let d of giftObj.amount">
                            <input type="radio" name="group2"  [(ngModel)]="giftObj.price" value="{{d.amount}}"/>
                            <span>{{d.amount | currency}}</span>
                            <small *ngIf="cryptoPrice.exchange_rate==0">{{cryptoPrice.display}}</small>
                            <small *ngIf="cryptoPrice.exchange_rate!=0">{{(cryptoPrice.exchange_rate * d.amount).toFixed(6)}} {{cryptoPrice.currency_code}}</small>
                        </label>
                    </div>
                    <div class="price-box">                        
                        <input type="text" class="form-control cardpopup-input" [(ngModel)]="giftObj.price" value="1000" placeholder="Please enter the amount which you want to purchase."/>
                        <small *ngIf="cryptoPrice.exchange_rate==0">{{cryptoPrice.display}}</small>
                        <small *ngIf="cryptoPrice.exchange_rate!=0">{{(cryptoPrice.exchange_rate * giftObj.price).toFixed(6)}} {{cryptoPrice.currency_code}}</small>
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-6 col-md-6 col-lg-6">
                            <app-crypto-dropdown [inputPrice]="'1'" [displayType]="'dropdown'" (convertedRate)="cryptoPrice=$event"></app-crypto-dropdown>
                            <select class="selebitcoin-qty" [(ngModel)]="giftObj.quantity">
                                <option>Select QTY</option>
                                <option *ngFor="let q of quantityLists;" value="{{q}}">{{q}}</option>
                            </select>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6" *ngIf="isLoggedIn">
                            <span *ngIf="!profileObj.is_document_verified">
                                <a *ngIf="!addingToCart && ((giftObj.quantity * giftObj.price)>limit_amount)" data-toggle="modal" data-target="#giftpurchae" class="waves-effect waves-light giftpurchase-btn">Purchase</a>
                                <a *ngIf="!addingToCart && ((giftObj.quantity * giftObj.price)<=limit_amount)" (click)="addToCart(giftObj)" class="waves-effect waves-light giftpurchase-btn">Purchase</a>
                                <a *ngIf="addingToCart" class="waves-effect waves-light giftpurchase-btn">Please wait...</a>
                            </span>
                            <span *ngIf="profileObj.is_document_verified">
                                <a *ngIf="!addingToCart" (click)="addToCart(giftObj)" class="waves-effect waves-light giftpurchase-btn">Purchase</a>
                                <a *ngIf="addingToCart" class="waves-effect waves-light giftpurchase-btn">Please wait...</a>
                            </span>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6" *ngIf="!isLoggedIn">
                            <a data-toggle="modal" data-target="#login" class="waves-effect waves-light giftpurchase-btn">Purchase</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="pb-5 minorbg1">
    <div class="container">
        <div class="row">
            
            <div class="col-sm-12">
                <!-- Nav tabs -->
                <ul class="nav nav-tabs md-tabs nav-justified gift-detailtab" style="border-bottom: none;">
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#panel1" role="tab" style="box-shadow: none;">About</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#panel2" role="tab" style="box-shadow: none;">How to Redeem/Use</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#panel3" role="tab" style="box-shadow: none;">Terms and Conditions</a>
                    </li>
                </ul>
</div>

<div class="col-sm-1"></div>
<div class="col-sm-10">
                <!-- Tab panels -->
                <div class="tab-content card dashbaord-table" style="box-shadow: none; background: transparent;">
                    <!--Panel 1-->
                    <div class="tab-pane fade in show active" id="panel1" role="tabpanel" style="box-shadow: none;">
                        <div class="card-body pl-0 pr-0" style="box-shadow: none;">
                            <p [innerHtml]="giftObj.card_about"></p>
                        </div>
                    </div>
                    <!--/.Panel 1-->
                    <!--Panel 2-->
                    <div class="tab-pane fade" id="panel2" role="tabpanel" style="box-shadow: none;">
                        <div class="card-body pl-0 pr-0" style="box-shadow: none;">
                            <p [innerHtml]="giftObj.how_to_redeem"></p>
                        </div>
                    </div>
                    <!--/.Panel 2-->
                    <!--Panel 3-->
                    <div class="tab-pane fade" id="panel3" role="tabpanel" style="box-shadow: none;">
                        <div class="card-body pl-0 pr-0" style="box-shadow: none;">
                            <p [innerHtml]="giftObj.terms_condition"></p>
                        </div>
                    </div>
                    <!--/.Panel 3-->
                </div>
            </div>
        </div>
    </div>
</section>

<div class="gradation-process-area bg-gray section-space--ptb_100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="gradation-title-wrapper section-space--mb_60">
                    <div class="gradation-title-wrap">
                        <h6 class="section-sub-title text-black mb-20">How Gift Cards Work</h6>
                        <h4 class="heading">Get gift cards anywhere, anytime - Gift Cards for 1000+ services</h4>
                    </div>
                    <div class="gradation-sub-heading">
                        <h3 class="heading"><mark>03</mark> Steps</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="ht-gradation style-01">
                    <!-- Single item gradation Start -->
                    <div class="item new1 item-1 animate wow fadeInRight" data-wow-delay="0.1s">
                        <div class="line"></div>
                        <div class="circle-wrap">
                            <div class="mask">
                                <div class="wave-pulse wave-pulse-1"></div>
                                <div class="wave-pulse wave-pulse-2"></div>
                                <div class="wave-pulse wave-pulse-3"></div>
                            </div>
                            <h6 class="circle">1</h6>
                        </div>
                        <div class="content-wrap">
                            <h6 class="heading">01. Enter the amount</h6>
                            <div class="text">Enter the amount you want the voucher to have</div>
                        </div>
                    </div>
                    <!-- Single item gradation End -->
                    <!-- Single item gradation Start -->
                    <div class="item new1 item-1 animate wow fadeInRight" data-wow-delay="0.15s">
                        <div class="line"></div>
                        <div class="circle-wrap">
                            <div class="mask">
                                <div class="wave-pulse wave-pulse-1"></div>
                                <div class="wave-pulse wave-pulse-2"></div>
                                <div class="wave-pulse wave-pulse-3"></div>
                            </div>
                            <h6 class="circle">2</h6>
                        </div>
                        <div class="content-wrap">
                            <h6 class="heading">02. Pay with <span class="text-yellow">Cryptos</span></h6>
                            <div class="text">Your payment is confirmed, the same minute in most cases</div>
                        </div>
                    </div>
                    <!-- Single item gradation End -->
                    <!-- Single item gradation Start -->
                    <div class="item new1 item-1 animate wow fadeInRight" data-wow-delay="0.20s">
                        <div class="line"></div>
                        <div class="circle-wrap">
                            <div class="mask">
                                <div class="wave-pulse wave-pulse-1"></div>
                                <div class="wave-pulse wave-pulse-2"></div>
                                <div class="wave-pulse wave-pulse-3"></div>
                            </div>
                            <h6 class="circle">3</h6>
                        </div>
                        <div class="content-wrap">
                            <h6 class="heading">03. That's it, redeem your code!</h6>
                            <div class="text">Once your payment is confirm, you will get your gift card code sent out.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section-space--ptb_100 more-prduct-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>More products on <span class="yellow-color1">Mate</span></h2>
            </div>
        </div>
        <div class="row mb-3 d-flex align-items-center justify-content-center giftiboxrow">
            <div class="col-6 col-md-6 col-sm-6 col-lg-3" *ngFor="let giftRow of gifts.slice(0,4)">
                <div class="gift-boxlist">
                    <a href="javascript:;" (click)="goTOPage(giftRow);">
                        <i class="fa-3x"><img src="{{giftRow.imageUrl}}" /></i>
                    </a>
                    <h3 (click)="goTOPage(giftRow);" class="h4 mt-3">{{giftRow.name}}</h3>
                    <div class="pl-3 pr-3 selec-center">
                        <select [(ngModel)]="giftRow.price">
                            <option *ngFor="let d of giftRow.denominations" selected value="{{d}}">{{giftRow.currencyCode}} {{d}}</option>
                        </select>
                        <select [(ngModel)]="giftRow.quantity">
                            <option>Select QTY</option>
                            <option *ngFor="let q of quantityLists;" value="{{q}}">Qty {{q}}</option>
                        </select>
                    </div>
                    <div>
                        <!-- *ngIf="isLoggedIn" <span *ngIf="!profileObj.is_document_verified">
                            <a *ngIf="!giftRow.addingToCart && ((giftRow.quantity * giftRow.price)>limit_amount)" data-toggle="modal" data-target="#giftpurchae" class="btn btn-outline-white addtocart waves-effect waves-light">Add to cart</a>
                            <a *ngIf="!giftRow.addingToCart && ((giftRow.quantity * giftRow.price)<=limit_amount)" (click)="addToCartGift(giftRow)" class="btn btn-outline-white addtocart waves-effect waves-light">Add to cart</a>
                            <a *ngIf="giftRow.addingToCart"  class="btn btn-outline-white addtocart waves-effect waves-light">Please Wait...</a>
                        </span> *ngIf="profileObj.is_document_verified" -->
                        <span *ngIf="!gifts.addedToCart">
                            <a *ngIf="!giftRow.addingToCart" (click)="addToCartGift(giftRow)" class="btn btn-outline-white addtocart waves-effect waves-light">Add to cart</a>
                            <a *ngIf="giftRow.addingToCart"  class="btn btn-outline-white addtocart waves-effect waves-light">Please Wait...</a>
                        </span>
                        <span *ngIf="gifts.addedToCart">
                            <a class="btn btn-outline-white addtocart waves-effect waves-light" style="background: #ffd91d73 !important; cursor: not-allowed;">Added to cart</a>
                        </span>
                    </div>
                    <!-- <div *ngIf="!isLoggedIn">
                        <a data-toggle="modal" data-target="#login" class="btn btn-outline-white addtocart waves-effect waves-light">Add to cart</a>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-9">
                <h4>
                    <b>Have more questions? <a href="https://help.cryptomate.com.cn/"  target="_blank" class="yellow-color1">Check out our FAQ page</a></b>
                </h4>
            </div>
            <div class="col-sm-3">
                <a href="https://help.cryptomate.com.cn/" target="_blank" class="Seeall waves-effect waves-light">See all <i class="fas fa-angle-right"></i></a>
            </div>
        </div>
    </div>
</div>
<app-gift-validation></app-gift-validation>