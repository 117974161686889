import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { CoreService } from 'src/app/shared/services/core.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { environment } from 'src/environments/environment';
import {ToastrService} from 'ngx-toastr';
import { HelperService } from '../../shared/services/helper-service.service';
declare var $: any;

@Component({
  selector: 'app-my-products',
  templateUrl: './my-products.component.html',
  styleUrls: ['./my-products.component.css']
})
export class MyProductsComponent implements OnInit {
  public imgBaseUrl = environment.AWSImageURL;
  public invoiceBaseUrl = environment.API_ENDPOINT+'download/invoice';
  public isLoading:boolean = false;
  public topupDetail:boolean = false;
  public giftCardDetail:boolean = false;
  public ecommerceDetail:boolean = false;
  public orderDetail:boolean = false;
  public cancelOrderDetail:boolean = false;
  public gameDetail:boolean = false;
  public orderLists:any = [];
  public cancelledOrderLists:any = [];
  public orderProductDetail:any = {};
  public topupDetailObj:any = {};
  public giftCardDetailObj:any = {};
  public ecommerceDetailObj:any = {};
  public orderDetailObj:any = {};
  public gameProductDetailObj:any = {};
  public orderSetting:any = {
    page : 1,
    perPage : 10,
    totalCount : 0
  }

  public ecommerceOrderSetting:any = {
    page : 1,
    perPage : 10,
    totalCount : 0
  }

  public gameOrderSetting:any = {
    page : 1,
    perPage : 10,
    totalCount : 0
  }

  public toupupOrderSetting:any = {
    page : 1,
    perPage : 10,
    totalCount : 0
  }

  public giftCardOrderSetting:any = {
    page : 1,
    perPage : 10,
    totalCount : 0
  }

  public cancelOrderSetting:any = {
    page : 1,
    perPage : 10,
    totalCount : 0
  }

  public lang:any = '';
  public orderProduct:any = {
    ecommerce : [],
    toupup : [],
    gift_card : [],
    game_product : []
  };
  
  constructor(
    private toast: ToastrService,
    public coreService: CoreService,
    private router: Router,
    private route: ActivatedRoute,
    private helperService: HelperService
  ) { }

  ngOnInit(): void {
    this.getMyOrderList();
    this.getEcommerceOrderProducts(['product'],'ecommerce');
    this.getTopupOrderProducts(['toupup'],'toupup');
    this.getGiftCardOrderProducts(['balance_card','gift_card'],'gift_card');
    this.getGamesOrderProducts(['game_product'],'game_product');
    this.getMyCancelledOrderList();
  }

  pageChanged(event:any): void {
    this.orderSetting.page = event;
    this.getMyOrderList();
  }

  ecommercePageChanged(event:any): void {
    this.ecommerceOrderSetting.page = event;
    this.getEcommerceOrderProducts(['product'],'ecommerce');
  }

  topupPageChanged(event:any): void {
    this.toupupOrderSetting.page = event;
    this.getTopupOrderProducts(['toupup'],'toupup');
  }

  gamePageChanged(event:any): void {
    this.gameOrderSetting.page = event;
    this.getGamesOrderProducts(['game_product'],'game_product');
  }

  giftCardPageChanged(event:any): void {
    this.giftCardOrderSetting.page = event;
    this.getGiftCardOrderProducts(['balance_card','gift_card'],'gift_card');
  }

  cancelledPageChanged(event:any): void {
    this.cancelOrderSetting.page = event;
    this.getMyCancelledOrderList();
  }

  getMyOrderList(){
    this.isLoading = true;
    this.coreService.post(`${ENDPOINTS.USER_ORDER.ORDER_LIST}/${this.orderSetting.page}/${this.orderSetting.perPage}`, {}).subscribe((res) => {
      this.isLoading = false;
      if (res.body.success) {
        this.orderLists = res.body.data.items;
        this.orderSetting.totalCount = res.body.data.count;
      } else {
        this.orderLists = [];
      }
    }, (err) => {
    });
  }

  showOrderView(obj:any,type:string){
    this.isLoading = true;
    if(type=='cancel'){
      this.orderDetail = false;
      this.cancelOrderDetail = true;
    }else{
      this.orderDetail = true;
      this.cancelOrderDetail = false;
    }
    this.coreService.get(`${ENDPOINTS.USER_ORDER.ORDER_DETAIL}`+obj.id, {}).subscribe((res) => {
      this.isLoading = false;
      if (res.body.success) {
        this.orderDetailObj = res.body.data;
        this.orderDetailObj.products = {
          ecommerce : [],
          toupup : [],
          gift_card : [],
          game_products : []
        }
        this.orderDetailObj.products.ecommerce = this.orderDetailObj.order_products.filter((curElem:any) => {
          return curElem.cart_item == 'product';
        });

        this.orderDetailObj.products.toupup = this.orderDetailObj.order_products.filter((curElem:any) => {
          return curElem.cart_item == 'toupup';
        });

        this.orderDetailObj.products.gift_card = this.orderDetailObj.order_products.filter((curElem:any) => {
          return (curElem.cart_item == 'gift_card' || curElem.cart_item == 'balance_card');
        });

        this.orderDetailObj.products.game_products = this.orderDetailObj.order_products.filter((curElem:any) => {
          return curElem.cart_item == 'game_product';
        });
      } else {
        this.orderDetail = false;
        this.cancelOrderDetail = false;
        this.orderDetailObj = {};
      }
    }, (err) => {
    });
  }

  getMyCancelledOrderList(){
    this.isLoading = true;
    this.coreService.post(`${ENDPOINTS.USER_ORDER.ORDER_LIST}/${this.cancelOrderSetting.page}/${this.cancelOrderSetting.perPage}`, {'is_cancelled':1}).subscribe((res) => {
      this.isLoading = false;
      if (res.body.success) {
        this.cancelledOrderLists = res.body.data.items;
        this.cancelOrderSetting.totalCount = res.body.data.count;
      } else {
        this.cancelledOrderLists = [];
      }
    }, (err) => {
    });
  }

  getEcommerceOrderProducts(obj:any,name:string){
    this.isLoading = true;
    this.coreService.post(`${ENDPOINTS.USER_ORDER.ORDER_PRODUCTS}/${this.ecommerceOrderSetting.page}/${this.ecommerceOrderSetting.perPage}`, {'type':obj}).subscribe((res) => {
      this.isLoading = false;
      if (res.body.success) {
        this.orderProduct[name] = res.body.data.items;
        this.ecommerceOrderSetting.totalCount = res.body.data.count;
      } else {
        this.orderProduct[name] = [];
      }
    }, (err) => {
    });
  }

  getGamesOrderProducts(obj:any,name:string){
    this.isLoading = true;
    this.coreService.post(`${ENDPOINTS.USER_ORDER.ORDER_PRODUCTS}/${this.gameOrderSetting.page}/${this.gameOrderSetting.perPage}`, {'type':obj}).subscribe((res) => {
      this.isLoading = false;
      if (res.body.success) {
        this.orderProduct[name] = res.body.data.items;
        this.gameOrderSetting.totalCount = res.body.data.count;
      } else {
        this.orderProduct[name] = [];
      }
    }, (err) => {
    });
  }

  getGiftCardOrderProducts(obj:any,name:string){
    this.isLoading = true;
    this.coreService.post(`${ENDPOINTS.USER_ORDER.ORDER_PRODUCTS}/${this.giftCardOrderSetting.page}/${this.giftCardOrderSetting.perPage}`, {'type':obj}).subscribe((res) => {
      this.isLoading = false;
      if (res.body.success) {
        this.orderProduct[name] = res.body.data.items;
        this.giftCardOrderSetting.totalCount = res.body.data.count;
      } else {
        this.orderProduct[name] = [];
      }
    }, (err) => {
    });
  }

  getTopupOrderProducts(obj:any,name:string){
    this.isLoading = true;
    this.coreService.post(`${ENDPOINTS.USER_ORDER.ORDER_PRODUCTS}/${this.toupupOrderSetting.page}/${this.toupupOrderSetting.perPage}`, {'type':obj}).subscribe((res) => {
      this.isLoading = false;
      if (res.body.success) {
        this.orderProduct[name] = res.body.data.items;
        this.toupupOrderSetting.totalCount = res.body.data.count;
      } else {
        this.orderProduct[name] = [];
      }
    }, (err) => {
    });
  }

  viewTopupProductDetails(obj:any){
    this.isLoading = true;
    this.coreService.get(`${ENDPOINTS.USER_ORDER.ORDER_PRODUCT_DETAIL}`+obj.id, {}).subscribe((res) => {
      this.isLoading = false;
      if (res.body.success) {
        this.topupDetail = true;
        this.topupDetailObj = res.body.data;
      } else {
        this.topupDetailObj = {};
      }
    }, (err) => {
    });
  }

  viewGamesProductDetails(obj:any){
    this.isLoading = true;
    this.coreService.get(`${ENDPOINTS.USER_ORDER.ORDER_PRODUCT_DETAIL}`+obj.id, {}).subscribe((res) => {
      this.isLoading = false;
      if (res.body.success) {
        this.gameDetail = true;
        this.gameProductDetailObj = res.body.data;
      } else {
        this.gameProductDetailObj = {};
      }
    }, (err) => {
    });
  }

  viewGiftCardDetail(obj:any){
    this.isLoading = true;
    this.coreService.get(`${ENDPOINTS.USER_ORDER.ORDER_PRODUCT_DETAIL}`+obj.id, {}).subscribe((res) => {
      this.isLoading = false;
      if (res.body.success) {
        this.giftCardDetail = true;
        this.giftCardDetailObj = res.body.data;
      } else {
        this.ecommerceDetailObj = {};
      }
    }, (err) => {
    });
  }

  showOrderStatus(status:any){
    switch(status){
      case 'paid':
        return "Paid";
      case 'processed':
        return "Processed";
      case 'canceled':
        return "Canceled - Refunded";
      case 'cancelled':
        return "Cancelled";
      case 'shipped':
        return "Shipped";
      case 'completed':
        return "Completed";
      case 'delivered':
        return "Delivered";
      case 'on-hold':
        return "On Hold";
      case 'pending':
        return "Pending";
      case 'failed':
        return "Failed";
      default:
        return status;
    }
  }

  copyURL(voucher:any){
    const el = document.createElement('textarea');
    el.value = voucher;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.toast.success("Copying to clipboard was successful!", "Success!");
  }

  viewProductDetails(obj:any){
    this.isLoading = true;
    this.coreService.get(`${ENDPOINTS.USER_ORDER.ORDER_PRODUCT_DETAIL}`+obj.id, {}).subscribe((res) => {
      this.isLoading = false;
      if (res.body.success) {
        this.ecommerceDetail = true;
        this.ecommerceDetailObj = res.body.data;
      } else {
        this.ecommerceDetailObj = {};
      }
    }, (err) => {
    });
  }

  showList(){
    this.ecommerceDetail = false;
    this.topupDetail = false;
    this.gameDetail = false;
    this.giftCardDetail = false;
    this.orderDetail = false;
    this.cancelOrderDetail = false;
    this.orderDetailObj = false;
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang);
  }
}
