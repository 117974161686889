import { Component, OnInit } from '@angular/core';
import { CoreService } from '../shared/services/core.service';
import { HelperService } from '../shared/services/helper-service.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {
  public lang:any = '';
  public isLoading:boolean = true;
  public pageObj:any = {};

  constructor(
    private coreService: CoreService,
    public helperService: HelperService
  ) { }

  ngOnInit(): void {
    this.coreService.get(`${ENDPOINTS.STATIC.CMSPAGES}`+'terms', {}).subscribe((res) => {
      this.isLoading = false;
      if (res.body.success) {
        this.pageObj = res.body.data;
      } else {
        this.pageObj = [];
      }
    }, (err) => {
    });
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang)
  }
}
