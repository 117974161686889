import { Component, OnInit,Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import {ToastrService} from 'ngx-toastr';
import { CoreService } from 'src/app/shared/services/core.service';
import { HelperService } from 'src/app/shared/services/helper-service.service';
import { AuthService } from 'src/app/shared/services/auth.service';
declare var $: any;

@Component({
  selector: 'app-wishlist-modal',
  templateUrl: './wishlist-modal.component.html',
  styleUrls: ['./wishlist-modal.component.css']
})
export class WishlistModalComponent implements OnInit {
  public isWishlistFatching:boolean = false;
  public isLoggedIn:boolean = false;
  public isLoading:boolean = false;
  public wishlistList:any  = [];
  public wishlistForm!: FormGroup;
  public wishlistFormObj: any = {
    isFormSubmit: false
  }
  @Input() public productDetails:any = '';
  @Input() public balanceCardDetail:any = '';
  @Input() public operatorObj:any = '';
  @Input() public topupProductObj:any = '';
  @Input() public giftCardDetail:any = '';
  @Input() public productName:any = '';
  @Input() public productType:any = 'product';

  constructor(
    private toast: ToastrService,
    private fb: FormBuilder,
    private coreService: CoreService,
    public helperService: HelperService,
    private route: ActivatedRoute,
    private _auth : AuthService,
    private router: Router,
  ) { 
    if(this._auth.loggedIn()){
      this.isLoggedIn = true;
    }else{
      this.isLoggedIn = false;
    }
  }

  ngOnInit(): void {
    this.wishlistForm = this.fb.group({
      wishlist_name: ['', [Validators.required]]
    });
    // $('.addwishlist').click(function(){
    //    $('.addwishlistopen').toggleClass('show');
    // });
  }
  get get() { return this.wishlistForm.controls; }

  getMyWishlist(){
    this.isWishlistFatching = true;
    let payload:any = {
      'type' : this.productType
    }
    if(this.productType=='product'){
      payload.product_id = this.productDetails.id;
    }

    else if(this.productType=='toupup'){
      payload.topup_operator_id = this.operatorObj.id;
      payload.topup_product_id = this.topupProductObj.id;
    }

    else if(this.productType=='balance_card'){
      payload.balance_card_id = this.balanceCardDetail.id;
    }

    else if(this.productType=='gift_card'){
      payload.gift_card_id = this.giftCardDetail.productId;
    }
    this.coreService.post(`${ENDPOINTS.WISHLIST.PRODUCT_LIST}`, payload).subscribe((res) => {
      this.isWishlistFatching = false;
      if (res.body.success) {
        this.wishlistList = res.body.data;
      } else {
        this.wishlistList = [];
      }
    }, (err) => {
      this.isWishlistFatching = false;
    });
  }

  fatchWishlist(){
    if(this.wishlistList.length==0)
    this.getMyWishlist();
  }

  goToWishlistDetail(obj:any) {
    $("#addnewwishlist").modal("hide");
    this.router?.navigate(['/user/wishlist'], { queryParams: {'wishlist':obj.id,'name':obj.wishlist_name}});
  }
  
  addProductInWishlist(obj:any){
    this.isWishlistFatching = true;
    
    let payload:any = {
      'wishlist_id' : obj.id,
      'type' : this.productType,
      'product_name' : this.productName
    }
    if(this.productType=='product'){
      payload.product_id = this.productDetails.id;
    }

    else if(this.productType=='toupup'){
      payload.topup_operator_id = this.operatorObj.id;
      payload.topup_product_id  = this.topupProductObj.id;
      payload.topup_info        = this.operatorObj;
      payload.topup_product_info= this.topupProductObj;
    }

    else if(this.productType=='balance_card'){
      payload.balance_card_id = this.balanceCardDetail.id;
    }

    else if(this.productType=='gift_card'){
      payload.gift_card_id = this.giftCardDetail.productId;
      payload.gift_card_info= this.giftCardDetail;
    }
    
    this.coreService.post(`${ENDPOINTS.WISHLIST.ADD_REMOVE_PRODUCT}`, payload).subscribe((res) => {
      if (res.body.success) {
        this.toast.success(res.body.message, "Success!");
      }else{
        this.toast.error(res.body.message, "Error!");
      }
    }, (err) => {
    });
  }

  /**
   * @method: createNow
   * @description: Using to create User Wishlist
   * @param formData
   */
   createNow(formData:any) {
    this.wishlistFormObj.isFormSubmit = true;
    if (this.wishlistForm.valid) {
      this.isLoading = true;
      const paylaod = {
        ...formData
      }
      paylaod.wishlist_name = paylaod.wishlist_name.trim();
      if(paylaod.wishlist_name==''){
        this.toast.error("Please enter valid name", "Error!");
        return;
      }
      this.coreService.post(`${ENDPOINTS.WISHLIST.CREATE}`, paylaod).subscribe((res) => {
        this.isLoading = false;
        if(res.body.success){
          this.wishlistForm.reset();
          this.wishlistList.push(res.body.data);
          this.wishlistFormObj.isFormSubmit = false;
          this.toast.success(res.body.message, "Success!");
          $("#addnewwishlist").modal("hide");
        }else{
          this.toast.error(res.body.message, "Error!");
          this.wishlistFormObj.isFormSubmit = false;
        }
      }, (err) => {
        this.isLoading = false;
      });
    }
  }
}
