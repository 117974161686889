<app-header [cartItemData]="cartItems"></app-header>
<section class="text-center mb-2 mt-3 pt-4 mt-50">
    <div class="container">
        <div class="row">
            <div class="col-sm-8 col-lg-8">
                <ul class="list-unstyled bradcam cart-process">
                    <li>
                        <a><span class="active">1</span> Cart</a>
                    </li>
                    <li *ngIf="cartItems.length>0 && !loadCartItem">
                        <a (click)="goToNavigation('checkout');"><i class="fas fa-chevron-right iconarrw"></i><span>2</span> Checkout</a>
                    </li>
                    <li *ngIf="cartItems.length>0 && !loadCartItem">
                        <a><i class="fas fa-chevron-right iconarrw"></i><span>3</span> Payment</a>
                    </li>
                </ul>
            </div>
            <div class="col-sm-4 col-lg-4 paddok">
                <div class="right-boxtitle" [hidden]="isFiatProductAdded">
                    <app-crypto-dropdown [inputPrice]="'1'" [displayType]="'dropdown'" (convertedRate)="cryptoPrice=$event"></app-crypto-dropdown>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="mb-5 myrem">
    <div class="container">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                <div *ngIf="(cartItems.length==0 && loadCartItem) || isLoading" id="loader"></div>
                <div class="cart-table-box mt-3">
                    <div class="table-responsive product-table">
                        <table class="table">
                            <thead *ngIf="cartItems.length>0">
                                <h5 class="fontbold">Order details</h5>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of cartItems">
                                    <td>
                                        <img *ngIf="item.cart_item=='product'" (click)="goToProductDetail(item.product_id);" src="{{imgBaseUrl}}products/{{item.product_id.product_image}}" alt="{{item.product_id.product_name}}" class="img-fluid z-depth-0" />
                                        <img *ngIf="item.cart_item=='balance_card'" (click)="goToNavigation('cryptomate-balance-card');" src="{{imgBaseUrl}}giftcard/{{item.balance_card_id.card_logo}}" alt="{{item.balance_card_id.card_name}}" class="img-fluid z-depth-0"/>
                                        <img *ngIf="item.cart_item=='toupup'" (click)="goToTopupDetails(item.topup_operator_id,item.topup_info.country.iso_code);" src="{{imgBaseUrl}}operator/{{item.topup_info.country.iso_code}}/{{item.topup_info.name}}.png" alt="{{item.topup_info.name}}" class="img-fluid z-depth-0" />
                                        <img *ngIf="item.cart_item=='gift_card'" (click)="goToGiftCardDetail(item.gift_card_id);" src="{{item.gift_card_info.imageUrl}}" alt="{{item.gift_card_info.name}}" class="img-fluid z-depth-0"/>
                                        <img *ngIf="item.cart_item=='game_product'" (click)="goToGameDetail(item.game_id);" src="{{item.game_product_info.coverImageOriginal}}" alt="{{item.game_product_info.name}}" class="img-fluid z-depth-0"/>
                                    </td>
                                    <td class="product-n-box">
                                        <div *ngIf="item.cart_item=='product'">
                                            <h6><a (click)="goToProductDetail(item.product_id);" title="{{item.product_id.product_name}}">{{(item.product_name)?item.product_name:item.product_id.product_name}}</a></h6>
                                            <p class="text-muted">{{item.price | currency}}</p>
                                        </div>

                                        <div *ngIf="item.cart_item=='balance_card'">
                                            <h6><a (click)="goToNavigation('cryptomate-balance-card');" title="{{item.balance_card_id.card_name}}">{{(item.product_name)?item.product_name:item.balance_card_id.card_name}}</a></h6>
                                            <p class="text-muted">{{item.price | currency}}</p>
                                        </div>

                                        <div *ngIf="item.cart_item=='toupup'">
                                            <h6><a (click)="goToTopupDetails(item.topup_operator_id,item.topup_info.country.iso_code);" title="{{item.topup_info.name}}">{{item.topup_product_info.name}}</a></h6>
                                            <p class="text-muted" >{{item.mobile_number}} <br>
                                                {{item.topup_product_info.destination.amount | currency:item.topup_product_info.destination.unit}}
                                            </p>
                                        </div>

                                        <div *ngIf="item.cart_item=='gift_card'">
                                            <h6><a (click)="goToGiftCardDetail(item.gift_card_id);" title="{{item.gift_card_info.name}}">{{(item.product_name)?item.product_name:item.gift_card_info.name}}</a></h6>
                                            <p class="text-muted">{{item.denomination | currency:item.gift_card_info.currencyCode}}</p>
                                        </div>

                                        <div *ngIf="item.cart_item=='game_product'">
                                            <h6><a (click)="goToGameDetail(item.game_id);" title="{{item.game_product_info.name}}">{{(item.product_name)?item.product_name:item.game_product_info.name}}</a></h6>
                                            <p class="text-muted">{{item.price | currency}}</p>
                                        </div>
                                    </td>
                                    <td></td>
                                    <td class="pt25" [hidden]="isFiatProductAdded">
                                        <span *ngIf="cryptoPrice.exchange_rate==0">{{cryptoPrice.display}}</span>
                                        <span *ngIf="cryptoPrice.exchange_rate!=0">{{(cryptoPrice.exchange_rate * (item.price*item.quantity)).toFixed(6)}} {{cryptoPrice.currency_code}}</span>
                                    </td>
                                    <td>
                                        <div class="number-input md-number-input">
                                            <button [disabled]="isLoading" (click)="updateCartItem(item,'minus');" class="minus"></button>
                                            <input class="quantity" min="0" name="quantity" value="{{item.quantity}}" [(ngModel)]="item.quantity" type="number" />
                                            <button [disabled]="isLoading" (click)="updateCartItem(item,'plus');" class="plus"></button>
                                        </div>
                                    </td>
                                    <td class="font-weight-bold pt25">
                                        <strong>{{item.price*item.quantity | currency}}</strong>
                                    </td>
                                    <td>
                                        <button [disabled]="isLoading" (click)="deleteItemFromCart(item)" type="button" class="btn btn-sm removebtn-cart">X</button>
                                    </td>
                                </tr>
                                <tr *ngIf="cartItems.length>0">
                                    <td class="text-left foote-cart-table toalesti">
                                        <h4 class="mt-2">
                                            <strong>
                                                Total Estimate 
												<a class="tool" data-tip="Due to price volatility in cryptos, there might be a tiny difference between the estimated price and the actual price you will be expected to pay at the last step of the order process." tabindex="1"><i class="fas fa-info-circle"></i></a>
                                            </strong>
                                        </h4>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td class="text-left foote-cart-table" [hidden]="isFiatProductAdded">
                                        <h4 class="mt-2 pl-3">
                                            <strong *ngIf="cryptoPrice.exchange_rate==0">{{cryptoPrice.display}}</strong>
                                            <strong *ngIf="cryptoPrice.exchange_rate!=0">{{(cryptoPrice.exchange_rate * totalCartValue).toFixed(6)}} {{cryptoPrice.currency_code}}</strong>
                                        </h4>
                                    </td>
                                    <td class="foote-cart-table" colspan="1"></td>
                                    <td colspan="2" class="text-left continu-shopbtn-td">
                                        <h4 class="mt-2">
                                            <strong>{{totalCartValue | currency}}</strong>
                                        </h4>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr *ngIf="cartItems.length==0 && !loadCartItem">
                                    <td colspan="7">
                                        <div class="col-md-12 notfound ">
                                            <h2>Your cart is empty</h2>
                                            <img src="/assets/img/cart-new.svg" />
                                            <a (click)="goToNavigation('shop')">Browse Products</a>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="loadCartItem">
                                    <td colspan="7">
                                        <div class="col-md-12 alert alert-info">
                                            We are fatching products <i class="fas fa-ellipsis-h"></i>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center" *ngIf="cartItems.length>0 && !loadCartItem">
            <button type="button" class="btn continu-shopbtn" (click)="goToNavigation('shop');" [disabled]="isLoading">Continue Shopping</button>
            <span *ngIf="!profileObj.is_document_verified">
                <button *ngIf="((totalCartValue)>limit_amount)" type="button" data-toggle="modal" data-target="#giftpurchae" class="btn continu-shopbtn checkout waves-effect waves-light" [disabled]="isLoading">Proceed to Checkout</button>

                <button *ngIf="((totalCartValue)<=limit_amount)" type="button" (click)="updateCartPrice();" class="btn continu-shopbtn checkout waves-effect waves-light" [disabled]="isLoading">Proceed to Checkout</button>
            </span>
            <span *ngIf="profileObj.is_document_verified">
                <button type="button" (click)="updateCartPrice();" class="btn continu-shopbtn checkout waves-effect waves-light" [disabled]="isLoading">Proceed to Checkout</button>
            </span>
        </div>
    </div>
</section>

<section class="text-center py-3">
    <div class="container">
        <div class="row d-flex align-items-center justify-content-center">
            <div class="col-md-8 col-lg-8">
                <div class="title-heading">
                   <h5><span class="font-28">Pay with your <b class="text-yellow">cryptos.</b></span> More than 60 Crypocoins are accepted here! <br> Bitcoin, Ethereum, XRP, BNB, Litecoin, Doge.</h5>
                </div>
                <div class="logo-icons">
                    <img src="/assets/img/coins.webp" />
                </div>
                <div class="css-1b9jzri--2">
                    <div style="color: var(--text-primary);" class="css-ezg0pp--2">
                        <div>
                            <img src="/assets/img/images_checkmark.svg" width="21" height="21" />
                        </div>
                        -	Blockchain Based Payments.
                    </div>
                    <div style="color: var(--text-primary);" class="css-ezg0pp--2">
                        <div>
                            <img src="/assets/img/images_checkmark.svg" width="21" height="21" />
                        </div>
                        - Easy, Fast and Secure.
                    </div>
                    <div style="color: var(--text-primary);" class="css-ezg0pp--2">
                        <div>
                            <img src="/assets/img/images_checkmark.svg" width="21" height="21" />
                        </div>
                        -	Pay Instantly, Anywhere and Anytime.
                    </div>
                    <div style="color: var(--text-primary);" class="css-ezg0pp--2">
                        <div>
                            <img src="/assets/img/images_checkmark.svg" width="21" height="21" />
                        </div>
                       - Pay with Stable Coins.
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-gift-validation></app-gift-validation>