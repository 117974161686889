<app-headers></app-headers>
<section class="text-center mb-2 settingTabs">
   <app-user-breadcrumb></app-user-breadcrumb>
</section>
<section class="mb-5 profileView">
   <div class="container">
      <div class="row">
         <div class="col-md-5 col-lg-3">
            <app-user-left-menu></app-user-left-menu>
         </div>
         <div class="col-md-7 col-lg-9">
            <div class="rightbox-dashbord mylistpage">
               <div *ngIf="isLoading" id="loader"></div>
               <div class="row">
                  <div class="col-sm-4 col-md-3 col-lg-3">
                     <h4 class="list-heading">My Orders</h4>
                  </div>
               </div>
               <ul class="nav nav-tabs md-tabs nav-justified mobilehide">
                  <li class="nav-item" (click)="showList()">
                     <a class="nav-link active" data-toggle="tab" href="#panel1" role="tab">All Orders</a>
                  </li>
                  <li class="nav-item" (click)="showList()">
                     <a class="nav-link" data-toggle="tab" href="#panel2" role="tab">E-Commerce</a>
                  </li>
                  <li class="nav-item" (click)="showList()">
                     <a class="nav-link" data-toggle="tab" href="#panel3" role="tab">My Gift Card</a>
                  </li>
                  <li class="nav-item" (click)="showList()">
                     <a class="nav-link" data-toggle="tab" href="#panel4" role="tab">My Top-up</a>
                  </li>
                  <li class="nav-item" (click)="showList()">
                     <a class="nav-link" data-toggle="tab" href="#panel6" role="tab">My Games</a>
                  </li>
                  <!-- <li class="nav-item" (click)="showList()">
                     <a class="nav-link" data-toggle="tab" href="#panel5" role="tab">Canceled Orders</a>
                  </li> -->
               </ul>
               <div class="wrap-drop" id="noble-gases">
                  <span>All Orders</span>
                  <ul class="nav nav-tabs md-tabs nav-justified mobis drop">
                     <li class="nav-item" (click)="showList()">
                        <a class="nav-link active" data-toggle="tab" href="#panel1" role="tab">All Orders</a>
                     </li>
                     <li class="nav-item" (click)="showList()">
                        <a class="nav-link" data-toggle="tab" href="#panel2" role="tab">E-Commerce</a>
                     </li>
                     <li class="nav-item" (click)="showList()">
                        <a class="nav-link" data-toggle="tab" href="#panel3" role="tab">My Gift Card</a>
                     </li>
                     <li class="nav-item" (click)="showList()">
                        <a class="nav-link" data-toggle="tab" href="#panel4" role="tab">My Top-up</a>
                     </li>
                     <!-- <li class="nav-item" (click)="showList()">
                        <a class="nav-link" data-toggle="tab" href="#panel5" role="tab">Canceled Orders</a>
                     </li> -->
                     <li class="nav-item" (click)="showList()">
                        <a class="nav-link" data-toggle="tab" href="#panel6" role="tab">My Games</a>
                     </li>
                  </ul>
               </div>
               <div class="tab-content card dashbaord-table">
                  <div class="tab-pane fade in show active" id="panel1" role="tabpanel">
                     <h5 class="fontbold  mb-3 mt-3">
                        <a *ngIf="orderDetail" (click)="showList()" href="javascript:;" class="list-heading" style="margin-top: 0;">Go Back</a> 
                        <span>Your All Orders</span>
                        <a *ngIf="orderDetail && orderDetailObj.payment_status!='pending' && orderDetailObj.payment_status!='canceled'" href="{{invoiceBaseUrl}}/{{orderDetailObj.id}}/user" class="contiunebtn ORDERPRINT waves-effect waves-light ml-2">Print Order</a>
                        <a *ngIf="orderDetail" (click)="showOrderView(orderDetailObj,'order')" class="contiunebtn ORDERPRINT waves-effect waves-light ml-2"><i class="fa fa-refresh"></i> Refresh</a>
                     </h5>
                     <div class="table-responsive text-nowrap" *ngIf="!orderDetail">
                        <table class="table table-striped">
                           <thead>
                              <tr>
                                 <th scope="col">Order ID</th>
                                 <th scope="col">Order date</th>
                                 <th scope="col">Status</th>
                                 <th scope="col">Discount code</th>
                                 <th scope="col">Value<br><small>Including Shipping</small></th>
                                 <th scope="col">Action</th>
                              </tr>
                           </thead>
                           <tbody >
                              <tr *ngFor="let orderRow of orderLists | paginate:{id:'pagination',itemsPerPage: orderSetting.perPage, currentPage:orderSetting.page, totalItems: orderSetting.totalCount}">
                                 <th scope="row">{{orderRow.order_number}}</th>
                                 <td>{{orderRow.createdAt | date:'dd MMM yyyy, hh:mm a':'UTC'}}</td>
                                 <td>{{showOrderStatus(orderRow.order_status)}}</td>
                                 <td>
                                    <span *ngIf="orderRow.discount_code!=''" class="codedisct-table">{{orderRow.discount_code}}</span>
                                    <span *ngIf="orderRow.discount_code==''">--</span>
                                 </td>
                                 <td>{{(orderRow.total_amount+orderRow.dedcut_from_wallet) | currency}}</td>
                                 <td>
                                    <a href="javascript:;" (click)="showOrderView(orderRow,'order')" class="editbtn-table"><i class="far fa-eye"></i></a>
                                 </td>
                              </tr>
                              <tr>
                                 <td colspan="6" *ngIf="!isLoading && orderLists.length==0">Record not found.</td>
                              </tr>
                           </tbody>
                        </table>
                        <pagination-controls id="pagination" (pageChange)="pageChanged($event)"></pagination-controls>
                     </div>
                     <div *ngIf="orderDetail" class="row">
                        <div class="col-md-12 col-lg-12" *ngIf="orderDetailObj.products.toupup.length>0">
                           <div class="checkoutleft-box mt-3">
                              <div *ngFor="let product of orderDetailObj.products.toupup;" class="c-boxlist gift-ordercople">
                                 <div class="row">
                                    <div class="col-4 col-sm-2 col-lg-2">
                                       <img src="{{imgBaseUrl}}operator/{{product.topup_info.country.iso_code}}/{{product.topup_info.name}}.png" alt="{{product.topup_info.name}}" class="img-fluid z-depth-0" />
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-6 pd-0">
                                       <h5>Top-up Delivered</h5>
                                       <p class="mb-0">{{product.topup_product_info.name}}</p>
                                       <p><small>The Top-up delivery has been confirmed by the operator.</small></p>
                                    </div>
                                    <div class="col-12 col-sm-4 col-lg-4 mb-2">
                                       <!-- <h6>0.00012890 BTC</h6> -->
                                       <h6>
                                          {{(product.topup_product_info.destination.amount*product.quantity) | currency:product.topup_product_info.destination.unit}}
                                       </h6>
                                       <span class="idcompple-rder">{{product.mobile_number}}</span>
                                       <span class="pull-right orderpack statusView w-100">
                                          <p class=" text-muted orderpack {{product.delivery_status}}">{{showOrderStatus(product.delivery_status)}}</p>
                                       </span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-12 col-lg-12" *ngIf="orderDetailObj.products.game_products.length>0">
                           <div class="checkoutleft-box mt-3">
                               <div *ngFor="let gameObj of orderDetailObj.products.game_products;" class="c-boxlist gift-ordercople">
                                   <div class="row">
                                       <div class="col-4 col-sm-2 col-lg-2">
                                           <img src="{{gameObj.game_product_info.coverImageOriginal}}" alt="{{gameObj.game_product_info.name}}" class="img-fluid z-depth-0" />
                                       </div>
                                       <div class="col-12 col-sm-8 col-lg-8 pd-0">
                                           <h5>{{gameObj.game_product_info.name}}</h5>
                                           <p class=""><small [innerHtml]="(gameObj.game_product_info.description.length>100)? (gameObj.game_product_info.description | slice:0:100)+'..':(gameObj.game_product_info.description)"></small></p>
                                       </div>
                                       <div class="col-12 col-sm-2 col-lg-2 mb-2">
                                           <h6>{{(gameObj.price*gameObj.quantity) | currency}}</h6>
                                           <span class="pull-right orderpack statusView">
                                               <p class=" text-muted orderpack {{gameObj.delivery_status}}">{{showOrderStatus(gameObj.delivery_status)}}</p>
                                           </span>
                                       </div>
                                   </div>
                               </div>
                           </div>
                        </div>
                        <div class="col-md-12 col-lg-12" *ngIf="orderDetailObj.products.gift_card.length>0">
                           <div class="checkoutleft-box mt-3">
                              <div *ngFor="let product of orderDetailObj.products.gift_card;" class="c-boxlist gift-ordercople">
                                 <div class="row" *ngIf="product.cart_item=='gift_card'">
                                    <div class="col-3 col-sm-2 col-lg-2">
                                       <img src="{{product.gift_card_info.imageUrl}}" alt="" class="img-fluid z-depth-0" />
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-6 pd-0">
                                       <h5>{{product.gift_card_info.name}}</h5>
                                       <p class="text-muted mb-0" [innerHtml]="product.gift_card_info.categories"></p>
                                       <p class=""><small [innerHtml]="(product.gift_card_info.description.length>100)? (product.gift_card_info.description | slice:0:100)+'..':(product.gift_card_info.description)"></small></p>
                                    </div>
                                    <div class="col-12 col-sm-4 col-lg-4">
                                       <!-- <h6>0.00012890 BTC</h6> -->
                                       <span class="idcompple-rder" *ngIf="product.gift_card_info.currencyCode!='USD'">
                                       {{(product.denomination*product.quantity) | currency:product.gift_card_info.currencyCode}}
                                       </span>
                                       <span class="idcompple-rder" *ngIf="product.gift_card_info.currencyCode=='USD'">
                                       {{product.total_price | currency}}
                                       </span>
                                       <a  (click)="goToNavigation('gift-designer');" class="waves-effect waves-light giftcard-comple">Design Gift Card</a>
                                       <span class="pull-right orderpack statusView w-100">
                                          <p *ngIf="!product.xoxo_response_object" class=" text-muted orderpack {{product.delivery_status}}">{{showOrderStatus(product.delivery_status)}}</p>
                                          <p *ngIf="product.xoxo_response_object" class=" text-muted orderpack {{product.xoxo_response_object.deliveryStatus}}">{{showOrderStatus(product.xoxo_response_object.deliveryStatus)}}</p>
                                      </span>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="product.xoxo_voucher_codes">
                                       <div class="box-grey" *ngFor="let voucher of product.xoxo_voucher_codes;">
                                          <span (click)="copyURL(voucher.voucherCode)" style="cursor: pointer;">{{voucher.voucherCode}} <i class="fas fa-copy"></i></span>
                                          <span *ngIf="voucher.pin && voucher.pin!=''" (click)="copyURL(voucher.pin)" style="cursor: pointer;"><br>Pin : {{voucher.pin}} <i class="fas fa-copy"></i></span>
                                          <span *ngIf="voucher.validity!=''" style="cursor: pointer;"><br>Validity : {{voucher.validity}}</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="row" *ngIf="product.cart_item=='balance_card'">
                                    <div class="col-3 col-sm-2 col-lg-2">
                                       <img src="{{imgBaseUrl}}giftcard/{{product.balance_card_id.card_logo}}" alt="" class="img-fluid z-depth-0" />
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-6 pd-0">
                                       <h5>{{product.balance_card_id.card_name}}</h5>
                                       <p class="text-muted mb-0" [innerHtml]="(product.balance_card_id.card_about.length>100)? (product.balance_card_id.card_about | slice:0:100)+'..':(product.balance_card_id.card_about)"></p>
                                       <div *ngIf="product.voucher_record">
                                          <div *ngFor="let voucher of product.voucher_record;">
                                             <span *ngIf="voucher.is_redeemed==0" (click)="copyURL(voucher.voucher_code)" style="cursor: pointer;">{{voucher.voucher_code}} <i class="fas fa-copy"></i></span>
                                             <span *ngIf="voucher.is_redeemed==1">Redeemed</span>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="col-12 col-sm-4 col-lg-4">
                                       <!-- <h6>0.00012890 BTC</h6> -->
                                       <span class="idcompple-rder">{{product.total_price | currency}}</span>
                                       <a  (click)="goToNavigation('gift-designer');" class="waves-effect waves-light giftcard-comple">Design Gift Card</a>
                                       <span class="pull-right orderpack statusView">
                                          <p class=" text-muted orderpack {{product.delivery_status}}">{{showOrderStatus(product.delivery_status)}}</p>
                                      </span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-12 col-lg-12" *ngIf="orderDetailObj.products.ecommerce.length>0">
                           <div class="checkoutleft-box mt-3">
                              <div *ngFor="let product of orderDetailObj.products.ecommerce;" class="c-boxlist">
                                 <div class="row">
                                    <div class="col-3 col-sm-2 col-lg-2">
                                       <img src="{{imgBaseUrl}}products/{{product.product_id.product_image}}" alt="{{(product.product_name)?product.product_name:product.product_id.product_name}}" class="img-fluid z-depth-0" />
                                    </div>
                                    <div class="col-5 col-sm-6 col-lg-6 pd-0">
                                       <h5>{{(product.product_name)?product.product_name:product.product_id.product_name}}</h5>
                                       <p class="text-muted">{{product.total_price | currency}}</p>
                                    </div>
                                    <div class="col-4 col-sm-4 col-lg-4">
                                       <!-- <h6>0.00012890 BTC</h6> -->
                                       <span class="qtyvision">Qty: {{product.quantity}}</span>
                                       <span class="pull-right orderpack statusView w-100">
                                          <p class=" text-muted orderpack {{product.delivery_status}}">{{showOrderStatus(product.delivery_status)}}</p>
                                      </span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6">
                           <div class="checkoutleft-box-1">
                              <div class="c-boxlist">
                                 <div class="row">
                                    <div class="col-7 col-sm-8 col-lg-8">
                                       <h5>Invoice id:</h5>
                                       <p class="text-muted">{{orderDetailObj.order_number}}</p>
                                    </div>
                                    <div class="col-5 col-sm-4 col-lg-4">
                                       <h6>{{orderDetailObj.createdAt | date:'dd MMM yyyy, hh:mm a':'UTC'}}</h6>
                                    </div>
                                 </div>
                                 <hr />
                                 <div class="row">
                                    <div class="col-6 col-sm-6 col-lg-6">
                                       <h5>Order Status</h5>
                                       <p class="text-muted orderpack {{orderDetailObj.order_status}}">{{showOrderStatus(orderDetailObj.order_status)}}</p>
                                    </div>
                                    <div class="col-6 col-sm-6 col-lg-6 text-right">
                                       <h5 class="mydel">Payment Status</h5>
                                       <p class="text-muted orderpack {{orderDetailObj.order_status}}">{{orderDetailObj.payment_status}}</p>
                                    </div>
                                 </div>
                                 <hr *ngIf="orderDetailObj.tracking_detail && orderDetailObj.tracking_detail!=''" />
                                 <div class="row mydelv" *ngIf="orderDetailObj.tracking_detail && orderDetailObj.tracking_detail!=''">
                                    <div class="col-7 col-sm-8 col-lg-8">
                                       <h5>Tracking details:</h5>
                                       <p class="text-muted">{{orderDetailObj.tracking_detail}}</p>
                                    </div>
                                    <div class="col-5 col-sm-4 col-lg-4">
                                       <h5 class="mydel">Delivery date</h5>
                                       <p class="text-muted">
                                          {{orderDetailObj.delivery_days}} business Day <br />
                                          ({{orderDetailObj.delivered_date | date:'dd MMM yyyy, hh:mm a':'UTC'}})
                                       </p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6" *ngIf="orderDetailObj.address!=''">
                           <div class="save-adress">
                              <label class="for-checkbox-tools waves-effect waves-light" for="tool-1">
                                 <span class="default-address">Shipping Address</span>
                                 <h5 class="float-left mt-1 w-100 block fontbold">{{orderDetailObj.address_type}}</h5>
                                 <p class="float-left">
                                    <i class="fas fa-map-marker-alt"></i>
                                    <span class="addsmall">{{orderDetailObj.address}}, {{orderDetailObj.zipcode}}, {{orderDetailObj.city}}, {{orderDetailObj.state}} ({{orderDetailObj.country}})</span>
                                 </p>
                                 <p class="float-left">
                                    <i class="fas fa-phone-square-alt"></i>
                                    <span class="addsmall"> {{orderDetailObj.phone_number}}</span>
                                 </p>
                              </label>
                           </div>
                           <div class="table-responsive text-nowrap mytabless">
                              <table class="table table-striped">
                                 <tbody>
                                    <tr>
                                       <th colspan="5" class="text-right">Sub total</th>
                                       <td class="text-center">{{orderDetailObj.product_price | currency}}</td>
                                    </tr>
                                    <tr>
                                       <th colspan="5" class="text-right">Shipping Price</th>
                                       <td class="text-center">{{orderDetailObj.shipping_amount | currency}}</td>
                                    </tr>
                                    <tr *ngIf="orderDetailObj.discount_amount>0">
                                       <th colspan="5" class="text-right">Discount</th>
                                       <td class="text-center">-{{orderDetailObj.discount_amount | currency}}</td>
                                    </tr>
                                    <tr *ngIf="orderDetailObj.dedcut_from_wallet>0">
                                       <th colspan="5" class="text-right">Dedcut from wallet</th>
                                       <td class="text-center">{{orderDetailObj.dedcut_from_wallet | currency}}</td>
                                    </tr>
                                    <!-- <tr *ngIf="orderDetailObj.dedcut_from_wallet>0">
                                       <th colspan="5" class="text-right">Balance Payable</th>
                                       <td class="text-center" >{{(orderDetailObj.total_amount+orderDetailObj.discount_amount)-orderDetailObj.dedcut_from_wallet | currency}}</td>
                                    </tr> -->
                                    <tr>
                                       <th colspan="5" class="text-right" style="font-weight: bold;">Total</th>
                                       <td class="text-center" style="font-weight: bold;">{{orderDetailObj.total_amount | currency}}</td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                        <div class="col-md-12 col-lg-12 text-center">
                           <div class="box-greyline">
                              <h2 class="fontbold">Help Us get better</h2>
                              <p class="mb-4">Tell us about how you use bitcoin so that we can serve you better in the future!</p>
                              <a href="https://www.trustpilot.com/review/cryptomate.com.cn" target="_blank" class="contiunebtn waves-effect waves-light"> Leave Review</a>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="panel2" role="tabpanel">
                      
                     <h5 class="fontbold  mt-3  mb-3">
                        <a *ngIf="ecommerceDetail" (click)="showList()" href="javascript:;" class="list-heading" style="margin-top: 0;">Go Back</a><span>E-Commerce</span>
                        <a *ngIf="ecommerceDetail && ecommerceDetailObj.delivery_status!='pending' && ecommerceDetailObj.delivery_status!='canceled'" href="{{invoiceBaseUrl}}/{{ecommerceDetailObj.order_id.id}}/user"  class="contiunebtn ORDERPRINT waves-effect waves-light"> Print Order</a>
                        <a *ngIf="ecommerceDetail" (click)="viewProductDetails(ecommerceDetailObj)" class="contiunebtn ORDERPRINT waves-effect waves-light"><i class="fa fa-refresh"></i> Refresh</a>
                     </h5>
                     <div class="table-responsive text-nowrap" *ngIf="!ecommerceDetail">
                        <table class="table table-striped">
                           <thead>
                              <tr>
                                 <th scope="col">Order ID</th>
                                 <th scope="col">Quantity</th>
                                 <th scope="col">Order date</th>
                                 <th scope="col">Status</th>
                                 <th scope="col">Value <br><small>Excluding Shipping</small></th>
                                 <th scope="col">Action</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr *ngFor="let row of orderProduct.ecommerce | paginate:{id:'pagination1',itemsPerPage: ecommerceOrderSetting.perPage, currentPage:ecommerceOrderSetting.page, totalItems: ecommerceOrderSetting.totalCount}">
                                 <th scope="row">{{row.order_number}}</th>
                                 <td>{{row.quantity}} Qty</td>
                                 <td>{{row.createdAt | date:'dd MMM yyyy, hh:mm a':'UTC'}}</td>
                                 <td>{{showOrderStatus(row.delivery_status)}}</td>
                                 <td>{{row.price | currency}}</td>
                                 <td>
                                    <a href="javascript:;" (click)="viewProductDetails(row);" class="editbtn-table"><i class="far fa-eye"></i></a>
                                 </td>
                              </tr>
                              <tr>
                                 <td colspan="6" *ngIf="!isLoading && orderProduct.ecommerce.length==0">Record not found.</td>
                              </tr>
                           </tbody>
                           <pagination-controls id="pagination1" (pageChange)="ecommercePageChanged($event)"></pagination-controls>
                        </table>
                     </div>
                     <div class="ecommerce row" *ngIf="ecommerceDetail">
                        <div class="col-md-12 col-lg-12">
                           <div class="checkoutleft-box mt-3">
                              <div class="c-boxlist cbn">
                                 <div class="row">
                                    <div class="col-3 col-sm-2 col-lg-2">
                                       <img
                                          src="{{imgBaseUrl}}products/{{ecommerceDetailObj.product_id.product_image}}"
                                          alt="{{(ecommerceDetailObj.product_name)?ecommerceDetailObj.product_name:ecommerceDetailObj.product_id.product_name}}"
                                          class="img-fluid z-depth-0"
                                          />
                                    </div>
                                    <div class="col-5 col-sm-6 col-lg-6 pd-0">
                                       <h5>{{ecommerceDetailObj.product_name}}</h5>
                                       <p class="text-muted">{{ecommerceDetailObj.total_price | currency}}</p>
                                    </div>
                                    <div class="col-4 col-sm-4 col-lg-4">
                                       <!-- <h6>0.00012890 BTC</h6> -->
                                       <span class="qtyvision">Qty: {{ecommerceDetailObj.quantity}}</span>
                                    </div>
                                    <div class="col-4 col-sm-12 col-lg-12">
                                       <span class="pull-right orderpack statusView w-100">
                                          <p class=" text-muted orderpack {{ecommerceDetailObj.delivery_status}}">{{showOrderStatus(ecommerceDetailObj.delivery_status)}}</p>
                                       </span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6">
                           <div class="checkoutleft-box-1">
                              <div class="c-boxlist cbn">
                                 <div class="row">
                                    <div class="col-7 col-sm-8 col-lg-8">
                                       <h5>Invoice id:</h5>
                                       <p class="text-muted">{{ecommerceDetailObj.order_number}}</p>
                                    </div>
                                    <div class="col-5 col-sm-4 col-lg-4">
                                       <h6>{{ecommerceDetailObj.createdAt | date:'dd MMM yyyy, hh:mm a':'UTC'}}</h6>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6" *ngIf="orderDetailObj.address!=''">
                           <div class="save-adress">
                              <label class="for-checkbox-tools waves-effect waves-light" for="tool-1">
                                 <span class="default-address">Shipping Address</span>
                                 <h5 class="float-left mt-1 w-100 block">{{ecommerceDetailObj.order_id.address_type}}</h5>
                                 <p class="float-left">
                                    <i class="fas fa-map-marker-alt"></i>
                                    <span class="addsmall">
                                    {{ecommerceDetailObj.order_id.address}}, {{ecommerceDetailObj.order_id.zipcode}}, {{ecommerceDetailObj.order_id.city}}, {{ecommerceDetailObj.order_id.state}}
                                    ({{ecommerceDetailObj.order_id.country}})
                                    </span>
                                 </p>
                                 <p class="float-left">
                                    <i class="fas fa-phone-square-alt"></i>
                                    <span class="addsmall"> {{ecommerceDetailObj.order_id.phone_number}}</span>
                                 </p>
                              </label>
                           </div>
                        </div>
                        <div class="col-md-12 col-lg-12 text-center">
                           <div class="box-greyline">
                              <h2 class="fontbold">Help Us get better</h2>
                              <p class="mb-4">Tell us about how you use bitcoin so that we can serve you better in the future!</p>
                              <a href="https://www.trustpilot.com/review/cryptomate.com.cn" target="_blank" class="contiunebtn waves-effect waves-light"> Leave Review </a>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="panel3" role="tabpanel">
                     <h5 class="fontbold  mb-3 mt-3">
                       <a *ngIf="giftCardDetail" (click)="showList()" href="javascript:;" class="list-heading" style="margin-top: 0;">Go Back</a>
                        <span>My Gift Card</span>
                        <a *ngIf="giftCardDetail && giftCardDetailObj.delivery_status!='pending' && giftCardDetailObj.delivery_status!='canceled'"  href="{{invoiceBaseUrl}}/{{giftCardDetailObj.order_id.id}}/user" class="contiunebtn ORDERPRINT waves-effect waves-light"> Print Order</a>
                        <a *ngIf="giftCardDetail" (click)="viewGiftCardDetail(giftCardDetailObj)" class="contiunebtn ORDERPRINT waves-effect waves-light"><i class="fa fa-refresh"></i> Refresh</a>
                     </h5>
                     <div class="table-responsive text-nowrap" *ngIf="!giftCardDetail">
                        <table class="table table-striped">
                           <thead>
                              <tr>
                                 <th scope="col">Order ID</th>
                                 <th scope="col">Quantity</th>
                                 <th scope="col">Order date</th>
                                 <th scope="col">Status</th>
                                 <th scope="col">Value</th>
                                 <th scope="col">Action</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr *ngFor="let row of orderProduct.gift_card | paginate:{id:'pagination2',itemsPerPage: giftCardOrderSetting.perPage, currentPage:giftCardOrderSetting.page, totalItems: giftCardOrderSetting.totalCount}">
                                 <th scope="row">{{row.order_number}}</th>
                                 <td>{{row.quantity}} Qty</td>
                                 <td>{{row.createdAt | date:'dd MMM yyyy, hh:mm a':'UTC'}}</td>
                                 <td>
                                    <span *ngIf="!row.xoxo_response_object">{{showOrderStatus(row.delivery_status)}}</span>
                                    <span *ngIf="row.xoxo_response_object">{{showOrderStatus(row.xoxo_response_object.deliveryStatus)}}</span>
                                 </td>
                                 <td>{{row.price | currency}}</td>
                                 <td>
                                    <a href="javascript:;" (click)="viewGiftCardDetail(row);" class="editbtn-table"><i class="far fa-eye"></i></a>
                                 </td>
                              </tr>
                              <tr>
                                 <td colspan="6" *ngIf="!isLoading && orderProduct.gift_card.length==0">Record not found.</td>
                              </tr>
                           </tbody>
                        </table>
                        <pagination-controls id="pagination2" (pageChange)="giftCardPageChanged($event)"></pagination-controls>
                     </div>
                     <div class="row giftcarddetails" *ngIf="giftCardDetail">
                        <div class="col-md-12 col-lg-6">
                           <a class="thanksleftimg" *ngIf="giftCardDetailObj.cart_item=='balance_card'">
                           <i class="fa-3x"><img src="{{imgBaseUrl}}giftcard/{{giftCardDetailObj.balance_card_id.card_logo}}" class="img-gift-detail" /></i>
                           </a>
                           <a class="thanksleftimg" *ngIf="giftCardDetailObj.cart_item=='gift_card'">
                           <i class="fa-3x"><img src="{{giftCardDetailObj.gift_card_info.imageUrl}}" class="img-gift-detail" /></i>
                           </a>
                        </div>
                        <div class="col-md-12 col-lg-6">
                           <div class="content-detail">
                              <h2 style="padding-right: 0;"><b>{{giftCardDetailObj.product_name}}</b></h2>
                              <div class="row price-shop">
                                 <div class="col-sm-6 col-md-6 col-lg-6" *ngIf="giftCardDetailObj.cart_item=='balance_card'">
                                    <h3><b>{{giftCardDetailObj.total_price | currency}}</b></h3>
                                    <!-- <p>Price: 0.00012405 BTC</p> -->
                                 </div>
                                 <div class="col-sm-6 col-md-6 col-lg-6" *ngIf="giftCardDetailObj.cart_item=='gift_card'">
                                    <h3 *ngIf="giftCardDetailObj.gift_card_info.currencyCode=='USD'"><b>{{giftCardDetailObj.total_price | currency}}</b></h3>
                                    <h3 *ngIf="giftCardDetailObj.gift_card_info.currencyCode!='USD'"><b>{{(giftCardDetailObj.denomination*giftCardDetailObj.quantity) | currency:giftCardDetailObj.gift_card_info.currencyCode}}</b></h3>
                                    <!-- <p>Price: 0.00012405 BTC</p> -->
                                 </div>
                                 <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="giftCardDetailObj.order_voucher_code && giftCardDetailObj.cart_item=='balance_card'">
                                    <div class="box-grey" *ngFor="let voucher of giftCardDetailObj.order_voucher_code;">
                                       <span *ngIf="voucher.is_redeemed==0" (click)="copyURL(voucher.voucher_code)" style="cursor: pointer;">{{voucher.voucher_code}} <i class="fas fa-copy"></i></span><br>
                                       <span *ngIf="voucher.is_redeemed==1">Redeemed</span>
                                    </div>
                                 </div>
                                 <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="giftCardDetailObj.xoxo_voucher_codes && giftCardDetailObj.cart_item=='gift_card'">
                                    <div class="box-grey" *ngFor="let voucher of giftCardDetailObj.xoxo_voucher_codes;">
                                       <span (click)="copyURL(voucher.voucherCode)" style="cursor: pointer;">{{voucher.voucherCode}} <i class="fas fa-copy"></i></span>
                                       <span *ngIf="voucher.pin && voucher.pin!=''" (click)="copyURL(voucher.pin)" style="cursor: pointer;"><br>Pin : {{voucher.pin}} <i class="fas fa-copy"></i></span>
                                       <span *ngIf="voucher.validity!=''" style="cursor: pointer;"><br>Validity : {{voucher.validity}}</span>
                                    </div>
                                 </div>
                              </div>
                              <p>Use Bitcoin or altcoins to buy and pay for many products that come across in our everyday life. Instant email delivery</p>
                              <div class="row mt-3">
                                 <div class="col-sm-12 col-lg-8 col-7"><a  (click)="goToNavigation('gift-designer');" class="waves-effect waves-light purchase-btn">Design Gift Card</a></div>
                                 <div class="col-sm-12 col-lg-4 col-5">
                                    <span class="pull-right orderpack statusView giftdetail">
                                       <p *ngIf="!giftCardDetailObj.xoxo_response_object" class=" text-muted orderpack {{giftCardDetailObj.delivery_status}}">{{showOrderStatus(giftCardDetailObj.delivery_status)}}</p>
                                       <p *ngIf="giftCardDetailObj.xoxo_response_object" class=" text-muted orderpack {{giftCardDetailObj.xoxo_response_object.deliveryStatus}}">{{showOrderStatus(giftCardDetailObj.xoxo_response_object.deliveryStatus)}}</p>
                                   </span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-12 col-lg-12 text-center">
                           <div class="box-greyline">
                              <h2 class="fontbold">Help Us get better</h2>
                              <p class="mb-4">Tell us about how you use bitcoin so that we can serve you better in the future!</p>
                              <a href="https://www.trustpilot.com/review/cryptomate.com.cn" target="_blank" class="contiunebtn waves-effect waves-light"> Leave Review </a>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="panel4" role="tabpanel">
                     <h5 class="fontbold mb-3 mt-3">
                     <a *ngIf="topupDetail" (click)="showList()" href="javascript:;" class="list-heading" style="margin-top: 0;">Go Back</a>
                        <span>My Top-up</span>
                        <a *ngIf="topupDetail  && topupDetailObj.delivery_status!='pending' && topupDetailObj.delivery_status!='canceled'"  href="{{invoiceBaseUrl}}/{{topupDetailObj.order_id.id}}/user" class="contiunebtn ORDERPRINT waves-effect waves-light"> Print Order</a>
                        <a *ngIf="topupDetail" (click)="viewTopupProductDetails(topupDetailObj)" class="contiunebtn ORDERPRINT waves-effect waves-light"><i class="fa fa-refresh"></i> Refresh</a>
                     </h5>
                     <div class="table-responsive text-nowrap" *ngIf="!topupDetail">
                        <table class="table table-striped">
                           <thead>
                              <tr>
                                 <th scope="col">Order ID</th>
                                 <th scope="col">Quantity</th>
                                 <th scope="col">Order date</th>
                                 <th scope="col">Status</th>
                                 <th scope="col">Value</th>
                                 <th scope="col">Action</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr *ngFor="let row of orderProduct.toupup | paginate:{id:'pagination3',itemsPerPage: toupupOrderSetting.perPage, currentPage:toupupOrderSetting.page, totalItems: toupupOrderSetting.totalCount}">
                                 <th scope="row">{{row.order_number}}</th>
                                 <td>{{row.quantity}} Qty</td>
                                 <td>{{row.createdAt | date:'dd MMM yyyy, hh:mm a':'UTC'}}</td>
                                 <td>{{showOrderStatus(row.delivery_status)}}</td>
                                 <td>{{row.price | currency}}</td>
                                 <td>
                                    <a href="javascript:;" (click)="viewTopupProductDetails(row);" class="editbtn-table"><i class="far fa-eye"></i></a>
                                 </td>
                              </tr>
                              <tr>
                                 <td colspan="6" *ngIf="!isLoading && orderProduct.toupup.length==0">Record not found.</td>
                              </tr>
                           </tbody>
                        </table>
                        <pagination-controls id="pagination3" (pageChange)="topupPageChanged($event)"></pagination-controls>
                     </div>
                     <div class="row topup-details" *ngIf="topupDetail">
                        <div class="col-md-12 col-lg-6">
                           <a href="javascript:;" class="thanksleftimg">
                           <i class="fa-3x">
                           <img src="{{imgBaseUrl}}operator/{{topupDetailObj.topup_info.country.iso_code}}/{{topupDetailObj.topup_info.name}}.png" alt="{{topupDetailObj.topup_info.name.name}}" class="img-gift-detail" />
                           </i>
                           </a>
                        </div>
                        <div class="col-md-12 col-lg-6">
                           <div class="content-detail">
                              <h2 style="padding-right: 0px;"><b>Top-up Delivered</b></h2>
                              <p>
                                 {{topupDetailObj.topup_product_info.name}}
                              </p>
                              <p>The Top-up delivery has been confirmed by the operator.</p>
                              <div class="row price-shop">
                                 <div class="col-sm-12 col-md-12 col-lg-12">
                                    <p class="mb-0"><small>Bill detail</small></p>
                                    <h3 class="mt-0"><b>{{topupDetailObj.product_name}}</b></h3>
                                    <p>
                                       {{(topupDetailObj.topup_product_info.destination.amount*topupDetailObj.quantity) | currency:topupDetailObj.topup_product_info.destination.unit}}
                                    </p>
                                 </div>
                                 <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="box-grey">
                                       Delivered to: {{topupDetailObj.mobile_number}}
                                    </div>
                                 </div>
                                 <div class="col-sm-12">
                                    <span class="pull-right orderpack statusView">
                                       <p class=" text-muted orderpack {{topupDetailObj.delivery_status}}">{{showOrderStatus(topupDetailObj.delivery_status)}}</p>
                                    </span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-12 col-lg-12 text-center">
                           <div class="box-greyline">
                              <h2 class="fontbold">Help Us get better</h2>
                              <p class="mb-4">Tell us about how you use bitcoin so that we can serve you better in the future!</p>
                              <a href="https://www.trustpilot.com/review/cryptomate.com.cn" target="_blank" class="contiunebtn waves-effect waves-light"> Leave Review</a>
                           </div>
                        </div>
                     </div>
                  </div>
                  <!--<div class="tab-pane fade" id="panel5" role="tabpanel">
                     <h5 class="fontbold mb-3 mt-3">
                        <a *ngIf="cancelOrderDetail" (click)="showList()" href="javascript:;" class="list-heading" style="margin-top: 0;">Go Back</a>
                        <span>Canceled Orders</span>
                        <a *ngIf="cancelOrderDetail && orderDetailObj.payment_status!='pending' && orderDetailObj.payment_status!='canceled'"  href="{{invoiceBaseUrl}}/{{orderDetailObj.id}}/user" class="contiunebtn ORDERPRINT waves-effect waves-light"> Print Order</a>
                        <a *ngIf="cancelOrderDetail" (click)="showOrderView(orderDetailObj,'cancel')" class="contiunebtn ORDERPRINT waves-effect waves-light"><i class="fa fa-refresh"></i> Refresh</a>                        
                     </h5>
                     <div *ngIf="!cancelOrderDetail" class="table-responsive text-nowrap">
                        <table class="table table-striped">
                           <thead>
                              <tr>
                                 <th scope="col">Order ID</th>
                                 <th scope="col">Order date</th>
                                 <th scope="col">Status</th>
                                 <th scope="col">Discount code</th>
                                 <th scope="col">Value</th>
                                 <th scope="col">Action</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr *ngFor="let row of cancelledOrderLists | paginate:{id:'pagination4',itemsPerPage: cancelOrderSetting.perPage, currentPage:cancelOrderSetting.page, totalItems: cancelOrderSetting.totalCount}">
                                 <th scope="row">{{row.order_number}}</th>
                                 <td>{{row.createdAt | date:'dd MMM yyyy, hh:mm a':'UTC'}}</td>
                                 <td>{{showOrderStatus(row.order_status)}}</td>
                                 <td>
                                    <span *ngIf="row.discount_code!=''" class="codedisct-table">{{row.discount_code}}</span>
                                    <span *ngIf="row.discount_code==''">--</span>
                                 </td>
                                 <td>{{row.total_amount+row.dedcut_from_wallet | currency}}</td>
                                 <td>
                                    <a href="javascript:;" (click)="showOrderView(row,'cancel')" class="editbtn-table"><i class="far fa-eye"></i></a>
                                 </td>
                              </tr>
                              <tr>
                                 <td colspan="6" *ngIf="!isLoading && cancelledOrderLists.length==0">Record not found.</td>
                              </tr>
                           </tbody>
                        </table>
                        <pagination-controls id="pagination4" (pageChange)="cancelledPageChanged($event)"></pagination-controls>
                     </div>
                     <div *ngIf="cancelOrderDetail" class="row">
                        <div class="col-md-12 col-lg-12" *ngIf="orderDetailObj.products.toupup.length>0">
                           <div class="checkoutleft-box mt-3">
                              <div *ngFor="let product of orderDetailObj.products.toupup;" class="c-boxlist gift-ordercople">
                                 <div class="row">
                                    <div class="col-4 col-sm-2 col-lg-2">
                                       <img src="{{imgBaseUrl}}operator/{{product.topup_info.country.iso_code}}/{{product.topup_info.name}}.png" alt="{{product.topup_info.name}}" class="img-fluid z-depth-0" />
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-6 pd-0">
                                       <h5>Top-up Delivered</h5>
                                       <p class="mb-0">
                                          Thank your order: <span style="color: green;"><b>{{product.id}}</b></span>
                                       </p>
                                       <p class=""><small>The Top-up delivery has been confirmed by the operator.</small></p>
                                    </div>
                                    <div class="col-12 col-sm-4 col-lg-4 mb-2">
                                       <h6>{{(product.product_name)?product.product_name:product.topup_info.name}}</h6>
                                       <span class="idcompple-rder">{{product.mobile_number}}</span>
                                       <span class="pull-right orderpack statusView">
                                          <p class=" text-muted orderpack {{product.delivery_status}}">{{showOrderStatus(product.delivery_status)}}</p>
                                       </span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-12 col-lg-12" *ngIf="orderDetailObj.products.game_products.length>0">
                           <div class="checkoutleft-box mt-3">
                               <div *ngFor="let gameObj of orderDetailObj.products.game_products;" class="c-boxlist gift-ordercople">
                                   <div class="row">
                                       <div class="col-4 col-sm-2 col-lg-2">
                                           <img src="{{gameObj.game_product_info.coverImageOriginal}}" alt="{{gameObj.game_product_info.name}}" class="img-fluid z-depth-0" />
                                       </div>
                                       <div class="col-12 col-sm-8 col-lg-8 pd-0">
                                           <h5>{{gameObj.game_product_info.name}}</h5>
                                           <p class=""><small [innerHtml]="(gameObj.game_product_info.description.length>100)? (gameObj.game_product_info.description | slice:0:100)+'..':(gameObj.game_product_info.description)"></small></p>
                                       </div>
                                       <div class="col-12 col-sm-2 col-lg-2 mb-2">
                                           <h6>{{(gameObj.price*gameObj.quantity) | currency}}</h6>
                                           <span class="pull-right orderpack statusView">
                                               <p class=" text-muted orderpack {{gameObj.delivery_status}}">{{showOrderStatus(gameObj.delivery_status)}}</p>
                                           </span>
                                       </div>
                                   </div>
                               </div>
                           </div>
                        </div>
                        <div class="col-md-12 col-lg-12" *ngIf="orderDetailObj.products.gift_card.length>0">
                           <div class="checkoutleft-box mt-3">
                              <div *ngFor="let product of orderDetailObj.products.gift_card;" class="c-boxlist gift-ordercople">
                                 <div class="row" *ngIf="product.cart_item=='gift_card'">
                                    <div class="col-3 col-sm-2 col-lg-2">
                                       <img src="{{product.gift_card_info.imageUrl}}" alt="" class="img-fluid z-depth-0" />
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-6 pd-0">
                                       <h5>{{product.gift_card_info.name}}</h5>
                                       <p class="text-muted mb-0" [innerHtml]="product.gift_card_info.categories"></p>
                                       <p class=""><small [innerHtml]="(product.gift_card_info.description.length>100)? (product.gift_card_info.description | slice:0:100)+'..':(product.gift_card_info.description)"></small></p>
                                    </div>
                                    <div class="col-12 col-sm-4 col-lg-4">
                                       <span class="idcompple-rder" *ngIf="product.gift_card_info.currencyCode!='USD'">
                                       {{(product.denomination*product.quantity) | currency:product.gift_card_info.currencyCode}}
                                       </span>
                                       <span class="idcompple-rder" *ngIf="product.gift_card_info.currencyCode=='USD'">
                                       {{product.total_price | currency}}
                                       </span>
                                       <a  (click)="goToNavigation('gift-designer');" class="waves-effect waves-light giftcard-comple">Design Gift Card</a>
                                       <span class="pull-right orderpack statusView">
                                          <p *ngIf="!product.xoxo_response_object" class=" text-muted orderpack {{product.delivery_status}}">{{showOrderStatus(product.delivery_status)}}</p>
                                          <p *ngIf="product.xoxo_response_object" class=" text-muted orderpack {{product.xoxo_response_object.deliveryStatus}}">{{showOrderStatus(product.xoxo_response_object.deliveryStatus)}}</p>
                                       </span>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="product.xoxo_voucher_codes">
                                       <div class="box-grey" *ngFor="let voucher of product.xoxo_voucher_codes;">
                                          <span (click)="copyURL(voucher.voucherCode)" style="cursor: pointer;">{{voucher.voucherCode}} <i class="fas fa-copy"></i></span>
                                          <span *ngIf="voucher.pin && voucher.pin!=''" (click)="copyURL(voucher.pin)" style="cursor: pointer;"><br>Pin : {{voucher.pin}} <i class="fas fa-copy"></i></span>
                                          <span *ngIf="voucher.validity!=''" style="cursor: pointer;"><br>Validity : {{voucher.validity}}</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="row" *ngIf="product.cart_item=='balance_card'">
                                    <div class="col-3 col-sm-2 col-lg-2">
                                       <img src="{{imgBaseUrl}}giftcard/{{product.balance_card_id.card_logo}}" alt="" class="img-fluid z-depth-0" />
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-6 pd-0">
                                       <h5>{{product.balance_card_id.card_name}}</h5>
                                       <p class="text-muted mb-0" [innerHtml]="(product.balance_card_id.card_about.length>100)? (product.balance_card_id.card_about | slice:0:100)+'..':(product.balance_card_id.card_about)"></p>
                                       <div *ngIf="product.voucher_record">
                                          <div *ngFor="let voucher of product.voucher_record;">
                                             <span *ngIf="voucher.is_redeemed==0" (click)="copyURL(voucher.voucher_code)" style="cursor: pointer;">{{voucher.voucher_code}} <i class="fas fa-copy"></i></span>
                                             <span *ngIf="voucher.is_redeemed==1">Redeemed</span>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="col-12 col-sm-4 col-lg-4">
                                       <span class="idcompple-rder">{{product.total_price | currency}}</span>
                                       <a  (click)="goToNavigation('gift-designer');" class="waves-effect waves-light giftcard-comple">Design Gift Card</a>
                                       <span class="pull-right orderpack statusView">
                                          <p class=" text-muted orderpack {{product.delivery_status}}">{{showOrderStatus(product.delivery_status)}}</p>
                                       </span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-12 col-lg-12" *ngIf="orderDetailObj.products.ecommerce.length>0">
                           <div class="checkoutleft-box mt-3">
                              <div *ngFor="let product of orderDetailObj.products.ecommerce;" class="c-boxlist">
                                 <div class="row">
                                    <div class="col-3 col-sm-2 col-lg-2">
                                       <img src="{{imgBaseUrl}}products/{{product.product_id.product_image}}" alt="{{(product.product_name)?product.product_name:product.product_id.product_name}}" class="img-fluid z-depth-0" />
                                    </div>
                                    <div class="col-5 col-sm-6 col-lg-6 pd-0">
                                       <h5>{{(product.product_name)?product.product_name:product.product_id.product_name}}</h5>
                                       <p class="text-muted">{{product.total_price | currency}}</p>
                                    </div>
                                    <div class="col-4 col-sm-4 col-lg-4">
                                       <span class="qtyvision">Qty: {{product.quantity}}</span>
                                       <span class="pull-right orderpack " style="width: 100%;text-align: right;">
                                          <p class=" text-muted orderpack {{product.delivery_status}}">{{showOrderStatus(product.delivery_status)}}</p>
                                       </span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6">
                           <div class="checkoutleft-box-1">
                              <div class="c-boxlist">
                                 <div class="row">
                                    <div class="col-7 col-sm-8 col-lg-8">
                                       <h5>Invoice id:</h5>
                                       <p class="text-muted">{{orderDetailObj.order_number}}</p>
                                    </div>
                                    <div class="col-5 col-sm-4 col-lg-4">
                                       <h6>{{orderDetailObj.createdAt | date}}</h6>
                                    </div>
                                 </div>
                                 <hr />
                                 <div class="row">
                                    <div class="col-6 col-sm-6 col-lg-6 orderpack">
                                       <h5>Order Status</h5>
                                       <p class="text-muted orderpack {{orderDetailObj.order_status}}">{{showOrderStatus(orderDetailObj.order_status)}}</p>
                                    </div>
                                    <div class="col-6 col-sm-6 col-lg-6 text-right orderpack">
                                       <h5 class="mydel">Payment Status</h5>
                                       <p class="text-muted orderpack {{orderDetailObj.payment_status}}">{{orderDetailObj.payment_status}}</p>
                                    </div>
                                 </div>
                                 <hr *ngIf="orderDetailObj.tracking_detail && orderDetailObj.tracking_detail!=''" />
                                 <div class="row mydelv" *ngIf="orderDetailObj.tracking_detail && orderDetailObj.tracking_detail!=''">
                                    <div class="col-7 col-sm-8 col-lg-8">
                                       <h5>Tracking details:</h5>
                                       <p class="text-muted">{{orderDetailObj.tracking_detail}}</p>
                                    </div>
                                    <div class="col-5 col-sm-4 col-lg-4">
                                       <h5 class="mydel">Delivery date</h5>
                                       <p class="text-muted">
                                          {{orderDetailObj.delivery_days}} business Day <br />
                                          ({{orderDetailObj.delivered_date | date:'dd MMM yyyy, hh:mm a':'UTC'}})
                                       </p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6" *ngIf="orderDetailObj.address!=''">
                           <div class="save-adress">
                              <label class="for-checkbox-tools waves-effect waves-light" for="tool-1">
                                 <span class="default-address">Shipping Address</span>
                                 <h5 class="float-left mt-1 w-100 block">{{orderDetailObj.address_type}}</h5>
                                 <p class="float-left">
                                    <i class="fas fa-map-marker-alt"></i>
                                    <span class="addsmall">{{orderDetailObj.address}}, {{orderDetailObj.zipcode}}, {{orderDetailObj.city}}, {{orderDetailObj.state}} ({{orderDetailObj.country}})</span>
                                 </p>
                                 <p class="float-left">
                                    <i class="fas fa-phone-square-alt"></i>
                                    <span class="addsmall"> {{orderDetailObj.phone_number}}</span>
                                 </p>
                              </label>
                           </div>
                           <div class="table-responsive text-nowrap mytabless">
                              <table class="table table-striped">
                                 <tbody>
                                    <tr>
                                       <th colspan="5" class="text-right">Sub total</th>
                                       <td class="text-center">{{orderDetailObj.product_price | currency}}</td>
                                    </tr>
                                    <tr>
                                       <th colspan="5" class="text-right">Shipping Price</th>
                                       <td class="text-center">{{orderDetailObj.shipping_amount | currency}}</td>
                                    </tr>
                                    <tr *ngIf="orderDetailObj.discount_amount>0">
                                       <th colspan="5" class="text-right">Discount</th>
                                       <td class="text-center">-{{orderDetailObj.discount_amount | currency}}</td>
                                    </tr>
                                    <tr *ngIf="orderDetailObj.dedcut_from_wallet>0">
                                       <th colspan="5" class="text-right">Dedcut from wallet</th>
                                       <td class="text-center">{{orderDetailObj.dedcut_from_wallet | currency}}</td>
                                    </tr>
                                    <tr *ngIf="orderDetailObj.dedcut_from_wallet>0">
                                       <th colspan="5" class="text-right">Balance Payable</th>
                                       <td class="text-center" >{{(orderDetailObj.total_amount+orderDetailObj.discount_amount)-orderDetailObj.dedcut_from_wallet | currency}}</td>
                                    </tr>
                                    <tr>
                                       <th colspan="5" class="text-right"  style="font-weight: bold;">Total</th>
                                       <td class="text-center" style="font-weight: bold;">{{orderDetailObj.total_amount | currency}}</td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                        <div class="col-md-12 col-lg-12 text-center">
                           <div class="box-greyline">
                              <h2 class="fontbold">Help Us get better</h2>
                              <p class="mb-4">Tell us about how you use bitcoin so that we can serve you better in the future!</p>
                              <a href="https://www.trustpilot.com/review/cryptomate.com.cn" target="_blank" class="contiunebtn waves-effect waves-light"> Leave Review</a>
                           </div>
                        </div>
                     </div>
                  </div>-->
                  
                  <div class="tab-pane fade" id="panel6" role="tabpanel">
                     <h5 class="fontbold mb-3 mt-3">
                        <a *ngIf="gameDetail" (click)="showList()" href="javascript:;" class="list-heading" style="margin-top: 0;">Go Back</a>
                        <span>My Game</span>
                        <a *ngIf="gameDetail  && gameProductDetailObj.delivery_status!='pending' && gameProductDetailObj.delivery_status!='canceled'"  href="{{invoiceBaseUrl}}/{{gameProductDetailObj.order_id.id}}/user" class="contiunebtn ORDERPRINT waves-effect waves-light"> Print Order</a>
                        <a *ngIf="gameDetail" (click)="viewTopupProductDetails(gameProductDetailObj)" class="contiunebtn ORDERPRINT waves-effect waves-light"><i class="fa fa-refresh"></i> Refresh</a>
                     </h5>
                     <div class="table-responsive text-nowrap" *ngIf="!gameDetail">
                        <table class="table table-striped">
                           <thead>
                              <tr>
                                 <th scope="col">Order ID</th>
                                 <th scope="col">Quantity</th>
                                 <th scope="col">Order date</th>
                                 <th scope="col">Status</th>
                                 <th scope="col">Value</th>
                                 <th scope="col">Action</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr *ngFor="let row of orderProduct.game_product | paginate:{id:'pagination6',itemsPerPage: gameOrderSetting.perPage, currentPage:gameOrderSetting.page, totalItems: gameOrderSetting.totalCount}">
                                 <th scope="row">{{row.order_number}}</th>
                                 <td>{{row.quantity}} Qty</td>
                                 <td>{{row.createdAt | date:'dd MMM yyyy, hh:mm a':'UTC'}}</td>
                                 <td>{{showOrderStatus(row.delivery_status)}}</td>
                                 <td>{{row.price | currency}}</td>
                                 <td>
                                    <a href="javascript:;" (click)="viewGamesProductDetails(row);" class="editbtn-table"><i class="far fa-eye"></i></a>
                                 </td>
                              </tr>
                              <tr>
                                 <td colspan="6" *ngIf="!isLoading && orderProduct.game_product.length==0">Record not found.</td>
                              </tr>
                           </tbody>
                        </table>
                        <pagination-controls id="pagination6" (pageChange)="gamePageChanged($event)"></pagination-controls>
                     </div>
                     <div class="row topup-details" *ngIf="gameDetail">
                        <div class="col-md-12 col-lg-6">
                           <a class="thanksleftimg">
                              <i class="fa-3x"><img src="{{gameProductDetailObj.game_product_info.coverImageOriginal}}" alt="{{gameProductDetailObj.game_product_info.name}}" class="img-gift-detail" /></i>
                           </a>
                        </div>
                        <div class="col-md-12 col-lg-6">
                           <div class="content-detail">
                              <h2 style="padding-right: 0;"><b>{{gameProductDetailObj.product_name}}</b></h2>
                              <div class="row price-shop">
                                 <div class="col-sm-6 col-md-6 col-lg-6">
                                    <h3><b>{{(gameProductDetailObj.price*gameProductDetailObj.quantity) | currency}}</b></h3>
                                 </div>
                                 <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="gameProductDetailObj.order_voucher_code">
                                    <div class="box-grey" *ngFor="let voucher of gameProductDetailObj.order_voucher_code;">
                                       <span *ngIf="voucher.is_redeemed==0" (click)="copyURL(voucher.voucher_code)" style="cursor: pointer;">{{voucher.voucher_code}} <i class="fas fa-copy"></i></span><br>
                                       <span *ngIf="voucher.is_redeemed==1">Redeemed</span>
                                    </div>
                                 </div>
                              </div>
                              <p [innerHtml]="gameProductDetailObj.game_product_info.description"></p>
                              <div class="row mt-3">
                                 <div class="col-sm-12 col-lg-8 col-7"><a  (click)="goToNavigation('gift-designer');" class="waves-effect waves-light purchase-btn">Design Gift Card</a></div>
                                 <div class="col-sm-12 col-lg-4 col-5">
                                    <span class="pull-right orderpack statusView giftdetail">
                                       <p *ngIf="!gameProductDetailObj.kinguin_response_object" class=" text-muted orderpack {{gameProductDetailObj.delivery_status}}">{{showOrderStatus(gameProductDetailObj.delivery_status)}}</p>
                                       <p *ngIf="gameProductDetailObj.kinguin_response_object" class=" text-muted orderpack {{gameProductDetailObj.kinguin_response_object.status}}">{{showOrderStatus(gameProductDetailObj.kinguin_response_object.status)}}</p>
                                   </span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-12 col-lg-12 text-center">
                           <div class="box-greyline">
                              <h2 class="fontbold">Help Us get better</h2>
                              <p class="mb-4">Tell us about how you use bitcoin so that we can serve you better in the future!</p>
                              <a href="https://www.trustpilot.com/review/cryptomate.com.cn" target="_blank" class="contiunebtn waves-effect waves-light"> Leave Review </a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>

