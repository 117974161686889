import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderStatus'
})
export class OrderStatusPipe implements PipeTransform {
  
  transform(value: string): string {
    switch(value){
      case 'paid':
        return "Paid";
      case 'processed':
        return "Processed";
      case 'canceled':
        return "Canceled - Refunded";
      case 'cancelled':
        return "Cancelled";
      case 'shipped':
        return "Shipped";
      case 'completed':
        return "Completed";
      case 'on-hold':
        return "On Hold";          
      default:
        return "Pending";
    }
  }

}
