<div *ngIf="isLoading" id="loader"></div>
<!-------============= LOGIN MODEL =================----------->
<div class="modal fade" id="login" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body model-pad0 mb-0 text-center">
                <div class="wrap-login100">
                    <div class="login100-pic logins">
                        <div class="signupp">
                            <img src="/assets/img/CPM-logo1.png" alt="IMG" />
                            <h1>Log In with Social media</h1>
                            <a  (click)="signInWithGoogle()">
                                <div class="google-btn">
                                    <div class="google-icon-wrapper">
                                        <img class="google-icon" src="/assets/img/gplus.png" />
                                    </div>
                                    <p class="btn-text">Log In with Google</p>
                                </div>
                            </a>
                            <a class="btn-fb" (click)="signInWithFB();">
                                <div class="fb-content">
                                    <div class="logo">
                                        <img src="/assets/img/fb.png" alt="" class="google-icon" />
                                    </div>
                                    <p class="btn-text">Log In with Facebook</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    
                    <form class="login100-form" [formGroup]="loginForm">
                        <button type="button" class="btn-close float-right" data-dismiss="modal" aria-label="Close"></button>
                        <h3 class="text-left mb-3"><b>LOGIN</b></h3>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="md-form">
                                    <span class="iconform"><i class="far fa-envelope"></i></span>
                                    <input class="form-control" id="e-mail" type="email" formControlName="email" name="email" required="required" [ngClass]="{ 'is-invalid': loginObj.isFormSubmit &&  loginget?.email?.errors }"/>
                                    <label for="e-mail">Email</label>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="md-form">
                                    <span class="iconform"><i class="fas fa-lock"></i></span>
                                    <input class="form-control" id="paswod" type="password" formControlName="password" name="password" required="required"  [ngClass]="{ 'is-invalid': loginObj.isFormSubmit &&  loginget?.password?.errors }"/>
                                    <label for="paswod">Password</label>
                                    <span class="eyeview show_paswod" (click)="showPassword('paswod')"><i class="fas fa-eye"></i></span>
                                    <span class="eyeview hide hide_paswod" (click)="hidePassword('paswod')"><i class="fas fa-eye-slash"></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-5 text-left">
                                <a href="javascript:;" data-toggle="modal" data-dismiss="modal" data-target="#forgotpassword" class="f-got"><i>Forgot Password?</i></a>
                            </div>
                            <div class="col-md-7 text-right">
                                <button type="submit" class="joinnow-btn waves-effect waves-light" (click)="loginNow(loginForm.value, false)"><i class="fas fa-sign-in-alt"></i> LOG IN</button>
                            </div>
                            <hr class="lgn-line" />
                            <div class="col-sm-12">
                                <p>
                                    I don’t have an account?
                                    <a (click)="clearSignupForm();" data-toggle="modal" data-dismiss="modal" data-target="#signup" class="sn-btn">SignUp</a>
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<!-------============= Signup MODEL =================----------->
<div class="modal fade" id="signup" tabindex="-1">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body model-pad0 mb-0 text-center">
                <div class="wrap-login100">
                    <div class="login100-pic signss ">
                        <div class="signupp">
                        <img src="/assets/img/CPM-logo1.png" alt="IMG" />
                        <h1>Sign Up with Social media</h1>
                        <a  (click)="signInWithGoogle()">
                            <div class="google-btn">
                                <div class="google-icon-wrapper">
                                    <img class="google-icon" src="/assets/img/gplus.png" />
                                </div>
                                <p class="btn-text">Sign Up with Google</p>
                            </div>
                        </a>
                        <a class="btn-fb"  (click)="signInWithFB();">
                            <div class="fb-content">
                                <div class="logo">
                                    <img src="/assets/img/fb.png" alt="" class="google-icon" />
                                </div>
                                <p class="btn-text">Sign Up with Facebook</p>
                            </div>
                        </a>
                    </div>
                    </div>
                    <form class="login100-form" [formGroup]="registerForm">
                        <input type="hidden" id="parent_id" name="parent_id" value="">
                        <button type="button" class="btn-close float-right" data-dismiss="modal" aria-label="Close"></button>
                        <h3 class="text-left mb-3"><b>SIGN UP</b></h3>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="md-form">
                                    <span class="iconform"><i class="far fa-user"></i></span>
                                    <input class="form-control" type="text" id="n-name" name="full_name" required="required"  formControlName="full_name" [ngClass]="{ 'is-invalid': isFormSubmit && register?.full_name?.errors }"/>
                                    <label for="n-name">Name</label>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="md-form">
                                    <span class="iconform"><i class="far fa-envelope"></i></span>
                                    <input class="form-control" type="email"  id="e-mail" name="email" required="required"  formControlName="email" [ngClass]="{ 'is-invalid': isFormSubmit &&  register?.email?.errors }"/>
                                    <label for="e-mail">Email</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="md-form">
                                    <span class="iconform"><i class="fas fa-lock"></i></span>
                                    <input class="form-control" type="password" id="passwod" name="password" required="required"  formControlName="password" [ngClass]="{ 'is-invalid': isFormSubmit &&  register?.password?.errors }"/>
                                    <label for="passwod">Password</label>
                                    <span class="eyeview show_passwod" (click)="showPassword('passwod')"><i class="fas fa-eye"></i></span>
                                    <span class="eyeview hide hide_passwod" (click)="hidePassword('passwod')"><i class="fas fa-eye-slash"></i></span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="md-form">
                                    <span class="iconform"><i class="fas fa-lock"></i></span>
                                    <input class="form-control" type="password" id="c-pass" name="cfpassword" required="required"  formControlName="cfpassword" [ngClass]="{ 'is-invalid': isFormSubmit &&  register?.cfpassword?.errors }"/>
                                    <label for="c-pass">Confirm Password</label>
                                    <span class="eyeview show_c-pass" (click)="showPassword('c-pass')"><i class="fas fa-eye"></i></span>
                                    <span class="eyeview hide hide_c-pass" (click)="hidePassword('c-pass')"><i class="fas fa-eye-slash"></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="checkbox coloured">
                                    <label>
                                        <input type="checkbox" formControlName="isTerms" checked/>
                                        <span class="checkbox-material">
                                            <span class="check"></span>
                                        </span>
                                        I Accept <a (click)="goToNavigation('terms');" target="_blank" style="font-weight: 600; color: #333;"><u>Terms</u></a> & <a target="_blank" (click)="goToNavigation('privacy-policy');" style="font-weight: 600; color: #333;"><u>Privacy Policy</u></a>
                                    </label>
                                </div>
                            </div>
                            <hr class="lgn-line" />
                            <div class="col-md-6 text-left">
                                <p class="f-got">I have an account? <a  (click)="clearLoginForm();" data-toggle="modal" data-dismiss="modal" data-target="#login" class="sn-btn">Log In</a></p>
                            </div>
                            <div class="col-md-6 text-right">
                                <button type="submit" class="joinnow-btn waves-effect waves-light" (click)="validateEmail(registerForm.value)"><i class="fas fa-sign-in-alt"></i> SIGN UP</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>