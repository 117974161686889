import { Component, Input,Output, OnChanges, OnInit,EventEmitter } from '@angular/core';
import { CoreService } from '../shared/services/core.service';
import { HelperService } from '../shared/services/helper-service.service';
import { TOPUP_COUNTRY } from 'src/app/shared/constants/app.topup-country.constants';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { AuthService } from '../shared/services/auth.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-topup',
  templateUrl: './topup.component.html',
  styleUrls: ['./topup.component.css']
})

export class TopupComponent implements OnInit {
  public imgBaseUrl = environment.AWSImageURL;
  public lang:any = '';
  public operators:any = [];
  public country:string = '';
  public countryLists:any = TOPUP_COUNTRY;
  public isLoggedIn :boolean = false;
  public isLoading:boolean = false;
  public topupObj:any = {};
  public mobile_number:string = '';
  public errorMsg:string = '';
  public country_iso_code:string = '';
  public myTotalWalletBalance:any = 0.00;
  public profileObj:any = '';
  public imageSrc:any = '';
  public cartItems:any = '';
  @Input() public cartItemData:any = [];
  constructor(
    private coreService: CoreService,
    private _auth : AuthService,
    private helperService: HelperService
  ) { }

  ngOnInit(): void {
    if(this._auth.loggedIn()){
      this.isLoggedIn = true;
    }else{
      this.isLoggedIn = false;
    }
	
	  $(window).scroll(function(){
      if ($(window).scrollTop() >= 130) {
        $('nav').addClass('fixed-header');
        $('nav div').addClass('visible-title');
      }
      else {
        $('nav').removeClass('fixed-header');
        $('nav div').removeClass('visible-title');
      }
    });
	
    let mobile_number:any = localStorage.getItem('mobile_number') != null ? localStorage.getItem('mobile_number') : '';
    this.mobile_number = mobile_number;

    let topupObj:any = localStorage.getItem('topupObj') != null ? localStorage.getItem('topupObj') : '{"country":{"iso_code":"RUS"}}';
    this.topupObj = JSON.parse(topupObj);


    let countryIsoCode:any = localStorage.getItem('country_iso_code') != null ? localStorage.getItem('country_iso_code') : '';
    this.country_iso_code = countryIsoCode;

    let countryObj:any = localStorage.getItem('country_code') != null ? localStorage.getItem('country_code') : '';
    this.country = countryObj;

    let operators:any = localStorage.getItem('operatorsList') != null ? localStorage.getItem('operatorsList') : JSON.stringify(this.operators);
    this.operators = JSON.parse(operators);

    
    if(this.operators.length==0){
      this.getOperatorLists()
    }
      
  }

  getOperatorLists(){
    if(this.country==''){
      this.coreService.getCurrentLocation().subscribe((res) => {
        let country = res.body.country_name;
        localStorage.setItem('country_code', country);
        let object = {
          "name" : country
        };
        var results = this.findByMatchingProperties(this.countryLists, object);
        if(results.length>0){
          let planObj = results[0];
          this.country = planObj.iso_code;
          localStorage.setItem('country_iso_code', planObj.iso_code);
        }		
        this.doSearchNext();
        this.saveCountryCode();
      }, (err) => {
        this.doSearchNext();
      });
    }else{
      this.doSearchNext();
    }
	
  }

  doSearchNext(){
    this.isLoading = true;
    localStorage.setItem('country_code', this.country);
    this.coreService.get(`${ENDPOINTS.DTONE.OPERATORS}`+'?country_iso_code='+this.country, {}).subscribe((res) => {
      if (res.body.success) {
        this.operators = res.body.data;
        localStorage.setItem('operatorsList', JSON.stringify(this.operators));
        this.saveCountryCode();
      } else {
        this.operators = [];
      }
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
    });
  }



  ngOnChanges(){
    this.cartItems = this.cartItemData;
  }
  cartItemLists(){
    if(this.isLoggedIn ){
      this.coreService.get(`${ENDPOINTS.CART.LIST}`, {}).subscribe((res) => {
        this.cartItems = res.body.data;
      }, (err) => {
      });
    }
  }

  getWalletTotal(){
    if(this.isLoggedIn ){
      this.coreService.post(`${ENDPOINTS.USER_PROFILE.TOTAL_WALLET}`, {}).subscribe((res) => {
        if (res.body.success) {
          this.myTotalWalletBalance = parseFloat(res.body.data.total_wallet_balance);
        } else {
          this.myTotalWalletBalance = 0.00;
        }
      }, (err) => {
      });
    }
  }

  logOut() {
    this.coreService.get(`${ENDPOINTS.AUTH.LOGOUT}`, {}).subscribe((res) => {
      this.cbLogout();
    }, (err) => {
      this.cbLogout();
    });
  }

  cbLogout() {
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('checkoutInfo');
    this.helperService.changeUserProfile(null);
    this.helperService.setLoggedUserInfo(false) ;
    this.helperService.navigate('/home', this.lang);
    window.location.reload();
  }

  saveCountryCode(){
    let object = {
      "iso_code" : this.country
    };
    var results = this.findByMatchingProperties(this.countryLists, object);
    if(results.length>0){
      let planObj = results[0];
      localStorage.setItem('country_iso_code', planObj.iso_code);
    }
  }

  doLookupMobile(){
    if(this.mobile_number!='')
    {
      this.isLoading = true;
      let object = {
        "iso_code" : this.country
      };
      var results = this.findByMatchingProperties(this.countryLists, object);
      let mobile_number = this.mobile_number;
      if(results.length>0){
        let planObj = results[0];
        mobile_number = "+"+planObj.countrycode + this.mobile_number;
        localStorage.setItem('country_iso_code',planObj.countrycode);
      }
      localStorage.setItem('mobile_number', this.mobile_number);
      this.coreService.post(`${ENDPOINTS.DTONE.LOOKUP_MOBILE}`, {'mobile':mobile_number}).subscribe((res) => {
        if (res.body.success){
          if(res.body.data.errors && res.body.data.errors.length){
            this.isLoading = false;
            this.errorMsg = res.body.data.errors[0].message;
            this.operators = [];
          }else{
            this.operators = res.body.data;
            for (var j = 0; j <this.operators.length; j++) {
              if(this.operators[j].identified){
                this.goToOperatorDetailsPage(this.operators[j]);
              }
            } 
            localStorage.setItem('operatorsList', JSON.stringify(this.operators));
            this.isLoading = false;
          }
        }else{
          this.isLoading = false;
          this.operators = [];
        }
		
		
      }, (err) => {
        this.isLoading = false;
      });
    }
  }

  public numbersOnlyValidator(event: any) {
    const pattern = /^[0-9\-]*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9\-]/g, "");
      this.mobile_number = event.target.value;
    }
  }

  onKeyDownEvent(event: any){
    if(event.key=='Enter'){
      this.isLoading = true;
      this.doLookupMobile();
    }
  }

  findByMatchingProperties(set:any, properties:any) {
    return set.filter(function (entry:any) {
        return Object.keys(properties).every(function (key) {
            return entry[key] === properties[key];
        });
    });
  }

  goToOperatorDetailsPage(obj:any){
    localStorage.setItem('topupObj', JSON.stringify(obj));
    this.helperService.navigate(`/topup-detail/${this.country}/${obj.id}`, this.lang);
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang)
  }
}
