import {
  Component,
  Input,
  Output,
  OnChanges,
  OnInit,
  EventEmitter,
} from '@angular/core';
import { HelperService } from '../shared/services/helper-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from '../shared/services/core.service';
import { Settings } from '../shared/constants/app.setting.constants';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { VOUCHER_CATEGORY } from 'src/app/shared/constants/app.voucher-category.constants';
import { VOUCHER_COUNTRY } from 'src/app/shared/constants/app.voucher-country.constants';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../shared/services/auth.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-gift',
  templateUrl: './gift.component.html',
  styleUrls: ['./gift.component.css'],
})
export class GiftComponent implements OnInit {
  public imgBaseUrl = environment.AWSImageURL;
  public voucherCategory: any = VOUCHER_CATEGORY;
  public voucherCountry: any = VOUCHER_COUNTRY;
  public prevValue: any = '';
  public selectedCategoryList: any = [];
  public isLoggedIn: boolean = false;
  public addingToCart: boolean = false;
  public profileObj: any = '';
  public imageSrc: any = '';
  public cartItems: any = '';
  public myTotalWalletBalance: any = 0.0;
  @Input() public cartItemData: any = [];
  public giftObj: any = {
    list: [],
    list_id: [],
    loadStatus: false,
    pagesToDraw: [],
    filterSettings: {
      currency_type: 'crypto',
      product_name: '',
      country: '',
      order_by: 'name',
      order_type: 'ASC',
      category_id: [],
    },
  };
  public quantityLists: any = [];
  public exchangeRates: any = 0;
  public collection: any = '';
  public queryParam: any = '';
  public preSelected: any = [];
  public paginationSetting: any = {
    page: 1,
    perPage: Settings.perPage,
    id: 'gifts',
    count: 0,
  };
  public limit_amount = environment.DAILY_PURCHASE_LIMIT;
  public lang: any = '';
  constructor(
    private toast: ToastrService,
    private coreService: CoreService,
    private route: ActivatedRoute,
    private helperService: HelperService,
    private _auth: AuthService
  ) {
    if (this._auth.loggedIn()) {
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }
    this.route.queryParams.subscribe((params) => {
      if (!params) return;
      this.queryParam = { ...params };
      if (this.queryParam.hasOwnProperty('collection')) {
        this.collection = this.queryParam.collection;
        this.giftObj.filterSettings.category_id = [];
        this.selectedCategoryList = [];
        localStorage.setItem(
          'giftFilters',
          JSON.stringify(this.giftObj.filterSettings)
        );
        localStorage.removeItem('voucherLists');
        localStorage.removeItem('voucherListIds');
        delete this.queryParam.collection;
      }
      if (this.queryParam.hasOwnProperty('search')) {
        let filter: any =
          localStorage.getItem('giftFilters') != null &&
          localStorage.getItem('giftFilters') != 'undefined'
            ? localStorage.getItem('giftFilters')
            : JSON.stringify(this.giftObj.filterSettings);
        this.giftObj.filterSettings = JSON.parse(filter);
        this.giftObj.filterSettings.product_name = this.queryParam.search;
        localStorage.setItem(
          'giftFilters',
          JSON.stringify(this.giftObj.filterSettings)
        );
        localStorage.removeItem('voucherLists');
        localStorage.removeItem('voucherListIds');
        delete this.queryParam.search;
      }
    });
  }

  ngOnInit(): void {
    let profileObj: any =
      localStorage.getItem('currentUser') != null
        ? localStorage.getItem('currentUser')
        : '{"name":""}';
    this.profileObj = JSON.parse(profileObj);

    if (this.giftObj.filterSettings.product_name == '') {
      let filter: any =
        localStorage.getItem('giftFilters') != null &&
        localStorage.getItem('giftFilters') != 'undefined'
          ? localStorage.getItem('giftFilters')
          : JSON.stringify(this.giftObj.filterSettings);
      this.giftObj.filterSettings = JSON.parse(filter);
      if (this.giftObj.filterSettings.product_name != '') {
        let that = this;
        this.giftObj.list = [];
        this.giftObj.list_id = [];
        setTimeout(function () {
          that.findVouchers();
        }, 100);
      }
      this.giftObj.filterSettings.product_name = '';
    }

    if (this.collection != '') {
      if (this.collection == 'entertainment') {
        this.preSelected = [
          'deals_subscriptions',
          'fashion_lifestyle',
          'home_living',
          'in_house_benefits',
          'mobile_recharge',
          'reading_learning',
          'subscriptions_software',
          'travel_entertainment',
          'wellness_sports',
        ];
      }
      if (this.collection == 'food') {
        this.preSelected = ['food_restaurant', 'grocery'];
      }
      if (this.collection == 'ecommerce') {
        this.preSelected = [
          'automobiles_accessories',
          'cash_prepaid_card',
          'electronics',
          'merchandise',
        ];
      }
    } else {
      this.preSelected = [];
    }

    for (var i = 0; i < this.voucherCategory.length; i++) {
      if (
        this.giftObj.filterSettings.category_id.includes(
          this.voucherCategory[i].filterValueCode
        ) ||
        this.preSelected.includes(this.voucherCategory[i].filterValueCode)
      ) {
        this.voucherCategory[i].isSelected = 1;
        this.selectedCategoryList.push(this.voucherCategory[i]);
        if (
          !this.giftObj.filterSettings.category_id.includes(
            this.voucherCategory[i].filterValueCode
          )
        ) {
          this.giftObj.filterSettings.category_id.push(
            this.voucherCategory[i].filterValueCode
          );
        }
      } else {
        this.voucherCategory[i].isSelected = 0;
      }
    }

    let vouchers: any =
      localStorage.getItem('voucherLists') != null &&
      localStorage.getItem('voucherLists') != undefined
        ? localStorage.getItem('voucherLists')
        : JSON.stringify(this.giftObj.list);
    if (vouchers != undefined && vouchers != 'undefined') {
      this.giftObj.list = JSON.parse(vouchers);
    }

    let voucherListsIds: any =
      localStorage.getItem('voucherListIds') != null
        ? localStorage.getItem('voucherListIds')
        : JSON.stringify(this.giftObj.list_id);
    this.giftObj.list_id = JSON.parse(voucherListsIds);
    if (
      this.giftObj.list.length == 0 ||
      this.giftObj.filterSettings.product_name != ''
    ) {
      this.findVouchers();
    }

    if (this.preSelected.length > 0) {
      let that = this;
      this.giftObj.list = [];
      this.giftObj.list_id = [];
      setTimeout(function () {
        that.findVouchers();
      }, 100);
    }

    $(window).scroll(function () {
      if ($(window).scrollTop() >= 130) {
        $('nav').addClass('fixed-header');
        $('nav div').addClass('visible-title');
      } else {
        $('nav').removeClass('fixed-header');
        $('nav div').removeClass('visible-title');
      }
    });
    for (var i = 1; i < 21; i++) {
      this.quantityLists.push(i);
    }
  }

  ngOnChanges() {
    this.cartItems = this.cartItemData;
  }
  cartItemLists() {
    if (this.isLoggedIn) {
      this.coreService.get(`${ENDPOINTS.CART.LIST}`, {}).subscribe(
        (res) => {
          this.cartItems = res.body.data;
        },
        (err) => {}
      );
    }
  }

  getWalletTotal() {
    if (this.isLoggedIn) {
      this.coreService
        .post(`${ENDPOINTS.USER_PROFILE.TOTAL_WALLET}`, {})
        .subscribe(
          (res) => {
            if (res.body.success) {
              this.myTotalWalletBalance = parseFloat(
                res.body.data.total_wallet_balance
              );
            } else {
              this.myTotalWalletBalance = 0.0;
            }
          },
          (err) => {}
        );
    }
  }

  logOut() {
    this.coreService.get(`${ENDPOINTS.AUTH.LOGOUT}`, {}).subscribe(
      (res) => {
        this.cbLogout();
      },
      (err) => {
        this.cbLogout();
      }
    );
  }

  cbLogout() {
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('checkoutInfo');
    this.helperService.changeUserProfile(null);
    this.helperService.setLoggedUserInfo(false);
    this.helperService.navigate('/home', this.lang);
    window.location.reload();
  }

  doSelectCategory(obj: any, id: any) {
    this.giftObj.filterSettings.category_id = [];
    this.giftObj.filterSettings.product_name = '';
    this.selectedCategoryList = [];
    for (let i = 0; i < this.voucherCategory.length; i++) {
      if (
        $('#' + id + this.voucherCategory[i].filterValueCode).is(':checked')
      ) {
        this.giftObj.filterSettings.category_id.push(
          this.voucherCategory[i].filterValueCode
        );
        this.selectedCategoryList.push(this.voucherCategory[i]);
      }
    }
    localStorage.setItem(
      'giftFilters',
      JSON.stringify(this.giftObj.filterSettings)
    );
    this.filterSelectSubCat();
  }

  saveValue() {
    this.prevValue = this.giftObj.filterSettings.product_name;
  }

  processChange() {
    if (this.giftObj.filterSettings.product_name !== this.prevValue) {
      this.filterSelectSubCat();
    }
  }

  clearAllFilter() {
    this.giftObj.filterSettings.category_id = [];
    this.selectedCategoryList = [];
    for (let i = 0; i < this.voucherCategory.length; i++) {
      $('#categorys_' + this.voucherCategory[i].filterValueCode).prop(
        'checked',
        false
      );
      $('#category_' + this.voucherCategory[i].filterValueCode).prop(
        'checked',
        false
      );
    }
    localStorage.setItem(
      'giftFilters',
      JSON.stringify(this.giftObj.filterSettings)
    );
    this.filterSelectSubCat();
  }

  remveFromList(obj: any) {
    // this.giftObj.filterSettings.category_id = [];
    var index = this.selectedCategoryList.indexOf(obj);
    this.selectedCategoryList.splice(index, 1);
    var index2 = this.giftObj.filterSettings.category_id.indexOf(
      obj.filterValueCode
    );
    this.giftObj.filterSettings.category_id.splice(index2, 1);
    localStorage.setItem(
      'giftFilters',
      JSON.stringify(this.giftObj.filterSettings)
    );
    $('#categorys_' + obj.filterValueCode).prop('checked', false);
    $('#category_' + obj.filterValueCode).prop('checked', false);
    this.filterSelectSubCat();
  }

  // When scroll down the screen
  onScroll() {
    if (!this.giftObj.loadStatus) {
      this.paginationSetting.page = this.paginationSetting.page + 1;
      this.findVouchers();
    }
  }

  filterSelectSubCat() {
    this.paginationSetting.page = 1;
    this.giftObj.list = [];
    this.giftObj.list_id = [];
    let that = this;
    this.giftObj.filterSettings.product_name = '';
    localStorage.setItem(
      'giftFilters',
      JSON.stringify(this.giftObj.filterSettings)
    );
    setTimeout(function () {
      that.findVouchers();
    }, 50);
  }

  filterBasedOnCurrency(str: string) {
    this.giftObj.filterSettings.currency_type = str;
    this.paginationSetting.page = 1;
    this.giftObj.list = [];
    this.giftObj.list_id = [];
    let that = this;
    this.giftObj.loadStatus = false;
    localStorage.setItem(
      'giftFilters',
      JSON.stringify(this.giftObj.filterSettings)
    );
    setTimeout(function () {
      that.findVouchers();
    }, 50);
  }

  findVouchers() {
    if (this.giftObj.filterSettings.country == '') {
      this.coreService.getCurrentLocation().subscribe(
        (res) => {
          this.giftObj.filterSettings.country =
            res.body.country_name.toLowerCase();
          localStorage.setItem(
            'giftFilters',
            JSON.stringify(this.giftObj.filterSettings)
          );
          this.doSearchNext();
        },
        (err) => {
          this.doSearchNext();
        }
      );
    } else {
      this.doSearchNext();
    }
  }

  doSearchNext() {
    if (!this.giftObj.loadStatus) {
      this.giftObj.loadStatus = true;
      const payload = {
        ...this.giftObj.filterSettings,
      };
      this.coreService
        .post(
          `${ENDPOINTS.GIFT_XOXO.VOUCHERS}${this.paginationSetting.page}/${this.paginationSetting.perPage}`,
          payload
        )
        .subscribe(
          (res) => {
            if (res.body.success) {
              this.paginationSetting.count = 2500;
              if (res.body.data.getVouchers != undefined) {
                res.body.data.getVouchers.data.forEach((item: any) => {
                  item.isImageLoading = true;
                  item.allow_only_fiat =
                    this.giftObj.filterSettings.currency_type == 'fiat' ? 1 : 0;
                  item.quantity = 1;
                  let denominations = item.valueDenominations.split(',');
                  item.denominations = denominations;
                  if (item.denominations.length > 0) {
                    item.price = item.denominations[0];
                  }
                  if (this.giftObj.list_id.indexOf(item.productId) == -1) {
                    this.giftObj.list.push(item);
                    this.giftObj.list_id.push(item.productId);
                  }
                });
                let that = this;
                setTimeout(function () {
                  localStorage.setItem(
                    'voucherLists',
                    JSON.stringify(that.giftObj.list)
                  );
                  localStorage.setItem(
                    'voucherListIds',
                    JSON.stringify(that.giftObj.list_id)
                  );
                }, 1000);
                this.giftObj.loadStatus = false;
              } else {
                this.giftObj.loadStatus = false;
              }
            } else {
              this.giftObj.list = [];
              this.giftObj.loadStatus = false;
            }
          },
          (err) => {
            this.giftObj.loadStatus = false;
          }
        );
    }
  }

  hideLoader(obj: any) {
    obj.isImageLoading = false;
  }

  goToNavigation(url: any) {
    this.helperService.navigate(url, this.lang);
  }

  goTOPage(obj: any) {
    localStorage.setItem('giftObj', JSON.stringify(obj));
    localStorage.setItem(
      'giftFilters',
      JSON.stringify(this.giftObj.filterSettings)
    );
    this.helperService.navigate(`/gift-detail/${obj.productId}`, this.lang);
  }

  findByMatchingProperties(set: any, properties: any) {
    return set.filter(function (entry: any) {
      return Object.keys(properties).every(function (key) {
        return entry[key] === properties[key];
      });
    });
  }

  /**
   * @method: addToCart
   * @description: Using to add item in the cart
   * @param productObj
   */
  addToCart(obj: any) {
    if (obj.quantity > 0 && obj.price > 0 && !this.addingToCart) {
      this.addingToCart = true;
      obj.addingToCart = true;
      var paylaod: any = {
        allow_only_fiat:
          this.giftObj.filterSettings.currency_type == 'fiat' ? 1 : 0,
        cart_item: 'gift_card',
        product_name: obj.name,
        gift_card_id: obj.productId,
        quantity: parseInt(obj.quantity),
        gift_card_info: obj,
        price: parseFloat(obj.price),
        denomination: parseFloat(obj.price),
      };
      this.coreService.post(`${ENDPOINTS.CART.ADD}`, paylaod).subscribe(
        (res) => {
          this.addingToCart = false;
          obj.addingToCart = false;
          if (res.body.success) {
            obj.addedToCart = true;
            this.cartItems = res.body.data;
            this.toast.success(res.body.message, 'Success!');
          } else {
            this.toast.error(res.body.message, 'Error!');
          }
          setTimeout(function () {
            $('#pol').show();
            $('.cartPopupModal').show();
          }, 500);
        },
        (err) => {
          obj.addingToCart = false;
          this.addingToCart = false;
        }
      );
    } else {
      this.toast.error('Please select price and quantity', 'Error!');
    }
  }
}
