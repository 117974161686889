<app-header (walletBalanceTotal)="walletBalance=$event"></app-header>
<section class="text-center mb-2 mt-3 pt-4 mt-50">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <ul class="list-unstyled bradcam cart-process">
                    <li>
                        <a (click)="goToNavigation('cart');"><span class="active">1</span> Cart</a>
                    </li>
                    <li>
                        <a *ngIf="!isOnlyGiftAndTopup" (click)="goBackToCheckout();"><i class="fas fa-chevron-right iconarrw"></i><span class="active">2</span> Checkout</a>
                        <a *ngIf="isOnlyGiftAndTopup"><i class="fas fa-chevron-right iconarrw"></i><span class="active">2</span> Checkout</a>
                    </li>
                    <li>
                        <a><i class="fas fa-chevron-right iconarrw"></i><span [ngClass]="{active: paymentPageEnable}">3</span> Payment</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
<section class="mb-5">
    <div class="container">
        <div class="row">
            <div *ngIf="loadCartItem || isLoading" id="loader"></div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                <div class="checkoutleft-box mt-3">
                    <div class="c-boxlist" *ngFor="let item of cartItems">
                        <div class="row">
                            <div class="col-3 col-sm-2 col-lg-2">
                                <img
                                    *ngIf="item.cart_item=='product'"
                                    (click)="goToProductDetail(item.product_id);"
                                    src="{{imgBaseUrl}}products/{{item.product_id.product_image}}"
                                    alt="{{item.product_id.product_name}}"
                                    class="img-fluid z-depth-0"
                                />
                                <img
                                    *ngIf="item.cart_item=='balance_card'"
                                    (click)="goToNavigation('cryptomate-balance-card');"
                                    src="{{imgBaseUrl}}giftcard/{{item.balance_card_id.card_logo}}"
                                    alt="{{item.balance_card_id.card_name}}"
                                    class="img-fluid z-depth-0"
                                />
                                <img
                                    *ngIf="item.cart_item=='toupup'"
                                    (click)="goToTopupDetails(item.topup_operator_id,item.topup_info.country.iso_code);"
                                    src="{{imgBaseUrl}}operator/{{item.topup_info.country.iso_code}}/{{item.topup_info.name}}.png"
                                    alt="{{item.topup_info.name}}"
                                    class="img-fluid z-depth-0"
                                />
                                <img *ngIf="item.cart_item=='gift_card'" (click)="goToGiftCardDetail(item.gift_card_id);" src="{{item.gift_card_info.imageUrl}}" alt="{{item.gift_card_info.name}}" class="img-fluid z-depth-0" />

                                <img *ngIf="item.cart_item=='game_product'" (click)="goToGameDetail(item.game_id);" src="{{item.game_product_info.coverImageOriginal}}" alt="{{item.game_product_info.name}}" class="img-fluid z-depth-0" />
                            </div>
                            <div class="col-4 col-sm-5 col-lg-5 pd-0">
                                <div *ngIf="item.cart_item=='product'">
                                    <h5><a (click)="goToProductDetail(item.product_id);" title="{{item.product_id.product_name}}">{{(item.product_name)?item.product_name:item.product_id.product_name}}</a></h5>
                                </div>
                                <div *ngIf="item.cart_item=='balance_card'">
                                    <h5><a (click)="goToNavigation('cryptomate-balance-card');" title="{{item.balance_card_id.card_name}}">{{(item.product_name)?item.product_name:item.balance_card_id.card_name}}</a></h5>
                                </div>
                                <div *ngIf="item.cart_item=='toupup'">
                                    <h5><a title="{{item.topup_info.name}}" (click)="goToTopupDetails(item.topup_operator_id,item.topup_info.country.iso_code);">{{item.topup_product_info.name}}</a></h5>
                                    <p class="text-muted">
                                        {{item.mobile_number}}
                                        <br />
                                        {{item.topup_product_info.destination.amount | currency:item.topup_product_info.destination.unit}}
                                        <!-- {{item.price | currency}} -->
                                    </p>
                                </div>
                                <div *ngIf="item.cart_item=='gift_card'">
                                    <h5><a title="{{item.gift_card_info.name}}" (click)="goToGiftCardDetail(item.gift_card_id);">{{(item.product_name)?item.product_name:item.gift_card_info.name}}</a></h5>
                                    <p class="text-muted">
                                        {{item.denomination | currency:item.gift_card_info.currencyCode}}
                                    </p>
                                </div>
                                <div *ngIf="item.cart_item=='game_product'">
                                    <h5><a title="{{item.game_product_info.name}}" (click)="goToGameDetail(item.game_id);">{{(item.product_name)?item.product_name:item.game_product_info.name}}</a></h5>
                                    <p class="text-muted">
                                        {{item.price | currency}}
                                    </p>
                                </div>
                            </div>
                            <div class="col-2 col-sm-2 col-lg-2 paddok">
                                <span class="qtyvision">Qty: {{item.quantity}}</span>
                            </div>
                            <div class="col-3 col-sm-3 col-lg-3 paddok">
                                <span class="qtyvision">{{item.price*item.quantity | currency}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="shippingAmount">
                        <div class="col-5">
                            <h5>Shipping Price</h5>
                        </div>
                        <div class="col-4">
                            <h6></h6>
                        </div>
                        <div class="col-3">
                            <h6>{{shippingAmount | currency}}</h6>
                        </div>
                    </div>
                    <hr class="chenoe" *ngIf="discountAmount" />
                    <div class="row" *ngIf="discountAmount">
                        <div class="col-5">
                            <h5>Discount Amount</h5>
                        </div>
                        <div class="col-4">
                            <h6></h6>
                        </div>
                        <div class="col-3">
                            <h6>-{{discountAmount | currency}}</h6>
                        </div>
                    </div>
                    <hr class="chenoe" *ngIf="dedcut_from_wallet>0" />
                    <div class="row" *ngIf="dedcut_from_wallet>0">
                        <div class="col-5">
                            <h5>Deduct From Wallet</h5>
                        </div>
                        <div class="col-4">
                            <h6></h6>
                        </div>
                        <div class="col-3">
                            <h6>-{{dedcut_from_wallet | currency}}</h6>
                        </div>
                    </div>
                    <hr class="chenoe"/>
                    <div class="row c-boxlist-total">
                        <div class="col-5">
                            <h5 style="overflow: visible;">
                                <strong>
                                    Total Estimate
                                    <a
                                        class="tool"
                                        rel="tooltip"
                                        data-tip="Due to price volatility in cryptos, there might be a tiny difference between the estimated price and the actual price you will be expected to pay at the last step of the order process"
                                    >
                                        <i class="fas fa-info-circle"></i>
                                    </a>
                                </strong>
                            </h5>
                        </div>
                        <div class="col-4" [hidden]="isFiatProductAdded">
                            <h6 *ngIf="!payWithWalletButton && cryptoPrice.exchange_rate==0">{{cryptoPrice.display}}</h6>
                            <h6 *ngIf="!payWithWalletButton && cryptoPrice.exchange_rate!=0">{{cryptoPrice.display}}</h6>
                        </div>
                        <div class="col-4" [hidden]="!isFiatProductAdded">
                        </div>
                        <div class="col-3">
                            <h6 *ngIf="!payWithWalletButton">{{totalCartValue | currency}}</h6>
                            <h6 *ngIf="payWithWalletButton">{{0.00 | currency}}</h6>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="!isOnlyGiftAndTopup && paymentPageEnable">
                    <div class="col-md-12 col-lg-12 float-right">
                        <div class="md-form searchbox mobileshow">
                            <button type="button" class="applypromo-btn" *ngIf="!discountAmount" (click)="verifyCoupon();">Apply</button>
                            <button type="button" class="applypromo-btn" *ngIf="discountAmount" (click)="removeCoupon();">Remove</button>
                            <input class="form-control" type="text" id="promoc" name="name" [(ngModel)]="coupon_code" [readonly]="discountAmount" />
                            <label for="promoc">Add Promo Code</label>
                            <p class="gotdiscount" *ngIf="msg">{{msg}} You got {{discountAmount | currency}} with this coupon</p>
                            <p class="errordiscount" *ngIf="errormsg">{{errormsg}}</p>
                        </div>                        
                    </div>
                </div>
                <div class="row fullw" *ngIf="!isOnlyGiftAndTopup && paymentPageEnable">
                  <div class="col-md-12 col-lg-12 float-right">
                      <div class="md-form searchbox mobilehidess showmobilesd">
                          <button type="button" class="applypromo-btn" *ngIf="!discountAmount" (click)="verifyCoupon();">Apply</button>
                          <button type="button" class="applypromo-btn" *ngIf="discountAmount" (click)="removeCoupon();">Remove</button>
                          <input class="form-control" type="text" id="promoc" name="name" [(ngModel)]="coupon_code" [readonly]="discountAmount" />
                          <label for="promoc">Add Promo Code</label>
                      </div>
                      <p class="gotdiscount" *ngIf="msg">{{msg}} You got {{discountAmount | currency}} with this coupon</p>
                      <p class="errordiscount" *ngIf="errormsg">{{errormsg}}</p>
                  </div>
               </div>
                <div class="checkoutleft-box-1 mt-3 guest" *ngIf="!isLoggedIn && !paymentPageEnable">
                    <div class="c-boxlist checknew">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-lg-12 radioguest">
                                <label class="pure-material-radio" (click)="doHideGuestForm('hide');" data-toggle="modal" data-target="#login">
                                    <input type="radio" name="checkout_type" value="LOGIN" class="hidebt" />
                                    <span>Sign Up/ Login</span>
                                </label>

                                <label class="pure-material-radio" (click)="doHideGuestForm('show');">
                                    <input type="radio" name="checkout_type" value="GUEST" checked class="hidebt" />
                                    <span>Continue as a Guest</span>
                                </label>

                                <div class="row loginge newtask guestlogin row" [hidden]="checkoutForm">
                                    <form [formGroup]="guestCheckoutForm" style="width:100%">
                                       <div class="col-md-4" style="float: left;">
                                       <div class="md-form">
                                          <input class="form-control" type="text" id="full_name" name="full_name" required="required" formControlName="full_name" [ngClass]="{ 'is-invalid': isFormSubmit &&  getcheckout?.full_name?.errors }" />
                                          <label>Enter Name</label>
                                         </div>
                                       </div>
                                       <div class=" col-md-4" style="float: left;">
                                        <div class="md-form">
                                          <input class="form-control" type="text" id="email" name="email" required="required" formControlName="email" [ngClass]="{ 'is-invalid': isFormSubmit &&  getcheckout?.email?.errors }" />
                                          <label>Enter Email</label>
                                       </div>
                                       </div>
                                       <div class="col-md-4" style="float: left;">
                                         <div class="md-form">
                                          <input class="form-control" type="text" id="phone_number" name="phone_number" required="required" formControlName="phone_number" [ngClass]="{ 'is-invalid': isFormSubmit &&  getcheckout?.phone_number?.errors }" />
                                          <label>Enter Mobile No.</label>
                                         </div>
                                       </div>
                                       <div class="col-md-12 float-right nop"><button type="button" (click)="guestCheckout()" class="joinnow-btn waves-effect waves-light w-100">Proceed to Payment</button></div>
                                    </form>
                                </div>
                            </div>
                            <div class="col-4 col-sm-4 col-lg-4"></div>
                        </div>
                    </div>
                </div>
                <div class="checkoutleft-box-1 mt-3" *ngIf="isLoggedIn || paymentPageEnable">
                    <div class="c-boxlist">
                        <div class="row">
                            <div class="col-8 col-sm-8 col-lg-8">
                                <h5>Email address:</h5>
                                <p class="text-muted" *ngIf="profileObj.email">{{profileObj.email}}</p>
                                <p class="text-muted" *ngIf="!profileObj.email">{{selectedAddres.email}}</p>
                            </div>
                            <div class="col-4 col-sm-4 col-lg-4"></div>
                        </div>
                    </div>
                    <div class="c-boxlist" *ngIf="selectedAddres.address">
                        <div class="row">
                            <div class="col-8 col-sm-8 col-lg-8">
                                <h5>Shipping address:</h5>
                                <p class="text-muted">{{selectedAddres.address}}, {{selectedAddres.landmark}}, {{selectedAddres.city}}, {{selectedAddres.zipcode}}, {{selectedAddres.country}}</p>
                            </div>
                            <div class="col-4 col-sm-4 col-lg-4">
                                <a (click)="goBackToCheckout();">Change</a>
                            </div>
                        </div>
                    </div>
                    <div class="c-boxlist bord_remove" *ngIf="walletBalance>0 && paymentPageEnable">
                        <div class="row">
                            <div class="col-8 col-sm-8 col-lg-8">
                                <div class="checkbox coloured">
                                    <label>
                                        <input type="checkbox" [(ngModel)]="pay_with_wallet" value="1" (change)="checkWalletBalance()" />
                                        <span class="checkbox-material">
                                            <span class="check"></span>
                                            Pay With Wallet
                                        </span>
                                        <img src="/assets/img/wallet-check.png" class="cartnewpage" />
                                    </label>
                                </div>
                            </div>
                            <div class="col-4 col-sm-4 col-lg-4 text-right" style="padding-top: 10px; padding-right: 20px;">
                                <span class="linenew">{{walletBalance | currency}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12" *ngIf="isLoggedIn" [hidden]="paymentPageEnable">
                <div class="accordion md-accordion ship-addressbox" id="accordionEx" role="tablist" aria-multiselectable="true">
                    <div class="card" *ngIf="addressList.length>0">
                        <div class="card-header" role="tab" id="headingOne1">
                            <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                                <h5 class="font-weight-normal mb-0">
                                    Existing Addresses
                                </h5>
                            </a>
                        </div>
                        <div id="collapseOne1" class="collapse show" role="tabpanel" aria-labelledby="headingOne1" data-parent="#accordionEx">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-6 col-md-4 col-lg-4" *ngFor="let address of addressList;">
                                        <div class="save-adress">
                                            <input class="checkbox-tools" type="radio" name="address_id" id="tool-{{address.id}}" value="{{address.id}}" [(ngModel)]="address_id" />
                                            <label class="for-checkbox-tools waves-effect waves-light" for="tool-{{address.id}}">
                                                <span class="default-address">{{address.address_type}}</span>
                                                <span class="edit-adress">
                                                    <a (click)="editAddress(address)" class="greenedit"><i class="fas fa-pencil-alt"></i></a>
                                                    <a (click)="deleteAddress(address)" class="redelete"><i class="far fa-trash-alt"></i></a>
                                                </span>
                                                <h5 class="float-left mt-1 w-100 block fontbold">{{address.first_name}} {{address.last_name}}</h5>
                                                <p class="float-left">
                                                    <i class="fas fa-map-marker-alt"></i>
                                                    <span class="addsmall">{{address.address}}, {{address.landmark}}, {{address.city}}, {{address.zipcode}}, {{address.country}}</span>
                                                </p>
                                                <p class="float-left">
                                                    <i class="fas fa-phone-square-alt"></i>
                                                    <span class="addsmall"> {{address.phone_number}}</span>
                                                </p>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingTwo2">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo2">
                                <h5 *ngIf="!addressForm.value.id" class="font-weight-normal mb-0">Add  Address</h5>
                                <h5 *ngIf="addressForm.value.id" class="font-weight-normal mb-0">Edit Address</h5>
                            </a>
                        </div>
                        <div id="collapseTwo2" class="collapse" [ngClass]="{show: addressList.length == 0}" role="tabpanel" aria-labelledby="headingTwo2" data-parent="#accordionEx">
                            <div class="card-body">
                                <h4 class="fontbold">Shipping Details</h4>
                                <form class="save-addreform" [formGroup]="addressForm">
                                    <div class="row">
                                        <div class="col-md-12 mb-10">
                                            <label class="pure-material-radio">
                                                <input type="radio" name="group" name="address_type" value="HOME" checked formControlName="address_type" />
                                                <span>HOME</span>
                                            </label>
                                            <label class="pure-material-radio">
                                                <input type="radio" name="group" name="address_type" value="OFFICE" formControlName="address_type" />
                                                <span>OFFICE</span>
                                            </label>
                                            <label class="pure-material-radio">
                                                <input type="radio" name="group" name="address_type" value="OTHER" formControlName="address_type" />
                                                <span>OTHER</span>
                                            </label>
                                        </div>
                                        <div class="col-6 col-md-6">
                                            <div class="md-form">
                                                <input class="form-control" type="text" id="f-name" name="name" required="required" formControlName="first_name" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.first_name?.errors }" />
                                                <label for="f-name">First Name*</label>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-6">
                                            <div class="md-form">
                                                <input class="form-control" type="text" id="l-name" name="last_name" required="required" formControlName="last_name" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.last_name?.errors }" />
                                                <label for="l-name">Last Name*</label>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-12">
                                            <div class="md-form">
                                                <input
                                                    class="form-control"
                                                    type="text"
                                                    id="p-number"
                                                    name="phone_number"
                                                    required="required"
                                                    formControlName="phone_number"
                                                    [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.phone_number?.errors }"
                                                />
                                                <label for="p-number">Phone Number*</label>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="md-form">
                                                <input class="form-control" type="text" id="ad-rss" name="address" required="required" formControlName="address" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.address?.errors }" />
                                                <label for="ad-rss">Address</label>
                                            </div>
                                            <div class="md-form">
                                                <input class="form-control" type="text" id="ad-rss1" name="landmark" required="required" formControlName="landmark" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.landmark?.errors }" />
                                                <label for="ad-rss1">Landmark</label>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-6">
                                            <div class="md-form">
                                                <input class="form-control" type="text" id="city" name="city" formControlName="city" required="required" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.city?.errors }" />
                                                <label for="city">City*</label>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-6">
                                            <div class="md-form">
                                                <input class="form-control" type="text" id="p-code" name="zipcode" required="required" formControlName="zipcode" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.zipcode?.errors }" />
                                                <label for="p-code">Postcode / ZIP*</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="md-form">
                                                <select
                                                    class="form-control cardpopup-input"
                                                    style="padding-left: 15px !important; font-size: 13px;"
                                                    required="required"
                                                    formControlName="country"
                                                    [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.country?.errors }"
                                                >
                                                    <option>Select Country</option>
                                                    <option *ngFor="let country of countryLists;" value="{{country}}">{{country}}</option>
                                                </select>
                                                <label for="p-code">Country*</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="md-form">
                                                <input class="form-control" type="text" id="s-te" name="state" required="required" formControlName="state" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.state?.errors }" />
                                                <label for="s-te">State / Province*</label>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="checkbox coloured">
                                                <label>
                                                    <input type="checkbox" name="save_for_later" formControlName="save_for_later" /> <span class="checkbox-material"><span class="check"></span></span> Save for later
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="md-form">
                                                <textarea id="form7" class="md-textarea form-control billing-textare" rows="3" formControlName="order_note"></textarea>
                                                <label for="form7">Order Note</label>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row float-right">
                    <div class="col-sm-12">
                        <a (click)="saveAddress();" class="joinnow-btn m-btn-righview waves-effect waves-light">Proceed to Payment</a>
                    </div>
                </div>
            </div>

            <div *ngIf="payWithWalletButton" class="col-6 col-sm-6 col-lg-4 col-md-4 coinbox_padd">
                <a (click)="doCheckoutWallet()" class="paypalbtn waves-effect waves-light">
                    <img src="/assets/img/wallet-check.png" style="width: 30px; height: 25px;" />
                    <span>Pay Using Wallet</span>
                </a>
            </div>
            
             
            
            <div class="col-12 col-sm-12 col-md-12 col-lg-12" [hidden]="!paymentPageEnable || payWithWalletButton">
                <div class="right-paymentbx mt-3" style="float: left;width: 100%;">
                    <h5>Choose Payment Method</h5>
                    <p>External Payment</p>
                    <div class="mycheckoutpage">
                        <div class="panel with-nav-tabs panel-default">
                            <div class="panel-heading">
                                <ul class="nav nav-tabs">
                                    <li>
                                        <a href="#tab2default" data-toggle="tab"  [ngClass]="{active: !isFiatProductAdded}"><img src="/assets/img/cryptos-new.png" /> Cryptos</a>
                                    </li>
                                    <li>
                                        <a href="#tab4default" data-toggle="tab"><img src="/assets/img/binance-pay.png" /> Binance Pay</a>
                                    </li>
                                    <li>
                                        <a href="#tab3default" data-toggle="tab"><img src="/assets/img/coin-base.png" /> Coinbase </a>
                                    </li>
                                    <li>
                                        <a href="#tab1default" data-toggle="tab" [ngClass]="{active: isFiatProductAdded}"> <img src="/assets/img/card-new.png" /> Card & Paypal</a>
                                    </li>
                                </ul>
                            </div>
                            
                            <div class="panel-body" style="float: left;width: 100%;">
                                <div class="tab-content" style="float: left;width: 100%;">
                                    <div class="tab-pane fade" id="tab1default"  [ngClass]="{'in active': isFiatProductAdded}">
                                        <span [hidden]="!isFiatProductAdded">
                                            <h6 *ngIf="conveyanceCharges">Processing fee:  {{conveyanceCharges | currency}}</h6>
                                            <ngx-paypal [config]="payPalConfig"></ngx-paypal>
                                        </span>
                                        <div class="card-ch" [hidden]="isFiatProductAdded">
                                            <p class="textbt"><span>Important:</span> PayPal is disabled for this transaction</p>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade " id="tab2default"  [ngClass]="{'in active': !isFiatProductAdded}">
                                        <div class="card-ch" [hidden]="!isFiatProductAdded">
                                            <p class="textbt"><span>Important:</span> Cryptos are disabled for this transaction</p>
                                        </div>
                                        <div class="top-list" [hidden]="isFiatProductAdded">
                                            <p class="textbt">“Select the coin you want to pay with and click the <span>“Complete Payment”</span> button. A pop up window will open with details to complete the payment.”</p>
                                            <app-crypto-dropdown [inputPrice]="totalCartValue" [displayType]="'listview'" (convertedRate)="cryptoPrice=$event"></app-crypto-dropdown>
                                        </div>
                                        <div class="float-right myri" [hidden]="isFiatProductAdded">
                                            <button type="button" *ngIf="!isOneTimePaymentDone" class="waves-effect waves-light purchase-btn" (click)="doPaymentWithCrypto('nowpayment')">
                                                Complete Payment
                                            </button>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="tab3default">
                                        <div class="card-ch" [hidden]="!isFiatProductAdded">
                                            <p class="textbt"><span>Important:</span> Coinbase is disabled for this transaction</p>
                                        </div>
                                        <div class="new-pa" [hidden]="isFiatProductAdded">
                                            <p class="textbt">
                                                When you click the <span>“Complete payment”</span> button, you will see the popup to pay with Coinbase. Login to your coinbase.com account and make the payment and your order will be processed
                                                and confirmed.
                                            </p>
                                        </div>
                                        <div class="float-right myri" [hidden]="isFiatProductAdded">
                                            <button type="button" *ngIf="!isOneTimePaymentDone" class="waves-effect waves-light purchase-btn" (click)="doPaymentWithCrypto('coinbase')">
                                                Complete Payment
                                            </button>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="tab4default">
                                        <div class="card-ch" [hidden]="!isFiatProductAdded">
                                            <p class="textbt"><span>Important:</span> Binanace Pay is disabled for this transaction</p>
                                        </div>
                                        <div class="new-pa" [hidden]="isFiatProductAdded">
                                            <p class="textbt">
                                                When you click the <span>“Complete Payment”</span> button, you will see the tab to pay with Binance. Login to your Binance account and make the payment and your order will be processed and
                                                confirmed.
                                            </p>
                                        </div>
                                        <div class="float-right myri" [hidden]="isFiatProductAdded">
                                            <button type="button" *ngIf="!isOneTimePaymentDone" class="waves-effect waves-light purchase-btn" (click)="doPaymentWithCrypto('binancepay')">
                                                Complete Payment
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal checkoutpop pop2s fade" id="paymentSendModal" data-toggle="modal" data-target="#exampleModalLabel" data-controls-modal="exampleModalLabel" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content pay-pag">
                                           
                                            <div class="modal-body">
                                                <div class="bottom-bt text-left">
                                                    <div class="row bor-bot">
                                                        <div class="col-sm-6 col-6 text-left">
                                                            <h2>PAYMENT</h2>
                                                        </div>
                                                        <div class="col-sm-6 col-6 text-right">
                                                            <span class="time-icon">
                                                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                    viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve" >
                                                                <g>
                                                                    <path d="M68.2,19.7c0.8-1.2,1.6-2.3,2.4-3.3c0.2-0.3,0.7-0.7,1-0.6c0.3,0,0.7,0.5,0.8,0.9c1,3.3,1.9,6.7,2.8,10.1
                                                                        c0.3,1.2-0.4,2-1.8,2.2c-2.8,0.3-5.7,0.5-8.5,0.8c-0.5,0-1,0.2-1.5,0.1c-0.4,0-0.9-0.2-1.1-0.4c-0.2-0.2,0-0.8,0.2-1.1
                                                                        c0.7-1.1,1.5-2.2,2.4-3.6c-2.3-1.1-4.4-2.3-6.7-3.2c-18-6.6-37.6,3.8-42.1,22.4c-4.2,17.3,6.9,35,24.4,38.7
                                                                        c0.4,0.1,0.9,0.2,1.3,0.3c1.6,0.5,2.5,2,2.1,3.6c-0.4,1.6-1.9,2.6-3.5,2.3c-5.6-1.1-10.9-3.2-15.5-6.6C15.2,75.1,9.9,65.6,9.1,53.8
                                                                        c-0.7-10.6,2.6-20,9.7-27.9c7.7-8.5,17.4-12.6,28.8-12.6c7,0,13.4,2,19.4,5.7C67.4,19.2,67.7,19.4,68.2,19.7z"/>
                                                                    <path d="M42.7,41c0-3.8,0-7.5,0-11.3c0-0.7,0.2-1.6,0.6-2.2c0.5-0.8,1.5-1,2.4-0.8c1,0.3,1.6,1,1.8,2c0.1,0.5,0.1,0.9,0.1,1.4
                                                                        c0,6.5,0,12.9,0,19.4c0,0.9,0.3,1.4,1.1,1.8c6,3.1,12,6.2,17.9,9.3c0.3,0.1,0.6,0.3,0.8,0.4c1.2,0.8,1.6,2.1,1.1,3.3
                                                                        c-0.6,1.2-2,1.7-3.3,1.1c-2.3-1.1-4.6-2.3-6.9-3.5c-4.9-2.5-9.8-5.1-14.7-7.6c-0.7-0.4-1-0.8-1-1.6C42.7,48.8,42.7,44.9,42.7,41z"
                                                                        />
                                                                    <path d="M85.1,51.2c0,0.2,0,0.4,0,0.6c0,1.9-1.2,3.3-2.9,3.4c-1.8,0.1-3.1-1.3-3.2-3.3c0-0.6,0-1.3,0-1.9c0.1-1.8,1.4-3.1,3.1-3.1
                                                                        c1.6,0,2.9,1.3,3,3.1C85.1,50.4,85,50.8,85.1,51.2C85,51.2,85.1,51.2,85.1,51.2z"/>
                                                                    <path d="M55,82.2c1.8,0,3.1,1,3.4,2.4c0.3,1.5-0.4,3-1.8,3.5c-1,0.3-2,0.6-3,0.7c-1.4,0.2-2.8-0.9-3.2-2.3
                                                                        c-0.3-1.4,0.3-2.9,1.7-3.4C53.1,82.6,54.3,82.4,55,82.2z"/>
                                                                    <path d="M79.5,35.3c1.4,0,2.4,0.8,3,2.4c0.7,1.7,1.4,3.4-0.3,4.9c-1.4,1.3-3.7,1-4.6-0.7c-0.5-0.9-0.9-2-1.1-3
                                                                        C76.1,37,77.6,35.4,79.5,35.3z"/>
                                                                    <path d="M83.4,62.4c-0.3,0.8-0.7,2.1-1.4,3.3c-0.6,1.1-2.2,1.4-3.4,1c-1.2-0.4-2.2-1.6-2-2.8c0.2-1.4,0.7-2.8,1.4-4
                                                                        c0.6-1.1,2.1-1.4,3.3-1C82.6,59.3,83.4,60.5,83.4,62.4z"/>
                                                                    <path d="M69.4,80.5c0.5,2.2-4.4,5.1-6.3,3.9c-1.8-1.1-2.2-3.6-0.5-5c0.8-0.6,1.7-1.2,2.6-1.6C67.1,76.8,69.3,78.3,69.4,80.5z"/>
                                                                    <path d="M74.7,69.3c2.5,0,4.1,2.3,3,4.3c-0.6,1-1.4,1.9-2.2,2.8c-0.9,0.9-2.5,0.8-3.5,0.1c-1.1-0.8-1.8-2.3-1.1-3.4
                                                                        c0.7-1.2,1.7-2.3,2.7-3.4C73.8,69.4,74.4,69.4,74.7,69.3z"/>
                                                                </g>
                                                                </svg> {{counter | formatTime}}</span>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-sm-4 text-left">
                                                            <p>Payment Amount</p>
                                                        </div>
                                                        <div class="col-sm-8 text-right" *ngIf="qrCodeUrl!=''">
                                                            <span class="paym" *ngIf="!payWithWalletButton">{{totalCartValue | currency}}</span>
                                                            <span class="paym" *ngIf="payWithWalletButton">{{0.00 | currency}}</span>
                                                        </div>
                                                        <div class="col-sm-8 text-right" *ngIf="qrCodeUrl==''">
                                                            <span class="paym" *ngIf="crypto_pay_amount"><img src="{{imgBaseUrl}}crypto/{{crypto_pay_code}}.png" />  {{crypto_pay_amount}}</span> <span class="priceTitle">&nbsp;{{crypto_pay_code}}</span>
                                                        </div>
                                                        <div class="col-sm-12 text-center scanicon" *ngIf="qrCodeUrl!=''">
                                                            <img src="{{qrCodeUrl}}" />
                                                            <p>Scan the QR code below to complete your payment</p>
                                                            <img src="https://www.travala.com/images-pc/binance/pay-binance.png" />
                                                            <p><a (click)="redirectToOrderDetail(orderId)" class="refresh">Payment Sent</a></p>
                                                        </div>

                                                        <div class="col-sm-12 scanicon" *ngIf="qrCodeUrl==''">
                                                            <div class="graybx">
                                                                <label for="text" class="mb-0">Pay Address</label>
                                                                <div class="md-form coplinkbtn mt-0">
                                                                    <textarea class="form-control PayInput" type="text" name="name" disabled [(ngModel)]="pay_address" ></textarea>
                                                                    <button type="button" (click)="copyURL(pay_address)" class="applypromo-btn waves-effect waves-light"><i class="fas fa-copy"></i></button>
                                                                    <span class="qrcodePayment" id="show-hidden-menu" (click)="showQRCode('hidden-menu')">
                                                                        <ngx-qrcode
                                                                        [elementType]="elementType"
                                                                        [errorCorrectionLevel]="correctionLevel"
                                                                        [value]="pay_address"
                                                                        cssClass="bshadow"></ngx-qrcode>
                                                                    </span>
                                                                    
                                                                     <div class="hidden-menu" style="display: none;">
                                                                       <ngx-qrcode
                                                                        [elementType]="elementType"
                                                                        [errorCorrectionLevel]="correctionLevel"
                                                                        [value]="pay_address"
                                                                        cssClass="bshadow"></ngx-qrcode>
                                                                   </div>
                                                                </div>
                                                            </div>
                                                            <div class="graybx" *ngIf="pay_extra_id!=''">
                                                                <label for="text" class="mb-0">Tag</label>
                                                                <div class="md-form coplinkbtn mt-0">
                                                                    <textarea class="form-control PayInput" type="text" name="name" disabled [(ngModel)]="pay_extra_id" ></textarea>
                                                                    <button type="button" (click)="copyURL(pay_extra_id)" class="applypromo-btn waves-effect waves-light"><i class="fas fa-copy"></i></button>
                                                                    <span class="qrcodePayment" id="tagshowqr" (click)="showQRCode('tagShowQR')">
                                                                        <ngx-qrcode
                                                                        [elementType]="elementType"
                                                                        [errorCorrectionLevel]="correctionLevel"
                                                                        [value]="pay_extra_id"
                                                                        cssClass="bshadow"></ngx-qrcode>
                                                                    </span>                                                                    
                                                                    <div class="tagShowQR" style="display: none;">
                                                                        <ngx-qrcode
                                                                            [elementType]="elementType"
                                                                            [errorCorrectionLevel]="correctionLevel"
                                                                            [value]="pay_extra_id"
                                                                            cssClass="bshadow"></ngx-qrcode>
                                                                    </div>                                                                    
                                                                </div>
                                                            </div>
                                                            <p>Please make your payment to the above address</p>
                                                            <p><a (click)="redirectToOrderDetail(orderId)" class="refresh">Payment Sent</a></p>
                                                            
                                                            <div class="text-center info-view" *ngIf="pay_extra_id!=''">
                                                                <span><i class="fa fa-info-circle" aria-hidden="true"></i></span> Both a wallet address and a destination tag/memo are required to make the payment successfully
                                                            </div>                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="modal checkoutpop pop2s fade" id="exampleModal3" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                            <div class="modal-body">
                                                <div class="bottom-bt text-left">
                                                    <div class="row">
                                                        <div class="col-sm-12 text-center scanicon">
                                                            <img src="/assets/img/Black_Small.gif" />
                                                            <h2><img src="/assets/img/new-cp.png" /></h2>
                                                            <h4>Join Our Refer A Friend Program. For Every Person you invite that completes an order of US $50 or more, you will receive a reward of US $5.</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#WalletUnder">Open modal</button> -->
                                <div class="modal fade" id="WalletUnder">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                                            <div class="modal-body">
                                                <div class="walletBx clearfix">
                                                    <h4>Wallet Under Maintenance!</h4>
                                                    <p>Please go back to select another coin or come back later.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="text-center py-3">
    <div class="container">
        <div class="row d-flex align-items-center justify-content-center">
            <div class="col-md-8 col-lg-8">
                <div class="title-heading">
                    <h5>
                        <span class="font-28">Pay with your <b class="text-yellow">cryptos.</b></span> More than 60 cryptocoins are accepted here! <br />
                        Bitcoin, Ethereum, XRP, BNB, Litecoin, Doge.
                    </h5>
                </div>
                <div class="logo-icons">
                    <img src="/assets/img/coins.webp" />
                </div>
                <div class="css-1b9jzri--2">
                    <div style="color: var(--text-primary);" class="css-ezg0pp--2">
                        <div>
                            <img src="/assets/img/images_checkmark.svg" width="21" height="21" />
                        </div>
                        - Blockchain Based Payments.
                    </div>
                    <div style="color: var(--text-primary);" class="css-ezg0pp--2">
                        <div>
                            <img src="/assets/img/images_checkmark.svg" width="21" height="21" />
                        </div>
                        - Easy, Fast and Secure.
                    </div>
                    <div style="color: var(--text-primary);" class="css-ezg0pp--2">
                        <div>
                            <img src="/assets/img/images_checkmark.svg" width="21" height="21" />
                        </div>
                        - Pay Instantly, Anywhere and Anytime.
                    </div>
                    <div style="color: var(--text-primary);" class="css-ezg0pp--2">
                        <div>
                            <img src="/assets/img/images_checkmark.svg" width="21" height="21" />
                        </div>
                        - Pay with Stable Coins.
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#paymentSendModal" id="btn_paymentSendModal" style="display: none;">Open modal</button>
<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#WalletUnder" id="btn_WalletUnder" style="display: none;">Open modal</button>