import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceXoxo'
})
export class ReplaceXoxoPipe implements PipeTransform {

  transform(value: string): string {
    value = value.replace(/Xoxoday/g, 'Cryptomate');
    value = value.replace(/XOXO/g, 'Cryptomate');
    return value;
  }
}
