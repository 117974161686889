<app-header></app-header>
<section class="text-center mb-2 pt-4 mt-3 mt-50">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <ul class="list-unstyled bradcam">
                    <li><a  (click)="goToNavigation('home');">Home</a></li>
                    <li><i class="fas fa-angle-double-right iconarrw"></i> Faq</li>
                </ul>
            </div>
        </div>
    </div>
</section>

<section class=" faq-page bgfaq">
    <div class="container">
        <div class="row d-flex align-items-center justify-content-center mt-3">
            <div class="title-heading faqpage">
                <h2 class="h1 pb-3">How Can We Help?</h2>
            </div>
        </div>
        <div class="topsearch">
            <div class="md-form searchbox">
                <button type="submit" (click)="filterFaq()" class="search-btn"><i class="fas fa-search"></i></button>
                <input class="form-control" type="text" id="searchp" (keydown)="onKeyDownEvent($event)" (focus)="saveValue()" (blur)="processChange()" [(ngModel)]="search"/>
                <label for="searchp">Search here...</label>
            </div>
        </div>

         <div class="row d-flex justify-content-center leftbarbox">
            <div class="col-md-3">
               <ul class="nav md-pills pills-primary flex-column mb-3" role="tablist">
                  <li class="nav-item" *ngFor="let faqObj of faqLists; let i = index"> 
                       <!-- *ngIf="faqObj.faqs.length>0" -->
                        <a *ngIf="active_tab==''" class="nav-link" [ngClass]="{active: i == 0}" data-toggle="tab" href="#{{faqObj.id}}" role="tab" (click)="showSection(faqObj.id)">
                            <i class=""><img src="{{imgBaseUrl}}faq/{{faqObj?.faq_logo}}" /></i> {{faqObj.category_name}}
                        </a>

                        <a *ngIf="active_tab!=''" class="nav-link" [ngClass]="{active: faqObj.id == '612757749362f1744614971c'}" data-toggle="tab" href="#{{faqObj.id}}" role="tab" (click)="showSection(faqObj.id)">
                            <i class=""><img src="{{imgBaseUrl}}faq/{{faqObj?.faq_logo}}" /></i> {{faqObj.category_name}}
                        </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#support" role="tab" (click)="showSection('support_contect')">
                        <i class=""><img src="/assets/img/img-faq.png" /></i> 
                        Support
                    </a>
                  </li>
               </ul>
            </div>
            <div class="col-md-9">
               <!-- Tab panels -->
               <div class="tab-content vertical">
                    <div *ngIf="isLoading" id="loader"></div>
                     <!-- Panel 1 -->
                     <div *ngFor="let faqObj2 of faqLists; let j = index" class="tab-pane fade faqdescr" id="{{faqObj2.id}}" role="tabpanel">
                        <!--Accordion wrapper-->
                        <div class="accordion md-accordion mt-0" id="accordionEx" role="tablist" aria-multiselectable="true" *ngIf="faqObj2.faqs.length>0">
                            <!-- Accordion card -->
                            <div class="card" *ngFor="let f of faqObj2.faqs; let k = index">
                                <!-- Card header -->
                                <div class="card-header" role="tab" id="{{f.id}}" [ngClass]="{show: f.is_active}">
                                    <a data-toggle="collapse" data-parent="#accordionEx" (click)="openSubTab(f,faqObj2)">
                                        <h5 class="font-weight-normal mb-0">
                                            {{f.faq_title}}
                                        </h5>
                                    </a>
                                </div>
                                <!-- Card body -->
                                <div id="sub_{{f.id}}" class="collapse faq_title"  [ngClass]="{show: f.is_active}">
                                    <div class="card-body">
                                        <div [innerHtml]="f.faq_description"></div>
                                    </div>
                                </div>
                            </div>
                            <!-- Accordion card -->
                        </div>
                        <div class="accordion md-accordion mt-0" id="accordionEx" role="tablist" aria-multiselectable="true" *ngIf="faqObj2.faqs.length==0">
                            <div class="col-md-12 alert alert-danger">
                                Record not found
                            </div>
                        </div>
                        <!-- Accordion wrapper -->
                    </div>
                    <!-- Panel 1 -->

                    <div class="tab-pane fade faqdescr" id="support_contect" role="tabpanel">
                        <div class="contat-box">
                            <div class="row d-flex justify-content-center mt-3">
                                <div class="col-sm-12" style="margin-bottom: 25px;">
                                    <h5 class="font-weight-normal mb-1" style="text-align: center;">
                                        Sorry if we missed your question! ☹ 
                                    </h5>
                                    <h5 class="font-weight-normal mb-1" style="text-align: center;">
                                        Drop us a message, and we will be happy to help!
                                    </h5>
                                </div>
                                <form class="col-md-12 col-lg-12 save-addreform"  [formGroup]="contectForm">
                                    <div class="row">
                                        <div class="col-12 col-md-6">
                                            <div class="md-form">
                                                <input class="form-control" type="text" id="text" name="full_name" required="required" formControlName="full_name" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.full_name?.errors }"/>
                                                <label for="text">Full Name*</label>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="md-form">
                                                <input class="form-control" type="text" id="email" name="email" required="required" formControlName="email" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.email?.errors }"/>
                                                <label for="email">Email*</label>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="md-form">
                                                <input class="form-control" type="text" id="phone" name="phone" required="required" formControlName="phone" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.phone?.errors }"/>
                                                <label for="phone">Phone*</label>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="md-form">
                                                <input class="form-control" type="text" id="subject" name="subject" required="required" formControlName="subject" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.subject?.errors }"/>
                                                <label for="subject">What are you contacting us about?*</label>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="md-form">
                                                <textarea id="form7" name="message" class="md-textarea form-control billing-textare" rows="3" formControlName="message"></textarea>
                                                <label for="form7">Your Message</label>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <button [disabled]="isLoading" type="button" class="joinnow-btn waves-effect waves-light w-100" (click)="submitContectUs(contectForm.value)">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
               </div>
            </div>
      </div>
    </div>
</section>
