export const METHODS = [
    // 'Bank Transfer',
    // "Cash",
    // "Paypal",
    // 'WesternUnion',
    // 'Money Gram',
    // 'Alipay',
    // 'WeChat',
    // 'E-Wallet',
    // 'Mobile Payments',
    // 'Cryptocurrencies',
    // 'Others'
    "BTC",
    "ETH",
    "BNB",
    "USDT",
    "SOL",
    "ADA",
    "XRP",
    "DOT",
    "USDC",
    "DOGE",
    "SHIB",
    "LUNA",
    "AVAX",
    "LTC",
    "LINK",
    "WBTC",
    "UNI",
    "BUSD",
    "ALGO",
    "BCH",
    "MATIC",
    "CRO",
    "VET",
    "XLM",
    "AXS",
    "ICP",
    "TRX",
    "FIL",
    "ETC",
    "ATOM",
    "THETA",
    "FTT",
    "BTCB",
    "FTM",
    "HBAR",
    "DAI",
    "EGLD",
    "MANA",
    "NEAR",
    "GRT",
    "HNT",
    "XTZ",
    "EOS",
    "XMR",
    "CAKE",
    "UST",
    "FLOW",
    "AAVE",
    "KLAY",
    "XEC",
    "KDA",
    "LRC",
    "MIOTA",
    "KSM",
    "NEO",
    "BSV",
    "CHZ",
    "RUNE",
    "QNT",
    "STX",
    "ONE",
    "MKR",
    "LEO",
    "ZEC",
    "ENJ",
    "SAND",
    "WAVES",
    "BTT",
    "AMP",
    "HOT",
    "DASH",
    "AR",
    "IOTX",
    "COMP",
    "CELO",
    "NEXO",
    "KCS",
    "TFUEL",
    "CRV",
    "BAT",
    "XEM",
    "OMG",
    "MINA",
    "QTUM",
    "OKB",
    "HT",
    "ICX",
    "DCR",
    "LPT",
    "SUSHI",
    "REV",
    "RVN",
    "ZIL",
    "TUSD",
    "AUDIO",
    "PERP",
    "YFI",
    "ZEN",
    "XDC",
    "BTG",
    "Other"
]
