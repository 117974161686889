import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CoreService } from 'src/app/shared/services/core.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { HelperService } from 'src/app/shared/services/helper-service.service';
import { environment } from 'src/environments/environment';
declare var $: any;
@Component({
  selector: 'app-headers',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public lang:any = '';
  public isLoggedIn:boolean = false;
  public isLoading:boolean = false;
  public cartItems:any = [];
  public walletBalance:any = '';
  public profileObj:any = {};
  public imgBaseUrl = environment.AWSImageURL;
  public imageSrc:any = '';
  constructor(
    private helperService: HelperService,
    private coreService: CoreService,
    private _auth : AuthService
  ) { 
    if(this._auth.loggedIn()){
      this.isLoggedIn = true;
    }else{
      this.isLoggedIn = false;
    }
  }

  ngOnInit(): void {
    let profileObj:any = localStorage.getItem('currentUser') != null ? localStorage.getItem('currentUser') : false;
    if(profileObj){
      this.profileObj = JSON.parse(profileObj);
    }else{
      this.helperService.navigate('/home', this.lang);
    }
    this.cartItemLists();
    this.getWalletTotal();

    window.addEventListener('mouseup',function(event:any){
      var pol = document.getElementById('pol');
      //console.log(event.target.parentNode.id)
      //console.log("Pradosh",event.target.parentNode);
      if(event.target.parentNode.id=="addwishlist"){
        $('.addwishlistopen').addClass("show");
      }
      else{
        //console.log("Out");
        $('.addwishlistopen').removeClass("show");
        if(event.target == pol || event.target.parentNode == pol || event.target.parentNode.id=='cart-items'){
            $("#pol").show();
            $(".cartPopupModal").show();
        }else{
            $("#pol").hide();
            $(".cartPopupModal").hide();
        }
      }
    }); 
  }

  /**
   * @method: addToCart
   * @description: Using to get list of cart
   * @param productObj
   */
   cartItemLists(){
    this.coreService.get(`${ENDPOINTS.CART.LIST}`, {}).subscribe((res) => {
      this.cartItems = res.body.data;
    }, (err) => {
      //this.loginObj.error = true;
      //this.toastr.error(err.body.data.message);
    });
  }

  /**
   * @method: deleteItemFromCart
   * @description: Using to delete item from cart
   * @param itemObj
   */
   updateCartItem(itemObj:any,type:string){
    var q = itemObj.quantity;
    if(type=='minus'){
      if(q>1){
        q = q - 1;
      }else{
        this.deleteItemFromCart(itemObj);
        return;
      }
    }else{
      q = q + 1;
    }
    this.isLoading = true;
    const paylaod = {
      'cart_id' : itemObj.id,
      'quantity' : q
    }
    this.coreService.post(`${ENDPOINTS.CART.UPDATE}`, paylaod).subscribe((res) => {
      this.isLoading = false;
      if(res.body.success){
        itemObj.quantity = q;
      }
    }, (err) => {
      this.isLoading = false;
    });
  }

    /**
   * @method: deleteItemFromCart
   * @description: Using to delete item from cart
   * @param itemObj
   */
     deleteItemFromCart(itemObj:any){
      this.isLoading = true;
      this.coreService.post(`${ENDPOINTS.CART.DELETE}`, {cart_id:itemObj.id}).subscribe((res) => {
        this.isLoading = false;
        if(res.body.success){
          this.cartItems = res.body.data;
          if(this.cartItems.length==0){
            $(".cartPopupModal").hide();
          }
        }     
      }, (err) => {
        this.isLoading = false;
      });
    }

  getWalletTotal(){
    this.coreService.post(`${ENDPOINTS.USER_PROFILE.TOTAL_WALLET}`, {}).subscribe((res) => {
      if (res.body.success) {
        this.walletBalance = parseFloat(res.body.data.total_wallet_balance);
      } else {
        this.walletBalance = 0.00;
      }
    }, (err) => {
    });
  }

  logOut() {
    this.coreService.get(`${ENDPOINTS.AUTH.LOGOUT}`, {}).subscribe((res) => {
      this.cbLogout();
    }, (err) => {
      this.cbLogout();
    });
  }

  cbLogout() {
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('checkoutInfo');
    this.helperService.changeUserProfile(null);
    this.helperService.setLoggedUserInfo(false) ;
    this.helperService.navigate('/home', this.lang);
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang)
  }
}
