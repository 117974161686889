import { Component, Input,Output, OnChanges, OnInit,EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from 'src/app/shared/services/core.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { HelperService } from 'src/app/shared/services/helper-service.service';
import { environment } from 'src/environments/environment';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import {ToastrService} from 'ngx-toastr';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit, OnChanges  {
  public lang:any = '';
  public isLoggedIn:boolean = false;
  public imgBaseUrl = environment.AWSImageURL;
  public cartItems:any = [];
  public profileObj:any = {};
  public imageSrc:any = '';
  public loadCartItem:boolean = false;
  public isLoading:boolean = false;
  public myTotalWalletBalance:any = 0.00;
  public product_name:string = '';
  public productnames:any = [];
  public gift_card_name:string = '';
  public queryParam: any = {};
  public giftCardProducts:any = [];
  public typeaheadLoading:boolean = false;
  @Input() public cartItemData:any = [];
  @Input() public pageName:any = 'other';
  @Output() public walletBalanceTotal:any = new EventEmitter();
  constructor(
    private toast: ToastrService,
    private helperService: HelperService,
    private coreService: CoreService,
    public router: Router,
    private route: ActivatedRoute,
    private _auth : AuthService
  ) { 
    if(this._auth.loggedIn()){
      this.isLoggedIn = true;
    }else{
      this.isLoggedIn = false;
    }

    this.route.queryParams.subscribe(params => {
      if (!params) return;
      this.queryParam = { ...params };
      if (this.queryParam.hasOwnProperty('search')) {
        this.gift_card_name = this.queryParam.search;
        this.product_name = this.queryParam.search;
        delete this.queryParam.search;
      }
    });
  }

  ngOnInit(): void {
    let profileObj:any = localStorage.getItem('currentUser') != null ? localStorage.getItem('currentUser') : '{"name":""}';
    this.profileObj = JSON.parse(profileObj);
    this.cartItemLists();
    this.getWalletTotal();

    window.addEventListener('mouseup',function(event:any){
      var pol = document.getElementById('pol');
      if(event.target.parentNode.id=="addwishlist"){
        $('.addwishlistopen').addClass("show");
      }
      else{
        $('.addwishlistopen').removeClass("show");
        if(event.target == pol || event.target.parentNode == pol || event.target.parentNode.id=='cart-items'){
            $("#pol").show();
            $(".cartPopupModal").show();
        }else{
            $("#pol").hide();
            $(".cartPopupModal").hide();
        }
      }
    }); 
  }
  
  goToShopPage(){
    if(this.product_name!=''){
      //this.router?.navigate(['/shop'], { queryParams: {'search':this.product_name,'param':Math.random()}});      
      window.location.href = '/shop?search='+this.product_name+"&param="+Math.random();
    }else
    this.helperService.navigate(`/shop`, this.lang);
  }

  // Selected value event
  typeaheadOnSelect(e: TypeaheadMatch): void {
    //this.helperService.navigate(`/shop-detail/${e.item.product_slug}`, this.lang);
    window.location.href = '/shop-detail/'+e.item.product_slug;
  }

  // Show loading indicator
  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  
  filterResults(){
    this.coreService.post(`${ENDPOINTS.LANDING.AUTOSUGGESTION}`, {'keyword':this.product_name}).subscribe((res) => {
      if (res.body.success) {
        $('.more-record').remove();
        this.productnames = res.body.data;
        if(this.productnames.length>6){
          let link = "/shop?search="+this.product_name;
          $("#ngb-typeahead-0").append("<span class='more-record' style='float: left;text-align: center;width: 100%;border-top: 1px solid #ddd;padding-top: 10px;padding-bottom: 7px;'><a style='font-weight: bold;' href='"+link+"'>Total Record "+this.productnames.length+"</a><span>");
        }
      } else {
        this.productnames = [];
      }
    }, (err) => {
      this.productnames = [];
    });
  }

  goToGiftCardPage(){
    if(this.gift_card_name!='')
      //this.router?.navigate(['/gift'], { queryParams: {'search':this.gift_card_name,'param':Math.random()}});
      window.location.href = '/gift?search='+this.gift_card_name+"&param="+Math.random();
    else
    this.helperService.navigate(`/gift`, this.lang);
  }

  filterResultsForGift(){
    this.coreService.post(`${ENDPOINTS.LANDING.GIFT_AUTOSUGGESTION}`, {'product_name':this.gift_card_name}).subscribe((res) => {
      if (res.body.success) {
        $('.more-record').remove();
        this.giftCardProducts = res.body.data;
        if(this.giftCardProducts.length>6){
          let link = "/shop?search="+this.product_name;
          $("#ngb-typeahead-0").append("<span class='more-record' style='float: left;text-align: center;width: 100%;border-top: 1px solid #ddd;padding-top: 10px;padding-bottom: 7px;'><a style='font-weight: bold;' href='"+link+"'>Total Record "+this.productnames.length+"</a><span>");
        }
      } else {
        this.giftCardProducts = [];
      }
    }, (err) => {
      this.giftCardProducts = [];
    });
  }

  // Selected value event
  typeaheadOnSelectForGift(e: TypeaheadMatch): void {
    this.helperService.navigate(`/gift-detail/${e.item.productId}`, this.lang);
  }

  // Show loading indicator
  changeTypeaheadLoadingForGift(e: boolean): void {
    this.typeaheadLoading = e;
  }

  logOut() {
    this.coreService.get(`${ENDPOINTS.AUTH.LOGOUT}`, {}).subscribe((res) => {
      this.cbLogout();
    }, (err) => {
      this.cbLogout();
    });
  }

  cbLogout() {
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('checkoutInfo');
    this.helperService.changeUserProfile(null);
    this.helperService.setLoggedUserInfo(false) ;
    if(this.router.url!='/home'){
      this.helperService.navigate('/home', this.lang);
    }
    else{
      window.location.reload();
    }
    //window.location.reload();
  }

  ngOnChanges(){
    this.cartItems = this.cartItemData;
  }

  /**
   * @method: deleteItemFromCart
   * @description: Using to delete item from cart
   * @param itemObj
   */
   updateCartItem(itemObj:any,type:string){
    var q = itemObj.quantity;
    if(type=='minus'){
      if(q>1){
        q = q - 1;
      }else{
        this.deleteItemFromCart(itemObj);
        return;
      }
    }else{
      q = q + 1;
    }    
    this.isLoading = true;
    const paylaod = {
      'cart_id' : itemObj.id,
      'quantity' : q
    }
    this.coreService.post(`${ENDPOINTS.CART.UPDATE}`, paylaod).subscribe((res) => {
      this.isLoading = false;
      if(res.body.success){
        itemObj.quantity = q;
      }else{
        this.toast.error(res.body.message, "Error!");
      }
    }, (err) => {
      this.isLoading = false;
      this.toast.error(err.body.message);
    });
  }

    /**
   * @method: deleteItemFromCart
   * @description: Using to delete item from cart
   * @param itemObj
   */
     deleteItemFromCart(itemObj:any){
      this.isLoading = true;
      this.coreService.post(`${ENDPOINTS.CART.DELETE}`, {cart_id:itemObj.id}).subscribe((res) => {
        this.isLoading = false;
        if(res.body.success){
          this.cartItems = res.body.data;
          if(this.cartItems.length==0){
            $(".cartPopupModal").hide();
          }
          this.cartItemData = [];
        }     
      }, (err) => {
        this.isLoading = false;
      });
    }

  OnChanges(){
  }

  /**
   * @method: addToCart
   * @description: Using to get list of cart
   * @param productObj
   */
   cartItemLists(){
      this.coreService.get(`${ENDPOINTS.CART.LIST}`, {}).subscribe((res) => {
        this.cartItems = res.body.data;
      }, (err) => {
      });
  }

  getWalletTotal(){
    if(this.isLoggedIn ){
      this.coreService.post(`${ENDPOINTS.USER_PROFILE.TOTAL_WALLET}`, {}).subscribe((res) => {
        if (res.body.success) {
          this.myTotalWalletBalance = parseFloat(res.body.data.total_wallet_balance);
        } else {
          this.myTotalWalletBalance = 0.00;
        }
        this.walletBalanceTotal.emit(this.myTotalWalletBalance);
      }, (err) => {
      });
    }
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang)
  }
}
