
const orgPath = 'https://s3-eu-west-1.amazonaws.com/foodimus-testing/';
const orgThumbPath = 'https://s3-eu-west-1.amazonaws.com/foodimus-testingresized/';
const orgThumbPath1 = 'https://foodimus-testing.s3.eu-west-1.amazonaws.com/';
const orgFtcPost = 'ftc-posts/';
const orgFtcThumbPost = 'resized-ftc-posts/';
export const Settings = {
  MealPlanImgBaseUrl: `${orgThumbPath}resized-foodimus_meal_plans/`,
  MealPlanImgProfileUrl: `${orgThumbPath}resized-foodimus_profile/`,
  websiteImageUrl: `${orgThumbPath}resized-static/`,
  MealPlanImgProfileStaticUrl: `${orgThumbPath1}static/`,
  thumbImagePath: (imageName:any) => {
    return `${orgThumbPath}${orgFtcThumbPost}${imageName}`;
  },
  orgImagePath: (imageName:any) => {
    return `${orgPath}${orgFtcPost}${imageName}`;
  },
  perPage: 18,
  profilePage: 8,
  AwsSetting: {
    'FOLDER': "",
    'ACCESS_KEY': "AKIAJ5RJYPAULOK7PAHQ",
    'SECRET_ACCESS_KEY': "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",
    'REGION': "eu-west-1",
    'BUCKET_NAME': "foodimus-testing"
  }
}

