import { Component, OnInit } from '@angular/core';
import { CoreService } from '../shared/services/core.service';
import { HelperService } from '../shared/services/helper-service.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-outsource',
  templateUrl: './outsource.component.html',
  styleUrls: ['./outsource.component.css']
})
export class OutsourceComponent implements OnInit {
  public imgBaseUrl = environment.AWSImageURL;
  public isLoadingData:boolean = true;
  public lang:any = '';
  public brandLists:any = {};
  public productList:any = {};
  constructor(
    private coreService: CoreService,
    public helperService: HelperService
  ) { }

  ngOnInit(): void {
    this.getOutSourceBrand();
  }

  viewProducts(obj:any){
    if(obj=='all'){
      $(".filter").show("1000");
    }else{
      $(".filter")
          .not(".filterclass_" + obj)
          .hide("1000");
      $(".filter")
          .filter(".filterclass_" + obj)
          .show("1000");
    }
    $(".filter-button").removeClass("active");
    $("#filter_"+obj).addClass("active");
  }
  

  getOutSourceBrand(){
    this.coreService.get(`${ENDPOINTS.SOLUTION.OUTSOURCE}`, {}).subscribe((res) => {
      if (res.body.success) {
        this.brandLists = res.body.data;
        let that = this;
        setTimeout(function(){
          let langCode = (localStorage.getItem("lang_direction")!='' && localStorage.getItem("lang_direction")!=null)?localStorage.getItem("lang_direction"):'ltr';
          $(".regular").slick({
            dots: true,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            rtl: (langCode=='rtl')?true:false,
              responsive: [
                  {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow: 4,
                      slidesToScroll: 1,
                      infinite: true,
                      dots: true
                    }
                  },
                  {
                    breakpoint: 600,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 1
                    }
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 1
                    }
                  }
                  // You can unslick at a given breakpoint now by adding:
                  // settings: "unslick"
                  // instead of a settings object
                ]
              });
              that.isLoadingData = false;
        },1000);
        this.getRecommendationProduct();
      } else {
        this.isLoadingData = false;
        this.brandLists = [];
      }
    }, (err) => {
      this.isLoadingData = false;
    });
  }

  getRecommendationProduct(){
    this.coreService.get(`${ENDPOINTS.SOLUTION.RECOMMENDATION}`+'?type=newidea', {}).subscribe((res) => {
      if (res.body.success) {
        this.productList = res.body.data;
        if(this.productList.category.length>0){
          let that = this;
          setTimeout(function(){
            that.viewProducts(that.productList.category[0].id);
          },1000);          
        }
      } else {
        this.productList = [];
      }
    }, (err) => {
    });
  }

  goToProductDetailPage(obj:any) {
    //this.helperService.navigate(`/shop-detail/${obj.product_slug}`, this.lang);
    window.location.href = '/shop-detail/'+obj.product_slug;
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang)
  }
}