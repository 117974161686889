import { Component,Input,Output, OnInit } from '@angular/core';
import { HelperService } from '../shared/services/helper-service.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { CoreService } from 'src/app/shared/services/core.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../shared/services/auth.service';
import {ToastrService} from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-balance-card',
  templateUrl: './balance-card.component.html',
  styleUrls: ['./balance-card.component.css']
})
export class BalanceCardComponent implements OnInit {
  public imgBaseUrl = environment.AWSImageURL;
  public limit_amount = environment.DAILY_PURCHASE_LIMIT;
  public isLoggedIn:boolean = false;
  public addingToCart:boolean = false;
  public isLoader:boolean = true;
  public lang:any = '';
  public giftObj:any = {};
  public price:any = '';
  public quantityLists:any = [];
  public profileObj:any = '';
  public cryptoPrice:any = {};
  public gifts:any = [];
  public exchangeRates:any = 0;
  public cartItems:any = '';
  @Input() public cartItemData:any = [];
  public filterSettings: any = {
      product_name : '',
      country : 'india',
      order_by : 'name',
      order_type : 'ASC',
      category_id: []
  };
  constructor(
    private toast: ToastrService,
    private coreService: CoreService,
    private helperService: HelperService,
    private _auth : AuthService
  ) {
    if(this._auth.loggedIn()){
      this.isLoggedIn = true;
    }else{
      this.isLoggedIn = false;
    }
  }

  ngOnInit(): void {
    let profileObj:any = (localStorage.getItem('currentUser') != null && localStorage.getItem('currentUser') != 'undefined') ? localStorage.getItem('currentUser') : '{"name":""}';
    if(profileObj!='undefined' && profileObj!=undefined){
      this.profileObj = JSON.parse(profileObj);
    }

    let gifts:any = (localStorage.getItem('voucherLists') != null  && localStorage.getItem('voucherLists') != 'undefined') ? localStorage.getItem('voucherLists') : JSON.stringify([]);
    if(gifts!='undefined' && gifts!=undefined){
      this.gifts = JSON.parse(gifts);
    }
    this.getExchangeRates('USD');

    let filter:any = (localStorage.getItem('giftFilters') != null && localStorage.getItem('giftFilters') != 'undefined') ? localStorage.getItem('giftFilters') : JSON.stringify(this.giftObj.filterSettings);
    if(filter!='undefined' && filter!=undefined){
      this.filterSettings = JSON.parse(filter);
    }
    this.filterSettings.category_id = ['cash_cards'];
    this.filterSettings.currency_type = 'crypto';
    this.findVouchers();
    this.coreService.get(`${ENDPOINTS.GIFT_CARD.BALANCE_CARD}`, {}).subscribe((res) => {
      if (res.body.success) {
        this.isLoader = false;
        this.giftObj = res.body.data;
        this.giftObj.quantity = 1;
        if(this.giftObj.amount.length>0){
          this.giftObj.amount  = this.giftObj.amount .filter((curElem:any) => {
            return curElem.amount != '';
          });
          this.giftObj.price = this.giftObj.amount[0].amount;
        }
      } else {
        this.isLoader = false;
        this.giftObj = {};
      }
    }, (err) => {
      this.isLoader = false;
    });
    for (var i = 1; i <21; i++) {
      this.quantityLists.push(i);
    }
  }

  
  ngOnChanges(){
    this.cartItems = this.cartItemData;
  }
  cartItemLists(){
    if(this.isLoggedIn ){
      this.coreService.get(`${ENDPOINTS.CART.LIST}`, {}).subscribe((res) => {
        this.cartItems = res.body.data;
      }, (err) => {
      });
    }
  }

  getExchangeRates(currencyCode:any){
    if(!this.exchangeRates){
      this.coreService.getExchangeRates(currencyCode).subscribe((res) => {
        this.exchangeRates = res.body.data.rates;
      }, (err) => {
        this.exchangeRates = 0;
      });
    }
  }

  findByMatchingProperties(set:any, properties:any) {
    return set.filter(function (entry:any) {
        return Object.keys(properties).every(function (key) {
            return entry[key] === properties[key];
        });
    });
  }

  findVouchers(){
      this.giftObj.loadStatus = true;
      const payload = {
        ...this.filterSettings
      }
      this.coreService.post(`${ENDPOINTS.GIFT_XOXO.VOUCHERS}1/8`, payload).subscribe((res) => {
        if (res.body.success) {
          if(res.body.data.getVouchers!=undefined){
            res.body.data.getVouchers.data.forEach((item: any) => {
              item.isImageLoading = true;
              item.quantity = 1;
              let denominations = item.valueDenominations.split(",");
              // if(item.currencyCode!='USD'){
              //   let rates:any = this.exchangeRates[item.currencyCode];
              //   item.denominations = [];
              //   denominations.forEach((d: any) => {
              //     let p:any = parseFloat(d) + (d * 0.1);
              //     p = p / rates;
              //     item.denominations.push({'amount':parseFloat(p).toFixed(2),'denomination':d});
              //   });
              // }else{
              //   item.denominations = [];
              //   denominations.forEach((d: any) => {
              //     item.denominations.push({'amount':d,'denomination':d});
              //   });
              // }
              item.denominations = denominations;
              if(item.denominations.length>0){
                //item.price = item.denominations[0].amount;
                item.price = item.denominations[0];
              }
              this.gifts.push(item);
            });
            this.giftObj.loadStatus = false;
          }else{
            this.giftObj.loadStatus = false;
          }
        } else {
          this.giftObj.list = [];
          this.giftObj.loadStatus = false;
        }
      }, (err) => {
        this.giftObj.loadStatus = false;
      });
  }

  /**
   * @method: addToCartGift
   * @description: Using to add item in the cart
   * @param productObj
   */
   addToCartGift(obj:any){
     //console.log(obj);
    if(obj.quantity>0 && obj.price>0){
      // let priceObj:any = {};
      // var results = this.findByMatchingProperties(obj.denominations,{'amount':obj.price});
      // if(results.length>0){
      //   priceObj = results[0];
      // }
      this.addingToCart = true;
      obj.addingToCart = true;
      var paylaod:any = {
        'allow_only_fiat' : (obj.allow_only_fiat)?obj.allow_only_fiat:0,
        'cart_item' : 'gift_card',
        'product_name' : obj.name,
        'gift_card_id' : obj.productId,      
        'quantity' : parseInt(obj.quantity),
        'gift_card_info' : obj,
        'price' : parseFloat(obj.price),
        'denomination' : parseFloat(obj.price)
      }    
      this.coreService.post(`${ENDPOINTS.CART.ADD}`, paylaod).subscribe((res) => {
        this.addingToCart = false;
        obj.addingToCart = false;
        if(res.body.success){
          obj.addedToCart = true;
          this.cartItems = res.body.data;
          this.toast.success(res.body.message, "Success!");
        }else{
          this.toast.error(res.body.message, "Error!");
        }
        setTimeout(function(){
          $("#pol").show();
          $(".cartPopupModal").show();
        },500);
      }, (err) => {
        this.addingToCart = false;
      });
    }else{
      this.toast.error("Please select price and quantity", "Error!");
    }
  }

  /**
   * @method: addToCart
   * @description: Using to add item in the cart
   * @param productObj
   */
   addToCart(obj:any){
    if(obj.quantity>0 && obj.price>0 && !this.addingToCart){
      this.addingToCart = true;
      var paylaod:any = {
        'allow_only_fiat' : (obj.allow_only_fiat)?obj.allow_only_fiat:0,
        'cart_item' : 'balance_card',
        'product_name' : obj.card_name,
        'balance_card_id' : obj.id,      
        'quantity' : parseInt(obj.quantity),
        'price' : parseFloat(obj.price)
      }    
      this.coreService.post(`${ENDPOINTS.CART.ADD}`, paylaod).subscribe((res) => {
          this.addingToCart = false;
          if(res.body.success){
            this.toast.success(res.body.message, "Success!");
            this.helperService.navigate('cart', this.lang)
          }else{
            this.toast.error(res.body.message, "Error!");
          }
      }, (err) => {
        this.addingToCart = false;
      });
    }else{
      this.toast.error("Please select price and quantity", "Error!");
    }
  }
  
  goTOPage(obj:any) {
    localStorage.setItem('giftObj', JSON.stringify(obj));
    localStorage.setItem('giftFilters', JSON.stringify(this.giftObj.filterSettings));
    this.helperService.navigate(`/gift-detail/${obj.productId}`, this.lang);
  }
  

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang);
  }
}
