export const ALL_REGIONS = [
    {
     "id": 1,
     "name": "Europe",
     "country" : [
    {
      "name": "Albania"
    },
    {
      "name": "Andorra"
    },
    {
      "name": "Austria"
    },
    {
      "name": "Belarus"
    },
    {
      "name": "Belgium"
    },
    {
      "name": "Bosnia & Herzegovina"
    },
    {
      "name": "Bulgaria"
    },
    {
      "name": "Croatia"
    },
    {
      "name": "Cyprus"
    },
    {
      "name": "Czech Republic"
    },
    {
      "name": "Denmark"
    },
    {
      "name": "Estonia"
    },
    {
      "name": "Faroe Islands"
    },
    {
      "name": "Finland"
    },
    {
      "name": "France"
    },
    {
      "name": "Germany"
    },
    {
      "name": "Gibraltar"
    },
    {
      "name": "Greece"
    },
    {
      "name": "Guernsey"
    },
    {
      "name": "Hungary"
    },
    {
      "name": "Iceland"
    },
    {
      "name": "Ireland"
    },
    {
      "name": "Isle Of Man"
    },
    {
      "name": "Italy"
    },
    {
      "name": "Jersey"
    },
    {
      "name": "Latvia"
    },
    {
      "name": "Liechtenstein"
    },
    {
      "name": "Lithuania"
    },
    {
      "name": "Luxembourg"
    },
    {
      "name": "Macedonia"
    },
    {
      "name": "Malta"
    },
    {
      "name": "Moldova"
    },
    {
      "name": "Monaco"
    },
    {
      "name": "Montenegro"
    },
    {
      "name": "Netherlands"
    },
    {
      "name": "Norway"
    },
    {
      "name": "Poland"
    },
    {
      "name": "Portugal"
    },
    {
      "name": "Romania"
    },
    {
      "name": "Russia"
    },
    {
      "name": "San Marino"
    },
    {
      "name": "Serbia"
    },
    {
      "name": "Slovakia"
    },
    {
      "name": "Slovenia"
    },
    {
      "name": "Spain"
    },
    {
      "name": "Svalbard & Jan Mayen"
    },
    {
      "name": "Sweden"
    },
    {
      "name": "Switzerland"
    },
    {
      "name": "Ukraine"
    },
    {
      "name": "United Kingdom"
    },
    {
      "name": "Vatican City"
    },
    {
      "name": "Åland Islands"
    }
  ],
    },
    {
     "id": 2,
     "name": "United States",
     "country" : [
        {
          "name": "United States"
        }
      ],
    },
    {
     "id": 3,
     "name": "Region free",
     "country" : [
    {
      "name": "Afghanistan"
    },
    {
      "name": "Albania"
    },
    {
      "name": "Algeria"
    },
    {
      "name": "American Samoa"
    },
    {
      "name": "Andorra"
    },
    {
      "name": "Angola"
    },
    {
      "name": "Anguilla"
    },
    {
      "name": "Antigua & Barbuda"
    },
    {
      "name": "Argentina"
    },
    {
      "name": "Armenia"
    },
    {
      "name": "Aruba"
    },
    {
      "name": "Australia"
    },
    {
      "name": "Austria"
    },
    {
      "name": "Azerbaijan"
    },
    {
      "name": "Bahamas"
    },
    {
      "name": "Bahrain"
    },
    {
      "name": "Bangladesh"
    },
    {
      "name": "Barbados"
    },
    {
      "name": "Belarus"
    },
    {
      "name": "Belgium"
    },
    {
      "name": "Belize"
    },
    {
      "name": "Benin"
    },
    {
      "name": "Bermuda"
    },
    {
      "name": "Bhutan"
    },
    {
      "name": "Bolivia"
    },
    {
      "name": "Bosnia & Herzegovina"
    },
    {
      "name": "Botswana"
    },
    {
      "name": "Brazil"
    },
    {
      "name": "British Virgin Islands"
    },
    {
      "name": "Brunei"
    },
    {
      "name": "Bulgaria"
    },
    {
      "name": "Burkina Faso"
    },
    {
      "name": "Burundi"
    },
    {
      "name": "Cambodia"
    },
    {
      "name": "Cameroon"
    },
    {
      "name": "Canada"
    },
    {
      "name": "Cape Verde"
    },
    {
      "name": "Cayman Islands"
    },
    {
      "name": "Central African Republic"
    },
    {
      "name": "Chad"
    },
    {
      "name": "Chile"
    },
    {
      "name": "China"
    },
    {
      "name": "Colombia"
    },
    {
      "name": "Comoros"
    },
    {
      "name": "Congo - Brazzaville"
    },
    {
      "name": "Congo - Kinshasa"
    },
    {
      "name": "Cook Islands"
    },
    {
      "name": "Costa Rica"
    },
    {
      "name": "Croatia"
    },
    {
      "name": "Cuba"
    },
    {
      "name": "Cyprus"
    },
    {
      "name": "Czech Republic"
    },
    {
      "name": "Côte D’Ivoire"
    },
    {
      "name": "Denmark"
    },
    {
      "name": "Djibouti"
    },
    {
      "name": "Dominica"
    },
    {
      "name": "Dominican Republic"
    },
    {
      "name": "Ecuador"
    },
    {
      "name": "Egypt"
    },
    {
      "name": "El Salvador"
    },
    {
      "name": "Equatorial Guinea"
    },
    {
      "name": "Eritrea"
    },
    {
      "name": "Estonia"
    },
    {
      "name": "Ethiopia"
    },
    {
      "name": "Falkland Islands"
    },
    {
      "name": "Faroe Islands"
    },
    {
      "name": "Fiji"
    },
    {
      "name": "Finland"
    },
    {
      "name": "France"
    },
    {
      "name": "French Guiana"
    },
    {
      "name": "French Polynesia"
    },
    {
      "name": "Gabon"
    },
    {
      "name": "Gambia"
    },
    {
      "name": "Georgia"
    },
    {
      "name": "Germany"
    },
    {
      "name": "Ghana"
    },
    {
      "name": "Gibraltar"
    },
    {
      "name": "Greece"
    },
    {
      "name": "Greenland"
    },
    {
      "name": "Grenada"
    },
    {
      "name": "Guadeloupe"
    },
    {
      "name": "Guam"
    },
    {
      "name": "Guatemala"
    },
    {
      "name": "Guernsey"
    },
    {
      "name": "Guinea"
    },
    {
      "name": "Guinea-Bissau"
    },
    {
      "name": "Guyana"
    },
    {
      "name": "Haiti"
    },
    {
      "name": "Honduras"
    },
    {
      "name": "Hong Kong Sar China"
    },
    {
      "name": "Hungary"
    },
    {
      "name": "Iceland"
    },
    {
      "name": "India"
    },
    {
      "name": "Indonesia"
    },
    {
      "name": "Iran"
    },
    {
      "name": "Iraq"
    },
    {
      "name": "Ireland"
    },
    {
      "name": "Isle Of Man"
    },
    {
      "name": "Israel"
    },
    {
      "name": "Italy"
    },
    {
      "name": "Jamaica"
    },
    {
      "name": "Japan"
    },
    {
      "name": "Jersey"
    },
    {
      "name": "Jordan"
    },
    {
      "name": "Kazakhstan"
    },
    {
      "name": "Kenya"
    },
    {
      "name": "Kiribati"
    },
    {
      "name": "Kuwait"
    },
    {
      "name": "Kyrgyzstan"
    },
    {
      "name": "Laos"
    },
    {
      "name": "Latvia"
    },
    {
      "name": "Lebanon"
    },
    {
      "name": "Lesotho"
    },
    {
      "name": "Liberia"
    },
    {
      "name": "Libya"
    },
    {
      "name": "Liechtenstein"
    },
    {
      "name": "Lithuania"
    },
    {
      "name": "Luxembourg"
    },
    {
      "name": "Macau Sar China"
    },
    {
      "name": "Macedonia"
    },
    {
      "name": "Madagascar"
    },
    {
      "name": "Malawi"
    },
    {
      "name": "Malaysia"
    },
    {
      "name": "Maldives"
    },
    {
      "name": "Mali"
    },
    {
      "name": "Malta"
    },
    {
      "name": "Marshall Islands"
    },
    {
      "name": "Martinique"
    },
    {
      "name": "Mauritania"
    },
    {
      "name": "Mauritius"
    },
    {
      "name": "Mayotte"
    },
    {
      "name": "Mexico"
    },
    {
      "name": "Micronesia"
    },
    {
      "name": "Moldova"
    },
    {
      "name": "Monaco"
    },
    {
      "name": "Mongolia"
    },
    {
      "name": "Montenegro"
    },
    {
      "name": "Montserrat"
    },
    {
      "name": "Morocco"
    },
    {
      "name": "Mozambique"
    },
    {
      "name": "Myanmar (Burma)"
    },
    {
      "name": "Namibia"
    },
    {
      "name": "Nauru"
    },
    {
      "name": "Nepal"
    },
    {
      "name": "Netherlands"
    },
    {
      "name": "New Caledonia"
    },
    {
      "name": "New Zealand"
    },
    {
      "name": "Nicaragua"
    },
    {
      "name": "Niger"
    },
    {
      "name": "Nigeria"
    },
    {
      "name": "Niue"
    },
    {
      "name": "Norfolk Island"
    },
    {
      "name": "North Korea"
    },
    {
      "name": "Northern Mariana Islands"
    },
    {
      "name": "Norway"
    },
    {
      "name": "Oman"
    },
    {
      "name": "Pakistan"
    },
    {
      "name": "Palau"
    },
    {
      "name": "Palestinian Territories"
    },
    {
      "name": "Panama"
    },
    {
      "name": "Papua New Guinea"
    },
    {
      "name": "Paraguay"
    },
    {
      "name": "Peru"
    },
    {
      "name": "Philippines"
    },
    {
      "name": "Pitcairn Islands"
    },
    {
      "name": "Poland"
    },
    {
      "name": "Portugal"
    },
    {
      "name": "Puerto Rico"
    },
    {
      "name": "Qatar"
    },
    {
      "name": "Romania"
    },
    {
      "name": "Russia"
    },
    {
      "name": "Rwanda"
    },
    {
      "name": "Réunion"
    },
    {
      "name": "Samoa"
    },
    {
      "name": "San Marino"
    },
    {
      "name": "Saudi Arabia"
    },
    {
      "name": "Senegal"
    },
    {
      "name": "Serbia"
    },
    {
      "name": "Seychelles"
    },
    {
      "name": "Sierra Leone"
    },
    {
      "name": "Singapore"
    },
    {
      "name": "Slovakia"
    },
    {
      "name": "Slovenia"
    },
    {
      "name": "Solomon Islands"
    },
    {
      "name": "Somalia"
    },
    {
      "name": "South Africa"
    },
    {
      "name": "South Korea"
    },
    {
      "name": "Spain"
    },
    {
      "name": "Sri Lanka"
    },
    {
      "name": "St. Barthélemy"
    },
    {
      "name": "St. Helena"
    },
    {
      "name": "St. Kitts & Nevis"
    },
    {
      "name": "St. Lucia"
    },
    {
      "name": "St. Martin"
    },
    {
      "name": "St. Pierre & Miquelon"
    },
    {
      "name": "St. Vincent & Grenadines"
    },
    {
      "name": "Sudan"
    },
    {
      "name": "Suriname"
    },
    {
      "name": "Svalbard & Jan Mayen"
    },
    {
      "name": "Swaziland"
    },
    {
      "name": "Sweden"
    },
    {
      "name": "Switzerland"
    },
    {
      "name": "Syria"
    },
    {
      "name": "São Tomé & Príncipe"
    },
    {
      "name": "Taiwan"
    },
    {
      "name": "Tajikistan"
    },
    {
      "name": "Tanzania"
    },
    {
      "name": "Thailand"
    },
    {
      "name": "Timor-Leste"
    },
    {
      "name": "Togo"
    },
    {
      "name": "Tokelau"
    },
    {
      "name": "Tonga"
    },
    {
      "name": "Trinidad & Tobago"
    },
    {
      "name": "Tunisia"
    },
    {
      "name": "Turkey"
    },
    {
      "name": "Turkmenistan"
    },
    {
      "name": "Turks & Caicos Islands"
    },
    {
      "name": "Tuvalu"
    },
    {
      "name": "U.S. Virgin Islands"
    },
    {
      "name": "Uganda"
    },
    {
      "name": "Ukraine"
    },
    {
      "name": "United Arab Emirates"
    },
    {
      "name": "United Kingdom"
    },
    {
      "name": "United States"
    },
    {
      "name": "Uruguay"
    },
    {
      "name": "Uzbekistan"
    },
    {
      "name": "Vanuatu"
    },
    {
      "name": "Vatican City"
    },
    {
      "name": "Venezuela"
    },
    {
      "name": "Vietnam"
    },
    {
      "name": "Wallis & Futuna"
    },
    {
      "name": "Western Sahara"
    },
    {
      "name": "Yemen"
    },
    {
      "name": "Zambia"
    },
    {
      "name": "Zimbabwe"
    },
    {
      "name": "Åland Islands"
    }
  ],
    },
    {
     "id": 4,
     "name": "Other",
     "country" : [
    {
      "name": "Afghanistan"
    },
    {
      "name": "Albania"
    },
    {
      "name": "Algeria"
    },
    {
      "name": "American Samoa"
    },
    {
      "name": "Andorra"
    },
    {
      "name": "Angola"
    },
    {
      "name": "Anguilla"
    },
    {
      "name": "Antigua & Barbuda"
    },
    {
      "name": "Argentina"
    },
    {
      "name": "Armenia"
    },
    {
      "name": "Aruba"
    },
    {
      "name": "Australia"
    },
    {
      "name": "Austria"
    },
    {
      "name": "Azerbaijan"
    },
    {
      "name": "Bahamas"
    },
    {
      "name": "Bahrain"
    },
    {
      "name": "Bangladesh"
    },
    {
      "name": "Barbados"
    },
    {
      "name": "Belarus"
    },
    {
      "name": "Belgium"
    },
    {
      "name": "Belize"
    },
    {
      "name": "Benin"
    },
    {
      "name": "Bermuda"
    },
    {
      "name": "Bhutan"
    },
    {
      "name": "Bolivia"
    },
    {
      "name": "Bosnia & Herzegovina"
    },
    {
      "name": "Botswana"
    },
    {
      "name": "Brazil"
    },
    {
      "name": "British Virgin Islands"
    },
    {
      "name": "Brunei"
    },
    {
      "name": "Bulgaria"
    },
    {
      "name": "Burkina Faso"
    },
    {
      "name": "Burundi"
    },
    {
      "name": "Cambodia"
    },
    {
      "name": "Cameroon"
    },
    {
      "name": "Canada"
    },
    {
      "name": "Cape Verde"
    },
    {
      "name": "Cayman Islands"
    },
    {
      "name": "Central African Republic"
    },
    {
      "name": "Chad"
    },
    {
      "name": "Chile"
    },
    {
      "name": "China"
    },
    {
      "name": "Colombia"
    },
    {
      "name": "Comoros"
    },
    {
      "name": "Congo - Brazzaville"
    },
    {
      "name": "Congo - Kinshasa"
    },
    {
      "name": "Cook Islands"
    },
    {
      "name": "Costa Rica"
    },
    {
      "name": "Croatia"
    },
    {
      "name": "Cuba"
    },
    {
      "name": "Cyprus"
    },
    {
      "name": "Czech Republic"
    },
    {
      "name": "Côte D’Ivoire"
    },
    {
      "name": "Denmark"
    },
    {
      "name": "Djibouti"
    },
    {
      "name": "Dominica"
    },
    {
      "name": "Dominican Republic"
    },
    {
      "name": "Ecuador"
    },
    {
      "name": "Egypt"
    },
    {
      "name": "El Salvador"
    },
    {
      "name": "Equatorial Guinea"
    },
    {
      "name": "Eritrea"
    },
    {
      "name": "Estonia"
    },
    {
      "name": "Ethiopia"
    },
    {
      "name": "Falkland Islands"
    },
    {
      "name": "Faroe Islands"
    },
    {
      "name": "Fiji"
    },
    {
      "name": "Finland"
    },
    {
      "name": "France"
    },
    {
      "name": "French Guiana"
    },
    {
      "name": "French Polynesia"
    },
    {
      "name": "Gabon"
    },
    {
      "name": "Gambia"
    },
    {
      "name": "Georgia"
    },
    {
      "name": "Ghana"
    },
    {
      "name": "Gibraltar"
    },
    {
      "name": "Greece"
    },
    {
      "name": "Greenland"
    },
    {
      "name": "Grenada"
    },
    {
      "name": "Guadeloupe"
    },
    {
      "name": "Guam"
    },
    {
      "name": "Guatemala"
    },
    {
      "name": "Guernsey"
    },
    {
      "name": "Guinea"
    },
    {
      "name": "Guinea-Bissau"
    },
    {
      "name": "Guyana"
    },
    {
      "name": "Haiti"
    },
    {
      "name": "Honduras"
    },
    {
      "name": "Hong Kong Sar China"
    },
    {
      "name": "Hungary"
    },
    {
      "name": "Iceland"
    },
    {
      "name": "India"
    },
    {
      "name": "Indonesia"
    },
    {
      "name": "Iran"
    },
    {
      "name": "Iraq"
    },
    {
      "name": "Ireland"
    },
    {
      "name": "Isle Of Man"
    },
    {
      "name": "Israel"
    },
    {
      "name": "Italy"
    },
    {
      "name": "Jamaica"
    },
    {
      "name": "Japan"
    },
    {
      "name": "Jersey"
    },
    {
      "name": "Jordan"
    },
    {
      "name": "Kazakhstan"
    },
    {
      "name": "Kenya"
    },
    {
      "name": "Kiribati"
    },
    {
      "name": "Kuwait"
    },
    {
      "name": "Kyrgyzstan"
    },
    {
      "name": "Laos"
    },
    {
      "name": "Latvia"
    },
    {
      "name": "Lebanon"
    },
    {
      "name": "Lesotho"
    },
    {
      "name": "Liberia"
    },
    {
      "name": "Libya"
    },
    {
      "name": "Liechtenstein"
    },
    {
      "name": "Lithuania"
    },
    {
      "name": "Luxembourg"
    },
    {
      "name": "Macau Sar China"
    },
    {
      "name": "Macedonia"
    },
    {
      "name": "Madagascar"
    },
    {
      "name": "Malawi"
    },
    {
      "name": "Malaysia"
    },
    {
      "name": "Maldives"
    },
    {
      "name": "Mali"
    },
    {
      "name": "Malta"
    },
    {
      "name": "Marshall Islands"
    },
    {
      "name": "Martinique"
    },
    {
      "name": "Mauritania"
    },
    {
      "name": "Mauritius"
    },
    {
      "name": "Mayotte"
    },
    {
      "name": "Mexico"
    },
    {
      "name": "Micronesia"
    },
    {
      "name": "Moldova"
    },
    {
      "name": "Monaco"
    },
    {
      "name": "Mongolia"
    },
    {
      "name": "Montenegro"
    },
    {
      "name": "Montserrat"
    },
    {
      "name": "Morocco"
    },
    {
      "name": "Mozambique"
    },
    {
      "name": "Myanmar (Burma)"
    },
    {
      "name": "Namibia"
    },
    {
      "name": "Nauru"
    },
    {
      "name": "Nepal"
    },
    {
      "name": "Netherlands"
    },
    {
      "name": "New Caledonia"
    },
    {
      "name": "New Zealand"
    },
    {
      "name": "Nicaragua"
    },
    {
      "name": "Niger"
    },
    {
      "name": "Nigeria"
    },
    {
      "name": "Niue"
    },
    {
      "name": "Norfolk Island"
    },
    {
      "name": "North Korea"
    },
    {
      "name": "Northern Mariana Islands"
    },
    {
      "name": "Norway"
    },
    {
      "name": "Oman"
    },
    {
      "name": "Pakistan"
    },
    {
      "name": "Palau"
    },
    {
      "name": "Palestinian Territories"
    },
    {
      "name": "Panama"
    },
    {
      "name": "Papua New Guinea"
    },
    {
      "name": "Paraguay"
    },
    {
      "name": "Peru"
    },
    {
      "name": "Philippines"
    },
    {
      "name": "Pitcairn Islands"
    },
    {
      "name": "Poland"
    },
    {
      "name": "Portugal"
    },
    {
      "name": "Puerto Rico"
    },
    {
      "name": "Qatar"
    },
    {
      "name": "Romania"
    },
    {
      "name": "Rwanda"
    },
    {
      "name": "Réunion"
    },
    {
      "name": "Samoa"
    },
    {
      "name": "San Marino"
    },
    {
      "name": "Saudi Arabia"
    },
    {
      "name": "Senegal"
    },
    {
      "name": "Serbia"
    },
    {
      "name": "Seychelles"
    },
    {
      "name": "Sierra Leone"
    },
    {
      "name": "Singapore"
    },
    {
      "name": "Slovakia"
    },
    {
      "name": "Slovenia"
    },
    {
      "name": "Solomon Islands"
    },
    {
      "name": "Somalia"
    },
    {
      "name": "South Africa"
    },
    {
      "name": "South Korea"
    },
    {
      "name": "Spain"
    },
    {
      "name": "Sri Lanka"
    },
    {
      "name": "St. Barthélemy"
    },
    {
      "name": "St. Helena"
    },
    {
      "name": "St. Kitts & Nevis"
    },
    {
      "name": "St. Lucia"
    },
    {
      "name": "St. Martin"
    },
    {
      "name": "St. Pierre & Miquelon"
    },
    {
      "name": "St. Vincent & Grenadines"
    },
    {
      "name": "Sudan"
    },
    {
      "name": "Suriname"
    },
    {
      "name": "Svalbard & Jan Mayen"
    },
    {
      "name": "Swaziland"
    },
    {
      "name": "Sweden"
    },
    {
      "name": "Switzerland"
    },
    {
      "name": "Syria"
    },
    {
      "name": "São Tomé & Príncipe"
    },
    {
      "name": "Taiwan"
    },
    {
      "name": "Tajikistan"
    },
    {
      "name": "Tanzania"
    },
    {
      "name": "Thailand"
    },
    {
      "name": "Timor-Leste"
    },
    {
      "name": "Togo"
    },
    {
      "name": "Tokelau"
    },
    {
      "name": "Tonga"
    },
    {
      "name": "Trinidad & Tobago"
    },
    {
      "name": "Tunisia"
    },
    {
      "name": "Turkey"
    },
    {
      "name": "Turkmenistan"
    },
    {
      "name": "Turks & Caicos Islands"
    },
    {
      "name": "Tuvalu"
    },
    {
      "name": "U.S. Virgin Islands"
    },
    {
      "name": "Uganda"
    },
    {
      "name": "Ukraine"
    },
    {
      "name": "United Arab Emirates"
    },
    {
      "name": "United Kingdom"
    },
    {
      "name": "United States"
    },
    {
      "name": "Uruguay"
    },
    {
      "name": "Uzbekistan"
    },
    {
      "name": "Vanuatu"
    },
    {
      "name": "Vatican City"
    },
    {
      "name": "Venezuela"
    },
    {
      "name": "Vietnam"
    },
    {
      "name": "Wallis & Futuna"
    },
    {
      "name": "Western Sahara"
    },
    {
      "name": "Yemen"
    },
    {
      "name": "Zambia"
    },
    {
      "name": "Zimbabwe"
    },
    {
      "name": "Åland Islands"
    }
  ],
    },
    {
     "id": 5,
     "name": "Outside Europe",
     "country" : [
    {
      "name": "Afghanistan"
    },
    {
      "name": "Algeria"
    },
    {
      "name": "American Samoa"
    },
    {
      "name": "Angola"
    },
    {
      "name": "Anguilla"
    },
    {
      "name": "Antigua & Barbuda"
    },
    {
      "name": "Argentina"
    },
    {
      "name": "Armenia"
    },
    {
      "name": "Aruba"
    },
    {
      "name": "Australia"
    },
    {
      "name": "Azerbaijan"
    },
    {
      "name": "Bahamas"
    },
    {
      "name": "Bahrain"
    },
    {
      "name": "Bangladesh"
    },
    {
      "name": "Barbados"
    },
    {
      "name": "Belize"
    },
    {
      "name": "Benin"
    },
    {
      "name": "Bermuda"
    },
    {
      "name": "Bhutan"
    },
    {
      "name": "Bolivia"
    },
    {
      "name": "Botswana"
    },
    {
      "name": "Brazil"
    },
    {
      "name": "British Virgin Islands"
    },
    {
      "name": "Brunei"
    },
    {
      "name": "Burkina Faso"
    },
    {
      "name": "Burundi"
    },
    {
      "name": "Cambodia"
    },
    {
      "name": "Cameroon"
    },
    {
      "name": "Canada"
    },
    {
      "name": "Cape Verde"
    },
    {
      "name": "Cayman Islands"
    },
    {
      "name": "Central African Republic"
    },
    {
      "name": "Chad"
    },
    {
      "name": "Chile"
    },
    {
      "name": "China"
    },
    {
      "name": "Colombia"
    },
    {
      "name": "Comoros"
    },
    {
      "name": "Congo - Brazzaville"
    },
    {
      "name": "Congo - Kinshasa"
    },
    {
      "name": "Cook Islands"
    },
    {
      "name": "Costa Rica"
    },
    {
      "name": "Cuba"
    },
    {
      "name": "Côte D’Ivoire"
    },
    {
      "name": "Djibouti"
    },
    {
      "name": "Dominica"
    },
    {
      "name": "Dominican Republic"
    },
    {
      "name": "Ecuador"
    },
    {
      "name": "Egypt"
    },
    {
      "name": "El Salvador"
    },
    {
      "name": "Equatorial Guinea"
    },
    {
      "name": "Eritrea"
    },
    {
      "name": "Ethiopia"
    },
    {
      "name": "Falkland Islands"
    },
    {
      "name": "Fiji"
    },
    {
      "name": "French Guiana"
    },
    {
      "name": "French Polynesia"
    },
    {
      "name": "Gabon"
    },
    {
      "name": "Gambia"
    },
    {
      "name": "Georgia"
    },
    {
      "name": "Ghana"
    },
    {
      "name": "Greenland"
    },
    {
      "name": "Grenada"
    },
    {
      "name": "Guadeloupe"
    },
    {
      "name": "Guam"
    },
    {
      "name": "Guatemala"
    },
    {
      "name": "Guinea"
    },
    {
      "name": "Guinea-Bissau"
    },
    {
      "name": "Guyana"
    },
    {
      "name": "Haiti"
    },
    {
      "name": "Honduras"
    },
    {
      "name": "Hong Kong Sar China"
    },
    {
      "name": "India"
    },
    {
      "name": "Indonesia"
    },
    {
      "name": "Iran"
    },
    {
      "name": "Iraq"
    },
    {
      "name": "Israel"
    },
    {
      "name": "Jamaica"
    },
    {
      "name": "Japan"
    },
    {
      "name": "Jordan"
    },
    {
      "name": "Kazakhstan"
    },
    {
      "name": "Kenya"
    },
    {
      "name": "Kiribati"
    },
    {
      "name": "Kuwait"
    },
    {
      "name": "Kyrgyzstan"
    },
    {
      "name": "Laos"
    },
    {
      "name": "Lebanon"
    },
    {
      "name": "Lesotho"
    },
    {
      "name": "Liberia"
    },
    {
      "name": "Libya"
    },
    {
      "name": "Macau Sar China"
    },
    {
      "name": "Madagascar"
    },
    {
      "name": "Malawi"
    },
    {
      "name": "Malaysia"
    },
    {
      "name": "Maldives"
    },
    {
      "name": "Mali"
    },
    {
      "name": "Marshall Islands"
    },
    {
      "name": "Martinique"
    },
    {
      "name": "Mauritania"
    },
    {
      "name": "Mauritius"
    },
    {
      "name": "Mayotte"
    },
    {
      "name": "Mexico"
    },
    {
      "name": "Micronesia"
    },
    {
      "name": "Mongolia"
    },
    {
      "name": "Montserrat"
    },
    {
      "name": "Morocco"
    },
    {
      "name": "Mozambique"
    },
    {
      "name": "Myanmar (Burma)"
    },
    {
      "name": "Namibia"
    },
    {
      "name": "Nauru"
    },
    {
      "name": "Nepal"
    },
    {
      "name": "New Caledonia"
    },
    {
      "name": "New Zealand"
    },
    {
      "name": "Nicaragua"
    },
    {
      "name": "Niger"
    },
    {
      "name": "Nigeria"
    },
    {
      "name": "Niue"
    },
    {
      "name": "Norfolk Island"
    },
    {
      "name": "North Korea"
    },
    {
      "name": "Northern Mariana Islands"
    },
    {
      "name": "Oman"
    },
    {
      "name": "Pakistan"
    },
    {
      "name": "Palau"
    },
    {
      "name": "Palestinian Territories"
    },
    {
      "name": "Panama"
    },
    {
      "name": "Papua New Guinea"
    },
    {
      "name": "Paraguay"
    },
    {
      "name": "Peru"
    },
    {
      "name": "Philippines"
    },
    {
      "name": "Pitcairn Islands"
    },
    {
      "name": "Puerto Rico"
    },
    {
      "name": "Qatar"
    },
    {
      "name": "Rwanda"
    },
    {
      "name": "Réunion"
    },
    {
      "name": "Samoa"
    },
    {
      "name": "Saudi Arabia"
    },
    {
      "name": "Senegal"
    },
    {
      "name": "Seychelles"
    },
    {
      "name": "Sierra Leone"
    },
    {
      "name": "Singapore"
    },
    {
      "name": "Solomon Islands"
    },
    {
      "name": "Somalia"
    },
    {
      "name": "South Africa"
    },
    {
      "name": "South Korea"
    },
    {
      "name": "Sri Lanka"
    },
    {
      "name": "St. Barthélemy"
    },
    {
      "name": "St. Helena"
    },
    {
      "name": "St. Kitts & Nevis"
    },
    {
      "name": "St. Lucia"
    },
    {
      "name": "St. Martin"
    },
    {
      "name": "St. Pierre & Miquelon"
    },
    {
      "name": "St. Vincent & Grenadines"
    },
    {
      "name": "Sudan"
    },
    {
      "name": "Suriname"
    },
    {
      "name": "Swaziland"
    },
    {
      "name": "Syria"
    },
    {
      "name": "São Tomé & Príncipe"
    },
    {
      "name": "Taiwan"
    },
    {
      "name": "Tajikistan"
    },
    {
      "name": "Tanzania"
    },
    {
      "name": "Thailand"
    },
    {
      "name": "Timor-Leste"
    },
    {
      "name": "Togo"
    },
    {
      "name": "Tokelau"
    },
    {
      "name": "Tonga"
    },
    {
      "name": "Trinidad & Tobago"
    },
    {
      "name": "Tunisia"
    },
    {
      "name": "Turkey"
    },
    {
      "name": "Turkmenistan"
    },
    {
      "name": "Turks & Caicos Islands"
    },
    {
      "name": "Tuvalu"
    },
    {
      "name": "U.S. Virgin Islands"
    },
    {
      "name": "Uganda"
    },
    {
      "name": "United Arab Emirates"
    },
    {
      "name": "United States"
    },
    {
      "name": "Uruguay"
    },
    {
      "name": "Uzbekistan"
    },
    {
      "name": "Vanuatu"
    },
    {
      "name": "Venezuela"
    },
    {
      "name": "Vietnam"
    },
    {
      "name": "Wallis & Futuna"
    },
    {
      "name": "Western Sahara"
    },
    {
      "name": "Yemen"
    },
    {
      "name": "Zambia"
    },
    {
      "name": "Zimbabwe"
    }
  ],
    },
    {
     "id": 6,
     "name": "RU VPN",
     "country" : [
    {
      "name": "Armenia"
    },
    {
      "name": "Azerbaijan"
    },
    {
      "name": "Belarus"
    },
    {
      "name": "Georgia"
    },
    {
      "name": "Kazakhstan"
    },
    {
      "name": "Kyrgyzstan"
    },
    {
      "name": "Moldova"
    },
    {
      "name": "Russia"
    },
    {
      "name": "Tajikistan"
    },
    {
      "name": "Turkmenistan"
    },
    {
      "name": "Ukraine"
    },
    {
      "name": "Uzbekistan"
    }
  ],
    },
    {
     "id": 7,
     "name": "Russia",
     "country" : [
    {
      "name": "Russia"
    }
  ],
    },
    {
     "id": 8,
     "name": "United Kingdom",
     "country" : [
    {
      "name": "United Kingdom"
    }
  ],
    },
    {
     "id": 9,
     "name": "China",
     "country" : [
    {
      "name": "China"
    }
  ],
    },
    {
     "id": 10,
     "name": "RoW (Rest of World)",
     "country" : [
    {
      "name": "Afghanistan"
    },
    {
      "name": "Albania"
    },
    {
      "name": "Algeria"
    },
    {
      "name": "American Samoa"
    },
    {
      "name": "Andorra"
    },
    {
      "name": "Angola"
    },
    {
      "name": "Anguilla"
    },
    {
      "name": "Antigua & Barbuda"
    },
    {
      "name": "Argentina"
    },
    {
      "name": "Armenia"
    },
    {
      "name": "Aruba"
    },
    {
      "name": "Australia"
    },
    {
      "name": "Austria"
    },
    {
      "name": "Azerbaijan"
    },
    {
      "name": "Bahamas"
    },
    {
      "name": "Bahrain"
    },
    {
      "name": "Bangladesh"
    },
    {
      "name": "Barbados"
    },
    {
      "name": "Belarus"
    },
    {
      "name": "Belgium"
    },
    {
      "name": "Belize"
    },
    {
      "name": "Benin"
    },
    {
      "name": "Bermuda"
    },
    {
      "name": "Bhutan"
    },
    {
      "name": "Bolivia"
    },
    {
      "name": "Bosnia & Herzegovina"
    },
    {
      "name": "Botswana"
    },
    {
      "name": "Brazil"
    },
    {
      "name": "British Virgin Islands"
    },
    {
      "name": "Brunei"
    },
    {
      "name": "Bulgaria"
    },
    {
      "name": "Burkina Faso"
    },
    {
      "name": "Burundi"
    },
    {
      "name": "Cambodia"
    },
    {
      "name": "Cameroon"
    },
    {
      "name": "Canada"
    },
    {
      "name": "Cape Verde"
    },
    {
      "name": "Cayman Islands"
    },
    {
      "name": "Central African Republic"
    },
    {
      "name": "Chad"
    },
    {
      "name": "Chile"
    },
    {
      "name": "China"
    },
    {
      "name": "Colombia"
    },
    {
      "name": "Comoros"
    },
    {
      "name": "Congo - Brazzaville"
    },
    {
      "name": "Congo - Kinshasa"
    },
    {
      "name": "Cook Islands"
    },
    {
      "name": "Costa Rica"
    },
    {
      "name": "Croatia"
    },
    {
      "name": "Cuba"
    },
    {
      "name": "Cyprus"
    },
    {
      "name": "Czech Republic"
    },
    {
      "name": "Côte D’Ivoire"
    },
    {
      "name": "Denmark"
    },
    {
      "name": "Djibouti"
    },
    {
      "name": "Dominica"
    },
    {
      "name": "Dominican Republic"
    },
    {
      "name": "Ecuador"
    },
    {
      "name": "Egypt"
    },
    {
      "name": "El Salvador"
    },
    {
      "name": "Equatorial Guinea"
    },
    {
      "name": "Eritrea"
    },
    {
      "name": "Estonia"
    },
    {
      "name": "Ethiopia"
    },
    {
      "name": "Falkland Islands"
    },
    {
      "name": "Faroe Islands"
    },
    {
      "name": "Fiji"
    },
    {
      "name": "Finland"
    },
    {
      "name": "France"
    },
    {
      "name": "French Guiana"
    },
    {
      "name": "French Polynesia"
    },
    {
      "name": "Gabon"
    },
    {
      "name": "Gambia"
    },
    {
      "name": "Georgia"
    },
    {
      "name": "Ghana"
    },
    {
      "name": "Gibraltar"
    },
    {
      "name": "Greece"
    },
    {
      "name": "Greenland"
    },
    {
      "name": "Grenada"
    },
    {
      "name": "Guadeloupe"
    },
    {
      "name": "Guam"
    },
    {
      "name": "Guatemala"
    },
    {
      "name": "Guernsey"
    },
    {
      "name": "Guinea"
    },
    {
      "name": "Guinea-Bissau"
    },
    {
      "name": "Guyana"
    },
    {
      "name": "Haiti"
    },
    {
      "name": "Honduras"
    },
    {
      "name": "Hong Kong Sar China"
    },
    {
      "name": "Hungary"
    },
    {
      "name": "Iceland"
    },
    {
      "name": "India"
    },
    {
      "name": "Indonesia"
    },
    {
      "name": "Iran"
    },
    {
      "name": "Iraq"
    },
    {
      "name": "Ireland"
    },
    {
      "name": "Isle Of Man"
    },
    {
      "name": "Israel"
    },
    {
      "name": "Italy"
    },
    {
      "name": "Jamaica"
    },
    {
      "name": "Japan"
    },
    {
      "name": "Jersey"
    },
    {
      "name": "Jordan"
    },
    {
      "name": "Kazakhstan"
    },
    {
      "name": "Kenya"
    },
    {
      "name": "Kiribati"
    },
    {
      "name": "Kuwait"
    },
    {
      "name": "Kyrgyzstan"
    },
    {
      "name": "Laos"
    },
    {
      "name": "Latvia"
    },
    {
      "name": "Lebanon"
    },
    {
      "name": "Lesotho"
    },
    {
      "name": "Liberia"
    },
    {
      "name": "Libya"
    },
    {
      "name": "Liechtenstein"
    },
    {
      "name": "Lithuania"
    },
    {
      "name": "Luxembourg"
    },
    {
      "name": "Macau Sar China"
    },
    {
      "name": "Macedonia"
    },
    {
      "name": "Madagascar"
    },
    {
      "name": "Malawi"
    },
    {
      "name": "Malaysia"
    },
    {
      "name": "Maldives"
    },
    {
      "name": "Mali"
    },
    {
      "name": "Malta"
    },
    {
      "name": "Marshall Islands"
    },
    {
      "name": "Martinique"
    },
    {
      "name": "Mauritania"
    },
    {
      "name": "Mauritius"
    },
    {
      "name": "Mayotte"
    },
    {
      "name": "Mexico"
    },
    {
      "name": "Micronesia"
    },
    {
      "name": "Moldova"
    },
    {
      "name": "Monaco"
    },
    {
      "name": "Mongolia"
    },
    {
      "name": "Montenegro"
    },
    {
      "name": "Montserrat"
    },
    {
      "name": "Morocco"
    },
    {
      "name": "Mozambique"
    },
    {
      "name": "Myanmar (Burma)"
    },
    {
      "name": "Namibia"
    },
    {
      "name": "Nauru"
    },
    {
      "name": "Nepal"
    },
    {
      "name": "Netherlands"
    },
    {
      "name": "New Caledonia"
    },
    {
      "name": "New Zealand"
    },
    {
      "name": "Nicaragua"
    },
    {
      "name": "Niger"
    },
    {
      "name": "Nigeria"
    },
    {
      "name": "Niue"
    },
    {
      "name": "Norfolk Island"
    },
    {
      "name": "North Korea"
    },
    {
      "name": "Northern Mariana Islands"
    },
    {
      "name": "Norway"
    },
    {
      "name": "Oman"
    },
    {
      "name": "Pakistan"
    },
    {
      "name": "Palau"
    },
    {
      "name": "Palestinian Territories"
    },
    {
      "name": "Panama"
    },
    {
      "name": "Papua New Guinea"
    },
    {
      "name": "Paraguay"
    },
    {
      "name": "Peru"
    },
    {
      "name": "Philippines"
    },
    {
      "name": "Pitcairn Islands"
    },
    {
      "name": "Poland"
    },
    {
      "name": "Portugal"
    },
    {
      "name": "Puerto Rico"
    },
    {
      "name": "Qatar"
    },
    {
      "name": "Romania"
    },
    {
      "name": "Russia"
    },
    {
      "name": "Rwanda"
    },
    {
      "name": "Réunion"
    },
    {
      "name": "Samoa"
    },
    {
      "name": "San Marino"
    },
    {
      "name": "Saudi Arabia"
    },
    {
      "name": "Senegal"
    },
    {
      "name": "Serbia"
    },
    {
      "name": "Seychelles"
    },
    {
      "name": "Sierra Leone"
    },
    {
      "name": "Singapore"
    },
    {
      "name": "Slovakia"
    },
    {
      "name": "Slovenia"
    },
    {
      "name": "Solomon Islands"
    },
    {
      "name": "Somalia"
    },
    {
      "name": "South Africa"
    },
    {
      "name": "South Korea"
    },
    {
      "name": "Spain"
    },
    {
      "name": "Sri Lanka"
    },
    {
      "name": "St. Barthélemy"
    },
    {
      "name": "St. Helena"
    },
    {
      "name": "St. Kitts & Nevis"
    },
    {
      "name": "St. Lucia"
    },
    {
      "name": "St. Martin"
    },
    {
      "name": "St. Pierre & Miquelon"
    },
    {
      "name": "St. Vincent & Grenadines"
    },
    {
      "name": "Sudan"
    },
    {
      "name": "Suriname"
    },
    {
      "name": "Svalbard & Jan Mayen"
    },
    {
      "name": "Swaziland"
    },
    {
      "name": "Sweden"
    },
    {
      "name": "Switzerland"
    },
    {
      "name": "Syria"
    },
    {
      "name": "São Tomé & Príncipe"
    },
    {
      "name": "Taiwan"
    },
    {
      "name": "Tajikistan"
    },
    {
      "name": "Tanzania"
    },
    {
      "name": "Thailand"
    },
    {
      "name": "Timor-Leste"
    },
    {
      "name": "Togo"
    },
    {
      "name": "Tokelau"
    },
    {
      "name": "Tonga"
    },
    {
      "name": "Trinidad & Tobago"
    },
    {
      "name": "Tunisia"
    },
    {
      "name": "Turkey"
    },
    {
      "name": "Turkmenistan"
    },
    {
      "name": "Turks & Caicos Islands"
    },
    {
      "name": "Tuvalu"
    },
    {
      "name": "U.S. Virgin Islands"
    },
    {
      "name": "Uganda"
    },
    {
      "name": "Ukraine"
    },
    {
      "name": "United Arab Emirates"
    },
    {
      "name": "United Kingdom"
    },
    {
      "name": "United States"
    },
    {
      "name": "Uruguay"
    },
    {
      "name": "Uzbekistan"
    },
    {
      "name": "Vanuatu"
    },
    {
      "name": "Vatican City"
    },
    {
      "name": "Venezuela"
    },
    {
      "name": "Vietnam"
    },
    {
      "name": "Wallis & Futuna"
    },
    {
      "name": "Western Sahara"
    },
    {
      "name": "Yemen"
    },
    {
      "name": "Zambia"
    },
    {
      "name": "Zimbabwe"
    },
    {
      "name": "Åland Islands"
    }
  ],
    },
    {
     "id": 11,
     "name": "Latin America",
     "country" : [
    {
      "name": "Argentina"
    },
    {
      "name": "Bahamas"
    },
    {
      "name": "Belize"
    },
    {
      "name": "Bolivia"
    },
    {
      "name": "Brazil"
    },
    {
      "name": "Chile"
    },
    {
      "name": "Colombia"
    },
    {
      "name": "Costa Rica"
    },
    {
      "name": "Ecuador"
    },
    {
      "name": "El Salvador"
    },
    {
      "name": "French Guiana"
    },
    {
      "name": "Guatemala"
    },
    {
      "name": "Guyana"
    },
    {
      "name": "Honduras"
    },
    {
      "name": "Nicaragua"
    },
    {
      "name": "Panama"
    },
    {
      "name": "Paraguay"
    },
    {
      "name": "Peru"
    },
    {
      "name": "Suriname"
    },
    {
      "name": "Uruguay"
    },
    {
      "name": "Venezuela"
    }
  ],
    },
    {
     "id": 12,
     "name": "Asia",
     "country" : [
    {
      "name": "Afghanistan"
    },
    {
      "name": "Armenia"
    },
    {
      "name": "Azerbaijan"
    },
    {
      "name": "Bahrain"
    },
    {
      "name": "Bangladesh"
    },
    {
      "name": "Bhutan"
    },
    {
      "name": "Brunei"
    },
    {
      "name": "Cambodia"
    },
    {
      "name": "China"
    },
    {
      "name": "Georgia"
    },
    {
      "name": "Hong Kong Sar China"
    },
    {
      "name": "India"
    },
    {
      "name": "Indonesia"
    },
    {
      "name": "Iran"
    },
    {
      "name": "Iraq"
    },
    {
      "name": "Israel"
    },
    {
      "name": "Japan"
    },
    {
      "name": "Jordan"
    },
    {
      "name": "Kazakhstan"
    },
    {
      "name": "Kuwait"
    },
    {
      "name": "Kyrgyzstan"
    },
    {
      "name": "Laos"
    },
    {
      "name": "Lebanon"
    },
    {
      "name": "Macau Sar China"
    },
    {
      "name": "Malaysia"
    },
    {
      "name": "Maldives"
    },
    {
      "name": "Mongolia"
    },
    {
      "name": "Myanmar (Burma)"
    },
    {
      "name": "Nepal"
    },
    {
      "name": "North Korea"
    },
    {
      "name": "Oman"
    },
    {
      "name": "Pakistan"
    },
    {
      "name": "Palestinian Territories"
    },
    {
      "name": "Philippines"
    },
    {
      "name": "Qatar"
    },
    {
      "name": "Saudi Arabia"
    },
    {
      "name": "Singapore"
    },
    {
      "name": "South Korea"
    },
    {
      "name": "Sri Lanka"
    },
    {
      "name": "Syria"
    },
    {
      "name": "Taiwan"
    },
    {
      "name": "Tajikistan"
    },
    {
      "name": "Thailand"
    },
    {
      "name": "Timor-Leste"
    },
    {
      "name": "Turkey"
    },
    {
      "name": "Turkmenistan"
    },
    {
      "name": "United Arab Emirates"
    },
    {
      "name": "Uzbekistan"
    },
    {
      "name": "Vietnam"
    },
    {
      "name": "Yemen"
    }
  ],
    },
    {
     "id": 13,
     "name": "Germany",
     "country" : [
    {
      "name": "Germany"
    }
  ],
    },
    {
     "id": 14,
     "name": "Australia",
     "country" : [
    {
      "name": "Australia"
    }
  ],
    },
    {
     "id": 15,
     "name": "Brazil",
     "country" : [
    {
      "name": "Brazil"
    }
  ],
    },
    {
     "id": 16,
     "name": "India",
     "country" : [
    {
      "name": "India"
    }
  ],
    },
    {
     "id": 17,
     "name": "Japan",
     "country" : [
    {
      "name": "Japan"
    }
  ],
    },
    {
     "id": 18,
     "name": "North America",
     "country" : [
    {
      "name": "Canada"
    },
    {
      "name": "United States"
    }
  ],
    }
  ];