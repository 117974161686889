<span *ngIf="displayType=='dropdown'">
    <select class="selebitcoin" [(ngModel)]="profileObj.currency_code" (change)="getUpdatedPrice();">
        <option *ngFor="let method of CyrptCurrency;" value="{{method.symbol}}">{{method.code}}</option>
    </select>
</span>
<span *ngIf="displayType=='listview'" class="top-list">
    <div class="search-list" style="margin-bottom: 15px;">
        <i class="fa fa-search"></i>
        <input type="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Enter Token/coin name" />
    </div>

    <div class="mt-2" *ngIf="(allRecentCrypto|filter:searchText).length>0">
        <h5>Recently Used</h5>
    </div>
    <ul class="row mb-5" *ngIf="(allRecentCrypto|filter:searchText).length>0">
        <li class="col-sm-6 col-md-4" *ngFor="let method of allRecentCrypto.slice(0,9) | filter:searchText">
            <div class="mybox">
                <!-- <div class="hidebox2"></div> -->
                <label (click)="selectCrypto(method);">
                    <input type="radio" name="tools" id="tool-{{method.code}}" value="{{method.code}}" /> <img src="{{imgBaseUrl}}crypto/{method.image}}" style="width: 50px; height: 50px;" />
                    <span>
                        <!--  ({{method.code}}) -->
                        {{method.display_name}}<br />
                        <i *ngIf="method.calculatedAmount">{{method.calculatedAmount}}</i>
                        <i *ngIf="!method.calculatedAmount">Calculating...</i>
                    </span>
                </label>
            </div>
        </li>
    </ul>
    <h5>All Cryptocurrencies</h5>
    <ul class="row">
        <li class="col-sm-6 col-md-4" *ngFor="let method of CyrptCurrency | filter:searchText">
            <div class="mybox">
                <!-- <div class="hidebox2"></div> -->
                <label (click)="selectCrypto(method);">
                    <input type="radio" name="tools" id="tool-{{method.code}}" value="{{method.code}}" /> <img src="{{imgBaseUrl}}crypto/{{method.image}}" style="width: 50px; height: 50px;" />
                    <span>
                        <!--  ({{method.code}}) -->
                        {{method.display_name}}<br />  
                        <i *ngIf="method.calculatedAmount">{{method.calculatedAmount}}</i>
                        <i *ngIf="!method.calculatedAmount">Calculating...</i>
                    </span>
                </label>
            </div>
        </li>
    </ul>
    <!-- <div *ngFor="let method of CyrptCurrency;" class="col-6 col-sm-6 col-md-4 col-lg-4 coinbox_padd">
        <div class="save-adress" (click)="selectCrypto(method);">
            <input class="checkbox-tools" type="radio" name="tools" id="tool-{{method.code}}" />
            <label class="for-checkbox-tools waves-effect waves-light" for="tool-{{method.code}}">
                <div class="extrnal-payment">
                    <img src="{{imgBaseUrl}}crypto/{{method.image}}" style="width:30px;height:30px;">
                    <div class="payname">
                        <p class="currancyname">{{method.display_name}} ({{method.code}})</p>
                        <span class="countcurancy">
                            <p class="bitpice" *ngIf="method.calculatedAmount">{{method.calculatedAmount}}</p>
                            <p class="bitpice" *ngIf="!method.calculatedAmount">Calculating...</p>
                        </span>
                    </div>
                </div>
            </label>
        </div>
    </div> -->
</span>
