import { Component, OnInit } from '@angular/core';
import { ENDPOINTS } from '../shared/constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CoreService } from '../shared/services/core.service';
import { HelperService } from '../shared/services/helper-service.service';
import { environment } from 'src/environments/environment';
import {ToastrService} from 'ngx-toastr';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
declare var $: any;

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {
  public newsSubscribeForm!: FormGroup;
  public imgBaseUrl = environment.AWSImageURL;
  public webUrl = environment.WEB_APP;
  public lang:any = '';
  public brands: any = [];
  public isLoggedIn:boolean = false;
  public isLoading:boolean = false;
  public productRecommendationList:any = [];
  public crptoNews: any = [];
  public user_email:any = '';
  public isFormSubmit:boolean = false;
  public typeaheadLoading:boolean = false;
  public productnames:any = [];
  public product_name:string = '';
  public productId:string = '';
  public queryParam:any = '';
  public reff:any = '';
  public referalUserId:any = '';
  constructor(
    private fb: FormBuilder,
    private toast: ToastrService,
    private coreService: CoreService,
    private route: ActivatedRoute,
    private helperService: HelperService,
    private _auth : AuthService,
    private router: Router,
  ) {
    
   }
   
  ngOnInit(): void {
    if(this._auth.loggedIn()){
      this.isLoggedIn = true;
    }else{
      this.isLoggedIn = false;
    }
    $(".explore_collection").slick({
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }
  ]
    });
    this.route.queryParams.subscribe(params => {
      if (!params) return;
      this.queryParam = { ...params };
      if (this.queryParam.hasOwnProperty('reff')) {
        this.reff = this.queryParam.reff;
        delete this.queryParam.reff;
      }

      if(this.reff!=''){
        if(!this.isLoggedIn){
          $("#signup").modal("show");
          this.coreService.post(`${ENDPOINTS.AUTH.VALIDATE_REFERRAL_CODE}`, {'referral_code':this.reff}).subscribe((res) => {
            if (res.body.success) {
              this.referalUserId = res.body.data;
              $("#parent_id").val(res.body.data.id);
            }
          }, (err) => {
          });
        }
        else{
          this.toast.error("You are already logged, Please do logout then hit the url.", "Error!");
        }
      }
    });

    this.newsSubscribeForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });

    this.getBrands();
    this.getRecommendationProduct();
    this.getCrytoNews();
    setTimeout(function(){
      $(".carousel.carousel-multi-item.v-2 .carousel-item").each(function (this:any) {
          var next = $(this).next();
          if (!next.length) {
              next = $(this).siblings(":first");
          }
          next.children(":first-child").clone().appendTo($(this));
          for (var i = 0; i < 4; i++) {
              next = next.next();
              if (!next.length) {
                  next = $(this).siblings(":first");
              }
              next.children(":first-child").clone().appendTo($(this));
          }
      });
    },3000);
  }

  get get() { return this.newsSubscribeForm.controls; }

  filterResults(){
    this.coreService.post(`${ENDPOINTS.LANDING.AUTOSUGGESTION}`, {'keyword':this.product_name}).subscribe((res) => {
      if (res.body.success) {
        this.productnames = res.body.data;
        $('.more-record').remove();
        if(this.productnames.length>6){
          let link = "/shop?search="+this.product_name;
          $("#ngb-typeahead-0").append("<span class='more-record' style='float: left;text-align: center;width: 100%;border-top: 1px solid #ddd;padding-top: 10px;padding-bottom: 7px;'><a style='font-weight: bold;' href='"+link+"'>Total Record "+this.productnames.length+"</a><span>");
        }
      } else {
        this.productnames = [];
      }
    }, (err) => {
      this.productnames = [];
    });
  }

  // Selected value event
  typeaheadOnSelect(e: TypeaheadMatch): void {
    //this.helperService.navigate(`/shop-detail/${e.item.product_slug}`, this.lang);
    window.location.href = '/shop-detail/'+e.item.product_slug;
  }

  // Show loading indicator
  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }
  
  getBrands(){
    this.coreService.get(`${ENDPOINTS.LANDING.GET_FEATURED_BRANDS}`, {}).subscribe((res) => {
      if (res.body.success) {
        this.brands = res.body.data;
      } else {
        this.brands = [];
      }
    }, (err) => {
    });
  }

  getCrytoNews(){
    this.coreService.getCryptonewsApi().subscribe((res) => {
      this.crptoNews = res.body.data;
    }, (err) => {
      this.crptoNews = [];
    });
  }

  viewProducts(obj:any){
    if(obj=='all'){
      $(".filter").show("1000");
    }else{
      this.productId = obj;
      $(".filter")
          .not(".filterclass_" + obj)
          .hide("1000");
      $(".filter")
          .filter(".filterclass_" + obj)
          .show("1000");
    }
    $(".filter-button").removeClass("active");
    $("#filter_"+obj).addClass("active");
  }

  getRecommendationProduct(){
    this.coreService.get(`${ENDPOINTS.SOLUTION.RECOMMENDATION}`+'?type=recommendation', {}).subscribe((res) => {
      if (res.body.success) {
        this.productRecommendationList = res.body.data;
        if(this.productRecommendationList.category.length>0){
          let that = this;
          setTimeout(function(){
            that.viewProducts(that.productRecommendationList.category[0].id);
          },1000);          
        }
      } else {
        this.productRecommendationList = [];
      }
    }, (err) => {
    });
  }

  doSubscribe(formData:any){
    this.isFormSubmit = true;
    if(this.newsSubscribeForm.valid && !this.isLoading){
      const paylaod = {
        ...formData
      }
      this.isLoading = true;
      this.coreService.post(`${ENDPOINTS.LANDING.SUBSCRIBE_EMAIL}`, paylaod).subscribe((res) => {
        this.isFormSubmit = false;
        this.isLoading = false;
        if (res.body.success) {
          this.toast.success(res.body.message);
          this.newsSubscribeForm.reset();
        } else {
          this.toast.error(res.body.message);
        }
      }, (err) => {
        this.isLoading = false;
        this.toast.error("something went wrong!");
      });
    }
  }

  openShopPage(){
    if(this.productId!='')
      this.router?.navigate(['/shop'], { queryParams: {'category':this.productId}});
    else
      this.helperService.navigate(`/shop`, this.lang);  
  }

  goToShopPage(){
    if(this.product_name!='')
      this.router?.navigate(['/shop'], { queryParams: {'search':this.product_name}});
    else
    this.helperService.navigate(`/shop`, this.lang);
  }

  goToBrandProducts(obj:any){
    this.router?.navigate(['/shop'], { queryParams: {'brand':obj.id}});
  }

  goToShopProduct(collectionName:any){
    this.router?.navigate(['/shop'], { queryParams: {'collection':collectionName}});
  }

  goToGiftCard(collectionName:any){
    this.router?.navigate(['/gift'], { queryParams: {'collection':collectionName}});
  }

  goToProductDetailPage(obj:any) {
    //this.helperService.navigate(`/shop-detail/${obj.product_slug}`, this.lang);
    window.location.href = '/shop-detail/'+obj.product_slug;
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang)
  }
  stopVideo(){
    var src = $('#youtube_videos').attr('src');
    $('#youtube_videos').attr('src','');
    $('#youtube_videos').attr('src',src);
  }
}
