import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/shared/services/core.service';
import { HelperService } from '../../shared/services/helper-service.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { environment } from 'src/environments/environment';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.css']
})
export class VerifyComponent implements OnInit {
  public imgBaseUrl = environment.AWSImageURL;
  public profileObj:any = {
    selfie_image    :   '',
    document_name   :   '',
    document_mime_type   :   'image',
    document_type   :   'passport'
  };
  public imageSrc:any = '';
  public lang:any = '';
  public formData:any = {
    selfie_image    :   '',
    document_name   :   '',
    document_mime_type   :   'image',
    document_type   :   'passport'
  };
  public isReupload:boolean = false;
  public isLoading:boolean = true;
  public flieType:any = '';
  constructor(
    public coreService: CoreService,
    private toast: ToastrService,
    private helperService: HelperService,
  ) { }

  ngOnInit(): void {
    this.getMyDocument();
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang)
  }

  getMyDocument(){
    this.coreService.get(`${ENDPOINTS.UPLOAD_FILE.GET_DOCUMENT}`, {}).subscribe((res) => {
      this.isLoading =false
      if (res.body.success) {
        this.profileObj = res.body.data;
        if(this.profileObj.document_type!=''){
          this.formData.document_type = this.profileObj.document_type;
        }
      }
    }, (err) => {
      this.isLoading =false
    });
  }

  handleInputChange(e:any,type:any){
    this.flieType = type;
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (file.type.match(pattern)) {
      if(this.flieType!='selfie'){
        this.formData.document_mime_type = 'image';
      }
      reader.onload = this.imageUIpload.bind(this);
      reader.readAsDataURL(file);
    }else{
      if(this.flieType!='selfie'){
        if (file.type.match('pdf')) {
          this.formData.document_mime_type = 'pdf';
          reader.onload = this.pdfUpload.bind(this);
          reader.readAsDataURL(file);
        }else{
          this.toast.error("File not supported!", "Error!");
        }      
      }else{
        this.toast.error("File not supported!", "Error!");
      }
    }
  }



  
  
  imageUIpload(e:any) {
    let reader = e.target;
    this.imageSrc = reader.result;
    this.coreService.post(`${ENDPOINTS.UPLOAD_FILE.IMAGE}`, {'type':'profile','image':reader.result}).subscribe((res) => {
      if (res.body.success) {
        if(this.flieType=='selfie'){
          this.formData.selfie_image = res.body.data;
        }else{
          this.formData.document_name = res.body.data;
        }
        this.toast.success(res.body.message, "Success!");
      } else {
        this.toast.error("File uploading failed!", "Error!");
      }
    }, (err) => {
    });



  }

  pdfUpload(e:any) {
    let reader = e.target;
    this.coreService.post(`${ENDPOINTS.UPLOAD_FILE.PDF}`, {'type':'profile','file':reader.result}).subscribe((res) => {
      if (res.body.success) {
        if(this.flieType=='selfie'){
          this.formData.selfie_image = res.body.data;
        }else{
          this.formData.document_name = res.body.data;
        }
        this.toast.success(res.body.message, "Success!");
      } else {
        this.toast.error("File uploading failed!", "Error!");
      }
    }, (err) => {
    });
  }
  
  reUploadDocument(){
    this.isReupload = true;
  }

  removeImage(image:string){
    if(image=='document_name'){
      this.formData.document_name = '';
    }else{
      this.formData.selfie_image = '';
    }
  }

  doUploadDocument(){
    if(!this.isLoading){
      //this.isLoading = true;
      this.coreService.post(`${ENDPOINTS.UPLOAD_FILE.UPLOAD_DOCUMENT}`, this.formData).subscribe((res) => {
        //this.isLoading = false;
        if (res.body.success) {
          this.isReupload = false;
          this.profileObj = res.body.data;
          this.toast.success(res.body.message, "Success!");
        }else{
          this.toast.error("File uploading failed!", "Error!");
        }
      }, (err) => {
        //this.isLoading = false;
        this.toast.error(err.body.message, "Error!");
      });
    }
  }
}




