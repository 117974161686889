import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoreService } from '../shared/services/core.service';
import { HelperService } from '../shared/services/helper-service.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../shared/services/auth.service';
import { COUNTRY } from 'src/app/shared/constants/app.country.constants';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { timer, Subscription } from "rxjs";
import {ToastrService} from 'ngx-toastr';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';


declare var $: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  countDown!: Subscription;
  counter:any = 3600;
  tick:any = 1000;
  public addressForm!: FormGroup;
  public guestCheckoutForm!: FormGroup;
  public lang:any = '';
  public imgBaseUrl = environment.AWSImageURL;
  public countryLists:any = COUNTRY;
  public cartItems:any = [];
  public totalCartValue:any = 0;
  public shippingAmount:any = 0;
  public discountAmount:any = 0;
  public walletBalance:any = 0;
  public conveyanceCharges:any = 0;
  public profileObj:any = {};
  public selectedAddres:any = {};
  public isOneTimePaymentDone:boolean = false;
  public isFiatProductAdded:boolean = false;
  public isAddressList:boolean = false;
  public checkoutForm:boolean = false;
  public addressList:any = [];
  public paymentPageEnable:boolean = false;
  public address_id:any = "";
  public coupon_code:string = "";
  public orderId:string = "";
  public msg:string = '';
  public errormsg:string = '';
  public isLoggedIn:boolean = false;
  public loadCartItem:boolean = true;
  public showSuccess:boolean = false;
  public isFormSubmit:boolean = false;
  public payWithWalletButton:boolean = false;
  public isLoading:boolean = true;
  public isOnlyGiftAndTopup:boolean = true;
  public payPalConfig?: IPayPalConfig;
  public payPalProductItems:any = [];
  public usedCryptoCode:any = [];
  public checkoutFormData:any = {};
  public cryptoPrice:any = {};
  public pay_with_wallet:any = '';
  public qrCodeUrl:any = '';
  public pay_address:any = '';
  public pay_extra_id:any = '';
  public crypto_pay_code:any = '';
  public crypto_pay_amount:any = '';
  public dedcut_from_wallet:any = 0;
  public exchangeRates:any = 0;
  public inrExchangeRates:any = 0;
  public elementType = NgxQrcodeElementTypes.URL;
  public correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  
  constructor(
    private fb: FormBuilder,
    private toast: ToastrService,
    private coreService: CoreService,
    public helperService: HelperService,
    private _auth : AuthService,
    private router: Router,
  ) {

    if(this._auth.loggedIn()){
      this.isLoggedIn = true;
    }else{
      this.isLoggedIn = false;
    }
   }

  ngOnInit(): void {    
    window.addEventListener('mouseup',function(event:any){
      $('.tagShowQR').hide("slow");
      $('.hidden-menu').hide("slow");
    });
    let profileObj:any = localStorage.getItem('currentUser') != null ? localStorage.getItem('currentUser') : '{"currency_code":"BTCUSDT"}';
    this.profileObj = JSON.parse(profileObj);

    let usedCryptoCode:any = (localStorage.getItem('usedCrypto') != null && localStorage.getItem('usedCrypto') != 'undefined') ? localStorage.getItem('usedCrypto') : JSON.stringify([]);
    this.usedCryptoCode = JSON.parse(usedCryptoCode);

    this.guestCheckoutForm = this.fb.group({
      full_name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone_number: ['', [Validators.required]]
    });

    this.addressForm = this.fb.group({
      address_type: ['', [Validators.required]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      phone_number: ['', [Validators.required]],
      address: ['', [Validators.required]],
      landmark: [''],
      city: [''],
      zipcode: [''],
      country: [''],
      state: [''],
      id: [''],
      save_for_later: false,
      order_note: [''],
    });
    this.addressForm.patchValue({
      address_type: 'HOME',
    });
    if(this.isLoggedIn){
      this.getAddressList();
    }
    this.getExchangeRates('USD');
    this.getExchangeRates('INR');
  }
  get get() { return this.addressForm.controls; }
  get getcheckout() { return this.guestCheckoutForm.controls; }

  getAddressList(){
    this.isAddressList = true;
    this.coreService.get(`${ENDPOINTS.USER_ADDRESS.LIST}`, {}).subscribe((res) => {
      this.isAddressList = false;
      if (res.body.success) {
        this.addressList = res.body.data;
        if(this.addressList.length>0){
          this.address_id = this.addressList[0].id;
        }
      } else {
        this.addressList = [];
      }
    }, (err) => {
    });
  }

  editAddress(addressObj:any){
    $("#collapseTwo2").addClass("show");
    this.addressForm.patchValue({
      id: addressObj?.id,
      address_type: addressObj?.address_type,
      first_name: addressObj?.first_name,
      last_name: addressObj?.last_name,
      phone_number: addressObj?.phone_number,
      address: addressObj?.address,
      landmark: addressObj?.landmark,
      city: addressObj?.city,
      zipcode: addressObj?.zipcode,
      country: addressObj?.country,
      state: addressObj?.state
    }); 
  }

  deleteAddress(addressObj:any){
    var c = confirm("Are you sure?");
    if(c){
      const paylaod = {
        'address_id' : addressObj.id
      }
      this.coreService.post(`${ENDPOINTS.USER_ADDRESS.DELETE}`, paylaod).subscribe((res) => {
        this.addressList = res.body.data;
        this.toast.success(res.body.message, "Success!");
      }, (err) => {
        this.toast.error(err.body.message, "Error!");
      });
    }
  }

  saveAddress(){
    if(this.addressList.length==0){
      this.isFormSubmit = true;
    }
    if (this.addressForm.valid) {
      this.loadCartItem = true;
      const paylaod = {
        ...this.addressForm.value
      }
      if(paylaod.save_for_later){
        this.coreService.post(`${ENDPOINTS.USER_ADDRESS.CREATE}`, paylaod).subscribe((res) => {
          this.addressList = res.body.data;
          this.toast.success(res.body.message, "Success!");
          this.addressForm.reset();
          this.addressForm.patchValue({
            address_type: 'HOME',
            id : ''
          });
          var results = this.findByMatchingProperties(this.addressList, {'address':paylaod.address});
          if(results.length>0){
            this.loadCartItem = false;
            this.selectedAddres = results[0];
            this.initConfig();
            this.paymentPageEnable = true;
          }else{
            this.loadCartItem = false;
            this.toast.error("Sometihng went wrong!", "Error!");
          }
        }, (err) => {
          this.loadCartItem = false;
          this.toast.error(err.body.message,"Error!");
        });
      }else{
        this.loadCartItem = false;
        this.selectedAddres = paylaod;
        this.initConfig();
        this.paymentPageEnable = true;
      }
    }else{
      this.loadCartItem = false;
      var results = this.findByMatchingProperties(this.addressList, {'id':this.address_id});
      if(results.length>0){
        this.selectedAddres = results[0];
        this.initConfig();
        this.paymentPageEnable = true;
      }else{
        this.toast.error("Required fields are missing", "Error!");
      }
    }
  }

  showQRCode(str:any){
    if($('.'+str).is(':hidden')){
     $('.'+str).show("slow");
    }
   }

  guestCheckout(){
    this.loadCartItem = true;
    this.isFormSubmit = true;
    if (this.guestCheckoutForm.valid) {
      const paylaod = {
        ...this.guestCheckoutForm.value
      }
      this.selectedAddres = paylaod;
      this.initConfig();
      this.paymentPageEnable = true;
      this.loadCartItem = false;
    }else{
      this.toast.error("Required fields are missing", "Error!");
      this.loadCartItem = false;
    }
  }

  checkWalletBalance(){
    let that = this;
    setTimeout(function(){
      if(that.pay_with_wallet){
        if(parseFloat(that.walletBalance)>0){
          if(parseFloat(that.totalCartValue)<=parseFloat(that.walletBalance)){
            let amount = that.totalCartValue;
            that.dedcut_from_wallet = parseFloat(amount);
            that.payWithWalletButton = true;
          }
          else if(parseFloat(that.walletBalance)>0 && parseFloat(that.walletBalance)<parseFloat(that.totalCartValue)){
            that.dedcut_from_wallet = parseFloat(that.walletBalance);
            that.totalCartValue = parseFloat(that.totalCartValue) - parseFloat(that.walletBalance);
          }
        }
      }else{
        that.dedcut_from_wallet = 0;
        that.payWithWalletButton = false;
        that.reCalculateCartValue();        
      }
    },100);
  }

  findByMatchingProperties(set:any, properties:any) {
    return set.filter(function (entry:any) {
        return Object.keys(properties).every(function (key) {
            return entry[key] === properties[key];
        });
    });
  }

  verifyCoupon(){    
    if(this.coupon_code!=''){
      const paylaod = {
        'discount_code' : this.coupon_code
      }
      this.coreService.post(`${ENDPOINTS.DISCOUNT.APPLY}`, paylaod).subscribe((res) => {
        this.msg = '';
        this.errormsg = '';
        if(res.body.success){
          this.msg = res.body.message;
          this.totalCartValue = res.body.data.total_after_discount + this.shippingAmount;
          this.discountAmount = res.body.data.discount_amount;
          //this.totalCartValue = parseFloat(this.totalCartValue) - this.dedcut_from_wallet;
          this.checkWalletBalance();
        }
        else{
          this.errormsg = res.body.message;
          this.discountAmount = 0;
          this.reCalculateCartValue();
        }
      }, (err) => {
        this.msg = '';
        this.errormsg = '';
        this.discountAmount = 0;
        this.reCalculateCartValue();
      });
    }
  }

  removeCoupon(){
    this.msg = '';
    this.errormsg = '';
    this.discountAmount = 0;
    this.reCalculateCartValue();
  }

  goBackToCheckout(){
    this.paymentPageEnable = false;
  }

  getExchangeRates(currencyCode:any){
    //if(!this.exchangeRates){
      this.coreService.getExchangeRates(currencyCode).subscribe((res) => {
        //this.exchangeRates = res.body.data.rates;
        if(currencyCode=='USD'){
          this.exchangeRates = res.body.data.rates;
        }
        else{
          this.inrExchangeRates = res.body.data.rates;
        }
        this.cartItemLists();
      }, (err) => {
        this.exchangeRates = 0;
      });
    //}
  }
  
 /**
 * @method: addToCart
 * @description: Using to get list of cart
 * @param productObj
 */
  cartItemLists(){
    this.coreService.get(`${ENDPOINTS.CART.LIST}`, {}).subscribe((res) => {
      this.cartItems = res.body.data;
      if(this.cartItems.length==0){
        this.helperService.navigate(`/shop`, this.lang);
      }
      this.isFiatProductAdded = false;
      this.updateCartTotal();
    }, (err) => {

    });
  }

  updateCartTotal(){
    if(this.isLoggedIn){
      this.paymentPageEnable = true;
    }else{
      this.paymentPageEnable = false;
    }
    this.totalCartValue = 0;
    for (var i = 0; i <this.cartItems.length; i++) {
      if(this.cartItems[i].allow_only_fiat){
        this.isFiatProductAdded = true;
      }
      let price:any = 0;
      let productName = '';
      if(this.cartItems[i].cart_item=='product'){
        if(!this.isLoggedIn){
          this.goToNavigation('shop');
          this.toast.error("Login Required!", "Error!");
        }
        this.paymentPageEnable = false;
        this.isOnlyGiftAndTopup = false;
        productName = this.cartItems[i].product_id.product_name;
        price = parseFloat(this.cartItems[i].price).toFixed(2);
      }else{
        if(this.cartItems[i].cart_item=='balance_card'){
          productName = this.cartItems[i].balance_card_id.card_name;
        }
        else if(this.cartItems[i].cart_item=='toupup')
        {
          productName = "Mobile Topup";
        }
        else if(this.cartItems[i].cart_item=='gift_card'){
          productName = this.cartItems[i].gift_card_info.name;
        }

        if(this.cartItems[i].cart_item=='toupup'){
          if(this.cartItems[i].topup_product_info.destination.unit!='USD'){
            let rates:any = this.exchangeRates[this.cartItems[i].topup_product_info.destination.unit];
            let p:any = parseFloat(this.cartItems[i].topup_product_info.destination.amount) + (this.cartItems[i].topup_product_info.destination.amount * environment.EXCHANGE_FEE);
            this.cartItems[i].price = p / rates;
            price = parseFloat(this.cartItems[i].price).toFixed(2);
          }
          else{
            let rates:any = 1;
            let p:any = parseFloat(this.cartItems[i].topup_product_info.destination.amount) + (this.cartItems[i].topup_product_info.destination.amount * environment.EXCHANGE_FEE);
            this.cartItems[i].price = p / rates;
            price = parseFloat(this.cartItems[i].price).toFixed(2);
          }
        }
        else if(this.cartItems[i].cart_item=='gift_card'){
          console.log("*******************************"+this.cartItems[i].gift_card_info.currencyCode+"******************************")
          let fee = (this.cartItems[i].gift_card_info.fee && this.cartItems[i].gift_card_info.fee>0)?this.cartItems[i].gift_card_info.fee:0;
          let feeAdditonalAmount = 0;
          if(fee>0){
            feeAdditonalAmount = (parseFloat(this.cartItems[i].denomination) * fee) / 100;
          }
          console.log("denomination",this.cartItems[i].denomination);
          console.log("feeAdditonalAmount",feeAdditonalAmount);
          let p:any = parseFloat(this.cartItems[i].denomination) + (this.cartItems[i].denomination * environment.GIFT_EXCHANGE_FEE) + feeAdditonalAmount;
          console.log("p",p);
          var inrPrice = 0;
          //Convert It into INR
          console.log("exchangeRateRule",this.cartItems[i].gift_card_info.exchangeRateRule)
          let xoxoInrRate = 1/parseFloat(this.cartItems[i].gift_card_info.exchangeRateRule);
          console.log("xoxoInrRate",xoxoInrRate);
          let inrRate = 1/parseFloat(this.inrExchangeRates[this.cartItems[i].gift_card_info.currencyCode]);
          console.log("inrRate",inrRate);
          if(inrRate>xoxoInrRate){
            inrPrice = p * inrRate;
            console.log("Here inrRate");
          }
          else{
            inrPrice = p * xoxoInrRate;
            console.log("Here xoxoInrRate");
          }
          console.log(inrPrice);
          let rates:any = this.exchangeRates['INR'];
          console.log("rates",rates);        
          this.cartItems[i].price = inrPrice / rates;
          price = parseFloat(this.cartItems[i].price);
          console.log(this.cartItems[i].price);
          console.log("******************************* End Here "+this.cartItems[i].gift_card_info.currencyCode+"******************************")
        }
        else{
          price = parseFloat(this.cartItems[i].price).toFixed(2);
        }
      }
      this.payPalProductItems.push(
        {
          name: productName.toString(),
          quantity: this.cartItems[i].quantity.toString(),
          category: 'DIGITAL_GOODS',
          unit_amount: {
            currency_code: 'USD',
            value: price.toString(),
          },
      });
      this.totalCartValue = parseFloat(this.totalCartValue) + (this.cartItems[i].quantity * price);
    }
    if(this.isOnlyGiftAndTopup){
      this.shippingAmount = 0;
    }else{
      this.shippingAmount = 30;
      this.payPalProductItems.push(
        {
          name: 'Shipping Price',
          quantity: '1',
          category: 'DIGITAL_GOODS',
          unit_amount: {
            currency_code: 'USD',
            value: this.shippingAmount.toString(),
          },
      });
    }
    this.totalCartValue = parseFloat(this.totalCartValue).toFixed(2);
    this.totalCartValue = parseFloat(this.shippingAmount) + parseFloat(this.totalCartValue);
    this.totalCartValue = parseFloat(this.totalCartValue).toFixed(2);
    this.conveyanceCharges = (parseFloat(this.totalCartValue) * 0.1) + 0.30;
    this.conveyanceCharges = parseFloat(this.conveyanceCharges).toFixed(2);
    this.payPalProductItems.push(
      {
        name: 'Conveyance charges',
        quantity: '1',
        category: 'DIGITAL_GOODS',
        unit_amount: {
          currency_code: 'USD',
          value: this.conveyanceCharges.toString(),
        },
    });

    let that = this;
    this.isLoading = false;
    this.loadCartItem = false;
    setTimeout(function(){
      if(that.paymentPageEnable){
        that.initConfig();
      }
    },100)
  }


  reCalculateCartValue(){
    this.totalCartValue = 0;
    for (var i = 0; i <this.cartItems.length; i++) {
      let price:any = 0;
      if(this.cartItems[i].cart_item=='product'){
        price = parseFloat(this.cartItems[i].price).toFixed(2);
      }else{
        if(this.cartItems[i].cart_item=='toupup'){
          if(this.cartItems[i].topup_product_info.destination.unit!='USD'){
            let rates:any = this.exchangeRates[this.cartItems[i].topup_product_info.destination.unit];
            let p:any = parseFloat(this.cartItems[i].topup_product_info.destination.amount) + (this.cartItems[i].topup_product_info.destination.amount * environment.EXCHANGE_FEE);
            this.cartItems[i].price = p / rates;
            price = parseFloat(this.cartItems[i].price).toFixed(2);
          }
          else{
            let rates:any = 1;
            let p:any = parseFloat(this.cartItems[i].topup_product_info.destination.amount) + (this.cartItems[i].topup_product_info.destination.amount * environment.EXCHANGE_FEE);
            this.cartItems[i].price = p / rates;
            price = parseFloat(this.cartItems[i].price).toFixed(2);
          }
        }
        else if(this.cartItems[i].cart_item=='gift_card'){
          console.log("*******************************"+this.cartItems[i].gift_card_info.currencyCode+"******************************")
          let fee = (this.cartItems[i].gift_card_info.fee && this.cartItems[i].gift_card_info.fee>0)?this.cartItems[i].gift_card_info.fee:0;
          let feeAdditonalAmount = 0;
          if(fee>0){
            feeAdditonalAmount = (parseFloat(this.cartItems[i].denomination) * fee) / 100;
          }
          console.log("denomination",this.cartItems[i].denomination);
          console.log("feeAdditonalAmount",feeAdditonalAmount);
          let p:any = parseFloat(this.cartItems[i].denomination) + (this.cartItems[i].denomination * environment.GIFT_EXCHANGE_FEE) + feeAdditonalAmount;
          console.log("p",p);
          var inrPrice = 0;
          //Convert It into INR
          console.log("exchangeRateRule",this.cartItems[i].gift_card_info.exchangeRateRule)
          let xoxoInrRate = 1/parseFloat(this.cartItems[i].gift_card_info.exchangeRateRule);
          console.log("xoxoInrRate",xoxoInrRate);
          let inrRate = 1/parseFloat(this.inrExchangeRates[this.cartItems[i].gift_card_info.currencyCode]);
          console.log("inrRate",inrRate);
          if(inrRate>xoxoInrRate){
            inrPrice = p * inrRate;
            console.log("Here inrRate");
          }
          else{
            inrPrice = p * xoxoInrRate;
            console.log("Here xoxoInrRate");
          }
          console.log(inrPrice);
          let rates:any = this.exchangeRates['INR'];
          console.log("rates",rates);        
          this.cartItems[i].price = inrPrice / rates;
          price = parseFloat(this.cartItems[i].price);
          console.log(this.cartItems[i].price);
          console.log("******************************* End Here "+this.cartItems[i].gift_card_info.currencyCode+"******************************")
        }
        else{
          price = parseFloat(this.cartItems[i].price).toFixed(2);
        }
      }
      this.totalCartValue = parseFloat(this.totalCartValue) + (this.cartItems[i].quantity * price);
    }
    this.totalCartValue = parseFloat(this.totalCartValue).toFixed(2);
    //console.log(parseFloat(this.shippingAmount) , parseFloat(this.totalCartValue) , parseFloat(this.dedcut_from_wallet) , parseFloat(this.discountAmount));
    this.totalCartValue = parseFloat(this.shippingAmount) + parseFloat(this.totalCartValue);// - parseFloat(this.dedcut_from_wallet) - parseFloat(this.discountAmount);
    this.checkWalletBalance();
  }

  goToTopupDetails(id:any,iso:any){
    this.helperService.navigate(`/topup-detail/${iso}/${id}`, this.lang);
  }

  goToGiftCardDetail(id:any){
    this.helperService.navigate(`/gift-detail/${id}`, this.lang);
  }

  goToGameDetail(id:any){
    this.helperService.navigate(`/game-products/${id}`, this.lang);
  }

  goToProductDetail(obj:any){
    //this.helperService.navigate(`/shop-detail/${obj.product_slug}`, this.lang);
    window.location.href = '/shop-detail/'+obj.product_slug;
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang)
  }

  private initConfig(): void {
    let totalPayPalAmount:any = parseFloat(this.conveyanceCharges) + parseFloat(this.totalCartValue); 
    totalPayPalAmount = totalPayPalAmount.toFixed(2);
    this.payPalConfig = {
      currency: 'USD',
      clientId: environment.PAYPAL_CLIENT_ID,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'USD',
              value: totalPayPalAmount.toString()
            }
          }
        ]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        console.log('onApprove - transaction was approved, but not authorized',data.orderID,data.payerID, data, actions);
        actions.order.get().then((details: any) => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);
        });
      },
      onClientAuthorization: (data) => {
        console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        this.showSuccess = true;
        this.doCheckout(data.status,data.id,data);
        //data.payer, data.purchase_units,data.status
        //Save here your Order Information
      },
      onCancel: (data, actions) => {
        //Do Nothing
        console.log('OnCancel', data, actions);
        this.toast.error("Payment Canceled!", "Error!");
      },
      onError: err => {
        //Do Nothing
        console.log('OnError', err);
        this.toast.error("Something went wrong!", "Error!");
      },
      onClick: (data, actions) => {
        //TODO Order Generate 
        console.log('onClick', data, actions);
      },
    };
  }

  doCheckout(status:any,transectionId:any,paymentResponse:any){
    if(!this.isLoading){
      this.isLoading = true;
      if(status=='COMPLETED' || status=='APPROVED'){
        this.checkoutFormData = {
          ...this.selectedAddres,
          product_price		: parseFloat(this.totalCartValue) + parseFloat(this.dedcut_from_wallet),
          discount_amount		: this.discountAmount,
          shipping_amount		: this.shippingAmount,
          total_amount		: this.totalCartValue,
          dedcut_from_wallet	: this.dedcut_from_wallet,
          discount_code		: this.coupon_code,
          transection_id		: transectionId,
          payment_response	: paymentResponse,
          payment_method 		: 'paypal',
          order_status		: 'paid',
          payment_status		: 'Paid',
          is_email_send		: 0
        }
        this.coreService.post(`${ENDPOINTS.CART.PLACE_ORDER}`, this.checkoutFormData).subscribe((res) => {
          if(res.body.success)
          {
            this.isLoading = false;
            this.helperService.navigate('/order-confirm/'+res.body.data, this.lang);
            this.toast.success(res.body.message, "Success!");
          }
          else
          {
            this.isLoading = false;
            this.toast.error("Sometihng went wrong!", "Error!");
          }
        }, (err) => {
          this.isLoading = false;
          this.toast.error("Sometihng went wrong!", "Error!");
        });
      }
      else{
        this.isLoading = false;
        this.toast.error("Sometihng went wrong!", "Error!");
      }
    }
  }

  doCheckoutCod(){
    if(!this.isLoading){
      this.isLoading = true;
      this.checkoutFormData = {
        ...this.selectedAddres,
        product_price		: parseFloat(this.totalCartValue) + parseFloat(this.dedcut_from_wallet),
        discount_amount		: this.discountAmount,
        shipping_amount		: this.shippingAmount,
        total_amount		: this.totalCartValue,
        dedcut_from_wallet	: this.dedcut_from_wallet,
        discount_code		: this.coupon_code,
        transection_id		: '',
        payment_response	: '',
        payment_method 		: 'COD',
        order_status		: 'paid',
        payment_status		: 'Paid',
        is_email_send		: 0
      }
      this.coreService.post(`${ENDPOINTS.CART.PLACE_ORDER}`, this.checkoutFormData).subscribe((res) => {
        this.isLoading = false;
        if(res.body.success)
        {
          this.helperService.navigate('/order-confirm/'+res.body.data, this.lang);
          this.toast.success(res.body.message, "Success!");
        }
        else
        {
          this.toast.error("Sometihng went wrong!", "Error!");
        }
      }, (err) => {
        this.isLoading = false;
        this.toast.error("Sometihng went wrong!", "Error!");
      });
    }
  }

  doPaymentWithCrypto(payment_method:any){
    if(!this.isLoading){
      this.isLoading = true;
      this.checkoutFormData = {
        ...this.selectedAddres,
        product_price		: parseFloat(this.totalCartValue) + parseFloat(this.dedcut_from_wallet),
        discount_amount		: this.discountAmount,
        shipping_amount		: this.shippingAmount,
        total_amount		: this.totalCartValue,
        dedcut_from_wallet	: this.dedcut_from_wallet,
        discount_code		  : this.coupon_code,
        transection_id		: '',
        payment_response	: '',
        payment_method 		: payment_method,
        order_status		: 'pending',
        payment_status		: 'pending',
        is_email_send		: 0
      }
      if(payment_method=='nowpayment'){
        this.checkoutFormData.payment_currency = this.cryptoPrice.currency_code;
        if(!this.usedCryptoCode.includes(this.cryptoPrice.currency_code)){
          this.usedCryptoCode.push(this.cryptoPrice.currency_code);
          localStorage.setItem('usedCrypto', JSON.stringify(this.usedCryptoCode));
        }        
      }
      this.coreService.post(`${ENDPOINTS.CART.PLACE_ORDER}`, this.checkoutFormData).subscribe((res) => {
        this.isLoading = false;
        if(res.body.success)
        {
          //this.toast.success(res.body.message, "Success!");
          if(payment_method=='coinbase'){
            this.isOneTimePaymentDone = true;
            window.location.href = res.body.data.hosted_url;
          }
          else if(payment_method=='nowpayment'){
            this.isOneTimePaymentDone = true;
            // window.location.href = res.body.data.invoice_url;
            this.orderId = res.body.order_id;
            if(res.body.data.pay_address && res.body.data.pay_address!=''){
              //$("#paymentSendModal").modal('show');
              $("#btn_paymentSendModal").click();
              this.qrCodeUrl = '';
              this.pay_address = res.body.data.pay_address;
              this.crypto_pay_amount = res.body.data.pay_amount;
              this.crypto_pay_code = res.body.data.pay_currency;
              this.pay_extra_id = (res.body.data.payin_extra_id)?res.body.data.payin_extra_id:'';
              this.countDown = timer(0, this.tick).subscribe(() => --this.counter);
            }else{
              this.isOneTimePaymentDone = false;
              //$("#WalletUnder").modal('show');
              $("#btn_WalletUnder").click();
            }
          }
          else if(payment_method=='binancepay'){
            if(res.body.data.qrcodeLink!=''){
              this.isOneTimePaymentDone = true;
              this.orderId = res.body.order_id;
              //$("#paymentSendModal").modal('show');
              $("#btn_paymentSendModal").click();
              this.qrCodeUrl = res.body.data.qrcodeLink;
              this.countDown = timer(0, this.tick).subscribe(() => --this.counter);
              //window.location.href = res.body.data.checkoutUrl;
            }
            else{
              this.isOneTimePaymentDone = false;
              //$("#WalletUnder").modal('show');
              $("#btn_WalletUnder").click();
            }
          }
          else{
            this.helperService.navigate('/order-confirm/'+res.body.data, this.lang);
          }          
        }
        else
        {
          this.isOneTimePaymentDone = false;
          this.toast.error("Sometihng went wrong!", "Error!");
        }
      }, (err) => {
        this.isOneTimePaymentDone = false;
        this.isLoading = false;
        this.toast.error("Sometihng went wrong!", "Error!");
      });
    }
  }

  

  copyURL(pay_address:string){    
    // const el = document.createElement('textarea');
    // el.value = pay_address;
    // document.body.appendChild(el);
    // el.select();
    // document.execCommand('copy');
    // document.body.removeChild(el);
    var textField = document.createElement('textarea');
    textField.innerText = pay_address;
    document.body.appendChild(textField);
    textField.select();
    textField.focus(); //SET FOCUS on the TEXTFIELD
    document.execCommand('copy');
    textField.remove();
    this.toast.success("Copying to clipboard was successful!", "Success!");
  }

  doHideGuestForm(str:string){
    if(str=='hide')
    this.checkoutForm = true;
    else
    this.checkoutForm = false;
  }

  doCheckoutWallet(){
    if(!this.isLoading){
      this.isLoading = true;
      this.checkoutFormData = {
        ...this.selectedAddres,
        product_price		: this.totalCartValue,
        discount_amount		: this.discountAmount,
        shipping_amount		: this.shippingAmount,
        total_amount		: this.totalCartValue,
        dedcut_from_wallet	: this.dedcut_from_wallet,
        discount_code		: this.coupon_code,
        transection_id		: '',
        payment_response	: '',
        payment_method 		: 'wallet',
        order_status		: 'paid',
        payment_status		: 'Paid',
        is_email_send		: 0
      }
      this.coreService.post(`${ENDPOINTS.CART.PLACE_ORDER}`, this.checkoutFormData).subscribe((res) => {
        this.isLoading = false;
        if(res.body.success)
        {
          this.helperService.navigate('/order-confirm/'+res.body.data, this.lang);
          this.toast.success(res.body.message, "Success!");
        }
        else
        {
          this.toast.error("Sometihng went wrong!", "Error!");
        }
      }, (err) => {
        this.isLoading = false;
        this.toast.error("Sometihng went wrong!", "Error!");
      });
    }
  }

  redirectToOrderDetail(orderId:string){
    this.router?.navigate(['/order-confirm/'+orderId], { queryParams: {'time':this.counter}});
  }
}
