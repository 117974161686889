<app-headers></app-headers>
<section class="text-center mb-2 settingTabs">
    <app-user-breadcrumb></app-user-breadcrumb>
</section>
<section class="mb-5 profileView">
    <div class="container">
        <div class="row">
            <div class="col-md-5 col-lg-3">
                <app-user-left-menu></app-user-left-menu>
            </div>
            <div class="col-md-7 col-lg-9">
                <div class="rightbox-dashbord" *ngIf="!wishlistProducts">
                    <div class="row">
                        <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                            <h4 class="list-heading">Wishlist</h4>
                        </div>
                        <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                            <a href="javascript:;" class="wishlisbtn waves-effect waves-light" data-toggle="modal" data-target="#addnewwishlist">+ Add Wishlist</a>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <div class="table-responsive text-nowrap walletbxtable">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Item</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let wishlist of wishlistList;">
                                            <td scope="row"><a (click)="viewDetails(wishlist)">{{wishlist.wishlist_name}}</a></td>
                                            <td>{{(wishlist.items_count)?wishlist.items_count.length:0}} Item</td>
                                            <td>
                                                <a class="btn adtocart-wishlist btn-md"  (click)="viewDetails(wishlist)" role="button"><i class="far fa-eye"></i></a>
                                                <a class="btn btn-md wishlist-remobe" (click)="deleteWishlist(wishlist)" role="button"><i class="far fa-trash-alt ml-1"></i></a>
                                            </td>
                                        </tr>
                                        <tr *ngIf="wishlistList.length==0 && !isWishlistFatching">
                                            <td colspan="3" class="required alert alert-danger">Record not found</td>
                                        </tr>
                                        <tr *ngIf="wishlistList.length==0 && isWishlistFatching">
                                            <td colspan="3">
                                                <div id="loader"></div>
                                            </td>
                                            
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="rightbox-dashbord" *ngIf="wishlistProducts">
                    <div class="row">
                        <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                            <h4 class="list-heading">{{wishlistDetails.wishlist_name}}</h4>
                        </div>
                        <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                            <a class="wishlisbtn waves-effect waves-light" (click)="goBack()">Back</a>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <div class="wishlisbox-list" *ngFor="let product of wishlistProductList;">
                                <div class="row" >
                                    <div class="col-md-8 col-lg-8 mb-lg-0 mb-lg-0 mb-2">
                                        <div class="media d-block d-md-flex list-product" *ngIf="product.product_id">
                                            <a (click)="goToProductDetailPage(product.product_id);">
                                                <img class="card-img-64 d-flex mx-auto mb-3" src="{{imgBaseUrl}}products/{{product.product_id.product_image}}" alt="{{product.product_id.product_name}}" />
                                            </a>
                                            <div class="media-body text-center text-md-left ml-md-3 ml-0 myspacebox">
                                                <h4 class="fontbold" (click)="goToProductDetailPage(product.product_id);">{{(product.product_name)?product.product_name:product.product_id.product_name}}</h4>
                                            </div>
                                        </div>

                                        <div class="media d-block d-md-flex list-product" *ngIf="product.type=='balance_card' && product.balance_card_id">
                                            <a (click)="goToNavigation('cryptomate-balance-card');">
                                                <img class="card-img-64 d-flex mx-auto mb-3" src="{{imgBaseUrl}}giftcard/{{product.balance_card_id.card_logo}}"/>
                                            </a>
                                            <div class="media-body text-center text-md-left ml-md-3 ml-0 myspacebox">
                                                <h4 class="fontbold" (click)="goToNavigation('cryptomate-balance-card');">{{product.balance_card_id.card_name}}</h4>
                                            </div>
                                        </div>

                                        <div class="media d-block d-md-flex list-product" *ngIf="product.type=='gift_card'">
                                            <a (click)="goToNavigation('cryptomate-balance-card');">
                                                <img class="card-img-64 d-flex mx-auto mb-3" src="{{product.gift_card_info.imageUrl}}"/>
                                            </a>
                                            <div class="media-body text-center text-md-left ml-md-3 ml-0 myspacebox">
                                                <h4 class="fontbold" (click)="goToNavigation('cryptomate-balance-card');">{{(product.product_name)?product.product_name:product.gift_card_info.name}}</h4>
                                            </div>
                                        </div>

                                        <div class="media d-block d-md-flex list-product" *ngIf="product.type=='toupup'">
                                            <a (click)="goToNavigation('cryptomate-balance-card');">
                                                <img class="card-img-64 d-flex mx-auto mb-3" src="{{imgBaseUrl}}operator/{{product.topup_info.country.iso_code}}/{{product.topup_info.name}}.png" alt="{{product.topup_info.name}}"/>
                                            </a>
                                            <div class="media-body text-center text-md-left ml-md-3 ml-0 myspacebox">
                                                <h4 class="fontbold" (click)="goToNavigation('cryptomate-balance-card');">{{(product.product_name)?product.product_name:product.topup_product_info.name}}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-lg-4 right-s">
                                        <a (click)="doDeleteProduct(product)" class="btn btn-md wishlist-remobe" role="button">
                                            <i class="far fa-trash-alt ml-1"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="alert alert-danger" *ngIf="wishlistProductList.length==0 && !isWishlistFatching">
                                Not any product added in this wishlist yet.
                            </div>
                            <div class="alert alert-info" *ngIf="wishlistProductList.length==0 && isWishlistFatching">
                                We are fatching your wishlist "{{wishlistDetails.wishlist_name}}" products...
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="modal fade" id="addnewwishlist" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <!--Content-->
        <div class="modal-content">
            <!--Body-->
            <div class="modal-body model-pad0 mb-0 text-center">
                <div class="wrap-login100">
                    <div class="for-login100-pic">
                        <img src="/assets/img/CPM-logo1.png" alt="IMG" />
                    </div>
                    <form class="login100-form" [formGroup]="wishlistForm">
                        <button type="button" class="btn-close float-right" data-dismiss="modal" aria-label="Close"></button>
                        <h3 class="text-left mb-3"><b>New Wishlist</b></h3>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="md-form">
                                    <span class="iconform"><i class="far fa-user"></i></span>
                                    <input class="form-control" type="email" id="e-mail" name="name" required="required"  formControlName="wishlist_name" [ngClass]="{ 'is-invalid': wishlistFormObj.isFormSubmit &&  get?.wishlist_name?.errors }"/>
                                    <label for="e-mail">Enter a wishlist name</label>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center mt-4">
                            <div class="col-md-12 text-center">
                                <button class="contiunebtn waves-effect waves-light" data-dismiss="modal" aria-label="Close" type="button">Cancel</button>
                                <button type="button" [disabled]="isLoading" (click)="createNow(wishlistForm.value)" class="joinnow-btn waves-effect waves-light">Create</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>