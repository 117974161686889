import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CoreService } from '../shared/services/core.service';
import { HelperService } from '../shared/services/helper-service.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-loyalty-programe-detail',
  templateUrl: './loyalty-programe-detail.component.html',
  styleUrls: ['./loyalty-programe-detail.component.css']
})
export class LoyaltyProgrameDetailComponent implements OnInit {
  public lang:any = '';
  public programeList:any = [];
  public isLoggedIn:boolean = false;
  public isLoading:boolean = false;
  public slugUrl:string = '';
  public lpObject:any= {};
  constructor(
    private coreService: CoreService,
    private toast: ToastrService,
    public helperService: HelperService,
    private route: ActivatedRoute,
    private _auth : AuthService,
    private router: Router,
  ) { 
    if(this._auth.loggedIn()){
      this.isLoggedIn = true;
    }else{
      this.isLoggedIn = false;
    }
  }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.slugUrl = params['slug'];
      this.getProgrameDetails();
    });
    let lpObject:any = localStorage.getItem('lpObj') != null ? localStorage.getItem('lpObj') : '';
    this.lpObject = lpObject;
    this.lpObject.remaining_orders = [];
    for(var i=1;i<this.lpObject.remaining_order;i++) {
      this.lpObject.remaining_orders.push(i);
    }
  }

  getProgrameDetails(){
    this.isLoading = true;
    this.coreService.get(`${ENDPOINTS.LOYALTY_PROGRAME.DETAIL}`+this.slugUrl, {}).subscribe((res) => {
      if (res.body.success) {
        this.isLoading = false;
        this.lpObject = res.body.data;
        this.lpObject.remaining_orders = [];
        for(var i=0;i<this.lpObject.remaining_order;i++) {
          this.lpObject.remaining_orders.push(i);
        }
      } else {
        this.isLoading = false;
        this.lpObject = {};
      }
    }, (err) => {
      this.isLoading = false;
    });
  }

  doActivatePrograme(obj:any){
    this.isLoading = true;
    this.coreService.post(`${ENDPOINTS.LOYALTY_PROGRAME.ACTIVATE}`, {'loyalty_program_id':obj.id}).subscribe((res) => {
      if (res.body.success) {
        this.isLoading = false;
        obj.is_activated = true;
        obj.is_availed=0;
        obj.order_count=0;
        this.toast.success(res.body.message);
      } else {
        this.isLoading = false;
        this.toast.error(res.body.message);
      }
    }, (err) => {
      this.isLoading = false;
      this.toast.error("something went wrong!");
    });
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang)
  }

}
