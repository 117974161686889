import { Component,Input, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from '../shared/services/core.service';
import { HelperService } from '../shared/services/helper-service.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { environment } from 'src/environments/environment';
import { AuthService } from '../shared/services/auth.service';
import {ToastrService} from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { ALL_REGIONS } from 'src/app/shared/constants/app.gameregion.constants';

declare var $: any;
@Component({
  selector: 'app-game-products',
  templateUrl: './game-products.component.html',
  styleUrls: ['./game-products.component.css'],
})
export class GameProductsComponent implements OnInit {
  title = 'appBootstrap';
  public allRegionLists = ALL_REGIONS;
  
  public isLoggedIn:boolean = false;
  public isLoading:boolean = false;
  public canActivated:boolean = false;
  closeResult: string = '';
  public lang:any = '';
  public cryptoPrice:any = {};
  public exchangeRates:any = 0;
  public addingToCart:boolean = false;
  public country:string = '';
  public cartItems:any = '';  
  public countryLists:any = [];
  @Input() public cartItemData:any = [];
  // slides = [
  //   {
  //     img: 'https://www.crictracker.com/wp-content/uploads/2017/12/Virat-Kohli-Rohit-Sharma.jpg',
  //   },
  //   {
  //     img: 'https://media.gettyimages.com/photos/virat-kohli-of-india-drives-the-ball-while-batting-during-day-2-of-picture-id1324370659?s=612x612',
  //   },
  //   {
  //     img: 'https://resize.indiatvnews.com/en/resize/newbucket/715_-/2020/08/gettyimages-1268512291-1598375580.jpg',
  //   },
  //   {
  //     img: 'https://img1.hscicdn.com/image/upload/f_auto/lsci/db/PICTURES/CMS/326200/326210.4.jpg',
  //   },
  //   {
  //     img: 'https://media.gettyimages.com/photos/virat-kohli-of-india-drives-the-ball-while-batting-during-day-2-of-picture-id1324370659?s=612x612',
  //   },
  // ];

  slideConfigmain = {
    slidesToShow: 1,
    slidesToScroll: 1,
	arrows: true,
  adaptiveHeight: true,
    autoplay: false,
  };

  slideConfig = {
    slidesToShow: 4,
	arrows: false,
    slidesToScroll: 1,
    autoplay: false,
  };

  // addSlide() {
  //   this.slides.push({ img: 'http://placehold.it/350x150/777777' });
  // }

  // removeSlide() {
  //   this.slides.length = this.slides.length - 1;
  // }

  slickInit(e: any) {
    console.log('slick initialized');
  }
  breakpoint(e: any) {
    console.log('breakpoint');
  }
  afterChange(e: any) {
    console.log('afterChange');
  }
  beforeChange(e: any) {
    console.log('beforeChange');
  }
  public slug:any = '';
  public imgBaseUrl = environment.AWSImageURL;
  public productDetails: any = {};
  constructor(
    private modalService: NgbModal,
    private toast: ToastrService,
    private coreService: CoreService,
    public helperService: HelperService,
    private route: ActivatedRoute,
    private _auth : AuthService,
    private router: Router,
    private _sanitizer: DomSanitizer
  ) {
    this.route.params.subscribe(params => {
      this.slug = params['slug'];
      this.getProductDetails();
    });
  }

  ngOnInit(): void {
    let countryObj:any = localStorage.getItem('country_name') != null ? localStorage.getItem('country_name') : '';
    this.country = countryObj;
    if(this.country==''){
      this.coreService.getCurrentLocation().subscribe((res) => {
        this.country = res.body.country_name;
        localStorage.setItem('country_name', this.country);
      }, (err) => {
      });
    }
    this.getExchangeRates('USD');
  }

  getExchangeRates(currencyCode:any){
    if(!this.exchangeRates){
      this.coreService.getExchangeRates(currencyCode).subscribe((res) => {
        this.exchangeRates = res.body.data.rates;
      }, (err) => {
        this.exchangeRates = 0;
      });
    }
  }

  open(content: any) {
    this.modalService
      .open(content, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        backdrop: 'static',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  isShowDiv = true;

  toggleDisplayDiv() {
    this.isShowDiv = !this.isShowDiv;
  }

  getProductDetails(){
    this.isLoading =true;
    this.coreService.get(`${ENDPOINTS.GAME_API.DETAIL}${this.slug}`, {}).subscribe((res) => {
      if (res.body.success) {
        this.productDetails = res.body.data;
        this.countryLists = this.allRegionLists[parseInt(this.productDetails.regionId)-1];
        let object = {
          "name" : this.country
        };
        var results = this.findByMatchingProperties(this.countryLists.country, object);
        if(results.length>0){
          this.canActivated = true;
        }
        else{
          this.canActivated = false;
        }

        if(this.productDetails.videos){
          this.productDetails.videos.forEach((item: any) => {
            item.videoUrl = this._sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/"+item.video_id);
          });
        }
        else{
          this.productDetails.videos = [];
        }
        let rates:any = this.exchangeRates['EUR'];
        let p:any = parseFloat(this.productDetails.price) + (this.productDetails.price * environment.GAME_EXCHANGE_FEE);
        this.productDetails.price = p / rates;
      }
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
    });
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang)
  }


   /**
   * @method: addToCart
   * @description: Using to add item in the cart
   * @param productObj
   */
    addToCart(obj:any){
        this.addingToCart = true;
        obj.addingToCart = true;
        if(obj.images){
          obj.coverImageOriginal = obj.images.cover.thumbnail
        }
        var paylaod:any = {
          'cart_item' : 'game_product',
          'product_name' : obj.name,
          'game_id' : obj.kinguinId,      
          'quantity' : 1,
          'game_product_info' : obj,
          'price' : parseFloat(obj.price)
        }    
        this.coreService.post(`${ENDPOINTS.CART.ADD}`, paylaod).subscribe((res) => {
          this.addingToCart = false;
          obj.addingToCart = false;
          if(res.body.success){
            obj.addedToCart = true;
            this.cartItems = res.body.data;
            this.toast.success(res.body.message, "Success!");
          }else{
            this.toast.error(res.body.message, "Error!");
          }
          setTimeout(function(){
            $("#pol").show();
            $(".cartPopupModal").show();
          },500);
        }, (err) => {
          this.addingToCart = false;
        });
    }

    findByMatchingProperties(set:any, properties:any) {
      return set.filter(function (entry:any) {
          return Object.keys(properties).every(function (key) {
              return entry[key] === properties[key];
          });
      });
    }
}
