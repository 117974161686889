<!-------============= FORGOT PASSWORD MODEL =================----------->
<div class="modal fade" id="forgotpassword" tabindex="-1">
    <div class="modal-dialog modal-lg" role="document">
        <!--Content-->
        <div class="modal-content">
            <!--Body-->
            <div class="modal-body model-pad0 mb-0 text-center">
                <div class="wrap-login100" *ngIf="!showResetForm">
                    <div class="for-login100-pic forg">
                        <div class="signupp">
                        <img src="/assets/img/CPM-logo1.png" alt="IMG" />
                    </div>
                    </div>
                    <form class="login100-form" [formGroup]="forgotForm">
                        <button type="button" class="btn-close float-right" data-dismiss="modal" aria-label="Close"></button>
                        <h3 class="text-left mb-3"><b>Forgot Password</b></h3>
						<div class="row">
                            <div class="col-md-12">
                                <div class="md-form">
                                    <span class="iconform"><i class="far fa-envelope"></i></span>
                                    <input class="form-control" type="email" id="e-mail" name="email" required="required"   formControlName="email"  [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.email?.errors }"/>
                                    <label for="e-mail">Email</label>
                                   
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-7 text-left">
                                <button type="button" (click)="sendForgotRequest(forgotForm.value)" class="joinnow-btn waves-effect waves-light"><i class="fas fa-sign-in-alt"></i> SUBMIT</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="wrap-login100" *ngIf="showResetForm">
                    <div class="for-login100-pic">
                        <img src="/assets/img/CPM-logo1.png" style="height: auto;" alt="IMG" />
                    </div>
                    <form class="login100-form" [formGroup]="resetForm">
                        <button type="button" class="btn-close float-right" data-dismiss="modal" aria-label="Close"></button>
                        <h3 class="text-left mb-3"><b>Reset Password</b></h3>
						<div class="row">
                            <div class="col-md-12">
                                <div class="md-form">
                                    <span class="iconform"><i class="far fa-envelope"></i></span>
                                    <input class="form-control" type="email" id="e-mail" name="name" required="required" formControlName="email" readonly/>
                                    <label for="e-mail">Email</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="md-form">
                                    <span class="iconform"><i class="fa fa-lock"></i></span>
                                    <input class="form-control" type="text" id="forgot_code" name="forgot_code" required="required"   formControlName="forgot_code" [ngClass]="{ 'is-invalid': isFormSubmit &&  rstget?.forgot_code?.errors }"/>
                                    <label for="forgot_code">Reset Code</label>
                                    <span class="eyeview show_forgot_code" (click)="showPassword('forgot_code')"><i class="fas fa-eye"></i></span>
                                    <span class="eyeview hide hide_forgot_code" (click)="hidePassword('forgot_code')"><i class="fas fa-eye-slash"></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="md-form">
                                    <span class="iconform"><i class="fa fa-lock"></i></span>
                                    <input class="form-control" type="password" id="password" name="password" required="required"   formControlName="password" [ngClass]="{ 'is-invalid': isFormSubmit &&  rstget?.password?.errors }"/>
                                    <label for="password">New Password</label>
                                    <span class="eyeview show_password" (click)="showPassword('password')"><i class="fas fa-eye"></i></span>
                                    <span class="eyeview hide hide_password" (click)="hidePassword('password')"><i class="fas fa-eye-slash"></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-7 text-left">
                                <button type="button" (click)="resetPassword(resetForm.value)" class="joinnow-btn waves-effect waves-light"><i class="fas fa-sign-in-alt"></i> SUBMIT</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>