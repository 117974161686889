<app-header></app-header>
<section class="text-center mb-2 mt-3 pt-4 mt-50">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <ul class="list-unstyled bradcam">
                    <li><a (click)="goToNavigation('home');">Home</a></li>
                    <li>
                        <a (click)="goToNavigation('loyalty-program');"><i class="fas fa-angle-double-right iconarrw"></i> Loyalty Program</a>
                    </li>
                    <li><i class="fas fa-angle-double-right iconarrw"></i> Loyalty Detail</li>
                </ul>
            </div>
        </div>
    </div>
</section>
<section class="text-left titleloyalty pb-0">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="title-heading">
                    <p class="mb-1">
                        Your orders will appear here only once we mark them as delivered.
                    </p>
                    <h6 class="mb-0">
                        Once you complete the required number of orders for this activated program, the reward will be credited to your wallet.
                    </h6>
                    <h6 class="mb-0">Please contact us if you are facing any issues</h6>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="mb-5 detail-bgloyalty">
    <div class="container">
        <div class="row">
            <div class="title-heading">
                <h2 class="h1 pb-3">Your Orders</h2>
            </div>
        </div>
        <div class="row pricing">
            <div class="col-lg-1"></div>
            <div class="col-12 col-sm-12 col-md-9 col-lg-7 mb-4">
                <div *ngIf="isLoading" id="loader"></div>
                <div class="card timineboxprice">
                    <div class="card-body">
                        <div class="timeline">
                            <div class="entry" *ngFor="let completedOrder of lpObject.orders;">
                                <div class="title is-done">
                                    <h3>{{completedOrder.order_id.createdAt | date}}</h3>
                                    <p>{{completedOrder.order_id.createdAt | date:'shortTime'}}</p>
                                </div>
                                <div class="body is-done-line">
                                    <h5>#{{completedOrder.order_id.order_number}}</h5>
                                    <p>{{completedOrder.order_id.total_amount | currency}} <span>( 0.000180 BTC )</span></p>
                                </div>
                            </div>
                            <div class="entry"  *ngFor="let order of lpObject.remaining_orders;">
                                <div class="title"></div>
                                <div class="body">
                                    <h5>Next Order?</h5>
                                </div>
                            </div>
                        </div>
                        <p class="enddate">Program extended till {{lpObject.end_date | date}}</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-8 col-md-8 col-lg-4 mb-4 tablprixe-media">
                <div class="card pricebox-1 bg-twoplan "> <!-- detailboxplan -->
                    <div class="card-body">
                        <div class="shape-circlce-2">
                            <h5 class="">{{lpObject.offer_title}}</h5>
                            <h2 class="font-weight-bold">{{lpObject.coupon_amount | currency}}</h2>
                            <p>Per month</p>
                        </div>
                        <p *ngIf="!lpObject.is_activated && lpObject.order_count==0 && isLoggedIn" class="grey-text mt-3">Place {{lpObject.number_of_time}} orders of min {{lpObject.min_amount}} dollar</p>
                        <div class="linedot"></div>
                        <p>Please activate this loyalty program to avail of the benefit</p>
                        <a *ngIf="lpObject.order_count>0 && lpObject.is_availed==0 && isLoggedIn" class="planbtn-2 waves-effect waves-light">In-Progress</a>
                        <a *ngIf="!lpObject.is_activated && lpObject.order_count==0 && isLoggedIn" (click)="doActivatePrograme(lpObject)" class="planbtn-1 waves-effect waves-light">Activate</a>
                        <a *ngIf="lpObject.is_activated && lpObject.is_availed==0 && lpObject.order_count==0" class="planbtn-1 waves-effect waves-light">Activated</a>
                        <button *ngIf="lpObject.is_availed==1" class="planbtn-1 waves-effect waves-light" disabled style="border: none;">Availed</button>
                        <a *ngIf="!isLoggedIn" class="planbtn-1 waves-effect waves-light" data-toggle="modal" data-target="#login">Activate</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="text-center py-3">
    <div class="container">
        <div class="row d-flex align-items-center justify-content-center">
            <div class="col-md-8 col-lg-8">
                <div class="title-heading">
                    <h5><span class="font-28">Pay with your <b class="text-yellow">cryptos.</b></span> More than 50 coins are accepted here! <br> Bitcoin, Ethereum, XRP, BNB, Litecoin, Doge ...</h5>
                </div>
                <div class="logo-icons">
                    <img src="/assets/img/images_coins.png" />
                </div>
                <div class="css-1b9jzri--2">
                    <div style="color: var(--text-primary);" class="css-ezg0pp--2">
                        <div>
                            <img src="/assets/img/images_checkmark.svg" width="21" height="21" />
                        </div>
                        -	Blockchain Based Payments
                    </div>
                    <div style="color: var(--text-primary);" class="css-ezg0pp--2">
                        <div>
                            <img src="/assets/img/images_checkmark.svg" width="21" height="21" />
                        </div>
                        - Easy, Fast and Secure
                    </div>
                    <div style="color: var(--text-primary);" class="css-ezg0pp--2">
                        <div>
                            <img src="/assets/img/images_checkmark.svg" width="21" height="21" />
                        </div>
                        -	Pay Instantly, Anywhere, Anytime
                    </div>
                    <div style="color: var(--text-primary);" class="css-ezg0pp--2">
                        <div>
                            <img src="/assets/img/images_checkmark.svg" width="21" height="21" />
                        </div>
                       - Pay with Stable Coins
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>