import { Component, Input,Output, OnChanges, OnInit,EventEmitter } from '@angular/core';
import { Settings } from '../shared/constants/app.setting.constants';
import { HelperService } from '../shared/services/helper-service.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from 'src/app/shared/services/core.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../shared/services/auth.service';
import {ToastrService} from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.css']
})
export class ShopComponent implements OnInit {
  public imgBaseUrl = environment.AWSImageURL;
  public bannerSlider :boolean = false;
  public subCatSlider :boolean = false;
  public isLoggedIn :boolean = false;
  public isLoading :boolean = false;
  public isLoaderLoading :boolean = true;
  public isWishlistFatching: boolean = true;
  public wishlistForm!: FormGroup;
  public prevValue:string = '';
  public shopBanners: any = [];
  public brands: any = [];
  public category: any = [];
  public sub_category: any = [];
  public wishlistList:any  = [];
  public bannerTerms: any ='';
  public learnMoreUrl: any ='#';
  public queryParam: any = {};
  public product_id:any = '';
  public collection:any  = '';
  public profileObj:any = '';
  public imageSrc:any = '';
  public cartItems:any = '';
  public myTotalWalletBalance:any = 0.00;
  @Input() public cartItemData:any = [];
  public productObj: any = {
    list: [],
    list_id: [],
    loadStatus: true,
    pagesToDraw: [],
    filterSettings: {
      sub_category_name : '',
      main_category_id : '',
      brand_id : [],
      search: '',
      order_by : 'createdAt DESC',
      category_id: []
    },
  };
  public wishlistFormObj: any = {
    isFormSubmit: false
  }

  public paginationSetting: any = {
    page: 1,
    perPage: Settings.perPage,
    id: 'products',
    count: 0,
  }

  public lang:any = '';
  public searchByKeyword:boolean = false;
  public searchByBrand:boolean = false;
  constructor(
    private fb: FormBuilder,
    private toast: ToastrService,
    private coreService: CoreService,
    private route: ActivatedRoute,
    private _auth : AuthService,
    private helperService: HelperService,
    private router: Router,
  ) { 
    $(".zoomContainer").remove();
    if(this._auth.loggedIn()){
      this.isLoggedIn = true;
    }else{
      this.isLoggedIn = false;
    }
    this.route.queryParams.subscribe(params => {
      if (!params) return;
      this.queryParam = { ...params };
      if (this.queryParam.hasOwnProperty('search')) {
        this.productObj.filterSettings.search = this.queryParam.search;
        this.searchByKeyword =true;
        localStorage.removeItem('shopFilters');
        localStorage.removeItem('categoryList');
        localStorage.removeItem('brandsList');
        localStorage.removeItem('shopProductLists');
        localStorage.removeItem('productListIds');
        delete this.queryParam.search;
      }
      if (this.queryParam.hasOwnProperty('brand')) {
        this.searchByBrand =true;
        this.productObj.filterSettings.brand_id.push(this.queryParam.brand);
        localStorage.removeItem('shopFilters');
        localStorage.removeItem('categoryList');
        localStorage.removeItem('brandsList');
        localStorage.removeItem('shopProductLists');
        localStorage.removeItem('productListIds');
        delete this.queryParam.brand;
      }
      if (this.queryParam.hasOwnProperty('category')) {
        this.productObj.filterSettings.brand_id.push(this.queryParam.category);
        localStorage.removeItem('shopFilters');
        localStorage.removeItem('categoryList');
        localStorage.removeItem('brandsList');
        localStorage.removeItem('shopProductLists');
        localStorage.removeItem('productListIds');
        delete this.queryParam.category;
      }
      if (this.queryParam.hasOwnProperty('collection')) {
        this.collection = this.queryParam.collection;
        localStorage.removeItem('shopFilters');
        localStorage.removeItem('categoryList');
        localStorage.removeItem('brandsList');
        localStorage.removeItem('shopProductLists');
        localStorage.removeItem('productListIds');
        delete this.queryParam.collection;
      }
    });
  }

  ngOnInit(): void {
    let profileObj:any = localStorage.getItem('currentUser') != null ? localStorage.getItem('currentUser') : '{"name":""}';
    this.profileObj = JSON.parse(profileObj);
    this.wishlistForm = this.fb.group({
      wishlist_name: ['', [Validators.required]]
    });

    $(window).scroll(function(){
        if ($(window).scrollTop() >= 130) {
            $('nav').addClass('fixed-header');
            $('nav div').addClass('visible-title');
        }
        else {
            $('nav').removeClass('fixed-header');
            $('nav div').removeClass('visible-title');
        }
    });

    let brands:any = (localStorage.getItem('brandsList') != null && localStorage.getItem('brandsList') != 'undefined') ? localStorage.getItem('brandsList') : JSON.stringify([]);
    this.brands = JSON.parse(brands);
    if(this.brands.length==0){
      this.getBrands();
    }

    //let shopBanners:any = (localStorage.getItem('shopBannersList') != null && localStorage.getItem('shopBannersList') != 'undefined') ? localStorage.getItem('shopBannersList') : JSON.stringify([]);
    //this.shopBanners = JSON.parse(shopBanners);
    
    let categorys:any = (localStorage.getItem('categoryList') != null && localStorage.getItem('categoryList') != 'undefined') ? localStorage.getItem('categoryList') : JSON.stringify([]);
    this.category = JSON.parse(categorys);
    if(this.category.length==0){
      this.getProductCategories();
    }

    if(this.productObj.filterSettings.search==''){
      let filter:any = (localStorage.getItem('shopFilters') != null && localStorage.getItem('shopFilters') != 'undefined') ? localStorage.getItem('shopFilters') : JSON.stringify(this.productObj.filterSettings);
      this.productObj.filterSettings = JSON.parse(filter);
      this.productObj.filterSettings.search ='';
    }

    let that = this;
    setTimeout(function(){
      that.productObj.filterSettings.brand_id.forEach((brandIds: any) => {
        $("#brand_"+brandIds).prop("checked", true);
        $("#brand_mobile_"+brandIds).prop("checked", true);
      })
      let isSelected = false;
      that.category.forEach((catRow: any) => {
        if(catRow.is_selected){
          isSelected = true;
          that.doSelectCategoryFromLocal(catRow);
        }
      })
      if(!isSelected){
        that.doSelectCategoryFromLocal(that.category[0]);
      }
    },2500);

    let productList:any = (localStorage.getItem('shopProductLists') != null && localStorage.getItem('shopProductLists') != undefined) ? localStorage.getItem('shopProductLists') : JSON.stringify(this.productObj.list);
    if(productList!=undefined && productList!='undefined'){
      this.productObj.list = JSON.parse(productList);
    }
    let productListIds:any = localStorage.getItem('productListIds') != null ? localStorage.getItem('productListIds') : JSON.stringify(this.productObj.list_id);
    this.productObj.list_id = JSON.parse(productListIds);
    
    if(this.productObj.list.length==0 || this.productObj.filterSettings.search!=''){
      this.productObj.list = [];
      this.productObj.list_id = [];
      this.findProducts()
    }

    this.getShopBanners();
    this.getWalletTotal();
    this.cartItemLists();
    setTimeout(function(){
      //$("html, body").animate({ scrollTop: 200 }, "slow");
      //that.subcategorySlider();
    },500);
  }
  get get() { return this.wishlistForm.controls; }
   
  findProducts(){
    if(!this.productObj.loadStatus){
      this.productObj.loadStatus = true;
      const payload = {
        ...this.productObj.filterSettings
      }
      localStorage.setItem('shopFilters', JSON.stringify(this.productObj.filterSettings));
      this.coreService.post(`${ENDPOINTS.SHOP.GET_PRODUCTS}${this.paginationSetting.page}/${this.paginationSetting.perPage}`, payload).subscribe((res) => {
        if (res.body.success) {
          if(this.productObj.filterSettings.search=='' && !this.searchByBrand){
            this.isLoaderLoading = false;
          }
          this.paginationSetting.count = res.body.data.count;
          res.body.data.items.forEach((item: any) => {
            item.isImageLoading = true;
            if(this.productObj.list_id.indexOf(item.id)==-1){
              this.productObj.list.push(item);
              this.productObj.list_id.push(item.id);
            }
          });
          this.productObj.loadStatus = false;
          localStorage.setItem('shopProductLists', JSON.stringify(this.productObj.list));
          localStorage.setItem('productListIds', JSON.stringify(this.productObj.list_id));
          localStorage.setItem('categoryList', JSON.stringify(this.category));
          localStorage.setItem('brandsList', JSON.stringify(this.brands));
        } else {
          this.productObj.list = [];
          this.productObj.list_id = [];
          localStorage.setItem('shopProductLists', JSON.stringify(this.productObj.list));
          localStorage.setItem('productListIds', JSON.stringify(this.productObj.list_id));
          localStorage.setItem('categoryList', JSON.stringify(this.category));
          localStorage.setItem('brandsList', JSON.stringify(this.brands));
          this.productObj.loadStatus = false;
        }
      }, (err) => {
        this.productObj.loadStatus = false;
      });
    }
  }

  ngOnChanges(){
    this.cartItems = this.cartItemData;
  }

  cartItemLists(){
      this.coreService.get(`${ENDPOINTS.CART.LIST}`, {}).subscribe((res) => {
        this.cartItems = res.body.data;
      }, (err) => {
      });
  }

  /**
 * @method: deleteItemFromCart
 * @description: Using to delete item from cart
 * @param itemObj
 */
  updateCartItem(itemObj:any,type:string){
    var q = itemObj.quantity;
    if(type=='minus'){
      if(q>1){
        q = q - 1;
      }else{
        return;
      }
    }else{
      q = q + 1;
    }
    if(!this.isLoading){
      this.isLoading = true;
      const paylaod = {
        'cart_id' : itemObj.id,
        'quantity' : q
      }
      this.coreService.post(`${ENDPOINTS.CART.UPDATE}`, paylaod).subscribe((res) => {
        this.isLoading = false;
        if(res.body.success){
          itemObj.quantity = q;
        }
      }, (err) => {
        this.isLoading = false;
      });
    }
  }

  getWalletTotal(){
    if(this.isLoggedIn ){
      this.coreService.post(`${ENDPOINTS.USER_PROFILE.TOTAL_WALLET}`, {}).subscribe((res) => {
        if (res.body.success) {
          this.myTotalWalletBalance = parseFloat(res.body.data.total_wallet_balance);
        } else {
          this.myTotalWalletBalance = 0.00;
        }
      }, (err) => {
      });
    }
  }

  logOut() {
    this.coreService.get(`${ENDPOINTS.AUTH.LOGOUT}`, {}).subscribe((res) => {
      this.cbLogout();
    }, (err) => {
      this.cbLogout();
    });
  }

  cbLogout() {
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('checkoutInfo');
    this.helperService.changeUserProfile(null);
    this.helperService.setLoggedUserInfo(false) ;
    this.helperService.navigate('/home', this.lang);
    window.location.reload();
  }

  findByMatchingProperties(set:any, properties:any) {
    return set.filter(function (entry:any) {
        return Object.keys(properties).every(function (key) {
            return entry[key] === properties[key];
        });
    });
  }

  subcategorySlider(){
      this.isLoaderLoading = true;
      let that = this;
      setTimeout(function(){
        let langCode = (localStorage.getItem("lang_direction")!='' && localStorage.getItem("lang_direction")!=null)?localStorage.getItem("lang_direction"):'ltr';
        $(".regular").slick({
          dots: true,
          infinite: true,
          slidesToShow: 6,
          slidesToScroll: 6,
          rtl: langCode == "rtl" ? true : false,      
          responsive: [
              {
                  breakpoint: 1024,
                  settings: {
                      slidesToShow: 4,
                      slidesToScroll: 4,
                      infinite: true,
                      dots: true,
                  },
              },
              {
                  breakpoint: 600,
                  settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                  },
              },
              {
                  breakpoint: 480,
                  settings: {
                      slidesToShow: 3,
					            centerPadding: '50px',
					            centerMode: false,
                      slidesToScroll: 1,
                  },
              },
          ],
        }); 
		
		    $(".mobilebt").click(function(){
			    $('.regular').slick('refresh');
			  });
        $(".slick-next").trigger("click");
        $(".slick-prev").trigger("click");
        setTimeout(function(){
          if(that.searchByKeyword){
            $(".subcategorys").addClass("hide");
          }
          that.isLoaderLoading = false;
        },2000);
      },500);
  }

  getProductCategories(){
    this.coreService.post(`${ENDPOINTS.SHOP.GET_CATEGORY}`, {}).subscribe((res) => {
      if (res.body.success) {
        this.category = res.body.data;
        if(this.category.length>0 && this.collection==''){
          if(!this.searchByKeyword){
            this.doSelectCategory(this.category[0]);
            this.subcategorySlider();
          }else{
            this.sub_category = [];
            this.productObj.list = [];
            this.productObj.list_id = [];
            this.paginationSetting.page = 1;
            this.productObj.loadStatus = false;
            this.findProducts();
            this.subcategorySlider();
          }        
        }
        else if(this.category.length>0 && this.collection!=''){
          if(this.collection=='phones'){
            var results = this.findByMatchingProperties(this.category, {id: "60dc3b6809941f04bfb2f409"});
            if(results.length>0){
              if(!this.searchByKeyword){           
                this.doSelectCategory(results[0]);
                this.subcategorySlider();
              }else{
                this.sub_category = [];
                this.productObj.list = [];
                this.productObj.list_id = [];
                this.paginationSetting.page = 1;
                this.productObj.loadStatus = false;
                this.findProducts();
                this.subcategorySlider();
              }
            }
          }
          else if(this.collection=='computer'){
            var results = this.findByMatchingProperties(this.category, {id: "60e819b17e9b0e161a9eeccb"});
            if(results.length>0){      
              if(!this.searchByKeyword){        
                this.doSelectCategory(results[0]);
                this.subcategorySlider();
              }else{
                this.sub_category = [];
                this.productObj.list = [];
                this.productObj.list_id = [];
                this.paginationSetting.page = 1;
                this.productObj.loadStatus = false;
                this.findProducts();
                this.subcategorySlider();
              }
            }
          }
        }
      } else {
        this.category = [];
        localStorage.setItem('categoryList', JSON.stringify(this.category));
      }
    }, (err) => {
    });
  }

  // When scroll down the screen  
  onScroll()  
  {  
    this.paginationSetting.page = this.paginationSetting.page + 1;  
    this.productObj.loadStatus = false;
    this.findProducts();
  }
  
  doSelectCategoryFromLocal(catObj:any){
    this.isLoaderLoading = true;
    this.sub_category = catObj.sub_categories;
    this.subcategorySlider();
    let that = this;
    setTimeout(function(){
      $(".subcategorys").addClass("hide");
      $("#"+catObj.id).removeClass("hide");      
      $(".slick-next").trigger("click");
      $(".slick-prev").trigger("click");
    },1000);
  }

  doSelectCategory(catObj:any){
    this.isLoaderLoading = true;
    this.productObj.filterSettings.sub_category_name = '';
    this.productObj.filterSettings.category_id = '';
    for (let i = 0; i < this.category.length; i++) {
      this.category[i].is_selected = false;
    }
    localStorage.setItem('categoryList', JSON.stringify(this.category));
    this.productObj.filterSettings.main_category_id = catObj.id;
    this.productObj.filterSettings.search = '';
    catObj.is_selected = true;
    this.sub_category = catObj.sub_categories;
    $(".waves-light").removeClass("active");
    if(this.sub_category.length>0){
      for (let i = 0; i < this.sub_category.length; i++) {
        this.sub_category[i].is_selected = false;
      }

      this.sub_category[0].is_selected = true;
      $("."+this.sub_category[0].id).addClass("active");
      this.productObj.filterSettings.sub_category_name = this.sub_category[0].category_name;
      this.productObj.filterSettings.category_id = this.sub_category[0].id;
      this.productObj.filterSettings.main_category_id = '';
      localStorage.setItem('categoryList', JSON.stringify(this.category));
    }
    this.productObj.list = [];
    this.productObj.list_id = [];
    this.paginationSetting.page = 1;
    this.productObj.loadStatus = false;
    $(".subcategorys").addClass("hide");
    $("#"+catObj.id).removeClass("hide");
    $(".slick-next").trigger("click");
    $(".slick-prev").trigger("click");
    this.findProducts();
  }

  hideLoader(obj:any){
    obj.isImageLoading=false;
  }
  
  getBrands(){
    this.coreService.get(`${ENDPOINTS.SHOP.GET_BRANDS}`, {}).subscribe((res) => {
      if (res.body.success) {
        this.brands = res.body.data;
        for (let i = 0; i < this.brands.length; i++) {
          if(this.productObj.filterSettings.brand_id.includes(this.brands[i].id)){
            this.brands[i].isSelected = this.brands[i].id;
          }
        }
        localStorage.setItem('brandsList', JSON.stringify(this.brands));
      } else {
        this.brands = [];
        localStorage.setItem('brandsList', JSON.stringify(this.brands));
      }
    }, (err) => {
    });
  }
  
  getShopBanners(){
    this.coreService.get(`${ENDPOINTS.SHOP.GET_BANNERS}`, {}).subscribe((res) => {
      if (res.body.success) {
        this.shopBanners = res.body.data;
        localStorage.setItem('shopBannersList', JSON.stringify(this.shopBanners));
        if(this.shopBanners.length>0){
          this.bannerSlider = true;
          setTimeout(function(){
             $('.cate-slider').slick({
                dots: false,
                infinite: true,
                speed: 300,
                slidesToShow: 6,
                slidesToScroll: 6,
                responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                  slidesToShow: 5,
                  slidesToScroll: 5,
                  infinite: true,
                  dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                  }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
                ]
              }); 
          },200);
        }else{
          this.bannerSlider = false;
        }
      } else {
        this.shopBanners = [];
        localStorage.setItem('shopBannersList', JSON.stringify(this.shopBanners));
      }
    }, (err) => {
    });    
  }

  bannerDetails(obj:any){
    this.bannerTerms = obj.terms_of_usages;
    this.learnMoreUrl = (obj.advertisement_url)?obj.advertisement_url:'#';
  }

  changeOrderBy(){
    this.paginationSetting.page = 1;
    this.productObj.list = [];
    this.productObj.list_id = [];
    let that = this;
    setTimeout(function(){
      that.productObj.loadStatus = false;
      that.findProducts();
    },50);
  };
    
  saveValue() {
    this.prevValue = this.productObj.filterSettings.search;
  }
  
  processChange() {
    if (this.productObj.filterSettings.search !== this.prevValue) {
      this.paginationSetting.page = 1;
      this.productObj.list = [];
      this.productObj.list_id = [];
      this.productObj.filterSettings.brand_id = [];
      for (let i = 0; i < this.brands.length; i++) {
        if($("#brand_"+this.brands[i].id).is(":checked")){
          this.productObj.filterSettings.brand_id.push(this.brands[i].id);
        }
      }
      let that = this;
      setTimeout(function(){
        //$("html, body").animate({ scrollTop: 500 }, "slow");
        that.productObj.loadStatus = false;
        that.findProducts();      
      },50);
    }
  }

  filterSelectSubCat(event:any,type:string,obj:any){
    event.preventDefault();
    this.productObj.filterSettings.search = '';
    this.paginationSetting.page = 1;
    this.productObj.list = [];
    this.productObj.list_id = [];
    if(type=='category'){
      $(".waves-light").removeClass("active");
      for (let i = 0; i < this.sub_category.length; i++) {
        this.sub_category[i].is_selected = false;
      }
      obj.is_selected = true;
      $("."+obj.id).addClass("active");
      this.productObj.filterSettings.sub_category_name = obj.category_name;
      this.productObj.filterSettings.category_id = obj.id;
      this.productObj.filterSettings.main_category_id = '';
      localStorage.setItem('categoryList', JSON.stringify(this.category));
    }else{
      if($("#brand_"+obj.id).is(":checked")){
        $("#brand_"+obj.id).prop("checked", false);
        $("#brand_mobile_"+obj.id).prop("checked", false);
      }else{
        $("#brand_"+obj.id).prop("checked", true);
        $("#brand_mobile_"+obj.id).prop("checked", true);
      }
    }
    this.productObj.filterSettings.brand_id = [];
    for (let i = 0; i < this.brands.length; i++) {
       if($("#brand_"+this.brands[i].id).is(":checked")){
        this.productObj.filterSettings.brand_id.push(this.brands[i].id);
       }
    }
    let that = this;
    setTimeout(function(){
      //$("html, body").animate({ scrollTop: 500 }, "slow");
      that.productObj.loadStatus = false;
      that.findProducts();      
    },50);    
  }

  setSocialMedia(url:any) {
    /** CODE NEED TO BE ENABLE **/
    if(environment.production && environment.isprod) {
     let seoObj = {
     title : 'Cryptomate',
     description: 'Cryptomate',
     image: url ? url : `${environment.WEB_APP}/assets/icons/icon-512x512.png`,
     url: `${environment.WEB_APP}/shop/`,
     }
   }
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang)
  }

  goToProductDetailPage(obj:any) {
    //this.helperService.navigate(`/shop-detail/${obj.product_slug}`, this.lang);
    window.location.href = '/shop-detail/'+obj.product_slug;
  }
  
  
     /**
     * @method: addToCart
     * @description: Using to add item in the cart
     * @param goToProductCart
     */
    goToProductCart(obj:any){
      if(obj.product_quantity>0 && !this.isLoading){
        this.isLoading =true;
        obj.is_added_cart = true;
        var paylaod:any = {
          'allow_only_fiat' : 0,
          'product_name' : obj.product_name,
          'cart_item' : 'product',
          'product_id' : obj.id,      
          'quantity' : 1,
          'product_type' : obj.product_type,
          'price' : (obj.special_price)?parseFloat(obj.special_price):parseFloat(obj.regular_price)
        }
        if(obj.product_type!='single'){
          if(obj.id){
            paylaod['combination_id'] = obj.default_combination_id;
          }
        }
        this.coreService.post(`${ENDPOINTS.CART.ADD}`, paylaod).subscribe((res) => {
          if(res.body.success){
            this.cartItems = res.body.data;
            this.isLoading = false;
            this.toast.success(res.body.message, "Success!");
            obj.is_added_cart = true; 
          }else{
            obj.is_added_cart = false;
            this.isLoading = false;
            this.toast.error(res.body.message, "Error!");
          }
          setTimeout(function(){
            $("#pol").show();
            $(".cartPopupModal").show();
          },500);
        }, (err) => {
        });
      }
    }

   /**
   * @method: createNow
   * @description: Using to create User Wishlist
   * @param formData
   */
  createNow(formData:any) {
    this.wishlistFormObj.isFormSubmit = true;
    if (this.wishlistForm.valid) {
      this.isLoading = true;
      const paylaod = {
        ...formData
      }
      paylaod.wishlist_name = paylaod.wishlist_name.trim();
      if(paylaod.wishlist_name==''){
        this.toast.error("Please enter valid name", "Error!");
        return;
      }
      this.coreService.post(`${ENDPOINTS.WISHLIST.CREATE}`, paylaod).subscribe((res) => {
        this.isLoading = false;
        if(res.body.success){
          this.wishlistForm.reset();
          this.wishlistList.push(res.body.data);
          this.wishlistFormObj.isFormSubmit = false;
          this.toast.success(res.body.message, "Success!");
          $("#addnewwishlist").modal("hide");
        }else{
          this.toast.error(res.body.message, "Error!");
          this.wishlistFormObj.isFormSubmit = false;
        }
      }, (err) => {
        this.isLoading = false;
      });
     
    }

  }


  doOpenWishlist(obj:any){
    this.product_id = obj.id;
    this.getMyWishlist();
    $("#addnewwishlist").modal("show");
  }

  getMyWishlist(){
    this.isWishlistFatching = true;
    let payload = {
      'type' : 'product',
      'product_id' : this.product_id
    }
    this.coreService.post(`${ENDPOINTS.WISHLIST.PRODUCT_LIST}`, payload).subscribe((res) => {
      this.isWishlistFatching = false;
      if (res.body.success) {
        this.wishlistList = res.body.data;
      } else {
        this.wishlistList = [];
      }
    }, (err) => {
    });
  }
  

  addProductInWishlist(obj:any){
    let payload = {
      'wishlist_id' : obj.id,
      'type' : 'product',
      'product_id' : this.product_id,
      'product_name' : obj.product_name
    }
    this.coreService.post(`${ENDPOINTS.WISHLIST.ADD_REMOVE_PRODUCT}`, payload).subscribe((res) => {
      if (res.body.success) {
        this.toast.success(res.body.message, "Success!");
      }else{
        this.toast.error(res.body.message, "Error!");
      }
    }, (err) => {
    });
  }

  goToWishlistDetail(obj:any) {
    $("#addnewwishlist").modal("hide");
    this.router?.navigate(['/user/wishlist'], { queryParams: {'wishlist':obj.id,'name':obj.wishlist_name}});
  }
}
