<app-header [cartItemData]="cartItems"></app-header>
<section class="text-center mb-2 pt-6 mt-3 mt-50">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <ul class="list-unstyled mt-3 bradcam">
                    <li><a (click)="goToNavigation('/');">Home</a></li>
                    <li>
                        <a (click)="goToNavigation('topup');"><i class="fas fa-angle-double-right iconarrw"></i> Topup</a>
                    </li>
                    <li><i class="fas fa-angle-double-right iconarrw"></i> {{topupObj.name}}</li>
                </ul>
            </div>
        </div>
    </div>
</section>
<section class="mb-5">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-6" *ngIf="topupObj.country">
                <a class="topup-img-left">
                    <i class="fa-3x"><img src="{{imgBaseUrl}}operator/{{topupObj.country.iso_code}}/{{topupObj.name}}.png" class="img-gift-detail" /></i>
                </a>
            </div>
            <div class="col-md-12 col-lg-6" *ngIf="!isLoading && products.length>0">
                <div *ngIf="isLoading" id="loader"></div>
                <div class="content-detail">
                    <h2><b>{{planObj.name}}</b></h2>
                    <div class="row">
                        <div class="col-6">
                            <span class="float-left checkinstant"><i class="far fa-check-circle"></i> Instant Delivery</span>
                        </div>
                        <div class="col-6">
                            <app-wishlist-modal [operatorObj]="topupObj" [topupProductObj]="planObj" [productName]="topupObj.name" [productType]="'toupup'"></app-wishlist-modal>
                        </div>
                    </div>
                    <div class="dropdown-divider-new"></div>
                    <div class="row displaynew">
                        <div class="col-sm-12">
                            <p>{{planObj.description}}</p>
                           <!-- <p *ngIf="cryptoPrice.exchange_rate!=0" style="margin-bottom: 5px;">{{planObj.destination.amount | currency}}</p>-->
                            <!-- <p *ngIf="cryptoPrice.exchange_rate==0">{{cryptoPrice.display}}</p>
                            <p *ngIf="cryptoPrice.exchange_rate!=0">{{(cryptoPrice.exchange_rate * (planObj.destination.amount)).toFixed(6)}} {{cryptoPrice.currency_code}}</p> -->
                        </div>
                        <div class="col-sm-7 col-md-7 col-lg-7">
                            <label class="cn"><b>Select Plan</b></label>
                            <select class="selebitcoin-topup" [(ngModel)]="planID" (change)="selectPlan()">
                                <option *ngFor="let productRow of products;" value="{{productRow.id}}">{{productRow.name}}</option>
                            </select>
                        </div>
                        <div class="col-sm-5 col-md-5 col-lg-5">
                            <label class="cn"><b>Select Currency</b></label>
                            <app-crypto-dropdown [inputPrice]="'1'" [displayType]="'dropdown'" (convertedRate)="cryptoPrice=$event"></app-crypto-dropdown>
                        </div>
                    </div>
                    <hr />
                    <div class="row mt-3">
                        <div class="col-sm-7 col-md-7 col-lg-7">
                            <label>The phone number to refill</label>
                            <input type="text" class="selebitcoin-topup" placeholder="1234567890" [(ngModel)]="mobile_number" id="mobile_number" (input)="numbersOnlyValidator($event)"/>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <button [disabled]="mobile_number==''" type="button" *ngIf="!addingToCart" (click)="doTopUp(planObj)" class="waves-effect waves-light purchase-btn">Top-up</button>
                            <a *ngIf="addingToCart" class="waves-effect waves-light purchase-btn">Please wait...</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-6" *ngIf="!isLoading && products.length==0">
                <div class="content-detail">
                    <div class="col-md-12 alert alert-danger">
                        Product is not available in your account
                    </div>
                </div>
            </div>
            <div *ngIf="isLoading" id="loader"></div>
        </div>
    </div>
</section>
<div class="gradation-process-area bg-gray section-space--ptb_100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="gradation-title-wrapper section-space--mb_60">
                    <div class="gradation-title-wrap">
                        <h6 class="section-sub-title text-black mb-20">How Top-up Cards Work</h6>
                        <h4 class="heading">Get Top-up anywhere, anytime - Top-up for 500+ brands</h4>
                    </div>
                    <div class="gradation-sub-heading">
                        <h3 class="heading"><mark>03</mark> Steps</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="ht-gradation style-01">
                    <div class="item new1 item-1 animate wow fadeInRight" data-wow-delay="0.1s">
                        <div class="line"></div>
                        <div class="circle-wrap">
                            <div class="mask">
                                <div class="wave-pulse wave-pulse-1"></div>
                                <div class="wave-pulse wave-pulse-2"></div>
                                <div class="wave-pulse wave-pulse-3"></div>
                            </div>
                            <h6 class="circle">1</h6>
                        </div>
                        <div class="content-wrap">
                            <h6 class="heading">01. Enter a phone number & amount</h6>
                            <div class="text">Type in the phone number and the amount you want us to Top-up</div>
                        </div>
                    </div>
                    <div class="item new1 item-1 animate wow fadeInRight" data-wow-delay="0.15s">
                        <div class="line"></div>
                        <div class="circle-wrap">
                            <div class="mask">
                                <div class="wave-pulse wave-pulse-1"></div>
                                <div class="wave-pulse wave-pulse-2"></div>
                                <div class="wave-pulse wave-pulse-3"></div>
                            </div>
                            <h6 class="circle">2</h6>
                        </div>
                        <div class="content-wrap">
                            <h6 class="heading">02. Pay with <span class="text-yellow">Cryptos</span></h6>
                            <div class="text">Your payment is confirmed, the same minute in most Top-ups</div>
                        </div>
                    </div>
                    <div class="item new1 item-1 animate wow fadeInRight" data-wow-delay="0.20s">
                        <div class="line"></div>
                        <div class="circle-wrap">
                            <div class="mask">
                                <div class="wave-pulse wave-pulse-1"></div>
                                <div class="wave-pulse wave-pulse-2"></div>
                                <div class="wave-pulse wave-pulse-3"></div>
                            </div>
                            <h6 class="circle">3</h6>
                        </div>
                        <div class="content-wrap">
                            <h6 class="heading">03. That's it, here is your card</h6>
                            <div class="text">Once your payment is confirmed, you will get your Top-up card sent out.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section-space--ptb_100 more-prduct-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>More products on <span class="yellow-color1">Mate</span></h2>
            </div>
        </div>
        <div class="row mb-3 d-flex align-items-center justify-content-center">
            <div class="col-6 col-sm-4 col-md-4 col-lg-3" *ngFor="let operatorRow of operators.slice(0,8)">
                <a (click)="goToOperatorDetailsPage(operatorRow);" title="{{operatorRow.name}}">
                    <div class="topupbox">
                        <img src="{{imgBaseUrl}}operator/{{operatorRow.country.iso_code}}/{{operatorRow.name}}.png" alt="{{operatorRow.name}}" />
                    </div>
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-9">
                <h4>
                    <b>Have more questions? <span>Check out our <a href="https://help.cryptomate.com.cn/" target="_blank" class="yellow-color1">FAQ</a> page</span></b>
                </h4>
            </div>
            <div class="col-sm-3">
                <a href="javascript:;" (click)="goToNavigation('topup');" class="Seeall waves-effect waves-light">See all <i class="fas fa-angle-right"></i></a>
            </div>
        </div>
    </div>
</div>