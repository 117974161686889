import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute, Params } from '@angular/router';
import { HelperService } from '../shared/services/helper-service.service';
import { CoreService } from 'src/app/shared/services/core.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { environment } from 'src/environments/environment';
import { AuthService } from '../shared/services/auth.service';
import {ToastrService} from 'ngx-toastr';
import { timer, Subscription } from "rxjs";
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

declare var $: any;

@Component({
  selector: 'app-order-confirm',
  templateUrl: './order-confirm.component.html',
  styleUrls: ['./order-confirm.component.css']
})
export class OrderConfirmComponent implements OnInit {
  countDown!: Subscription;
  counter:any = 0;
  tick:any = 1000;
  public imgBaseUrl = environment.AWSImageURL;
  public invoiceBaseUrl = environment.API_ENDPOINT+'download/invoice';
  public isLoading:boolean = true;
  public orderDetail:boolean = false;
  public orderDetailObj:any = {};
  public orderid:any = '';
  public lang:any = '';
  public queryParam: any = {};
  public orderType:any = '';
  public isLoggedIn:boolean = false;
  public timerRunning:boolean = false;
  public elementType = NgxQrcodeElementTypes.URL;
  public correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  constructor(
    private toast: ToastrService,
    public coreService: CoreService,
    private router: Router,
    private _auth : AuthService,
    private route: ActivatedRoute,
    public helperService: HelperService
  ) { 

    if(this._auth.loggedIn()){
      this.isLoggedIn = true;
    }else{
      this.isLoggedIn = false;
    }
    this.route.params.subscribe(params => {
      this.orderid = params['orderID'];
      this.showOrderView();
    });

    this.route.queryParams.subscribe(params => {
      if (!params) return;
      this.queryParam = { ...params };
      if (this.queryParam.hasOwnProperty('time') && !this.timerRunning ) {
        this.timerRunning = true;
        this.counter = this.queryParam.time;
        this.countDown = timer(0, this.tick).subscribe(
            () => {
              let text = this.router.url;
              if(text.search("order-confirm")>0){
              if(this.counter>0){
                if(this.counter % 300 ==0){
                  this.showOrderView();
                }
                const queryParams: Params = { time: this.counter };
                this.router.navigate([], {relativeTo: route,queryParams: queryParams});
                --this.counter;
                }
              }
            }
			
          );
        delete this.queryParam.time;
      }		  
    });
  }

 showQRCode(str:any){
   if($('.'+str).is(':hidden')){
    $('.'+str).show("slow");
   }
  }	
	

  ngOnInit(): void {
    window.addEventListener('mouseup',function(event:any){
        $('.tagShowQR').hide("slow");
        $('.hidden-menu').hide("slow");
    }); 
  }

  copyURL(pay_address:string){    
    const el = document.createElement('textarea');
    el.value = pay_address;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.toast.success("Copying to clipboard was successful!", "Success!");
  }
  

  showOrderView(){
    this.isLoading = true;
    this.orderDetail = true;
    this.coreService.get(`${ENDPOINTS.USER_ORDER.ORDER_DETAIL}`+this.orderid, {}).subscribe((res) => {
      this.isLoading = false;
      if (res.body.success) {
        this.orderDetailObj = res.body.data;
        this.orderDetailObj.products = {
          ecommerce : [],
          toupup : [],
          gift_card : [],
          game_products : []
        }
        this.orderDetailObj.products.ecommerce = this.orderDetailObj.order_products.filter((curElem:any) => {
          return curElem.cart_item == 'product';
        });
        
        if(this.orderDetailObj.products.ecommerce.length>0){
          this.orderType = 'all';
        }
        
        this.orderDetailObj.products.toupup = this.orderDetailObj.order_products.filter((curElem:any) => {
          return curElem.cart_item == 'toupup';
        });

        if(this.orderDetailObj.products.toupup.length>0){
          if(this.orderType == ''){
            this.orderType = 'topup'
          }else{
            this.orderType = 'all';
          } 
        }

        this.orderDetailObj.products.game_products = this.orderDetailObj.order_products.filter((curElem:any) => {
          return curElem.cart_item == 'game_product';
        });

        if(this.orderDetailObj.products.game_products.length>0){
          if(this.orderType == ''){
            this.orderType = 'game_product'
          }else{
            this.orderType = 'all';
          } 
        }

        this.orderDetailObj.products.gift_card = this.orderDetailObj.order_products.filter((curElem:any) => {
          return (curElem.cart_item == 'gift_card' || curElem.cart_item == 'balance_card');
        });

        if(this.orderDetailObj.products.gift_card.length>0){
          if(this.orderType == ''){
            this.orderType = 'gift_card'
          }else{
            this.orderType = 'all';
          }
        }
      } else {
        this.helperService.navigate('/user/my-products', this.lang);
      }
    }, (err) => {
    });
  }

  showOrderStatus(status:any){
    switch(status){
      case 'paid':
        return "Paid";
      case 'processed':
        return "Processed";
      case 'canceled':
        return "Canceled - Refunded";
      case 'cancelled':
        return "Cancelled";
      case 'shipped':
        return "Shipped";
      case 'completed':
        return "Completed";
      case 'delivered':
        return "Delivered";
      case 'on-hold':
        return "On Hold";
      case 'pending':
        return "Pending";
      case 'failed':
        return "Failed";
      default:
        return "Pending";
    }
  }
  
  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang)
  }
}
