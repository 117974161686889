<app-headers></app-headers>
<section class="text-center mb-2 mt-3 about_new">
    <div class="container">
        <div class="row">
            <div class="col-sm-4 text-left newpage">                
                <h1>Page not found</h1>
                <p>Page not found details here</p>                
            </div>
        </div>
    </div>
</section>