<app-header></app-header>
<section class="text-center mb-2 mt-3 pt-4 mt-50">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <ul class="list-unstyled bradcam">
                    <li><a>Home</a></li>
                    <li><i class="fas fa-angle-double-right iconarrw"></i> Card Designer</li>
                </ul>
            </div>
        </div>
    </div>
</section>
<div *ngIf="isLoading" id="loader"></div>
<section class="detail-bgloyalty pb-3">
    <div class="container">
        <div class="mt-3 text-center">
            <div class="title-heading">
                <h2 class="h1 pb-3 cardnew">Card Designer</h2>
                    <h5 class="font-weight-bold mb-1">You owe it to your loved ones to give them something special. 😉 <br /></h5>
                    <h5 class="font-weight-normal mb-1">Here, you can develop a design that will impress them when you present your gift.</h5>
            </div>
        </div>
        <div class="rightbox-dashbord" [hidden]="isLoading">
            <div class="row d-flex align-items-center justify-content-center mt-3 wi100">
                <div class="title-heading">
                    <h2 class="h1 pb-3 mb-0">Select Design</h2>
                </div>
            </div>
            <div class="giftTabSlider clearfix myfi">
                <div class="container">
                    <div class="slider gift-slider">
                        <div class="design-title" *ngFor="let designRow of designList; let k = index" (click)="viewProducts(designRow)" id="filter_{{designRow.id}}">{{designRow.category_name}}</div>
                    </div>
                </div>
            </div>
            <div class="row  align-items-center ">
                <div *ngFor="let designRow of designList" style="width: 100%;">
                    <div *ngFor="let image of designRow.all_images" class="col-6 col-md-3 col-sm-4 col-lg-2 mb-3 filter filterclass_{{designRow.id}}">
                        <div class="save-adress" (click)="selectImage(designRow,image.image_name)">
                            <input class="checkbox-tools" type="radio" name="tools" id="tool-{{image.id}}" />
                            <label class="for-checkbox-tools waves-effect waves-light" for="tool-{{image.id}}">
                                <div class="extrnal-payment">
                                    <div
                                        class="img-url"
                                        style="width: 100%;background-position: bottom;background-size: cover;background-repeat: no-repeat;height: 220px;background-image: url('{{imgBaseUrl}}gift-design/{{image.image_name}}'); "
                                    ></div>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
                <div class="contat-box">
                    <div class="row d-flex justify-content-center mt-3">
                        <form class="col-md-12 col-lg-12 save-addreform" [formGroup]="designForm">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="md-form">
                                        <input class="form-control" type="text" id="to" name="to" required="required" formControlName="to" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.to?.errors }" placeholder="To" />
                                        <label for="to">To</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="md-form">
                                        <input class="form-control" type="text" id="from" name="from" required="required" formControlName="from" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.from?.errors }" placeholder="From" />
                                        <label for="from">From</label>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="md-form">
                                        <textarea
                                            id="message"
                                            class="md-textarea form-control billing-textare"
                                            rows="3"
                                            required="required"
                                            formControlName="message"
                                            [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.message?.errors }"
                                            placeholder="Your Message"
                                        ></textarea>
                                        <label for="message">Your Message</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="md-form">
                                        <input class="form-control" type="text" id="brand" name="brand" required="required" formControlName="brand" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.brand?.errors }" placeholder="Brand" />
                                        <label for="brand">Brand</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="md-form">
                                        <input class="form-control" type="text" id="code" name="code" required="required" formControlName="code" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.code?.errors }" placeholder="Code" />
                                        <label for="code">Code</label>
                                    </div>
                                </div>

                                <div class="col-12 col-md-12">
                                    <div class="md-form">
                                        <input class="form-control" type="text" id="email" name="email" required="required" formControlName="email" [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.email?.errors }" placeholder="Email" />
                                        <label for="code">Email</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-9">
                                    <div class="md-form topcart">
                                        <span>
                                            <label class="pure-material-radio" (click)="changeInstanttype('instant')">
                                                <input type="radio" name="generate_type" value="instant" checked formControlName="generate_type" class="hidebt" />
                                                <span>Instant</span>
                                            </label>
                                        </span>
                                        <span>
                                            <label class="pure-material-radio" (click)="changeInstanttype('schedule')">
                                                <input type="radio" name="generate_type" value="schedule" formControlName="generate_type" class="hidebt" />
                                                <span>Schedule On</span>
                                            </label>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12">
                                    <div class="md-form">
                                        <div id="poldg">
                                            <div class="hiddendiv">
                                                <div class="col-12 col-md-4" style="float: left; padding-left: 0 !important;">
                                                    <div class="md-form">
                                                        <input
                                                            class="form-control"
                                                            type="date"
                                                            id="schedule_date"
                                                            name="schedule_date"
                                                            min="{{minDate}}"
                                                            formControlName="schedule_date"
                                                            [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.schedule_date?.errors }"
                                                            placeholder="Date"
                                                            style="padding-left: 15px; padding-right: 15px; border: 1px solid #ced4da;"
                                                        />
                                                        <label for="brand">Date</label>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-4" style="float: left; padding-left: 0 !important;">
                                                    <div class="md-form">
                                                        <input
                                                            class="form-control"
                                                            type="time"
                                                            id="schedule_time"
                                                            name="schedule_time"
                                                            formControlName="schedule_time"
                                                            [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.schedule_time?.errors }"
                                                            placeholder="Time"
                                                            style="padding-left: 15px; padding-right: 15px; border: 1px solid #ced4da;"
                                                        />
                                                        <label for="brand">Time</label>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-4" style="float: left;">
                                                    <div class="md-form">
                                                        <select
                                                            class="form-control"
                                                            name="schedule_timezone"
                                                            style="padding-left: 15px !important; font-size: 13px;"
                                                            formControlName="schedule_timezone"
                                                            [ngClass]="{ 'is-invalid': isFormSubmit &&  get?.schedule_timezone?.errors }"
                                                        >
                                                            <option value="">Select Timezone</option>
                                                            <option *ngFor="let zone of timeZones;" value="{{zone.timezone}}">{{zone.value}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 12">
                                    <div class="md-form topcart">
                                        <h5>Select File Type<span style="color: #f00;">*</span></h5>
                                        <label class="pure-material-radio">
                                            <input type="radio" name="view_type" value="png" formControlName="view_type" class="hidebt" />
                                            <span>PNG</span>
                                        </label>
                                        <label class="pure-material-radio">
                                            <input type="radio" name="view_type" value="jpg" formControlName="view_type" class="hidebt" />
                                            <span>JPG</span>
                                        </label>
                                        <label class="pure-material-radio">
                                            <input type="radio" name="view_type" value="pdf" checked formControlName="view_type" class="hidebt" />
                                            <span>PDF</span>
                                        </label>
                                    </div>
                                </div>

                                <div class="col-12 col-md-12">
                                    <div class="md-form topcart text-center">
                                        <button type="button" class="joinnow-btn waves-effect waves-light w-100" (click)="showPreview()" style="background: #373737 !important; color: #fff !important;">Preview</button>
                                        <button [disabled]="isLoading" type="button" class="joinnow-btn waves-effect waves-light w-100" (click)="submitDesignForm(designForm.value)">Send</button>
                                    </div>
                                </div>

                                <div class="row justify-content-center d-flex myfull">
                                    <div class="col-md-4"></div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
    </div>
</section>