<app-header></app-header>
<section class="mb-2 pt-4 mt-3  pt-4 mt-50">
    <div class="container">
        <div class="col-sm-12">
            <ul class="list-unstyled bradcam">
                <li><a (click)="goToNavigation('home');">Home</a></li>
                <li>
                    <a (click)="showDetailPage();"><i class="fas fa-angle-double-right iconarrw"></i> Refer A Friend</a>
                </li>
            </ul>
        </div>
        <div class="row d-flex align-items-center justify-content-center mt-3">
            <div class="title-heading">
               <h2 class="h1 pb-3">{{pageObj?.title}}</h2>
            </div>
        </div>
        <div class="row d-flex justify-content-center">
            <div class="col-md-2 "></div>
            <div class="col-md-8  ">
                <h2>{{pageObj?.sub_title}}</h2>
				<div [innerHtml]="pageObj.description"></div>
			</div>
             <div class="col-md-2 "></div>			
        </div>
    </div>
</section>