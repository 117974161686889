<header>
   <nav class="navbar navbar-expand-lg navbar-dark fixed-top scrolling-navbar" id="navbar">
       <div class="container">
           <a class="navbar-brand" (click)="goToNavigation('/home');"><img src="/assets/img/logo.svg" width="60px" /></a>          
            <span class="browser-product"><a  (click)="goToNavigation('shop');" routerLinkActive="active">Browse  Products </a></span>

           <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
               <span class="navbar-toggler-icon"></span>
           </button>
           <div class="collapse navbar-collapse" id="navbarContent">
               <ul class="navbar-nav ml-auto">
                   <!--<li class="nav-item"><a class="nav-link" (click)="goToNavigation('/home');" routerLink="/home" routerLinkActive="active">Home</a></li>
                   <li class="nav-item"><a class="nav-link" (click)="goToNavigation('shop');" routerLinkActive="active">Shop</a></li>
                   <li class="nav-item dropdown">
                       <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                           Solutions
                       </a>
                       <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                           <a class="dropdown-item" (click)="goToNavigation('wepay');">WePay</a>
                           <a class="dropdown-item" (click)="goToNavigation('outsourcing');">Outsourcing</a>
                           <a class="dropdown-item" (click)="goToNavigation('otc');">OTC</a>
                       </div>
                   </li>
                   <li class="nav-item"><a class="nav-link">Academy</a></li>
                   <li class="nav-item"><a class="nav-link" (click)="goToNavigation('contact-us');" routerLink="/contact-us" routerLinkActive="active">Contact Us</a></li>-->
                    <li class="nav-item"> 
                        <a class="nav-link" onClick="document.getElementById('pol').style.display='block';document.getElementById('cartPopupModal').style.display='block';">
                            <img src="/assets/img/cart-icon.png" /><span class="badge" *ngIf="cartItems.length>0 && cartItems.length==0">{{cartItems.length}}</span>
                            <span class="badge" *ngIf="cartItems.length>0">{{cartItems.length}}</span>
                        </a>
                        <div id="pol" *ngIf="cartItems.length==0" style="height: auto;min-height: 10px;padding-top:40px;padding-bottom:40px;">
                            <div class="emplty">
                                <h5>Your cart is empty</h5>
                                <p>Looks like you haven't added anything to your cart yet</p>
                                <p><a (click)="goToNavigation('shop');" routerLinkActive="active" class="htl">Browse Products</a>
                            </div>
                        </div>
                        <div id="pol" *ngIf="cartItems.length>0">
                            <!-- list View-->
                            <div class="rel-pop">
                                <div class="bottom-bt" >
                                    <ul class="list-cart">
                                        <li *ngFor="let item of cartItems" class="pol" id="cart-items">
                                            <img *ngIf="item.cart_item=='product'"  src="{{imgBaseUrl}}products/{{item.product_id.product_image}}" alt="{{item.product_id.product_name}}" class="img-fluid z-depth-0" />
                                            <img *ngIf="item.cart_item=='balance_card'" (click)="goToNavigation('cryptomate-balance-card');" src="{{imgBaseUrl}}giftcard/{{item.balance_card_id.card_logo}}" alt="{{item.balance_card_id.card_name}}" class="img-fluid z-depth-0"/>
                                            <img *ngIf="item.cart_item=='toupup'" src="{{imgBaseUrl}}operator/{{item.topup_info.country.iso_code}}/{{item.topup_info.name}}.png" alt="{{item.topup_info.name}}" class="img-fluid z-depth-0" />
                                            <img *ngIf="item.cart_item=='gift_card'" src="{{item.gift_card_info.imageUrl}}" alt="{{item.gift_card_info.name}}" class="img-fluid z-depth-0"/>
                                            <span *ngIf="item.cart_item=='product'">
                                                {{(item.product_name)?item.product_name:item.product_id.product_name}}<br />
                                                <i *ngIf="item.product_type=='variable' && item.combination_id">{{item.combination_id.price | currency}}</i>
                                                <i *ngIf="item.product_type=='single' && item.product_id">{{item.product_id.regular_price | currency}}</i>
                                            </span>

                                            <span *ngIf="item.cart_item=='balance_card'">
                                                {{(item.product_name)?item.product_name:item.balance_card_id.card_name}}<br />
                                                <i>{{item.price | currency}}</i>
                                            </span>

                                            <span *ngIf="item.cart_item=='toupup'">
                                                {{item.topup_product_info.name}}<br />
                                                <i>{{item.mobile_number}} <br>{{item.topup_product_info.destination.amount | currency:item.topup_product_info.destination.unit}}</i>
                                            </span>

                                            <span *ngIf="item.cart_item=='gift_card'">
                                                {{(item.product_name)?item.product_name:item.gift_card_info.name}}<br />
                                                <i>{{item.denomination | currency:item.gift_card_info.currencyCode}}</i>
                                            </span>

                                            <div class="add-list" id="cart-items">
                                                <button [disabled]="isLoading" (click)="updateCartItem(item,'minus');" class="minus">-</button>
                                                <input class="quantity" min="0" name="quantity" value="{{item.quantity}}" [(ngModel)]="item.quantity" type="number" />
                                                <button [disabled]="isLoading" (click)="updateCartItem(item,'plus');" class="plus">+</button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <ul class="bottom-fix">
                                <li>
                                    <a (click)="goToNavigation('shop');" routerLinkActive="active">Keep Shopping</a>
                                </li>
                                <li>
                                    <a href="javascript:;" (click)="goToNavigation('cart');" routerLinkActive="active">Checkout</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                   <li class="nav-item">
                       <a class="nav-link" (click)="goToNavigation('user/wallet');" routerLink="user/wallet" routerLinkActive="active"> <img src="/assets/img/wallet.png" /><span class="yellow-color">{{walletBalance | currency}}</span></a>
                   </li>
                   <li class="nav-item dropdown">
                       <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <img src="/assets/img/user-login.png" /></a>
                       <div class="dropdown-menu dropdown-menu-right myprofile" aria-labelledby="navbarDropdownMenuLink">
                        <span>
                              <img *ngIf="imageSrc" src="{{imageSrc}}" alt="{{profileObj.full_name}}" />
                              <img *ngIf="!imageSrc && profileObj.profile_image" src="{{imgBaseUrl}}profile/{{profileObj.profile_image}}" alt="{{profileObj.full_name}}" />
                              <img *ngIf="!imageSrc && !profileObj.profile_image" src="/assets/img/blank-profile.png" alt="{{profileObj.full_name}}" />
                        </span>
                        <b class="dropdown-item" href="javascript:;">
                              <strong>{{profileObj.full_name}} </strong> <br />{{profileObj.email}}
                        </b>
                        <a class="dropdown-item" (click)="goToNavigation('user/my-products');" routerLink="/user/my-products" routerLinkActive="active">My orders</a>
                        <a class="dropdown-item" (click)="goToNavigation('user/wishlist');" routerLink="/user/wishlist" routerLinkActive="active">Wishlists</a>
                        <a class="dropdown-item" (click)="goToNavigation('user/redeem-balance-card');" routerLink="/user/redeem-balance-card" routerLinkActive="active">Redeem Balance Card</a>
                        <a class="dropdown-item" (click)="goToNavigation('user/settings');" routerLink="/user/settings" routerLinkActive="active">Settings</a>
                        <a class="dropdown-item" (click)="logOut();">Sign out</a>
                       </div>
                   </li>
               </ul>
           </div>
       </div>
   </nav>
</header>

<div class="mob-menu">
    <ul>
        <li>
            <a (click)="goToNavigation('/home');" routerLink="/home" routerLinkActive="active"> <i class="fa fa-home"></i> Home</a>
        </li>
        <li>
            <a (click)="goToNavigation('/shop');" routerLink="/shop" routerLinkActive="active"> <i class="fa fa-briefcase"></i> Shop</a>
        </li>
        <li>
            <a (click)="goToNavigation('cart');" routerLink="/cart" routerLinkActive="active"> <i class="fa fa-shopping-cart"> </i> Cart</a>
            <span class="badge" *ngIf="cartItems.length>0">{{cartItems.length}}</span>
        </li>
        <li>
            <a (click)="goToNavigation('user/my-products');" routerLink="user/my-products" routerLinkActive="active"> <i class="fa fa-user"></i> Profile </a>
        </li>
    </ul>
</div>
<div class="cartPopupModal" id="cartPopupModal"></div>