<app-header></app-header>
<!-- =================================
  shope banner html start from here
  ================================= -->  
<section class="banner my-5">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-9">
                <div class="main-search">
                    <form autocomplete="off" (submit)="getSearchResult()">
                        <div class="input-group mb-3 search-group"> 
                            <input 
                            autofill="off"
                            autocomplete="new-password"
                            class="form-control" 
                            id="searchhome"
                            [(ngModel)]="product_name"
                            [typeahead]="productnames"
                            (typeaheadLoading)="changeTypeaheadLoading($event)"
                            (ngModelChange)="filterResults()"
                            (typeaheadOnSelect)="typeaheadOnSelect($event)"
                            [typeaheadOptionsLimit]="6"
                            typeaheadOptionField="name"
                            aria-label="Text input with dropdown button" placeholder="search"
                            type="search" 
                            [typeaheadItemTemplate]="rt"
                            name="hidden" 
                            appAutocompleteOff
                            />
                            <ng-template #rt let-model="item" let-index="index">
                                <img src="{{model.images.cover.thumbnail}}" style="width: 50px;border-radius: 25px;height: 50px;border: 1px solid rgb(221, 221, 221);float: left;margin-right: 15px;position: unset;right: unset;top: unset;"/>
                                <span style="float: left;padding-top: 12px;width: 142px;overflow: hidden;text-overflow: ellipsis;-webkit-line-clamp: 5;-webkit-box-orient: vertical;">{{model.name}}</span>
                            </ng-template>
                            <div class="icon" (click)="getSearchResult()">
                                <img src="assets/img/Search.png" alt="search" />
                            </div>
                            <div *ngIf="searchingStart" [hidden]="productnames.length>0" class="searchSearchingbox">Searching...</div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-md-12">
                <div class="section-title">
                    <h4>Featured</h4>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <ngx-slick-carousel
                    class="carousel seller-slider"
                    #slickModal="slick-carousel"
                    [config]="slideConfig"
                    (init)="slickInit($event)"
                    (breakpoint)="breakpoint($event)"
                    (afterChange)="afterChange($event)"
                    (beforeChange)="beforeChange($event)"
                >
                    <div ngxSlickItem *ngFor="let sliderRow of gameObj.best_seller" class="slide">
                        <div class="banner-wrapper">
                            <div class="banner-img">
                                <img src="{{sliderRow.imageUrl}}" alt="banner" />
                                <div class="discount-badge">
                                    <span>-{{sliderRow.price.discount}}%</span>
                                </div>
                            </div>
                            <div class="banner-content">
                                <h6>{{sliderRow.name}}</h6>
                                <div class="banner-icons">
                                    <ul>
                                        <li>
                                            <a class="slider-icons" data-toggle="tooltip" title="Hooray!" data-placement="top" href="javascript:void(0)"><i class="fa-brands fa-steam-symbol"></i></a>
                                        </li>
                                        <li>
                                            <a class="slider-icons" data-toggle="tooltip" title="Hooray!" data-placement="top" href="javascript:void(0)"><i class="fa-solid fa-gamepad"></i></a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="banner-price">
                                    <span class="ms-auto">{{sliderRow.price.lowestOffer | currency}}</span>
                                    <del>{{sliderRow.price.discount | currency}}</del>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-slick-carousel>
            </div>
        </div> -->
    </div>
</section>

<!-- =====================
  shope banner html end here
  ==========================-->

<!-- =====================
  categories html end here
  ==========================-->

<section class="categories mb-5">
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <div class="category-list">
                    <div class="filter-box mb-0">
                        <h4>Filter</h4>
                        <a *ngIf="selectedCategoryList.length>0 || gameObj.filterSettings.regionId!='' || gameObj.platForm  !=''" (click)="doClearFilter()">clear</a>
                    </div>
                    <h5>Video Games</h5>
                    <ul>
                        <li *ngFor="let row of videoCategory">
                            <label class="checkbox checkbox-primary" (click)="doFitlerGames($event,row,'video')"> <input type="checkbox" value="{{row.name}}" [(ngModel)]="row.isSelected"/><span>{{row.name}}</span><span class="checkmark"></span> </label>
                        </li>
                    </ul>
                    <h5>Software</h5>
                    <ul>
                        <li>
                            <label class="checkbox checkbox-primary" (click)="doSelectAll($event)"> <input type="checkbox" value="all" [(ngModel)]="software_all"/><span>All</span><span class="checkmark"></span> </label>
                        </li>
                        <li *ngFor="let row of OSCategory">
                            <label class="checkbox checkbox-primary" (click)="doFitlerGames($event,row,'software')"> <input type="checkbox"  value="{{row.name}}" [(ngModel)]="row.isSelected"/><span>{{row.name}}</span><span class="checkmark"></span> </label>
                        </li>
                    </ul>
                    <h5>Gaming Gift Cards</h5>
                    <ul>
                        <li *ngFor="let row of giftCard">
                            <label class="checkbox checkbox-primary" (click)="doFitlerGames($event,row,'card')"> <input type="checkbox"  value="{{row.name}}" [(ngModel)]="row.isSelected"/><span>{{row.name}}</span><span class="checkmark"></span> </label>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-9">
                <div class="row">
                    <div class="col-md-12">
                        <div class="section-title">
                            <div class="filter-list">
                                <ul>
                                    <li *ngFor="let row of selectedCategoryList">
                                        <h6>{{row.name}}</h6>
                                        <a (click)="remveFromList(row)">
                                            <i class="fa-solid fa-xmark"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div class="category-dropdown">
                                <select class="form-select" [(ngModel)]="gameObj.filterSettings.regionId" (change)="applyFilter()">
                                    <option value="" selected>Region</option>
                                    <option *ngFor="let row of allRegionLists" value="{{row.id}}">{{row.name}}</option>
                                </select>

                                <select class="form-select" [(ngModel)]="gameObj.platForm" (change)="applyFilter()">
                                    <option value="" selected>Platform</option>
                                    <option *ngFor="let row of platForms" value="{{row}}">{{row}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="gameObj.list.length > 0"
                    class="row mb-3 d-flex align-items-center justify-content-center m-lr-0 giftiboxrow"
                    infiniteScroll
                    [infiniteScrollDistance]="1"
                    [infiniteScrollThrottle]="50"
                    (scrolled)="onScroll()"
                    [scrollWindow]="true"
                    style="overflow-y: auto"
                  >
                  <!--  | paginate:{id:'pagination',itemsPerPage: paginationSetting.perPage, currentPage:paginationSetting.page, totalItems: paginationSetting.totalCount} -->
                    <div 
                    *ngFor="let game of gameObj.list"
                    class="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div class="banner-wrapper" (click)="goToDetailPage(game);" *ngIf="game.name">
                            <div class="banner-img">
                                <img src="{{game.coverImageOriginal}}" alt="banner" />
                                <!-- <div class="discount-badge">
                                    <span>-89%</span>
                                </div> -->
                            </div>
                            <div class="banner-content">
                                <h6>{{game.name}}</h6>
                                <div class="banner-icons">
                                    <ul>
                                        <li>
                                            <a class="slider-icons" href="javascript:void(0)" title="{{game.platform}}">
                                                <img src="assets/games/platoforms/{{game.platform.toLowerCase()}}.svg" style="width: 30px;"/>
                                            </a>
                                        </li>
                                        <li>
                                            <a class="slider-icons" href="javascript:void(0)" title="{{game.regionalLimitations}}">
                                                <img src="assets/games/region/{{game.regionalLimitations.toLowerCase()}}.svg" style="width: 30px;"/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="banner-button">
                                    <span>{{game.price | currency}}</span>
                                    <!-- <del>{{game.price | currency}}</del> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                  *ngIf="gameObj.list.length == 0 && !gameObj.loadStatus"
                  class="row mb-3 d-flex align-items-center justify-content-center mypast"
                >
                  <div class="col-md-12 alert alert-danger">
                    Record not found, Please try again.
                  </div>
                </div>
                <div
                  *ngIf="gameObj.list.length == 0 && gameObj.loadStatus"
                  class="row mb-3 d-flex align-items-center justify-content-center mypast"
                >
                  <div class="col-md-12 alert alert-info" id="loader"></div>
                   We are fatching gifts vouchers <i class="fas fa-ellipsis-h"></i>
                </div>
                <div
                  class="row mb-3 d-flex align-items-center justify-content-center mypast"
                  *ngIf="gameObj.list.length > 0 && gameObj.loadStatus"
                >
                  <div class="col-md-4" style="text-align: center">
                    <a href="javascript:;" class="loadmore waves-effect waves-light"
                      >Loading more <i class="fas fa-ellipsis-h"></i
                    ></a>
                  </div>
                </div>
                <!-- <div class="row">
                    <div class="col-md-12">
                        <pagination-controls id="pagination" (pageChange)="pageChanged($event)"></pagination-controls>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</section>

<!-- =====================
  categories html end here
  ==========================-->