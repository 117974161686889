import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WalletComponent } from './wallet/wallet.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { VerifyComponent } from './verify/verify.component';
import { AddressComponent } from './address/address.component';
import { RedeemGiftCardComponent } from './redeem-gift-card/redeem-gift-card.component';
import { SettingComponent } from './setting/setting.component';
import { ReferralComponent } from './referral/referral.component';
import { MyProductsComponent } from './my-products/my-products.component';
import { BatchRefillComponent } from './batch-refill/batch-refill.component';
import { RouterModule } from '@angular/router';
import { MyreportComponent } from './myreport/myreport.component';
import { UserLeftMenuComponent } from '../include/user-left-menu/user-left-menu.component';
import { HeaderComponent } from './header/header.component';
import { FormsModule } from '@angular/forms'; 
import { ReactiveFormsModule } from '@angular/forms';
import { UserBreadcrumbComponent } from '../include/user-breadcrumb/user-breadcrumb.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const userRoutes = [
  {path: "user", children:[
    {path: "wallet", component:WalletComponent},
    {path: "wishlist", component:WishlistComponent},
    {path: "verify", component:VerifyComponent},
    {path: "address", component:AddressComponent},
    {path: "redeem-balance-card", component:RedeemGiftCardComponent},
    {path: "my-products", component:MyProductsComponent},
    {path: "my-record", component:MyreportComponent},
    {path: "settings", component:SettingComponent},
    {path: "referral", component:ReferralComponent},    
    {path: "batch-refill", component:BatchRefillComponent},
    { path: '**', component:PageNotFoundComponent}
  ]}
];

@NgModule({
  declarations: [
    WalletComponent,
    WishlistComponent,
    VerifyComponent,
    AddressComponent,
    RedeemGiftCardComponent,
    SettingComponent,
    ReferralComponent,
    MyProductsComponent,
    BatchRefillComponent,
    MyreportComponent,
    UserLeftMenuComponent,
    UserBreadcrumbComponent,
    HeaderComponent,
    PageNotFoundComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    RouterModule.forChild(userRoutes)
  ]
})
export class UserModule { }
