import { Component, OnInit } from '@angular/core';
import { HelperService } from '../../shared/services/helper-service.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { CoreService } from 'src/app/shared/services/core.service';
import {ToastrService} from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public lang:any = '';
  public isLoggedIn:boolean = false;
  public langList = [{"language_name":"한국어","lang_code":"ko","lang_direction":"ltr","id":"61740502f88d9d3ea9dca163"},{"language_name":"Italiano","lang_code":"it","lang_direction":"ltr","id":"617404d7f88d9d3ea9dca162"},{"language_name":"tiếng Việt","lang_code":"vi","lang_direction":"ltr","id":"6174048ff88d9d3ea9dca161"},{"language_name":"Português","lang_code":"pt","lang_direction":"ltr","id":"61740440f88d9d3ea9dca0e5"},{"language_name":"Deutsch","lang_code":"de","lang_direction":"ltr","id":"6174041cf88d9d3ea9dca0e4"},{"language_name":"Español","lang_code":"es","lang_direction":"ltr","id":"617403ebf88d9d3ea9dca0e2"},{"language_name":"Français","lang_code":"fr","lang_direction":"ltr","id":"617403b8f88d9d3ea9dca0e1"},{"language_name":"Русский","lang_code":"ru","lang_direction":"ltr","id":"61740377f88d9d3ea9dca0e0"},{"language_name":"العربية","lang_code":"ar","lang_direction":"rtl","id":"617402da98ee732bce1915fc"},{"language_name":"简体中文","lang_code":"zh","lang_direction":"ltr","id":"6173d2db98ee732bce190fa4"},{"language_name":"English","lang_code":"en","lang_direction":"ltr","id":"6173d2c698ee732bce190fa3"}];
  constructor(
    public translate: TranslateService,
    private helperService: HelperService,
    private toast: ToastrService,
    private _auth : AuthService,
    private coreService: CoreService
  ) { 
    if(this._auth.loggedIn()){
      this.isLoggedIn = true;
    }else{
      this.isLoggedIn = false;
    }
  }

  ngOnInit(): void {
    let langList:any = localStorage.getItem('supportedLang') != null ? localStorage.getItem('supportedLang') : JSON.stringify(this.langList);
    this.langList = JSON.parse(langList);
    this.getLanguageLists();
  }

  getLanguageLists(){
    this.coreService.post(`${ENDPOINTS.LANGUAGELIST}`, {}).subscribe((res) => {
      this.langList = res.body.data;
      localStorage.setItem('supportedLang', JSON.stringify(this.langList));
    }, (err) => {
    });

  }

  setCurrentLang(langCode:any){
    localStorage.setItem('lang_code', langCode.lang_code);
    localStorage.setItem('lang_direction', langCode.lang_direction);
    this.translate.use(langCode.lang_code);
    if(langCode.lang_direction=='rtl'){
        $("html").attr("dir",'rtl');
        $("#main_style").attr("href","/assets/css/main-rtl.css?ver=1.1.0");
    }else{
        $("html").removeAttr("dir");
        $("#main_style").attr("href","/assets/css/main.css?ver=1.2.0");
    }
    this.toast.success("The language selected successfully.", "Success!");
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang)
  }
}

