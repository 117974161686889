import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoreService } from '../shared/services/core.service';
import { HelperService } from '../shared/services/helper-service.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  public lang:any = '';
  public contectForm!: FormGroup;
  public isFormSubmit:boolean = false;
  public isLoading:boolean = false;
  constructor(
    private toast: ToastrService,
    private fb: FormBuilder,
    private coreService: CoreService,
    public helperService: HelperService
  ) { }

  ngOnInit(): void {
    this.contectForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      full_name: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      subject: [''],
      message: ['']
    });
  }

  get get() { return this.contectForm.controls; }

   /**
   * @method: submitContectUs
   * @description: using for to submit Contect form
   * @param formData
   */
  submitContectUs(formData:any) {
    this.isFormSubmit = true;
    if (this.contectForm.valid && !this.isLoading) {
      this.isLoading = true;
      const paylaod = {
        ...formData
      }
      this.coreService.post(`${ENDPOINTS.CONTECTUS}`, paylaod).subscribe((res) => {
        this.isLoading = false;
        this.isFormSubmit = false;
        if(res.body.success){
          this.toast.success(res.body.message, "Success!");
          this.contectForm.reset();
        }else{
          this.toast.error(res.body.message, "Error!");
        }
      }, (err) => {
        this.isLoading = false;
        this.toast.error(err.body.message, "Error!");
      });
    }
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang)
  }
}
