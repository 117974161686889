<app-header></app-header>
<section class="text-center mb-2 pt-4 mt-3 mt-50">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <ul class="list-unstyled bradcam">
                    <li><a (click)="goToNavigation('home');">Home</a></li>
                    <li><a (click)="goToNavigation('loyalty-program');"><i class="fas fa-angle-double-right iconarrw"></i> {{pageObj?.title}}</a></li>
                </ul>
            </div>
        </div>
    </div>
</section>
<section class="mb-5">
    <div class="container">
        <div class="row d-flex align-items-center justify-content-center mt-3">
            <div class="title-heading">
                <h2 class="h1 pb-3">{{pageObj?.title}}</h2>
            </div>
        </div>

        <div class="row d-flex justify-content-center">
             <div class="col-md-1 "></div>
            <div class="col-md-10  ">
                <h2>{{pageObj?.sub_title}}</h2>
				<div [innerHtml]="pageObj.description"></div>
			</div>			
        </div>
    </div>
</section>