<app-headers></app-headers>
<section class="text-center mb-2 settingTabs">
    <app-user-breadcrumb></app-user-breadcrumb>
</section>
<section class="mb-5 profileView">
    <div class="container">
        <div class="row">
            <!--  dashboard-menu-->
            <div class="col-md-5 col-lg-3">
                <app-user-left-menu></app-user-left-menu>
            </div>
            <div class="col-md-7 col-lg-9">
                <div class="rightbox-dashbord">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <h4 class="list-heading">Batch Refill</h4>
                            <h5 class="fontbold mt-1">Perform refills in batch</h5>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <p>Please <a href="/assets/cryptomate-batch-refill-example.xlsx"><i class="fa fa-download"></i> click here</a> to download example file, Please do not do any changes in the excel title, Else your data might not be submitted.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="file-upload-wrapper">
                                <p>Upload a CSV file containing a list of refill details (example.csv)</p>
                                <div class="card card-body view file-upload">
                                    <div class="card-text file-upload-message">
                                        <i class="far fa-file-alt"></i>
                                        <p>
                                            <span class="imgcolorupload">Drag & drop</span> files here or <br />
                                            <span class="imgcolorupload">Browse</span> to begin the upload
                                        </p>
                                    </div>
                                    <input type="file" id="input-file-max-fs" (change)="uploadedFile($event)" class="file_upload" data-max-file-size="2M" accept=".xlsx"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center justify-content-center">
                        <button type="button" [disabled]="isLoading || jsonData.length==0" class="joinnow-btn waves-effect waves-light" (click)="submitFile()"><span *ngIf="isLoading">Please wait...</span><span *ngIf="!isLoading">Send Refills</span></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>