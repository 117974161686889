import { Component, OnInit } from '@angular/core';
import { CoreService } from '../shared/services/core.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelperService } from '../shared/services/helper-service.service';
import { ENDPOINTS } from 'src/app/shared/constants/app.endpoints.constants';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import {ToastrService} from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  public lang:any = '';
  public contectForm!: FormGroup;
  public activeClass:string = ' in show active';
  public faqLists:any = [];
  public imgBaseUrl = environment.AWSImageURL;
  public search:string = '';
  public prevValue:string = '';
  public isLoading:boolean = false;
  public queryParam: any = {};
  public active_tab: any = '';
  public isFormSubmit:boolean = false;
  constructor(
    private fb: FormBuilder,
    private toast: ToastrService,
    private coreService: CoreService,
    public helperService: HelperService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (!params) return;
      this.queryParam = { ...params };
      if (this.queryParam.hasOwnProperty('active')) {
        this.active_tab = this.queryParam.active;
        delete this.queryParam.active;
      }
    });

    this.contectForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      full_name: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      subject: [''],
      message: ['']
    });
    this.getFaqs();
  }

  get get() { return this.contectForm.controls; }

  onKeyDownEvent(event: any){
    if(event.key=='Enter'){
      this.isLoading = true;
      this.filterFaq();
    }
 }

  /**
   * @method: submitContectUs
   * @description: using for to submit Contect form
   * @param formData
   */
   submitContectUs(formData:any) {
    this.isFormSubmit = true;
    if (this.contectForm.valid && !this.isLoading) {
      this.isLoading = true;
      const paylaod = {
        ...formData
      }
      this.coreService.post(`${ENDPOINTS.CONTECTUS}`, paylaod).subscribe((res) => {
        this.isLoading = false;
        this.isFormSubmit = false;
        this.toast.success(res.body.message, "Success!");
        this.contectForm.reset();
      }, (err) => {
        this.isLoading = false;
        this.isFormSubmit = false;
        this.toast.error(err.body.message, "Error!");
      });
    }
  }

  getFaqs(){
    this.isLoading = true;
    this.coreService.get(`${ENDPOINTS.STATIC.FAQ}`+'?search='+this.search, {}).subscribe((res) => {
      if (res.body.success) {
        this.faqLists = res.body.data;
        if(this.faqLists.length>0){
          let that = this;
          setTimeout(function(){
            $(".faqdescr").removeClass("in show active");
            if(that.active_tab==''){
              $("#"+that.faqLists[0].id).addClass("in show active");
            }
            else{
              $("#612757749362f1744614971c").addClass("in show active");
            }
          },500);

          for (var i = 0; i <this.faqLists.length; i++) {
            if(this.faqLists[i].faqs){
              if(this.faqLists[i].faqs.length>0){
                this.faqLists[i].faqs[0].is_active = true;
              }
            }
          }
        }
        this.isLoading = false;
        
      } else {
        this.isLoading = false;
        this.faqLists = [];
      }
    }, (err) => {
      this.isLoading = false;
    });
  }

  filterFaq(){
    this.getFaqs();
  }

  saveValue() {
    this.prevValue = this.search;
  }
  
  processChange() {
    if (this.search !== this.prevValue) {
      this.getFaqs();
    }
  }

  showSection(objId:any){
    $(".faqdescr").removeClass("in show active");
    $("#"+objId).addClass("in show active");
  }

  openSubTab(obj:any,otherObj:any){
    for (var i = 0; i <otherObj.faqs.length; i++) {
      otherObj.faqs[i].is_active = false;
    }
    obj.is_active = !obj.is_active;
  }

  goToNavigation(url:any) {
    this.helperService.navigate(url, this.lang)
  }

}
