export const CRYPTOS = [
    'Bitcoin (BTC)',
    'Ethereum (ETH)',
    'Tether (USDT)',
    'Binance Coin (BNB)',
    'Cardano (ADA)',
    'USD Coin (USDC)',
    'XRP (XRP)',
    'Dogecoin (DOGE)',
    'Polkadot (DOT)',
    'Binance USD (BUSD)',
    'Uniswap (UNI)',
    'Bitcoin Cash (BCH)',
     'Litecoin (LTC)',
    'Solana (SOL)',
     'Chainlink (LINK)',
    'Wrapped Bitcoin (WBTC)',
     'Stellar (XLM)',
     'Polygon (MATIC)',
     'Ethereum Classic (ETC)',
     'Dai (DAI)',
     'Internet Computer (ICP)',
     'THETA (THETA)',
     'VeChain (VET)',
     'Filecoin (FIL)',
     'TRON (TRX)',
     'Monero (XMR)',
     'Aave (AAVE)',
     'EOS (EOS)',
     'Terra (LUNA)',
     'Crypto.com Coin (CRO)',
     'FTX Token (FTT)',
     'PancakeSwap (CAKE)',
     'SHIBA INU (SHIB)',
     'UNUS SED LEO (LEO)',
     'Algorand (ALGO)',
     'Maker (MKR)',
     'Cosmos (ATOM)',
     'Klaytn (KLAY)',
     'Bitcoin BEP2 (BTCB)',
     'Bitcoin SV (BSV)',
     'Tezos (XTZ)',
     'Compound (COMP)',
     'TerraUSD (UST)',
     'Neo (NEO)',
     'Avalanche (AVAX)',
     'IOTA (MIOTA)',
     'Amp (AMP)',
     'The Graph (GRT)',
     'Elrond (EGLD)',
     'Waves (WAVES)',
     'Hedera Hashgraph (HBAR)',
     'Decred (DCR)',
     'Dash (DASH)',
     'Kusama (KSM)',
     'Axie Infinity (AXS)',
     'Theta Fuel (TFUEL)',
     'Huobi Token (HT)',
     'BitTorrent (BTT)',
     'Chiliz (CHZ)',
     'NEM (XEM)',
     'TrueUSD (TUSD)',
     'Celsius (CEL)',
     'Stacks (STX)',
     'Zcash (ZEC)',
     'THORChain (RUNE)',
     'yearn.finance (YFI)',
     'Synthetix (SNX)',
     'Helium (HNT)',
     'Decentraland (MANA)',
     'SushiSwap (SUSHI)',
     'Enjin Coin (ENJ)',
     'Paxos Standard (PAX)',
     'Quant (QNT)',
     'XinFin Network (XDC)',
     'Flow (FLOW)',
     'Holo (HOT)',
     'Nexo (NEXO)',
     'KuCoin Token (KCS)',
     'Basic Attention Token (BAT)',
     'NEAR Protocol (NEAR)',
     'Telcoin (TEL)',
     'OKB (OKB)',
     'Zilliqa (ZIL)',
     'Harmony (ONE)',
     'Bancor (BNT)',
     'Bitcoin Gold (BTG)',
     'Mdex (MDX)',
     'Celo (CELO)',
     'HUSD (HUSD)',
     'Curve DAO Token (CRV)',
     'SwissBorg (CHSB)',
     'Qtum (QTUM)',
     'Horizen (ZEN)',
     'Ontology (ONT)',
     'DigiByte (DGB)',
     '0x (ZRX)',
     'Siacoin (SC)',
     'UMA (UMA)',
     'Ravencoin (RVN)',
     'OMG Network (OMG)',
     'Other'
]
