<footer class="page-footer indigo darken-2 center-on-small-only py-5 pad-btm-0">
  <div class="container pad-btm-50 spaceremove">
    <div class="row">
      <div class="col-md-12 col-lg-4 mb-5">
        <h6>
          <a (click)="goToNavigation('/home')"
            ><img src="/assets/img/logo.svg" class="footer-logo"
          /></a>
        </h6>
        <p>Your Mate to the Crypto Space</p>
        <div class="switch">
          <label
            >Light <input type="checkbox" id="theme-switcher" /><span
              class="lever"
            ></span>
            Dark</label
          >
        </div>
        <h6>Copyright © 2022 All Rights Reserved.</h6>
        <a
          href="https://www.facebook.com/Mates3339"
          target="_blank"
          class="fb-ic mr-3"
          role="button"
          ><i class="fab fa-lg fa-facebook-f"></i
        ></a>
        <a
          href="https://twitter.com/Mates3339"
          target="_blank"
          class="tw-ic mr-3"
          role="button"
          ><i class="fab fa-lg fa-twitter"></i
        ></a>
        <a
          href="https://www.instagram.com/Mates33339"
          target="_blank"
          class="ins-ic mr-3"
          role="button"
          ><i class="fab fa-lg fa-instagram"></i
        ></a>
        <a
          href="https://t.me/Mates3339"
          target="_blank"
          class="ins-ic"
          role="button"
          ><i class="fab fa-lg fa-telegram-plane"></i
        ></a>
      </div>
      <div class="col-6 col-md-3 col-lg-2 mb-5 mobilhe">
        <h5>Company</h5>
        <ul class="list-unstyled mb-0 links">
          <!--<li><a (click)="https://careers.cryptomate.com.cn/pages/about-us" routerLink="/about-us" routerLinkActive="active" class="text-white waves-effect waves-light">About</a></li>-->
          <li>
            <a
              href="https://careers.cryptomate.com.cn/pages/about-us"
              class="text-white waves-effect waves-light"
              target="_blank"
              >About</a
            >
          </li>

          <li>
            <a
              (click)="goToNavigation('terms')"
              routerLink="/terms"
              routerLinkActive="active"
              class="text-white waves-effect waves-light"
              >Terms and Conditions</a
            >
          </li>
          <li>
            <a
              (click)="goToNavigation('privacy-policy')"
              routerLink="/privacy-policy"
              routerLinkActive="active"
              class="text-white waves-effect waves-light"
              >Privacy Policy</a
            >
          </li>
          <!--  <li><a (click)="https://careers.cryptomate.com.cn" routerLink="/career" routerLinkActive="active" class="text-white waves-effect waves-light">Careers</a></li>-->

          <li>
            <a
              href="https://careers.cryptomate.com.cn"
              class="text-white waves-effect waves-light"
              target="_blank"
              >Careers</a
            >
          </li>
          <li>
            <a
              (click)="goToNavigation('refer-a-friend')"
              routerLink="/refer-a-friend"
              routerLinkActive="active"
              class="text-white waves-effect waves-light"
              >Refer A Friend</a
            >
          </li>
        </ul>
      </div>
      <div class="col-6 col-md-3 col-lg-2 mb-5 mobilhe">
        <h5>Live on CryptoMate</h5>
        <ul class="list-unstyled mb-0 links">
          <li>
            <a
              (click)="goToNavigation('games')"
              routerLink="/games"
              routerLinkActive="active"
              class="text-white waves-effect waves-light"
              >Best Sellers</a
            >
          </li>
          <li>
            <a
              (click)="goToNavigation('game-products')"
              routerLink="/game-products"
              routerLinkActive="active"
              class="text-white waves-effect waves-light"
              >Game Products</a
            >
          </li>
          <li>
            <a
              (click)="goToNavigation('shop')"
              routerLink="/shop"
              routerLinkActive="active"
              class="text-white waves-effect waves-light"
              >Electronics</a
            >
          </li>
          <li>
            <a
              (click)="goToNavigation('gift')"
              routerLink="/gift"
              routerLinkActive="active"
              class="text-white waves-effect waves-light"
              >Gift Cards</a
            >
          </li>
          <li>
            <a
              (click)="goToNavigation('topup')"
              routerLink="/topup"
              routerLinkActive="active"
              class="text-white waves-effect waves-light"
              >Phone Top-up</a
            >
          </li>
          <li>
            <a
              (click)="goToNavigation('loyalty-program')"
              routerLink="/loyalty-program"
              routerLinkActive="active"
              class="text-white waves-effect waves-light"
              >Loyalty Program</a
            >
          </li>
          <li>
            <a
              (click)="goToNavigation('cryptomate-balance-card')"
              routerLink="/cryptomate-balance-card-terms"
              routerLinkActive="active"
              class="text-white waves-effect waves-light"
              >Mate Balance Card
            </a>
          </li>
        </ul>
      </div>
      <div class="col-6 col-md-3 col-lg-2 mb-5 mobilhe">
        <h5>Solutions</h5>
        <ul class="list-unstyled mb-0 links">
          <li>
            <a
              (click)="goToNavigation('outsourcing')"
              routerLink="/outsourcing"
              routerLinkActive="active"
              class="text-white waves-effect waves-light"
              >Outsourcing</a
            >
          </li>
          <!--(click)="goToNavigation('wepay');" routerLink="/wepay" routerLinkActive="active"-->
          <li>
            <a href="javascript:;" class="text-white waves-effect waves-light"
              >WePay (Soon)</a
            >
          </li>
          <!--(click)="goToNavigation('otc');" routerLink="/otc" routerLinkActive="active"-->
          <li>
            <a href="javascript:;" class="text-white waves-effect waves-light"
              >OTC (Soon)</a
            >
          </li>
          <li>
            <a href="javascript:;" class="text-white waves-effect waves-light"
              >Academy (Soon)</a
            >
          </li>
        </ul>
      </div>
      <div class="col-6 col-md-3 col-lg-2 mb-5 mobilhe">
        <h5>Help</h5>
        <ul class="list-unstyled mb-0 links">
          <li>
            <a
              href="https://help.cryptomate.com.cn/"
              target="_blank"
              class="text-white waves-effect waves-light"
              target="_blank"
              >FAQ</a
            >
          </li>
          <!--(click)="goToNavigation('faq');" routerLink="/faq" routerLinkActive="active"-->
          <li>
            <a
              (click)="goToNavigation('contact-us')"
              routerLink="/contact-us"
              routerLinkActive="active"
              class="text-white waves-effect waves-light"
              >Contact Us</a
            >
          </li>
          <li>
            <a
              (click)="goToNavigation('product-request')"
              routerLink="/product-request"
              routerLinkActive="active"
              class="text-white waves-effect waves-light"
              target="_blank"
              >Product Request</a
            >
          </li>
          <li>
            <a
              (click)="goToNavigation('gift-designer')"
              routerLink="/gift-designer"
              routerLinkActive="active"
              class="text-white waves-effect waves-light"
              >Gift Card Designer</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div
    class="panel-group mobiledhow"
    id="accordion"
    role="tablist"
    aria-multiselectable="true"
  >
    <div class="panel panel-default">
      <div class="panel-heading" role="tab" id="headingOne">
        <h4 class="panel-title">
          <a
            class="collapsed"
            data-toggle="collapse"
            data-parent="#accordion"
            href="#collapseOne"
            aria-expanded="false"
            aria-controls="collapseOne"
          >
            Company
          </a>
        </h4>
      </div>
      <div
        id="collapseOne"
        class="panel-collapse collapse in"
        role="tabpanel"
        aria-labelledby="headingOne"
      >
        <div class="panel-body">
          <ul class="list-unstyled mb-0 links">
            <!--<li><a (click)="https://careers.cryptomate.com.cn/pages/about-us" routerLink="/about-us" routerLinkActive="active" class="text-white waves-effect waves-light">About</a></li>-->
            <li>
              <a
                href="https://careers.cryptomate.com.cn/pages/about-us"
                class="text-white waves-effect waves-light"
                target="_blank"
                >About</a
              >
            </li>

            <li>
              <a
                (click)="goToNavigation('terms')"
                routerLink="/terms"
                routerLinkActive="active"
                class="text-white waves-effect waves-light"
                >Terms and Conditions</a
              >
            </li>
            <li>
              <a
                (click)="goToNavigation('privacy-policy')"
                routerLink="/privacy-policy"
                routerLinkActive="active"
                class="text-white waves-effect waves-light"
                >Privacy Policy</a
              >
            </li>
            <!--  <li><a (click)="https://careers.cryptomate.com.cn" routerLink="/career" routerLinkActive="active" class="text-white waves-effect waves-light">Careers</a></li>-->

            <li>
              <a
                href="https://careers.cryptomate.com.cn"
                class="text-white waves-effect waves-light"
                target="_blank"
                >Careers</a
              >
            </li>
            <li>
              <a
                (click)="goToNavigation('refer-a-friend')"
                routerLink="/refer-a-friend"
                routerLinkActive="active"
                class="text-white waves-effect waves-light"
                >Refer A Friend</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="panel panel-default">
      <div class="panel-heading" role="tab" id="headingTwo">
        <h4 class="panel-title">
          <a
            class="collapsed"
            data-toggle="collapse"
            data-parent="#accordion"
            href="#collapseTwo"
            aria-expanded="false"
            aria-controls="collapseTwo"
          >
            Live on CryptoMate
          </a>
        </h4>
      </div>
      <div
        id="collapseTwo"
        class="panel-collapse collapse"
        role="tabpanel"
        aria-labelledby="headingTwo"
      >
        <div class="panel-body">
          <ul class="list-unstyled mb-0 links">
            <li>
              <a
                (click)="goToNavigation('games')"
                routerLink="/games"
                routerLinkActive="active"
                class="text-white waves-effect waves-light"
                >Best Sellers</a
              >
            </li>
            <li>
              <a
                (click)="goToNavigation('game-products')"
                routerLink="/game-products"
                routerLinkActive="active"
                class="text-white waves-effect waves-light"
                >Game Products</a
              >
            </li>
            <li>
              <a
                (click)="goToNavigation('shop')"
                routerLink="/shop"
                routerLinkActive="active"
                class="text-white waves-effect waves-light"
                >Electronics</a
              >
            </li>
            <li>
              <a
                (click)="goToNavigation('gift')"
                routerLink="/gift"
                routerLinkActive="active"
                class="text-white waves-effect waves-light"
                >Gift Cards</a
              >
            </li>
            <li>
              <a
                (click)="goToNavigation('topup')"
                routerLink="/topup"
                routerLinkActive="active"
                class="text-white waves-effect waves-light"
                >Mobile Top-up</a
              >
            </li>
            <li>
              <a
                (click)="goToNavigation('loyalty-program')"
                routerLink="/loyalty-program"
                routerLinkActive="active"
                class="text-white waves-effect waves-light"
                >Loyalty Program</a
              >
            </li>
            <li>
              <a
                (click)="goToNavigation('cryptomate-balance-card')"
                routerLink="/cryptomate-balance-card-terms"
                routerLinkActive="active"
                class="text-white waves-effect waves-light"
                >Mate Balance Card</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="panel panel-default">
      <div class="panel-heading" role="tab" id="headingThree">
        <h4 class="panel-title">
          <a
            class="collapsed"
            data-toggle="collapse"
            data-parent="#accordion"
            href="#collapseThree"
            aria-expanded="false"
            aria-controls="collapseThree"
          >
            Solutions
          </a>
        </h4>
      </div>
      <div
        id="collapseThree"
        class="panel-collapse collapse"
        role="tabpanel"
        aria-labelledby="headingThree"
      >
        <div class="panel-body">
          <ul class="list-unstyled mb-0 links">
            <li>
              <a
                (click)="goToNavigation('outsourcing')"
                routerLink="/outsourcing"
                routerLinkActive="active"
                class="text-white waves-effect waves-light"
                >Outsourcing</a
              >
            </li>
            <!--(click)="goToNavigation('wepay'); routerLink="/wepay" routerLinkActive="active" -->
            <li>
              <a href="javascript:;" class="text-white waves-effect waves-light"
                >WePay (Soon)</a
              >
            </li>

            <!--(click)="goToNavigation('otc');"routerLink="/otc" routerLinkActive="active"-->
            <li>
              <a href="javascript:;" class="text-white waves-effect waves-light"
                >OTC (Soon)</a
              >
            </li>
            <li>
              <a href="javascript:;" class="text-white waves-effect waves-light"
                >Academy (Soon)</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="panel panel-default">
      <div class="panel-heading" role="tab" id="headingFour">
        <h4 class="panel-title">
          <a
            class="collapsed"
            data-toggle="collapse"
            data-parent="#accordion"
            href="#collapseFour"
            aria-expanded="false"
            aria-controls="collapseFour"
          >
            Help
          </a>
        </h4>
      </div>
      <div
        id="collapseFour"
        class="panel-collapse collapse"
        role="tabpanel"
        aria-labelledby="headingFour"
      >
        <div class="panel-body">
          <ul class="list-unstyled mb-0 links">
            <li>
              <a
                href="https://help.cryptomate.com.cn/"
                target="_blank"
                class="text-white waves-effect waves-light"
                target="_blank"
                >FAQ</a
              >
            </li>
            <!--(click)="goToNavigation('faq');" routerLink="/faq" routerLinkActive="active"-->
            <li>
              <a
                (click)="goToNavigation('contact-us')"
                routerLink="/contact-us"
                routerLinkActive="active"
                class="text-white waves-effect waves-light"
                >Contact Us</a
              >
            </li>
            <li>
              <a
                (click)="goToNavigation('product-request')"
                routerLink="/product-request"
                routerLinkActive="active"
                class="text-white waves-effect waves-light"
                target="_blank"
                >Product Request</a
              >
            </li>
            <li>
              <a
                (click)="goToNavigation('gift-designer')"
                routerLink="/gift-designer"
                routerLinkActive="active"
                class="text-white waves-effect waves-light"
                >Gift Card Designer</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!--<div class="footer-copyright text-center py-3 font-small">
        <div class="container-fluid">
            <div>
                <a *ngFor="let langRow of langList;" (click)="setCurrentLang(langRow);">
                    <span>{{langRow.language_name}}</span>
                </a>                
            </div>
        </div>
    </div>-->
</footer>
