<app-header></app-header>
<div *ngIf="isLoading" id="loader"></div>
<section class="mb-2 pt-4 mt-3 mt-50" *ngIf="!isLoading">
    <div class="container">
        <div class="row d-flex justify-content-center">
            <div class="col-md-8">
                <div class="row">
                    <div class="col-md-12 col-lg-12 text-center thanksbx">
                        <div class="centeboxcheck">
                            <img *ngIf="orderDetailObj.payment_status=='pending'" src="/assets/img/pending.svg" alt="Thank you !" />
                            <img *ngIf="orderDetailObj.payment_status=='canceled'" src="/assets/img/cancel.svg" alt="Thank you !" />
                            <img *ngIf="orderDetailObj.payment_status!='pending' && orderDetailObj.payment_status!='canceled'" src="/assets/img/success.svg" alt="Thank you !" />
                        </div>
                        <span *ngIf="orderDetailObj.payment_status=='pending'">
                            <h1 class="fontbold">Pending Confirmation!</h1>
                            <p class="mb-0">Awaiting the transaction to be confirmed.</p>
                            <p class="">Once confirmed your order will be processed within seconds</p>
                        </span>
                        <span *ngIf="orderDetailObj.payment_status=='canceled'">
                            <h1 class="fontbold">Failed!</h1>
                            <p class="mb-0">This transaction has timed out and therefore expired.</p>
                            <p class="">Please pay on time in your next purchase</p>
                            <div class="helpCard">
                                “If you believe that the transaction was confirmed late,<br>
                                    please write us an email at help@cryptomate.com.cn”
                            </div>
                        </span>
                        <span *ngIf="orderDetailObj.payment_status!='pending' && orderDetailObj.payment_status!='canceled'">
                            <h1 class="fontbold">Thank you !</h1>
                            <p class="mb-0">Thank you for your purchase</p>
                            <p class="">you should receive a confirmation email soon</p>
                            <a href="{{invoiceBaseUrl}}/{{orderid}}/user" class="contiunebtn waves-effect waves-light flat-left mr-3">Print Order</a>
                        </span>
                        <a (click)="showOrderView()" class="contiunebtn waves-effect waves-light flat-left" style="margin-right: 10px;"><i class="fa fa-refresh"></i> Refresh</a>
                    </div>
                    <div class="col-md-12 col-lg-12 text-center" *ngIf="orderDetailObj.payment_status=='pending' && (orderDetailObj.payment_method=='nowpayment' || orderDetailObj.payment_method=='binancepay')">
                        
                        <div class="barcodepage" style="float: left; width: 100%;">
                            <div class="row" *ngIf="orderDetailObj.payment_method=='binancepay'">
                                <div class="col-sm-3">
                                    <img src="{{orderDetailObj.qrcodeLink}}" />
                                </div>
                                <div class="col-sm-9">
                                    <div class="cal-ne text-left">
                                        <h2>Open the Binance app and scan QR Code</h2>
                                        <p>
                                            This Payment will <span>expire in <i class="fa fa-time"></i>{{counter | formatTime}}</span>
                                        </p>
                                        <span class="newspan">Payment Amount <i>{{(orderDetailObj.total_amount+orderDetailObj.discount_amount)-orderDetailObj.dedcut_from_wallet | currency}}</i></span>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="orderDetailObj.payment_method=='nowpayment'">
                                <div class="col-sm-12">
                                    <div class="cal-ne text-left">
                                       <div class="graybg clearfix"> 
                                            <label for="text" class="mb-0">Pay Address</label>
                                            <div class="md-form searchbox coplinkbtn mt-0">
                                                <textarea class="form-control PayInput1" type="text" name="name" disabled [(ngModel)]="orderDetailObj.pay_address"></textarea>
                                                <button type="button" (click)="copyURL(orderDetailObj.pay_address)" class="applypromo-btn waves-effect waves-light"><i class="fas fa-copy"></i></button>
                                                <span class="qrcodePayment" id="show-hidden-menu" (click)="showQRCode('hidden-menu')">
                                                    <ngx-qrcode
                                                    [elementType]="elementType"
                                                    [errorCorrectionLevel]="correctionLevel"
                                                    [value]="orderDetailObj.pay_address"
                                                    cssClass="bshadow"></ngx-qrcode>
                                                </span>
                                                
                                                <div class="hidden-menu" style="display: none;">
                                                    <ngx-qrcode
                                                    [elementType]="elementType"
                                                    [errorCorrectionLevel]="correctionLevel"
                                                    [value]="orderDetailObj.pay_address"
                                                    cssClass="bshadow"></ngx-qrcode>
                                               </div>
                                                
                                            </div>
                                        </div>
                                        <div class="graybg clearfix" *ngIf="orderDetailObj.pay_extra_id">
                                            <label for="text" class="mb-0">Tag</label>
                                            <div class="md-form coplinkbtn mt-0">
                                                <!--<input class="form-control" type="text" name="name" disabled [(ngModel)]="orderDetailObj.pay_extra_id" />-->
                                                <textarea class="form-control PayInput1" type="text" name="name" disabled [(ngModel)]="orderDetailObj.pay_extra_id" ></textarea>
                                                <button type="button" (click)="copyURL(orderDetailObj.pay_extra_id)" class="applypromo-btn waves-effect waves-light"><i class="fas fa-copy"></i></button>
                                                <span class="qrcodePayment thansk" id="tagshowqr" (click)="showQRCode('tagShowQR')">
                                                    <ngx-qrcode
                                                    [elementType]="elementType"
                                                    [errorCorrectionLevel]="correctionLevel"
                                                    [value]="orderDetailObj.pay_extra_id"
                                                    cssClass="bshadow"></ngx-qrcode>
                                                </span>
                                                
                                                <div class="tagShowQR" style="display: none;">
                                                    <ngx-qrcode
                                                    [elementType]="elementType"
                                                    [errorCorrectionLevel]="correctionLevel"
                                                    [value]="orderDetailObj.pay_extra_id"
                                                    cssClass="bshadow"></ngx-qrcode>
                                                </div>                                                
                                            </div>                                             
                                        </div>
                                        <div class="info-view mb-2" *ngIf="orderDetailObj.pay_extra_id && orderDetailObj.pay_address">
                                            <span><i class="fa fa-info-circle" aria-hidden="true"></i></span> Both a wallet address and a destination tag/memo are required to make the payment successfully
                                        </div>
                                        <div class="info-view mb-3" *ngIf="!orderDetailObj.pay_extra_id && orderDetailObj.pay_address">
                                            <span><i class="fa fa-info-circle" aria-hidden="true"></i></span> Please make your payment to the above address. 
                                        </div> 
                                        <p>This Payment will <span>expire in <svg width="20" height="20" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                            viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve" >
                                        <g>
                                            <path d="M68.2,19.7c0.8-1.2,1.6-2.3,2.4-3.3c0.2-0.3,0.7-0.7,1-0.6c0.3,0,0.7,0.5,0.8,0.9c1,3.3,1.9,6.7,2.8,10.1
                                                c0.3,1.2-0.4,2-1.8,2.2c-2.8,0.3-5.7,0.5-8.5,0.8c-0.5,0-1,0.2-1.5,0.1c-0.4,0-0.9-0.2-1.1-0.4c-0.2-0.2,0-0.8,0.2-1.1
                                                c0.7-1.1,1.5-2.2,2.4-3.6c-2.3-1.1-4.4-2.3-6.7-3.2c-18-6.6-37.6,3.8-42.1,22.4c-4.2,17.3,6.9,35,24.4,38.7
                                                c0.4,0.1,0.9,0.2,1.3,0.3c1.6,0.5,2.5,2,2.1,3.6c-0.4,1.6-1.9,2.6-3.5,2.3c-5.6-1.1-10.9-3.2-15.5-6.6C15.2,75.1,9.9,65.6,9.1,53.8
                                                c-0.7-10.6,2.6-20,9.7-27.9c7.7-8.5,17.4-12.6,28.8-12.6c7,0,13.4,2,19.4,5.7C67.4,19.2,67.7,19.4,68.2,19.7z"/>
                                            <path d="M42.7,41c0-3.8,0-7.5,0-11.3c0-0.7,0.2-1.6,0.6-2.2c0.5-0.8,1.5-1,2.4-0.8c1,0.3,1.6,1,1.8,2c0.1,0.5,0.1,0.9,0.1,1.4
                                                c0,6.5,0,12.9,0,19.4c0,0.9,0.3,1.4,1.1,1.8c6,3.1,12,6.2,17.9,9.3c0.3,0.1,0.6,0.3,0.8,0.4c1.2,0.8,1.6,2.1,1.1,3.3
                                                c-0.6,1.2-2,1.7-3.3,1.1c-2.3-1.1-4.6-2.3-6.9-3.5c-4.9-2.5-9.8-5.1-14.7-7.6c-0.7-0.4-1-0.8-1-1.6C42.7,48.8,42.7,44.9,42.7,41z"
                                                />
                                            <path d="M85.1,51.2c0,0.2,0,0.4,0,0.6c0,1.9-1.2,3.3-2.9,3.4c-1.8,0.1-3.1-1.3-3.2-3.3c0-0.6,0-1.3,0-1.9c0.1-1.8,1.4-3.1,3.1-3.1
                                                c1.6,0,2.9,1.3,3,3.1C85.1,50.4,85,50.8,85.1,51.2C85,51.2,85.1,51.2,85.1,51.2z"/>
                                            <path d="M55,82.2c1.8,0,3.1,1,3.4,2.4c0.3,1.5-0.4,3-1.8,3.5c-1,0.3-2,0.6-3,0.7c-1.4,0.2-2.8-0.9-3.2-2.3
                                                c-0.3-1.4,0.3-2.9,1.7-3.4C53.1,82.6,54.3,82.4,55,82.2z"/>
                                            <path d="M79.5,35.3c1.4,0,2.4,0.8,3,2.4c0.7,1.7,1.4,3.4-0.3,4.9c-1.4,1.3-3.7,1-4.6-0.7c-0.5-0.9-0.9-2-1.1-3
                                                C76.1,37,77.6,35.4,79.5,35.3z"/>
                                            <path d="M83.4,62.4c-0.3,0.8-0.7,2.1-1.4,3.3c-0.6,1.1-2.2,1.4-3.4,1c-1.2-0.4-2.2-1.6-2-2.8c0.2-1.4,0.7-2.8,1.4-4
                                                c0.6-1.1,2.1-1.4,3.3-1C82.6,59.3,83.4,60.5,83.4,62.4z"/>
                                            <path d="M69.4,80.5c0.5,2.2-4.4,5.1-6.3,3.9c-1.8-1.1-2.2-3.6-0.5-5c0.8-0.6,1.7-1.2,2.6-1.6C67.1,76.8,69.3,78.3,69.4,80.5z"/>
                                            <path d="M74.7,69.3c2.5,0,4.1,2.3,3,4.3c-0.6,1-1.4,1.9-2.2,2.8c-0.9,0.9-2.5,0.8-3.5,0.1c-1.1-0.8-1.8-2.3-1.1-3.4
                                                c0.7-1.2,1.7-2.3,2.7-3.4C73.8,69.4,74.4,69.4,74.7,69.3z"/>
                                        </g>
                                        </svg> {{counter | formatTime}}</span></p>
                                        <span class="newspan" *ngIf="!orderDetailObj.crypto_exchange_rate">Payment Amount <i>{{(orderDetailObj.total_amount+orderDetailObj.discount_amount)-orderDetailObj.dedcut_from_wallet | currency}}</i></span>
                                        <span class="newspan" *ngIf="orderDetailObj.crypto_exchange_rate">Payment Amount <i><img style="width: 30px;" src="{{imgBaseUrl}}crypto/{{orderDetailObj.payment_currency}}.png" /> {{orderDetailObj.crypto_exchange_rate}} {{orderDetailObj.payment_currency}}</i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                    <div *ngIf="!isLoading" style="width: 100%; margin-top: 15px;">
                        <div class="col-md-12 col-lg-12" *ngIf="orderDetailObj.products.toupup.length>0">
                            <div class="checkoutleft-box mt-3">
                                <div *ngFor="let product of orderDetailObj.products.toupup;" class="c-boxlist gift-ordercople">
                                    <div class="row">
                                        <div class="col-4 col-sm-2 col-lg-2">
                                            <img src="{{imgBaseUrl}}operator/{{product.topup_info.country.iso_code}}/{{product.topup_info.name}}.png" alt="" class="img-fluid z-depth-0" />
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-6 pd-0">
                                            <h5>Top-up Delivered</h5>
                                            <p class="mb-0">{{product.topup_product_info.name}}</p>
                                            <p><small>The Top-up delivery has been confirmed by the operator.</small></p>
                                        </div>
                                        <div class="col-12 col-sm-4 col-lg-4 mb-2">
                                            <!-- <h6>0.00012890 BTC</h6> -->
                                            <h6>{{(product.topup_product_info.destination.amount*product.quantity) | currency:product.topup_product_info.destination.unit}}</h6>
                                            <span class="idcompple-rder">{{product.mobile_number}}</span>
                                            <span class="pull-right orderpack statusView">
                                                <p class=" text-muted orderpack {{product.delivery_status}}">{{showOrderStatus(product.delivery_status)}}</p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 col-lg-12" *ngIf="orderDetailObj.products.game_products.length>0">
                            <div class="checkoutleft-box mt-3">
                                <div *ngFor="let gameObj of orderDetailObj.products.game_products;" class="c-boxlist gift-ordercople">
                                    <div class="row">
                                        <div class="col-4 col-sm-2 col-lg-2">
                                            <img src="{{gameObj.game_product_info.coverImageOriginal}}" alt="{{gameObj.game_product_info.name}}" class="img-fluid z-depth-0" />
                                        </div>
                                        <div class="col-12 col-sm-8 col-lg-8 pd-0">
                                            <h5>{{gameObj.game_product_info.name}}</h5>
                                            <p class=""><small [innerHtml]="(gameObj.game_product_info.description.length>100)? (gameObj.game_product_info.description | slice:0:100)+'..':(gameObj.game_product_info.description)"></small></p>
                                        </div>
                                        <div class="col-12 col-sm-2 col-lg-2 mb-2">
                                            <h6>{{(gameObj.price*gameObj.quantity) | currency}}</h6>
                                            <span class="pull-right orderpack statusView">
                                                <p class=" text-muted orderpack {{gameObj.delivery_status}}">{{showOrderStatus(gameObj.delivery_status)}}</p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 col-lg-12" *ngIf="orderDetailObj.products.gift_card.length>0">
                            <div class="checkoutleft-box mt-3">
                                <div *ngFor="let product of orderDetailObj.products.gift_card;" class="c-boxlist gift-ordercople">
                                    <div class="row" *ngIf="product.cart_item=='gift_card'">
                                        <div class="col-3 col-sm-2 col-lg-2">
                                            <img src="{{product.gift_card_info.imageUrl}}" alt="" class="img-fluid z-depth-0" />
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-6 pd-0">
                                            <h5>{{product.gift_card_info.name}}</h5>
                                            <p class="text-muted mb-0" [innerHtml]="product.gift_card_info.categories"></p>
                                            <p class=""><small [innerHtml]="(product.gift_card_info.description.length>100)? (product.gift_card_info.description | slice:0:100)+'..':(product.gift_card_info.description)"></small></p>
                                        </div>
                                        <div class="col-12 col-sm-4 col-lg-4">
                                            <!-- <h6>0.00012890 BTC</h6> -->
                                            <span class="idcompple-rder" *ngIf="product.gift_card_info.currencyCode!='USD'">
                                                {{(product.denomination*product.quantity) | currency:product.gift_card_info.currencyCode}}
                                            </span>
                                            <span class="idcompple-rder" *ngIf="product.gift_card_info.currencyCode=='USD'">
                                                {{product.total_price | currency}}
                                            </span>
                                            <a (click)="goToNavigation('gift-designer');" class="waves-effect waves-light giftcard-comple">Design Gift Card</a>
                                            <span class="pull-right orderpack statusView">
                                                <p *ngIf="!product.xoxo_response_object" class=" text-muted orderpack {{product.delivery_status}}">{{showOrderStatus(product.delivery_status)}}</p>
                                                <p *ngIf="product.xoxo_response_object" class=" text-muted orderpack {{product.xoxo_response_object.deliveryStatus}}">{{showOrderStatus(product.xoxo_response_object.deliveryStatus)}}</p>
                                            </span>
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="product.xoxo_voucher_codes">
                                            <div class="box-grey" *ngFor="let voucher of product.xoxo_voucher_codes;">
                                               <span (click)="copyURL(voucher.voucherCode)" style="cursor: pointer;">{{voucher.voucherCode}} <i class="fas fa-copy"></i></span>
                                               <span *ngIf="voucher.pin!=''" (click)="copyURL(voucher.pin)" style="cursor: pointer;"><br>Pin : {{voucher.pin}} <i class="fas fa-copy"></i></span>
                                               <span *ngIf="voucher.validity!=''" style="cursor: pointer;"><br>Validity : {{voucher.validity}}</span>
                                               <!-- <span *ngIf="voucher.is_redeemed==1">Redeemed</span> -->
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="product.cart_item=='balance_card'">
                                        <div class="col-3 col-sm-2 col-lg-2">
                                            <img src="{{imgBaseUrl}}giftcard/{{product.balance_card_id.card_logo}}" alt="" class="img-fluid z-depth-0" />
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-6 pd-0">
                                            <h5>{{product.balance_card_id.card_name}}</h5>
                                            <p class="text-muted mb-0" [innerHtml]="(product.balance_card_id.card_about.length>150)? (product.balance_card_id.card_about | slice:0:150)+'..':(product.balance_card_id.card_about)"></p>
                                        </div>
                                        <div class="col-12 col-sm-4 col-lg-4">
                                            <!-- <h6>0.00012890 BTC</h6> -->
                                            <span class="idcompple-rder">{{product.total_price | currency}}</span>
                                            <a (click)="goToNavigation('gift-designer');" class="waves-effect waves-light giftcard-comple">Design Gift Card</a>
                                            <span class="pull-right orderpack statusView">
                                                <p class=" text-muted orderpack {{product.delivery_status}}">{{showOrderStatus(product.delivery_status)}}</p>
                                            </span>
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="product.voucher_record">
                                            <div class="box-grey" *ngFor="let voucher of product.voucher_record;">
                                               <span *ngIf="voucher.is_redeemed==0" (click)="copyURL(voucher.voucher_code)" style="cursor: pointer;">{{voucher.voucher_code}} <i class="fas fa-copy"></i></span>
                                               <span *ngIf="voucher.is_redeemed==1">Redeemed</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 col-lg-12" *ngIf="orderDetailObj.products.ecommerce.length>0">
                            <div class="checkoutleft-box mt-3">
                                <div *ngFor="let product of orderDetailObj.products.ecommerce;" class="c-boxlist">
                                    <div class="row">
                                        <div class="col-2 col-sm-2 col-lg-2">
                                            <img src="{{imgBaseUrl}}products/{{product.product_id.product_image}}" alt="{{(product.product_name)?product.product_name:product.product_id.product_name}}" class="img-fluid z-depth-0" />
                                        </div>
                                        <div class="col-5 col-sm-6 col-lg-6 pd-0">
                                            <h5>{{(product.product_name)?product.product_name:product.product_id.product_name}}</h5>
                                            <p class="text-muted">{{product.total_price | currency}}</p>
                                        </div>
                                        <div class="col-5 col-sm-4 col-lg-4">
                                            <!-- <h6>0.00012890 BTC</h6> -->
                                            <span class="qtyvision">Qty: {{product.quantity}}</span>
                                            <span class="pull-right orderpack statusView">
                                                <p class=" text-muted orderpack {{product.delivery_status}}">{{showOrderStatus(product.delivery_status)}}</p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-md-6 col-lg-6">
                            <div class="checkoutleft-box-1">
                                <div class="c-boxlist">
                                    <div class="row">
                                        <div class="col-7 col-sm-8 col-lg-8">
                                            <h5>Invoice id:</h5>
                                            <p class="text-muted">{{orderDetailObj.order_number}}</p>
                                        </div>
                                        <div class="col-5 col-sm-4 col-lg-4">
                                            <h6>{{orderDetailObj.createdAt | date}}</h6>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="col-6 col-sm-6 col-lg-6">
                                            <h5>Order Status</h5>
                                            <p class="text-muted orderpack  {{orderDetailObj.order_status}}">{{showOrderStatus(orderDetailObj.order_status)}}</p>
                                        </div>
                                        <div class="col-6 col-sm-6 col-lg-6 text-right">
                                            <h5 class="mydel">Payment Status</h5>
                                            <p class="text-muted orderpack {{orderDetailObj.order_status}}">{{orderDetailObj.payment_status}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-md-6 col-lg-6">
                            <div class="save-adress" *ngIf="orderDetailObj.address!='' && orderDetailObj.address_type!=''">
                                <label class="for-checkbox-tools waves-effect waves-light" for="tool-1">
                                    <span class="default-address">Shipping Address</span>
                                    <h5 class="float-left mt-1 w-100 block">{{orderDetailObj.address_type}}</h5>
                                    <p class="float-left">
                                        <i class="fas fa-map-marker-alt"></i>
                                        <span class="addsmall">{{orderDetailObj.address}}, {{orderDetailObj.zipcode}}, {{orderDetailObj.city}}, {{orderDetailObj.state}} ({{orderDetailObj.country}})</span>
                                    </p>
                                    <p class="float-left">
                                        <i class="fas fa-phone-square-alt"></i>
                                        <span class="addsmall"> {{orderDetailObj.phone_number}}</span>
                                    </p>
                                </label>
                            </div>
                            <div class="table-responsive text-nowrap mytabless">
                                <table class="table table-striped">
                                    <tbody>
                                        <tr>
                                            <th colspan="5" class="text-right">Sub Total</th>
                                            <td class="text-center">{{orderDetailObj.product_price | currency}}</td>
                                        </tr>
                                        <tr *ngIf="orderDetailObj.shipping_amount>0">
                                            <th colspan="5" class="text-right">Shipping Price</th>
                                            <td class="text-center">{{orderDetailObj.shipping_amount | currency}}</td>
                                        </tr>
                                        <tr *ngIf="orderDetailObj.discount_amount>0">
                                            <th colspan="5" class="text-right">Discount (-)</th>
                                            <td class="text-center">-{{orderDetailObj.discount_amount | currency}}</td>
                                        </tr>
                                        <tr *ngIf="orderDetailObj.dedcut_from_wallet>0">
                                            <th colspan="5" class="text-right">Dedcut from Wallet (-)</th>
                                            <td class="text-center">{{orderDetailObj.dedcut_from_wallet | currency}}</td>
                                        </tr>
                                        <!-- <tr *ngIf="orderDetailObj.dedcut_from_wallet>0">
                                            <th colspan="5" class="text-right">Balance Payable</th>
                                            <td class="text-center">{{(orderDetailObj.total_amount+orderDetailObj.discount_amount)-orderDetailObj.dedcut_from_wallet | currency}}</td>
                                        </tr> -->
                                        <tr>
                                            <th colspan="5" class="text-right" style="font-weight: bold;">Total</th>
                                            <td class="text-center" style="font-weight: bold;">{{orderDetailObj.total_amount | currency}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-12 text-center">
                        <div class="box-greyline">
                            <h2 class="fontbold">Help Us get better</h2>
                            <p class="mb-4">Tell us about how you use bitcoin so that we can serve you better in the future!</p>
                            <a href="https://www.trustpilot.com/review/cryptomate.com.cn" target="_blank" class="waves-effect waves-light purchase-btn-thanksyou">Leave Review</a>

                            <a *ngIf="isLoggedIn" class="ordernow" (click)="goToNavigation('user/my-products');" routerLink="/user/my-products" routerLinkActive="active">My orders</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>