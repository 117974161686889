export const VOUCHER_CATEGORY = [
    {
        "filterValue": "Automobiles &amp; Accessories",
        "isoCode": "",
        "filterValueCode": "automobiles_accessories"
    },
    {
        "filterValue": "Baby &amp; Kids",
        "isoCode": "",
        "filterValueCode": "baby_kids"
    },
    {
        "filterValue": "Cash &amp; Prepaid Card",
        "isoCode": "",
        "filterValueCode": "cash_prepaid_card"
    },
    {
        "filterValue": "Charity &amp; Donations",
        "isoCode": "",
        "filterValueCode": "charity_donations"
    },
    {
        "filterValue": "Deals &amp; Subscriptions",
        "isoCode": "",
        "filterValueCode": "deals_subscriptions"
    },
    {
        "filterValue": "Electronics",
        "isoCode": "",
        "filterValueCode": "electronics"
    },
    {
        "filterValue": "Fashion &amp; Lifestyle",
        "isoCode": "",
        "filterValueCode": "fashion_lifestyle"
    },
    {
        "filterValue": "Food &amp; Restaurant",
        "isoCode": "",
        "filterValueCode": "food_restaurant"
    },
    {
        "filterValue": "Gold &amp; Jewelry",
        "isoCode": "",
        "filterValueCode": "gold_jewelry"
    },
    {
        "filterValue": "Grocery",
        "isoCode": "",
        "filterValueCode": "grocery"
    },
    {
        "filterValue": "Home &amp; Living",
        "isoCode": "",
        "filterValueCode": "home_living"
    },
    {
        "filterValue": "In-house benefits",
        "isoCode": "",
        "filterValueCode": "in_house_benefits"
    },
    {
        "filterValue": "Merchandise",
        "isoCode": "",
        "filterValueCode": "merchandise"
    },
    {
        "filterValue": "Mobile Recharge",
        "isoCode": "",
        "filterValueCode": "mobile_recharge"
    },
    {
        "filterValue": "Reading &amp; Learning",
        "isoCode": "",
        "filterValueCode": "reading_learning"
    },
    {
        "filterValue": "Remote Work",
        "isoCode": "",
        "filterValueCode": "remote_work"
    },
    {
        "filterValue": "Subscriptions &amp; Software",
        "isoCode": "",
        "filterValueCode": "subscriptions_software"
    },
    {
        "filterValue": "Travel &amp; Entertainment",
        "isoCode": "",
        "filterValueCode": "travel_entertainment"
    },
    {
        "filterValue": "Wellness &amp; Sports",
        "isoCode": "",
        "filterValueCode": "wellness_sports"
    }
];