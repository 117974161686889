<!-------============= GIFT PURCHASE MODEL =================----------->
<div class="modal fade" id="giftpurchae" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <!--Content-->
        <div class="modal-content">
            <!--Body-->
            <div class="modal-body model-pad0 mb-0 text-center">
                <div class="wrap-login100">
                    <div class="for-login100-pic">
                        <img src="/assets/img/CPM-logo1.png" alt="IMG" />
                    </div>
                    <form class="login100-form">
                        <button type="button" class="btn-close float-right" data-dismiss="modal" aria-label="Close"></button>
                        <div class="row heading-purchase">
                            <div class="col-md-12">
                                <h3 class="text-left purchase mb-3"><b>Purchase Limit Exceeded!</b></h3>
                                <p class="purchase-p">Kindly verify your identity to continue.</p>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center">
                            <div class="col-md-12 text-center">
                                <a class="contiunebtn waves-effect waves-light" data-toggle="modal" data-dismiss="modal" (click)="goToNavigation('user/verify')"> Continue</a>
                                <a class="joinnow-btn waves-effect waves-light" data-dismiss="modal" aria-label="Close"> Go Back</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>

<!-------============= GIFT VOUCHER MODEL =================----------->
<div class="modal fade" id="giftvoucher" tabindex="-1">
    <div class="modal-dialog modal-lg" role="document">
        <!--Content-->
        <div class="modal-content">
            <!--Body-->
            <div class="modal-body model-pad0 mb-0 text-center">
                <div class="wrap-login100">
                    <div class="for-login100-pic">
                        <img src="/assets/img/CPM-logo1.png" alt="IMG" />
                    </div>
                    <form class="login100-form">
                        <button type="button" class="btn-close float-right" data-dismiss="modal" aria-label="Close"></button>
                        <div class="row heading-purchase">
                            <div class="col-md-12">
                                <h3 class="text-left purchase-credit mb-3"><b>Do you want to Buy/Credit in your wallet or create gift voucher.</b></h3>
                                <p class="purchase-p">Kindly verify your identity to continue.</p>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center">
                            <div class="col-md-12 text-center">
                                <a (click)="goToNavigation('user/verify')" class="contiunebtn waves-effect waves-light"> Credit</a>
                                <a (click)="goToNavigation('user/verify')" class="joinnow-btn waves-effect waves-light"> Gift Voucher</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>