import { Component } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import {ToastrService} from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Cryptomate frontend';

  public lang:any = '';
  public isLoggedIn:boolean = false;
  showSpinner: boolean = false;
  constructor(
    public translate: TranslateService,
    private toast: ToastrService,
    private _auth : AuthService
  ) { }
    
  ngOnInit(): void {
    let langCode:any = (localStorage.getItem("lang_code")!='' && localStorage.getItem("lang_code")!=null)?localStorage.getItem("lang_code"):'en';
    this.translate.use(langCode);    
    let lang_direction = (localStorage.getItem("lang_direction")!='' && localStorage.getItem("lang_direction")!=null)?localStorage.getItem("lang_direction"):'ltr';
    if(lang_direction=='rtl'){
        $("html").attr("dir",'rtl');
        $("#main_style").attr("href","/assets/css/main-rtl.css?ver=1.1.0");
    }else{
        $("html").removeAttr("dir");
        $("#main_style").attr("href","/assets/css/main.css?ver=1.2.0");
    }
    if(this._auth.loggedIn()){
      this.isLoggedIn = true;
    }else{
      this.isLoggedIn = false;
    }
  }
}
