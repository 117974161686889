import { Settings } from './../constants/app.setting.constants';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
/*Rx Js*/
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
/*Constant*/
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CoreService {
  public setting: any = Settings
  public CONFIG: any = this.setting.AwsSetting
  public language:any ='';
  public token: any = '';;

  constructor(
    private http: HttpClient,
    private router: Router
  ) { 

    this.language = localStorage.getItem('DefaultLangSet') != null ? localStorage.getItem('DefaultLangSet') : 'en';
    this.token = localStorage.getItem('token') != null ? localStorage.getItem('token') : '';
  }

  randomString() {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for ( var i = 0; i < 26; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    let session_token:any = localStorage.getItem('session_token') != null ? localStorage.getItem('session_token') : result;
    localStorage.setItem('session_token', session_token);
    return localStorage.getItem('session_token');
  }

  /**
  * @method - get
  * @param url - url of API you want to call
  * @param param - optional value if required for GET request
  * @param responseType - optional value if user want response in other format
  * @desc - method for call HTTP GET request
  */
  get(url: any, param: any): Observable<any> {
    this.language = localStorage.getItem('DefaultLangSet') != null ? localStorage.getItem('DefaultLangSet') : 'en';
    this.token = localStorage.getItem('token') != null ? localStorage.getItem('token') : '';
    param.session_id = this.randomString();
    let isIE = /msie\s|trident\//i.test(window.navigator.userAgent);
    let random = isIE ? `?${Math.random().toString(36).substring(2, 15)}` : '';
    let urls = environment.API_ENDPOINT + url;
    if (url.includes("extreme")) {
      urls = url
    }
    var httpOptions  = {}
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'lang_code': this.language,
        'Authorization': this.token
      }),
      params: this.setParam(param),
      observe: 'response'
    }
    return this.http.get<any>(urls + random, httpOptions)
      .pipe(
        tap()
      );
  }

  /**
  * @method - post
  * @param url - url of API you want to call
  * @param param - required for POST request
  * @param responseType - optional value if user want response in other format
  * @desc - method for call HTTP POST request
  */
  post(url: any, param: any): Observable<any> {
    this.language = localStorage.getItem('DefaultLangSet') != null ? localStorage.getItem('DefaultLangSet') : 'en';
    this.token = localStorage.getItem('token') != null ? localStorage.getItem('token') : '';
    var httpOptions  = {}
    param.session_id = this.randomString();
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'lang_code': this.language,
        'Authorization': this.token
      }),
      observe: 'response'
    }
    return this.http.post<any>(environment.API_ENDPOINT + url, param, httpOptions).pipe(
      tap()
    );
  }

  postGeo(url: any, param: any, responseType: any): Observable<any> {
    return this.http.post<any>(url, param, { observe: 'response' }).pipe(
      tap()
    );
  }

  /**
  * @method - put
  * @param url - url of API you want to call
  * @param param - required for POST request
  * @param responseType - optional value if user want response in other format
  * @desc - method for call HTTP PUT request
  */
  put(url: any, param: any, responseType: any): Observable<any> {
    return this.http.put<any>(environment.API_ENDPOINT + url, param, { observe: 'response' }).pipe(
      tap()
    );
  }

  /**
 * @method - setParam
 * @param - param- data for set HTTP Param
 * @desc - method for set param dynemically
 */
  setParam(params: any) {
    let httpParams = new HttpParams();
    if (params) {
      for (let param in params) {
        httpParams = httpParams.append(param, params[param]);
      }
    }
    return httpParams;
  }

  /**
   * @method: setLocalStorage
   * @desc: Generic method to set data in local storage
   * @param key
   * @param data
   */
  setLocalStorage(key:any, data:any) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  /**
   * @method: getLocalstorage
   * @desc: Generic method to get data in local storage
   * @param key
   * @param data
   */
  getLocalstorage(key:any) {
    return localStorage.getItem(key);
  }

  /**
  * @method - getApi
  * @param url - url of API you want to call
  * @desc - method for call HTTP DELETE request
  */
  deleteApi(url: any, payload:any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: payload,
    };
    return this.http.delete(environment.API_ENDPOINT + url, options)
  }

  /**
  * @method - delete
  * @param url - url of API you want to call
  * @desc - method for call HTTP DELETE request
  */
  delete(url: any) {
    return this.http.delete(environment.API_ENDPOINT + url);
  }
  
  /**
  * @method - post
  * @desc - method for call HTTP POST request
  */
   getCryptonewsApi(): Observable<any> {
    var httpOptions  = {}
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      }),
      observe: 'response'
    }
    return this.http.get<any>("https://cryptonews-api.com/api/v1?tickers=BTC,ETH,XRP&items=10&token="+environment.cryptoNewsToken, httpOptions).pipe(
      tap()
    );
  }

  /**
  * @method - post
  * @desc - method for call HTTP POST request
  */
   getExchangeRates(baseCurrency:any): Observable<any> {
    var httpOptions  = {}
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      }),
      observe: 'response'
    }
    return this.http.get<any>("https://api.coinbase.com/v2/exchange-rates?currency="+baseCurrency, httpOptions).pipe(
      tap()
    );
  }

  getCurrentLocation(): Observable<any> {
    var httpOptions  = {}
    httpOptions = {
      jsonpCallback: "callback",
      dataType: "jsonp",
      observe: 'response'
    }
    return this.http.get<any>("https://geolocation-db.com/json/", httpOptions).pipe(
      tap()
    );
  }
}
